import React from 'react';
import styled from 'styled-components';

import soldOutImg from "assets/images/sold-out.webp";

const SoldOut = ({ className }) => {
  return (
    <div className={ `${ className } status` }>
      <img
        className='img-fluid'
        src={ soldOutImg }
        alt="sold out"
      />
    </div>
  );
};

const StyledSoldOut = styled(SoldOut)`
  position: absolute;
  top: 0;
`;

export default React.memo(StyledSoldOut);
