import React from "react";
import styled from "styled-components";

const QuantityCount = ({ className, totalQty }) => {
  return (
    <div className={`${className} qty-count overflow-auto`}>
      <span>{totalQty}</span>
    </div>
  );
};

const StyledQuantityCount = styled(QuantityCount)`
  color: #c1c1c1;
  font-size: 14px;    
  margin-top: 3px;
`;

export default React.memo(StyledQuantityCount);
