import { takeEvery } from "redux-saga/effects";

import { DELETE_DATA } from "actions/apiOperations.js";

import { deleteJSON } from "apis/apiHelpers.js";

import sharedApiSaga from "sagas/sharedApiSaga.js";

export default function* watchDeleteData() {
  yield takeEvery(DELETE_DATA, deleteData);
}

export const deleteData = function*({ path, actions, params }) {
  return yield* sharedApiSaga(deleteJSON, path, actions, params);
};
