export const groupByProductsActions = {
  startLoader: "START_GROUP_BY PRODUCTS_LOADERS",
  init: "INITIATE_GROUP_BY_PRODUCTS_SAGA",
  alterSelectedGroupByFilter: "ALTER_SELECTED_GROUP_BY_FILTER",
  request: "GROUP_BY_PRODUCTS_REQUEST",
  success: "GROUP_BY_PRODUCTS_SUCCESS",
  failure: "GROUP_BY_PRODUCTS_FAILURE",
  reset: "GROUP_BY_PRODUCTS_RESET"
};

export const getGroupByProducts = (
  releaseId,
  limit = 10,
  offset = 0,
  override = false,
  type = null,
  buyplanId,
  selectedGroupByFilter
) => {
  return {
    type: groupByProductsActions.request,
    payload: {
      releaseId,
      limit,
      offset,
      override,
      type,
      buyplan_id: buyplanId,
      selectedGroupByFilter
    }
  };
};

export const changeSelectedGroupByFilter = selectedGroupByFilter => {
  return {
    type: groupByProductsActions.alterSelectedGroupByFilter,
    payload: {
      selectedGroupByFilter
    }
  };
};

export const resetGroupedProducts = () => {
  return {
    type: groupByProductsActions.reset
  };
};
