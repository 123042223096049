export const stockModelCopyActions = {
  startLoader: "START_STOCK_MODEL_COPY_LOADER",
  request: "REQUEST_STOCK_MODEL_COPY",
  success: "STOCK_MODEL_COPY_SUCCESS",
  failure: "STOCK_MODEL_COPY_FAILURE"
};

export const copyStockModelAsTemplate = payload => {
  return {
    type: stockModelCopyActions.request,
    payload
  };
};
