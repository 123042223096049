import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import List from "@researchgate/react-intersection-list";
import { CSSTransition } from "react-transition-group";

import "assets/stylesheets/transition.css";

import {
  isLoadingSelector,
  hasErrorsSelector,
  productsDataSelector,
  displayWsRangeSelector,
  showDiscountPercentSelector
} from "selectors/productsSelector.js";
import { filtersReducerSelector, isDataLoadingSelector } from "selectors/filtersSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";
import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import { currentUserDataSelector } from "selectors/currentUserSelector.js";

import { getProducts } from "actions/productsActions.js";
import { getFilters } from "actions/filterActions.js";

import ProductCard from "components/ProductCard/ProductCard.jsx";
import Loader from "components/Loader/Loader.jsx";
import i18next from "i18next";

import {
  RANGE,
  PER_PAGE,
  BRANDSCOPE_ADMIN,
  AGENT_ADMIN,
  SUPER_SUPPLIER_ADMIN,
  SUPPLIER_ADMIN,
  SALES_STAFF,
  SALES_REP
} from "constants.js";

const itemsRenderer = (items, ref) => {
  return <div ref={ref}>{items}</div>;
};

const getProductsListLoader = () => <Loader />;

let prevOffset = 0;

const ProductsListContainer = () => {
  const { releaseId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const buyPlanState = queryParams.get("type");

  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);
  const hasErrors = useSelector(hasErrorsSelector);
  const data = useSelector(productsDataSelector);
  const buyplanId = useSelector(buyplanIdSelector);
  const warehouseId = useSelector(warehouseIdSelector);
  const currentUser = useSelector(currentUserDataSelector);
  const showWsRange = useSelector(displayWsRangeSelector);
  const showDiscountPercent = useSelector(showDiscountPercentSelector);

  const isAdminUser = currentUser.get("role") === BRANDSCOPE_ADMIN;
  const isAgentAdmin = currentUser && currentUser.get("role") === AGENT_ADMIN;
  const isSalesRep = currentUser && currentUser.get("role") === SALES_REP;
  const isSalesStaff = currentUser && currentUser.get("role") === SALES_STAFF;
  const isSupplierAdmin =
    currentUser && currentUser.get("role") === SUPPLIER_ADMIN;
  const isSuperSupplierAdmin =
    currentUser && currentUser.get("role") === SUPER_SUPPLIER_ADMIN;

  const filters = useSelector(filtersReducerSelector);
  const appliedFilters = filters.get("appliedFilters");
  const hideWholesalePrice = currentUser.get("hide_ws_price");
  const isDataLoading = useSelector(isDataLoadingSelector);

  const itemRenderer = index => {
    let product = data.getIn(["pr", index]);

    if (product) {
      let prdct = product.product;
      const isThreeSixty = (data.has('tenant_id') && data?.get("tenant_id"))?true:false;
      const tenantId = (data.has('tenant_id') && data?.get("tenant_id")) || null;
      const brandScopeTag = prdct?.product_orb360_tag_value || null;
      const isPreOrderProduct = prdct?.is_pre_order_enabled || false;

      return (
        <ProductCard
          key={`${product.id}-${prdct.display_colour}-${prdct.sku}-${index}`}
          imgPath={prdct.primary_image}
          imgAltText={prdct.name}
          productId={product.product_id}
          releaseId={product.release_id}
          showMonths={true}
          captionName={prdct.name}
          captionColor={prdct.display_colour}
          hideWholesalePrice={hideWholesalePrice}
          retailPriceFormatted={product.retail_price_formatted}
          displayUnitRetailPrice={product.display_unit_retail_price}
          captionSku={prdct.sku}
          isDiscounted={product["discounted?"]}
          pbDiscounted={product.pb_discounted}
          effectiveDisplayWholesalePrice={
            product.effective_display_wholesale_price
          }
          discountedDisplayWholesaleRange={
            product.discounted_display_wholesale_range
          }
          discountedDisplayWholesalePrice={
            product.discounted_wholesale_price_formatted
          }
          showWsRange={showWsRange}
          displayWholesalePrice={product.display_wholesale_price}
          displayWholesaleRange={product.display_wholesale_range}
          brandScopeTag={brandScopeTag}
          isThreeSixty={isThreeSixty}
          tenantId={tenantId}
          pbPercentOff={product.pb_percent_off}
          showDiscountPercent={showDiscountPercent}
          isPreOrderProduct={isPreOrderProduct}
          productCallout={product.callout_label}
          calloutBgColor={product.callout_bg_color}
          calloutBrColor={product.callout_br_color}
          calloutTxtColor={product.callout_txt_color}
          calloutIcon={product.callout_icon}
        />
      );
    }
  };

  const onIntersection = size => {
    let newOffset = prevOffset + PER_PAGE;

    if (size !== prevOffset) {
      dispatch(
        getProducts(
          releaseId,
          PER_PAGE,
          newOffset,
          appliedFilters,
          false,
          buyPlanState,
          buyplanId,
          warehouseId
        )
      );

      prevOffset = newOffset;
    }
  };

  useEffect(() => {
    if (
      buyplanId ||
      isAdminUser ||
      isAgentAdmin ||
      isSuperSupplierAdmin ||
      isSalesRep ||
      isSalesStaff ||
      isSupplierAdmin
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");

      dispatch(getFilters(releaseId, RANGE, buyplanId, code));
    }
  }, [
    dispatch,
    releaseId,
    buyplanId,
    isAdminUser,
    isAgentAdmin,
    isSuperSupplierAdmin,
    isSalesRep,
    isSalesStaff,
    isSupplierAdmin
  ]);

  useEffect(() => {
    prevOffset = 0;

    if (isDataLoading === false) {
      dispatch(
        getProducts(
          releaseId,
          PER_PAGE,
          0,
          appliedFilters,
          true,
          buyPlanState,
          buyplanId,
          warehouseId
        )
      );
    }
  }, [
    dispatch,
    isDataLoading,
    releaseId,
    appliedFilters,
    buyPlanState,
    buyplanId,
    warehouseId
  ]);

  const totalProductsCount = data.get("count");
  const loadedProducts = data.get("pr");
  const loadedProductsCount = loadedProducts.size;
  const awaitingMoreProducts = () => data.get("mostRecentPrSize") === PER_PAGE;

  return (
    <>
      {isLoading && getProductsListLoader()}

      {hasErrors && <div className="error-msg">{data.get("message")}</div>}

      {!isLoading && totalProductsCount === 0 && (
        <div className="d-flex justify-content-center align-items-center h-100">
          {i18next.t("dnd.messages.noProductsFound")}
        </div>
      )}

      <CSSTransition
        classNames="fade"
        timeout={1200}
        in={!isLoading && !hasErrors && totalProductsCount > 0}
        unmountOnExit
      >
        <>
          <List
            pageSize={PER_PAGE}
            itemCount={loadedProductsCount}
            awaitMore={awaitingMoreProducts()}
            threshold="50%"
            itemsRenderer={itemsRenderer}
            renderItem={itemRenderer}
            onIntersection={onIntersection}
          />

          {awaitingMoreProducts() && getProductsListLoader()}
        </>
      </CSSTransition>
    </>
  );
};

export default React.memo(ProductsListContainer);
