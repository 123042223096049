export const pricingDetailsVisibilityActions = {
  showPricingDetails: "SHOW_PRICING_DETAILS",
  hidePricingDetails: "HIDE_PRICING_DETAILS",
  togglePricingDetails: "TOGGLE_PRICING_DETAILS"
};

export const showPricingFn = buyplanItemId => {
  return {
    type: pricingDetailsVisibilityActions.showPricingDetails,
    payload: { buyplanItemId }
  };
};

export const hidePricingFn = buyplanItemId => {
  return {
    type: pricingDetailsVisibilityActions.hidePricingDetails,
    payload: { buyplanItemId }
  };
};

export const togglePricingFn = buyplanItemId => {
  return {
    type: pricingDetailsVisibilityActions.togglePricingDetails,
    payload: { buyplanItemId }
  };
};
