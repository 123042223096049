import { fromJS } from "immutable";

import { viewAtsActions } from "actions/viewAtsActions.js";

const initialState = fromJS({})

export default (state = initialState, action) => {
  switch (action && action.type) {
    case viewAtsActions.startLoader:
      return state.set("isLoading", true);
    case viewAtsActions.success:
      return state
        .merge({
          isLoading: false,
          error: null
        })
        .set("ats_data", fromJS(action.payload.data.ats_data))
        .set("sizes", fromJS(action.payload.data.sizes))
        .set("attr_sizes", fromJS(action.payload.data.attr_sizes))
        .set("product_name", fromJS(action.payload.data.product_name))
        .set("product_sku", fromJS(action.payload.data.product_sku));
    case viewAtsActions.failure:
      return state
        .merge({
          isLoading: false,
          error: action.payload.data
        })
        .set("data", initialState.get("data"));
    default:
      return state;
  }
};
