import { createSelector } from "reselect";

export const stockModelIdReducerSelector = createSelector(
  state => state.get("stockModelIdReducer"),
  stockModelIdReducer => stockModelIdReducer
);

export const isLoadingSelector = createSelector(
  stockModelIdReducerSelector,
  stockModelIdReducer => stockModelIdReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  stockModelIdReducerSelector,
  stockModelIdReducer => stockModelIdReducer.get("hasErrors")
);

export const stockModelIdDataSelector = createSelector(
  stockModelIdReducerSelector,
  stockModelIdReducer => stockModelIdReducer.get("data")
);

export const stockModelIdSelector = createSelector(
  stockModelIdDataSelector,
  stockModelIdReducer => stockModelIdReducer.get("id")
);
