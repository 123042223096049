import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageGallery from "components/ImageGallery/index.jsx";

import {
  productImagesSelector,
  isLoadingSelector
} from "selectors/productReleaseSelector.js";

import { getProductRelease } from "actions/productReleaseActions.js";
import Loader from "components/Loader/Loader.jsx";

const ImageGalleryContainer = ({ productReleaseId, buyplanId, OrbUrl }) => {
  const dispatch = useDispatch();
  const productImages = useSelector(productImagesSelector);
  const isLoading = useSelector(isLoadingSelector);

  useEffect(() => {
    dispatch(getProductRelease({ productReleaseId, buyplan_id: buyplanId }));
  }, [dispatch, productReleaseId, buyplanId]);

  if (isLoading) {
    return <Loader />;
  }

  return <ImageGallery images={productImages} OrbUrl={OrbUrl} />;
};

export default React.memo(ImageGalleryContainer);
