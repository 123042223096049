export const listShortUrlsActions = {
  startLoader: "START_LIST_SHORT_URLS_LOADER",
  request: "REQUEST_LIST_SHORT_URLS",
  success: "LIST_SHORT_URLS_SUCCESS",
  failure: "LIST_SHORT_URLS_FAILURE",
  reset: "RESET_LIST_SHORT_URLS",
};

export const listShortUrls = (payload) => {
  return {
    type: listShortUrlsActions.request,
    payload,
  };
};

export const listShortUrlsSuccess = (payload) => {
  return {
    type: listShortUrlsActions.success,
    payload,
  };
};

export const listShortUrlsFailure = (errorPayload) => {
  return {
    type: listShortUrlsActions.failure,
    payload: errorPayload,
  };
};
