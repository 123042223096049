import { takeLatest, put, take, race } from "redux-saga/effects";

import { qtyGridBarcodeScanningActions, storeBarcodeScanLogs } from "actions/StockModal/barcodeScanningActions.js";

import { POST_DATA } from "actions/apiOperations.js";

export const requestStockModelQtyGridAsync = function*(action) {
  const { stockModalId, ...params } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/stock_models/${stockModalId}/items/find_or_create_by_barcode.json`,
    actions: {
      startLoader: qtyGridBarcodeScanningActions.startLoader,
      success: qtyGridBarcodeScanningActions.stockModelQtyGridSuccess,
      failure: qtyGridBarcodeScanningActions.stockModelQtyGridFailure
    },
    params
  });

  const { success, error } = yield race({
    success: take(qtyGridBarcodeScanningActions.stockModelQtyGridSuccess),
    error: take(qtyGridBarcodeScanningActions.stockModelQtyGridFailure)
  });

  if (success) {
    const log = {
      barcode: params.barcode,
      product_name: success.payload.data.product_release.product_name,
      product_image: success.payload.data.stock_model_item.primary_image_url,
      size: success.payload.data.size,
      type: "success"
    }
    yield put(storeBarcodeScanLogs(log));
  } else if (error) {
    const log = {
      barcode: params.barcode,
      error: error.payload.data.errors[0],
      type: "error"
    }
    yield put(storeBarcodeScanLogs(log));
  }
};

export const requestBuyplanQtyGridAsync = function*(action) {
  const { buyplanId, ...params } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/buyplans/${buyplanId}/items/find_or_create_by_barcode.json`,
    actions: {
      startLoader: qtyGridBarcodeScanningActions.startLoader,
      success: qtyGridBarcodeScanningActions.buyplanQtyGridSuccess,
      failure: qtyGridBarcodeScanningActions.buyplanQtyGridFailure
    },
    params
  });
};

export const watchStockModelRequestQtyGrid = function*() {
  yield takeLatest(qtyGridBarcodeScanningActions.stockModelRequestQtyGrid, requestStockModelQtyGridAsync);
};

export const watchBuyplanRequestQtyGrid = function*() {
  yield takeLatest(qtyGridBarcodeScanningActions.buyplanRequestQtyGrid, requestBuyplanQtyGridAsync);
};
