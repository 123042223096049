import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import ProductsListContainer from "containers/ProductsListContainer.jsx";
import GroupedProductsContainer from "containers/GroupedProductsContainer.jsx";
import FilterPillContainer from "containers/FilterPillContainer.jsx";

import {
  getGroupByProducts,
  resetGroupedProducts
} from "actions/groupByProductsActions.js";

import { selectedGroupByFilterSelector } from "selectors/groupedProductsSelector.js";
import {
  fetchedDynamicFiltersSelector,
  appliedDynamicFiltersSelector,
  appliedCommonFiltersSelector
} from "selectors/filtersSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";
import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";

import { CATALOGUE_ORDER, PER_PAGE } from "constants.js";

const RangeContainer = () => {
  const { releaseId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const buyPlanState = queryParams.get("type");
  const buyplanId = useSelector(buyplanIdSelector);
  const warehouseId = useSelector(warehouseIdSelector);

  const selectedGroupByFilter = useSelector(
    selectedGroupByFilterSelector
  ).toJS();
  const groupByFilter = selectedGroupByFilter.value;
  const appliedFilters = useSelector(appliedDynamicFiltersSelector);
  const appliedCommonFilters = useSelector(appliedCommonFiltersSelector);
  const fetchedFilters = useSelector(fetchedDynamicFiltersSelector);

  useEffect(() => {
    if (groupByFilter !== CATALOGUE_ORDER) {
      dispatch(
        getGroupByProducts(
          releaseId,
          PER_PAGE,
          0,
          true,
          buyPlanState,
          buyplanId,
          groupByFilter
        )
      );
      return () => {
        dispatch(resetGroupedProducts());
      };
    }
  }, [
    dispatch,
    releaseId,
    buyPlanState,
    buyplanId,
    appliedFilters,
    appliedCommonFilters,
    warehouseId,
    groupByFilter
  ]);

  if (groupByFilter === CATALOGUE_ORDER) {
    return (
      <>
        <FilterPillContainer />
        <ProductsListContainer />
      </>
    );
  } else {
    if (groupByFilter) {
      let subcategoryFilters = [];

      if (
        appliedFilters &&
        appliedFilters.get(groupByFilter) &&
        appliedFilters.get(groupByFilter).size > 0
      ) {
        subcategoryFilters = appliedFilters.get(groupByFilter);
      } else {
        fetchedFilters.get(groupByFilter).forEach(filterOption => {
          const isExistingFilterOption = subcategoryFilters.find(option => {
            return option.get("name") === filterOption.get("name");
          });

          isExistingFilterOption === undefined &&
            subcategoryFilters.push(filterOption);
        });
      }

      return subcategoryFilters.map((value, index) => {
        return (
          <GroupedProductsContainer
            key={index}
            groupName={value.get("name")}
            groupByFilter={groupByFilter}
          />
        );
      });
    }
  }
};

export default React.memo(RangeContainer);
