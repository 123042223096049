import { takeLatest, put, select } from "redux-saga/effects";

import { CATALOGUE_ORDER } from "constants.js";

import { generateShortUrlActions } from "actions/generateShortUrlActions.js";

import { POST_DATA } from "actions/apiOperations.js";

import { appliedFiltersSelector } from "selectors/filtersSelector.js";
import { selectedGroupByFilterSelector } from "selectors/groupedProductsSelector.js";

export const requestShortUrl = function*(action) {
  const appliedFilters = yield select(appliedFiltersSelector);
  const selectedGroupByFilter = yield select(selectedGroupByFilterSelector);
  const appliedFiltersWithStoryTelling = appliedFilters.set(
    "tell_story_by",
    selectedGroupByFilter?.get("value") || CATALOGUE_ORDER
  );

  yield put({
    type: POST_DATA,
    path: `/api/filters/generate_short_link.json`,
    actions: generateShortUrlActions,
    params: {
      ...action.payload,
      filters: appliedFiltersWithStoryTelling
    }
  });
};

export const watchRequestShortUrl = function*() {
  yield takeLatest(generateShortUrlActions.request, requestShortUrl);
};
