import { createSelector } from "reselect";

export const globalMenuReducerSelector = createSelector(
  state => state.get("globalMenuReducer"),
  globalMenuReducer => globalMenuReducer
);

export const isLoadingSelector = createSelector(
  globalMenuReducerSelector,
  globalMenuReducer => globalMenuReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  globalMenuReducerSelector,
  globalMenuReducer => globalMenuReducer.get("hasErrors")
);

export const globalMenuDataSelector = createSelector(
  globalMenuReducerSelector,
  globalMenuReducer => globalMenuReducer.get("data")
);
