import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { updateActiveTab } from "actions/filterActions.js";

import ProductReleaseContainer from "containers/ProductReleaseContainer.jsx";
import QuantityGridContainer from "containers/QuantityGridContainer.jsx";

const TabsList = ({ className, tabsConfig }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const tabIds = useMemo(() => Object.keys(tabsConfig), [tabsConfig]);
  const [activeTabId, setActiveTabId] = useState(tabIds[0]);
  const [tabBody, setTabBody] = useState();

  const changeActiveTab = evt => {
    const selectedTabId = evt.target.dataset.tabId;

    setActiveTabId(selectedTabId);
  };

  useEffect(() => {
    const component = activeTabId && tabsConfig[activeTabId].body;
    dispatch(updateActiveTab(activeTabId));
    setTabBody(component);
  }, [dispatch, activeTabId, tabsConfig]);

  return (
    <>
      <Nav pills className={className}>
        {tabIds.map(k => {
          return (
            <NavItem key={k}>
              <NavLink
                className={activeTabId === k ? "active" : ""}
                data-tab-id={k}
                onClick={changeActiveTab}
              >
                {tabsConfig[k].title}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>

      <TabContent activeTab={activeTabId}>
        <TabPane tabId={activeTabId}>
          {tabBody}

          <Route
            path={`${path}/:productReleaseId/quick-view`}
            exact
            component={ProductReleaseContainer}
          />

          <Route
            path={`${path}/stock-modal-items/:stockModalItemId/quantity-grid`}
            exact
            component={QuantityGridContainer}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

const StyledTabsList = styled(TabsList)`
  padding: 0;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin: 0 0 30px;

  @media (max-width: 575.98px) {
    justify-content: center;
  }

  .nav-item {
    cursor: pointer;
  }

  .nav-item > .nav-link {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    padding: 18px 20px;
    color: #222f3e;
    margin-right: 2px;
    border-radius: 0;
    border-bottom: 2px solid #f9f9f9;

    &:hover {
      color: #ffffff;
      background-color: #222f3e;
      border-bottom: 2px solid #222f3e;
    }

    &.active {
      background-color: var(--body-bg-color);
      color: #368ee0;
      cursor: not-allowed;
      font-weight: 500;
      border-bottom: 3px solid #368ee0;
    }
  }
`;

export default React.memo(StyledTabsList);
