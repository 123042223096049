import { createSelector } from "reselect";

export const buyplanReportReducerSelector = createSelector(
  state => state.get("buyplanReportReducer"),
  buyplanReportReducer => buyplanReportReducer
);

export const isLoadingSelector = createSelector(
  buyplanReportReducerSelector,
  buyplanReportReducer => buyplanReportReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  buyplanReportReducerSelector,
  buyplanReportReducer => buyplanReportReducer.get("hasErrors")
);

export const buyplanReportDataSelector = createSelector(
  buyplanReportReducerSelector,
  buyplanReportReducer => buyplanReportReducer.get("data")
);

export const reportInvoiceSelector = createSelector(
  buyplanReportDataSelector,
  buyplanReportData => buyplanReportData.get("invoice")
);

export const invoiceCategoryRulesSelector = createSelector(
  reportInvoiceSelector,
  reportInvoice => reportInvoice.get("category_rules")
);

export const reportTotalsHeaderSelector = createSelector(
  buyplanReportDataSelector,
  buyplanReportData => buyplanReportData.get("totals_header")
);

export const reportMonthsTotalSelector = createSelector(
  buyplanReportDataSelector,
  buyplanReportData => (
    buyplanReportData.get("months_totals") &&
    buyplanReportData.get("months_totals").sortBy(obj => obj.get("index"))
  )
);

export const reportDivisionsTotalSelector = createSelector(
  buyplanReportDataSelector,
  buyplanReportData => buyplanReportData.get("divisions_total")
);

export const reportCategoriesTotalSelector = createSelector(
  buyplanReportDataSelector,
  buyplanReportData => buyplanReportData.get("categories_total")
);
