import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import classNames from "classnames";

import { orderMinumumsSelector } from "selectors/orderDetailsSelector.js";
import i18next from "i18next";

const BuyplanTotals = ({
  className,
  totalPriceFormatted,
  totalItemsCount,
  totalPriceLabel = i18next.t("invoice.total"),
  totalItemsCountLabel = i18next.t("invoice.totalItems"),
  isDiscounted,
  originalPriceFormatted,
  padMinOrderAmt = false,
  isPayNowEnable = false
}) => {
  const orderMinimums = useSelector(orderMinumumsSelector);
  const isMinOrderFeatureEnabled = !!orderMinimums?.get("apply_order_minimums");
  const minOrderAmt = orderMinimums?.get("minimum_order_amount");

  return (
    <>
      <div className={`${className} buyplan-totals`}>
        <div className="total-price-wrapper">
          <label>{totalPriceLabel}:&nbsp;</label>
          <span>
            {isDiscounted && !isPayNowEnable && (
              <>
                <del>{originalPriceFormatted}</del>
                &nbsp;
              </>
            )}
            {totalPriceFormatted || "0.00"}
          </span>
        </div>

        <div className="total-items-count-wrapper">
          <label>{totalItemsCountLabel}:&nbsp;</label>
          <span>{totalItemsCount || 0}</span>
        </div>
      </div>

      {isMinOrderFeatureEnabled && (
        <div
          className={classNames("font-weight-normal", {
            "pl-4": padMinOrderAmt
          })}
        >
          {i18next.t("order.orderMinAmtPrefix")} &nbsp;
          <span
            className="font-weight-bold"
            style={{ color: "var(--bs-grey-color-silver)" }}
          >
            {minOrderAmt}
          </span>
        </div>
      )}
    </>
  );
};

const StyledBuyplanTotals = styled(BuyplanTotals)`
  color: black;
  font-size: 20px;
  font-weight: 700;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 20em 14em;
  margin: 0 0 15px;
  padding: 15px 0 0;
  border-top: 1px dotted #ddd;
  color: var(--bs-dark-navy) @media (max-width: 575.98px) {
    grid-template-columns: unset;
    grid-template-rows: 30px 30px;
    align-items: center;
  }

  label {
    margin: 0;
    font-size: 20px;
    color: var(--bs-dark-navy);
    font-weight: 700;
  }

  span {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: #90979e;
  }

  ${({ isBuyplanTab }) => isBuyplanTab && `
    position: fixed;
    width: 100%;
    bottom: 0;
    margin: auto;
    padding: 10px;
  `}
`;


export default React.memo(StyledBuyplanTotals);
