import { fromJS } from "immutable";

import { overwriteShortUrlActions } from "actions/overwriteShortUrlActions.js";

const initialState = fromJS({
  isLoading: false,
  hasErrors: false,
  data: {
    id: null,
    short_link: null
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case overwriteShortUrlActions.startLoader:
      return state.set("isLoading", true);
    case overwriteShortUrlActions.reset:
      return initialState;
    case overwriteShortUrlActions.success:
      return state.merge({
        isLoading: false,
        hasErrors: false,
        data: fromJS(action.payload.data)
      });
    case overwriteShortUrlActions.failure:
      return state.merge({
        isLoading: false,
        hasErrors: true,
        data: fromJS(action.payload.data)
      });
    default:
      return state;
  }
};
