import { takeLatest, put } from "redux-saga/effects";

import { buyplanReportActions } from "actions/buyplanReportActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestBuyplanReport = function*(action) {
  const { buyplanId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/buyplans/${buyplanId}/report.json`,
    actions: buyplanReportActions,
    params
  });
};

export const watchRequestBuyplanReport = function*() {
  yield takeLatest(buyplanReportActions.request, requestBuyplanReport);
};

export const requestStockModalBuyplanReport = function*(action) {
  const { stockModalId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/stock_models/${stockModalId}/report.json`,
    actions: buyplanReportActions,
    params
  });
};

export const watchRequestStockModalBuyplanReport = function*() {
  yield takeLatest(
    buyplanReportActions.requestStockModalReport,
    requestStockModalBuyplanReport
  );
};
