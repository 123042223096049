import styled from "styled-components";

export default styled.button`
  &:hover {
    background-color: #c12417 !important;
  }
  background-color: #ef392a !important;
  color: #ffffff;
  text-shadow: none;
  filter: none;
  border-radius: 0;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border: 0 !important;
  box-shadow: none !important;
  padding: 5px 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  border-width: 0px;
  border-style: initial;
  border-color: initial !important;
  border-image: initial;
  margin-right: 20px;
  border-right: 1px solid #dddddd;
`;
