import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import CheckboxWithLabel from "components/CheckboxWithLabel.jsx";

import {
  hasErrorsSelector,
  areTermsRequiredSelector,
  orderMinumumsSelector,
  orderMinimumsByMonthSelector
} from "selectors/orderDetailsSelector.js";

import { isLoadingSelector as isOrderValidationsSubmittingSelector } from "selectors/orderValidationsSelector.js";
import { isLoadingSelector as isOrderSubmittingSelector } from "selectors/orderSelector.js";
import { isLoadingSelector as isOrderDetailsLoadingSelector } from "selectors/orderDetailsSelector.js";

import OrderTermsDialog from "components/BuyplanReport/OrderTermsDialog.jsx";
import { LoaderIcon } from "components/Loader/Loader";
import i18next from "i18next";

/* Note: Always Sync V2 - src/components/BuyplanReport/V2/OrderDialogFooter.jsx */

const OrderDialogFooter = ({ closeModalFn, onClickCallback }) => {
  const hasError = useSelector(hasErrorsSelector);
  const areTermsRequired = useSelector(areTermsRequiredSelector);
  const orderMinimums = useSelector(orderMinumumsSelector);
  const isOrderDetailsLoasing = useSelector(isOrderDetailsLoadingSelector);
  const orderMinimumsByMonth = useSelector(orderMinimumsByMonthSelector);

  const [isModalOpen, setModalState] = useState(false);
  const toggleModalFn = useCallback(() => {
    setModalState(!isModalOpen);
  }, [isModalOpen]);

  const [isTermsChecked, setTermsCheckbox] = useState(false);
  const toggleTermsCheckbox = useCallback(() => {
    setTermsCheckbox(!isTermsChecked);
  }, [isTermsChecked]);

  const isOrderValidationsSubmitting = useSelector(
    isOrderValidationsSubmittingSelector
  );
  const isOrderSubmitting = useSelector(isOrderSubmittingSelector);

  if (hasError) return null;

  let isSubmitBtnDisabled = areTermsRequired
    ? !isTermsChecked || isOrderValidationsSubmitting || isOrderSubmitting || isOrderDetailsLoasing
    : isOrderValidationsSubmitting || isOrderSubmitting || isOrderDetailsLoasing;

  if (orderMinimums?.get("apply_order_minimums")) {
    if (orderMinimums?.get("enforce_order_minimums")) {
      isSubmitBtnDisabled =
        isSubmitBtnDisabled || !orderMinimums?.get("is_min_order_criteria_met");
    } else if (orderMinimums?.get("is_min_order_criteria_met") === false) {
      isSubmitBtnDisabled =
        isSubmitBtnDisabled || !orderMinimums?.get("is_acknowledged");
    }
  } // else continue with existing value of "isSubmitBtnDisabled"

  if (orderMinimumsByMonth?.get("apply_order_minimums") && orderMinimumsByMonth?.get("is_min_order_criteria_met") === false) {
    isSubmitBtnDisabled = isSubmitBtnDisabled || !orderMinimumsByMonth?.get("is_min_order_criteria_met");
  } // else continue with existing value of "isSubmitBtnDisabled"

  return (
    <>
      {areTermsRequired && (
        <div className="d-flex align-items-center">
          <CheckboxWithLabel
            id="terms_and_conditions"
            isChecked={isTermsChecked}
            onToggle={toggleTermsCheckbox}
            labelText={i18next.t("general.readAndAgree")}
          />

          <StyledTermConditionButton
            className="btn btn-link"
            onClick={toggleModalFn}
          >
            {i18next.t("general.tnc")}
          </StyledTermConditionButton>
        </div>
      )}

      <button className="btn btn-secondary rounded" onClick={closeModalFn}>
        {i18next.t("buttons.back")}
      </button>

      <button
        className="btn btn-primary rounded"
        disabled={isSubmitBtnDisabled}
        onClick={onClickCallback}
      >
        {isSubmitBtnDisabled ? <LoaderIcon /> : i18next.t("buttons.submit")}
      </button>

      {isModalOpen && (
        <OrderTermsDialog
          isOpen={isModalOpen}
          closeModalCallback={toggleModalFn}
        />
      )}
    </>
  );
};

const StyledTermConditionButton = styled.button`
  color: #3b90dd;
`;

export default React.memo(OrderDialogFooter);
