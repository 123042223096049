import React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";
import { findByProductReleaseId } from "selectors/productsSelector.js";

import SoldOut from "components/SoldOut/index.jsx";

import DefaultProductImage from "assets/images/default-product-image-128x160.jpg";

const Thumbnail = ({
  className,
  imgSrc,
  altText,
  productReleaseId,
  buyplanId,
  productId,
  isSoldOut,
  isClickable,
  productCallout,
  calloutIcon,
  height = 160
}) => {
  const stockModalId = useSelector(stockModelIdSelector);
  const { url } = useRouteMatch();
  const productRelease = useSelector(findByProductReleaseId(productReleaseId));
  const stockModalItemId = productRelease?.stock_model_item?.id;
  const queryString = stockModalId
    ? `?stockModalItemId=${stockModalItemId}`
    : `?buyplanId=${buyplanId}`;

  const quickViewPath = `${url}/${productReleaseId}/quick-view${queryString}`;
  const imageSrc = imgSrc || DefaultProductImage;

  return (
    <div className={`${className} thumbnail`} data-product-id={productId}>
      {isSoldOut && <SoldOut />}

      <ThumbnailImg
        imgSrc={imageSrc}
        altText={altText}
        isClickable={isClickable}
        linkTo={quickViewPath}
        height={height}
        calloutIcon={calloutIcon}
        productCallout={productCallout}
      />
    </div>
  );
};

export const ThumbnailImg = ({
  imgSrc,
  altText,
  height = 160,
  isClickable = true,
  linkTo,
  calloutIcon,
  productCallout
}) => {
  const addDefaultSrc = ev => (ev.target.src = DefaultProductImage);

  if (isClickable) {
    return (
      <Link to={linkTo}>
        <div className={`callout`}>
          {calloutIcon ?
            <img
              src={calloutIcon}
              alt={altText}
              height={35}
              width={35}
              onError={DefaultProductImage}
            /> :
            <div className={`callout-label`}> { productCallout } </div> }
        </div>
        <img
          className="thumbnail_img"
          src={imgSrc}
          alt={altText}
          height={height}
          onError={addDefaultSrc}
        />
      </Link>
    );
  } else {
    return (
      <>
        <div className={`callout`}>
          {calloutIcon ?
            <img
              src={calloutIcon}
              alt={altText}
              height={35}
              width={35}
              onError={DefaultProductImage}
            /> :
            <div className={`callout-label`}> { productCallout } </div> }
        </div>
        <img src={imgSrc} alt={altText} height={height} onError={addDefaultSrc} />
      </>
    )
  }
};

const StyledThumbnail = styled(Thumbnail)`
  border: none;
  box-shadow: none;
  display: block;
  padding: 5px 5px 5px 0;
  line-height: 16px;
  transition: all 0.2s ease-in-out;
  height: auto;
  width: inherit;

  > a {
    display: inline-block;
  }

  > a > img {
    width: 100%;
  }

  .callout {
    position: absolute;
    .callout-label {
      ${props =>
        props.calloutBgColor &&
        css`
          border: 1px solid;
          border-top-left-radius: 4px;
          padding-left: 4px;
          background-color: ${props.calloutBgColor}
          border-color: ${props.calloutBrColor}
          color: ${props.calloutTxtColor}
      `};
    }
    .callout-label::before {
      ${props =>
        css`
          content: "";
          position: absolute;
          right: -14px;
          top: 0px;
          width: 0;
          height: 0;
          border-top: 18px solid ${props.calloutBrColor};
          border-color: ${props.calloutBrColor}
          border-right: 14px solid transparent;
      `}
    }
    .callout-label::after {
      ${props =>
        css`
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-top: 16px solid ${props.calloutBgColor};
          border-color: ${props.calloutBgColor}
          border-right: 13px solid transparent;
      `}
    }
  }
`;

export default React.memo(StyledThumbnail);
