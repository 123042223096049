import { takeLeading, put, take, call, race, select } from "redux-saga/effects";

import { orderActions } from "actions/orderActions.js";

import { POST_DATA } from "actions/apiOperations.js";

import { redirectTo } from "apis/apiHelpers.js";

import { addNotification } from "notificationStore";

import {
  isBrightPearlOrderIntegratedSelector,
  orderBillingAddressSelector,
  selectedShippingAddrIdSelector
} from "selectors/orderDetailsSelector.js";

import { buyplanStateSelector } from "selectors/buyplanIdSelector";

export const requestOrder = function*(action) {
  const { buyplanId, ...params } = action.payload;

  const isBrightPearlOrderIntegrated = yield select(
    isBrightPearlOrderIntegratedSelector
  );
  const billingAddress = yield select(orderBillingAddressSelector);
  const selectedShippingAddrId = yield select(selectedShippingAddrIdSelector);
  const buyplanState = yield select(buyplanStateSelector);

  if (!isBrightPearlOrderIntegrated) {
    params.buyplan.order_detail_attributes.billing_address_id = billingAddress.get(
      "id"
    );

    params.buyplan.order_detail_attributes.shipping_address_id = selectedShippingAddrId;
  }

  yield put({
    type: POST_DATA,
    path: `/api/buyplans/${buyplanId}/submit_order.json`,
    actions: orderActions,
    params
  });

  const { success } = yield race({
    success: take(orderActions.success),
    failure: take(orderActions.failure)
  });

  const submittedForApprovalMsg = success?.payload?.data?.submitted_for_approval_msg
  if (submittedForApprovalMsg) {
    addNotification({ message: submittedForApprovalMsg });
    yield call(redirectTo, `/${buyplanState}s`);
  }
  
  const orderNumber = success?.payload?.data?.order_number
  if (orderNumber) {
    yield call(redirectTo, "/orders");
  }
};

export const watchRequestOrder = function*() {
  yield takeLeading(orderActions.request, requestOrder);
};

export const requestConsignmentRequest = function*(action) {
  const { buyplanId, ...params } = action.payload;

  const isBrightPearlOrderIntegrated = yield select(
    isBrightPearlOrderIntegratedSelector
  );
  const billingAddress = yield select(orderBillingAddressSelector);
  const selectedShippingAddrId = yield select(selectedShippingAddrIdSelector);

  if (!isBrightPearlOrderIntegrated) {
    params.buyplan.order_detail_attributes.billing_address_id = billingAddress.get(
      "id"
    );

    params.buyplan.order_detail_attributes.shipping_address_id = selectedShippingAddrId;
  }

  yield put({
    type: POST_DATA,
    path: `/api/consignments/${buyplanId}/submit_request.json`,
    actions: orderActions,
    params
  });

  const { success } = yield race({
    success: take(orderActions.success),
    failure: take(orderActions.failure)
  });

  if (success) {
    yield call(redirectTo, "/consignment_requests");
  }
};

export const watchRequestConsignmentRequest = function*() {
  yield takeLeading(
    orderActions.consignment_request,
    requestConsignmentRequest
  );
};

export const requestStockModalOrder = function*(action) {
  const { stockModalId, ...params } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/stock_models/${stockModalId}/submit_order.json`,
    actions: orderActions,
    params
  });

  const { success } = yield race({
    success: take(orderActions.success),
    failure: take(orderActions.failure)
  });

  if (success) {
    yield call(redirectTo, "/orders");
  }
};

export const watchRequestStockModalOrder = function*() {
  yield takeLeading(
    orderActions.requestStockModalOrderSubmission,
    requestStockModalOrder
  );
};
