import { fromJS } from "immutable";

import { bannerInfoActions } from "actions/bannerInfoActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    release_id: null,
    release_name: null,
    banner_url: null
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case bannerInfoActions.startLoader:
      return state.set("isLoading", true);
    case bannerInfoActions.reset:
      return initialState;
    case bannerInfoActions.success:
      return state.merge({
        isLoading: false,
        hasErrors: false,
        data: fromJS(action.payload.data)
      });
    case bannerInfoActions.failure:
      return state.merge({
        isLoading: false,
        hasErrors: true,
        data: fromJS(action.payload.data)
      });
    default:
      return state;
  }
};
