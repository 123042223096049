import styled from "styled-components";

export default styled.button`
  line-height: 18px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  padding: 5px 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  border-right: 1px solid #dddddd;
`;
