export const qtyGridBarcodeScanningActions = {
  startLoader: "START_STOCK_MODAL_BARCODE_SCAN_QTY_GRID_LOADER",
  storeScanLogs: "STORE_SCAN_LOGS",
  resetScanLogs: "RESET_SCAN_LOGS",
  stockModelRequestQtyGrid: "REQUEST_STOCK_MODAL_BARCODE_SCAN_QTY_GRID",
  stockModelQtyGridSuccess: "STOCK_MODAL_BARCODE_SCAN_QTY_GRID_SUCCESS",
  stockModelQtyGridFailure: "STOCK_MODAL_BARCODE_SCAN_QTY_GRID_FAILURE",
  buyplanRequestQtyGrid: "REQUEST_BUYPLAN_BARCODE_SCAN_QTY_GRID",
  buyplanQtyGridSuccess: "BUYPLAN_BARCODE_SCAN_QTY_GRID_SUCCESS",
  buyplanQtyGridFailure: "BUYPLAN_BARCODE_SCAN_QTY_GRID_FAILURE",
};

export const stockModelRequestQtyGrid = ({
  releaseId,
  stockModalId,
  warehouseId,
  barcode
}) => {
  return {
    type: qtyGridBarcodeScanningActions.stockModelRequestQtyGrid,
    payload: {
      release_id: releaseId,
      stockModalId,
      warehouse_id: warehouseId,
      barcode
    }
  };
};

export const buyplanRequestQtyGrid = ({
  releaseId,
  buyplanId,
  warehouseId,
  barcode
}) => {
  return {
    type: qtyGridBarcodeScanningActions.buyplanRequestQtyGrid,
    payload: {
      release_id: releaseId,
      buyplanId,
      warehouse_id: warehouseId,
      barcode
    }
  };
};

export const storeBarcodeScanLogs = (newLog) => {
  return {
    type: qtyGridBarcodeScanningActions.storeScanLogs,
    payload: {
      newLog
    }
  };
};

export const resetScanLogs = () => {
  return {
    type: qtyGridBarcodeScanningActions.resetScanLogs,
  };
};

