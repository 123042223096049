import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Row, Col } from "reactstrap";

import { getOrderDetails } from "actions/orderDetailsActions.js";

import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import {
  hasErrorsSelector,
  orderDetailsErrorsSelector,
  orderInvoiceSelector,
  isBrightPearlOrderIntegratedSelector,
  isDaiwaBrandSelector,
  orderApprovalRuleMsgSelector,
  isOrderApprovalRequiredSelector
} from "selectors/orderDetailsSelector.js";

import OrderMinimumsContainer from "containers/OrderMinimumsContainer.jsx";

import BuyplanTotals from "components/BuyplanDetails/BuyplanTotals.jsx";
import Addresses from "components/BuyplanReport/Addresses.jsx";
import BrightPearlAddresses from "components/BuyplanReport/BrightPearlAddresses.jsx";
import BrightPearlDeliveryDates from "components/BuyplanReport/BrightPearlDeliveryDates.jsx";
import OrderInfo from "components/BuyplanReport/OrderInfo.jsx";
import DaiwaOrderInfo from "components/BuyplanReport/DaiwaOrderInfo.jsx";
import Alert from "components/Alert";
import i18next from "i18next";
import { isObjectEmpty } from "utils";

const OrderDialogBody = ({
  isChecked = false,
  onPurchaseOrderBlurCallback,
  onDeliveryInstructionsBlurCallback,
  onDepositAmountCallback,
  handleShippingMethodChange,
  handleNetsuiteOrderTypeChange,
  handleShippingAddressChange,
  handleDeliveryDatesChange,
  handleDeliveryDateChange,
  handleCancelDateChange,
  handleSalesPersonChange,
  deliveryDate,
  cancelDate,
  onCustomShippingAddressChangeCallback
}) => {
  const dispatch = useDispatch();
  const hasError = useSelector(hasErrorsSelector);
  const orderDetailsErrors = useSelector(orderDetailsErrorsSelector);
  const buyplanId = useSelector(buyplanIdSelector);
  const invoice = useSelector(orderInvoiceSelector);
  const isBrightPearlOrderIntegrated = useSelector(isBrightPearlOrderIntegratedSelector);

  const isDaiwaBrand = useSelector(isDaiwaBrandSelector);

  const isOrderApprovalRequired = useSelector(isOrderApprovalRequiredSelector);
  const orderApprovalRuleMsg = useSelector(orderApprovalRuleMsgSelector);

  const pay_now_discount = invoice.get('pay_now_discount_percentage') > 0 ? true : false;
  const is_discounted = invoice.get("is_discounted");
  const is_deposit_payment_enabled = invoice.get("deposit_payment_enabled");
  const depositAmount = invoice && invoice.get("minimum_deposit_amount");
  const maxdepositAmount = invoice && invoice.get("maximum_deposit_amount");
  const total_postage = invoice.get('total_postage') > 0 ? true : false;
  const showMonthwisePostage = !isObjectEmpty(invoice.get("monthwise_postage_formatted").toJS());

  useEffect(() => {
    dispatch(getOrderDetails(buyplanId));
  }, [dispatch, buyplanId]);

  if (hasError) {
    const errorMsg = orderDetailsErrors.get(0);

    return <div className="error-msg">{errorMsg}</div>;
  }

  return (
    <>
      {isOrderApprovalRequired && orderApprovalRuleMsg && (
        <Alert variant="info">{orderApprovalRuleMsg}</Alert>
      )}

      <div className="device-info">
        {isBrightPearlOrderIntegrated ? (
          <Row className="w-100">
            <Col md={5}>
              <BrightPearlAddresses handleShippingAddressChange={handleShippingAddressChange} />
            </Col>

            <Col md={5}>
              <BrightPearlDeliveryDates handleDeliveryDatesChange={handleDeliveryDatesChange} />
            </Col>
          </Row>
        ) : (
          <Addresses
            isChecked={isChecked}
            customShippingAddressFormHandler={onCustomShippingAddressChangeCallback}
          />
        )}
      </div>

      {isDaiwaBrand ? (
        <DaiwaOrderInfo
          handleShippingMethodChange={handleShippingMethodChange}
          handleNetsuiteOrderTypeChange={handleNetsuiteOrderTypeChange}
          handleDeliveryDateChange={handleDeliveryDateChange}
          handleCancelDateChange={handleCancelDateChange}
          onPurchaseOrderBlurCallback={onPurchaseOrderBlurCallback}
          onDeliveryInstructionsBlurCallback={onDeliveryInstructionsBlurCallback}
          onDepositAmountCallback={onDepositAmountCallback}
          handleSalesPersonChange={handleSalesPersonChange}
          deliveryDate={deliveryDate}
          cancelDate={cancelDate}
        />
      ) : (
        <OrderInfo
          handleShippingMethodChange={handleShippingMethodChange}
          handleNetsuiteOrderTypeChange={handleNetsuiteOrderTypeChange}
          onPurchaseOrderBlurCallback={onPurchaseOrderBlurCallback}
          onDeliveryInstructionsBlurCallback={onDeliveryInstructionsBlurCallback}
          onDepositAmountCallback={onDepositAmountCallback}
          handleSalesPersonChange={handleSalesPersonChange}
        />
      )}

      <StyledBuyplanTotals
        totalPriceLabel={i18next.t("invoice.originalOrderTotal")}
        totalPriceFormatted={invoice.get("original_price_formatted")}
        totalItemsCountLabel={i18next.t("invoice.numberOfItems")}
        totalItemsCount={invoice.get("total_quantity")}
        className="mb-0"
        orderModal={true}
        isDiscounted={invoice.get("is_discounted")}
        originalPriceFormatted={invoice.get("original_price_formatted")}
        padMinOrderAmt={true}
        isPayNowEnable={true}
      />

      {(is_discounted || pay_now_discount || total_postage) && (
        <table className="float-right">
          <tbody>
            <tr>
              <th className="invoice-header pr-4">{i18next.t("invoice.subTotal")}</th>
              <th>{invoice.get("original_price_formatted")}</th>
            </tr>
            <tr><td>--------------------------------------------------------------</td></tr>
            {is_discounted &&
              <>
                <tr>
                  <th className="invoice-header pr-4">
                    {i18next.t("invoice.categoryDiscount")}
                  </th>
                  <th>{invoice.get("category_discount") > 0 ? `-${invoice.get("category_discount_formatted")}` : invoice.get("category_discount_formatted")}</th>
                </tr>
                <tr>
                  <th className="invoice-header pr-4">
                    {i18next.t("invoice.orderDiscount", {
                      value: invoice.get("order_discount_percent")
                    })}
                  </th>
                  <th>{`-${invoice.get("order_discount_formatted")}`}</th>
                </tr>
              </>
            }
            {pay_now_discount && (
              <tr>
                <th className="invoice-header pr-4">{i18next.t("invoice.payNowDiscount")} ({invoice.get('pay_now_discount_percentage')}%)</th>
                <th>{`-${invoice.get("total_instant_pay_discount_price_formatted")}`}</th>
              </tr>
            )
            }
            <tr><td>--------------------------------------------------------------</td></tr>
            {
              <>
                {total_postage && showMonthwisePostage
                  ? invoice.get("monthwise_postage_formatted").entrySeq().map(([month, value]) => (
                      <tr key={month}>
                        <th className="invoice-header pr-4">
                          {i18next.t("invoice.postageAmountMonth", { month: month })}
                        </th>
                        <th>{value}</th>
                      </tr>
                    ))
                  : total_postage && (
                      <tr>
                        <th className="invoice-header pr-4">
                          {i18next.t("invoice.postageAmount")}
                        </th>
                        <th>{invoice.get("total_postage_formatted")}</th>
                      </tr>
                    )}
                <tr><td>--------------------------------------------------------------</td></tr>
              </>
            }
            <tr>
              <th className="invoice-header pr-4">{i18next.t("invoice.totalExcludingTax")}</th>
              <th>{invoice.get("total_price_excluding_tax_formatted")}</th>
            </tr>
            <tr><td>--------------------------------------------------------------</td></tr>
            <tr>
              <th className="invoice-header pr-4">{i18next.t("invoice.taxRate")}(+{invoice.get('pay_now_tax_percentage')}%)</th>
              <th>{invoice.get("total_tax_price_on_pay_now_formatted")}</th>
            </tr>
            <tr><td>--------------------------------------------------------------</td></tr>
            <tr>
              <th className="invoice-header pr-4">{i18next.t("invoice.totalIncludingTax")}</th>
              <th>{invoice.get("total_price_after_postage_on_pay_now_formatted")}</th>
            </tr>
            {is_deposit_payment_enabled && (
              <tr>
                <th className="invoice-header pr-4">{i18next.t("invoice.depositPaymentAmount")}</th>
                <th>
                  <input
                  type="number"
                  name="buyplan[order_detail_attributes][deposit_amount]"
                  id="deposit_amount"
                  onBlur={onDepositAmountCallback}
                  min={depositAmount}
                  max={maxdepositAmount}
                  defaultValue={depositAmount}
                  required
                  />
                </th>
              </tr>
            )
            }
          </tbody>
        </table>
      )}

      <OrderMinimumsContainer className="mt-4" />
    </>
  );
};

const StyledBuyplanTotals = styled(BuyplanTotals)`
  padding-left: 20px;
  border-top: 1px solid #dddddd;
  padding-top: 2ex;
`;

export default React.memo(OrderDialogBody);
