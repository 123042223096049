import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getInvalidOrders,
  resetInvalidQtyProducts
} from "actions/StockModal/invalidQtyProductsActions.js";

import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";
import {
  isLoadingSelector,
  hasErrorsSelector,
  invalidQtyProductsDataSelector
} from "selectors/invalidStockModelQtyProductsSelector.js";

import InvalidQtyProductsDialog from "components/BuyplanReport/InvalidQtyProductsDialog.jsx";
import OrderSummaryDialog from "components/BuyplanReport/StockModal/OrderSummaryDialog.jsx";
import { orderBuyplanSelector } from "selectors/orderDetailsSelector.js";
import V2OrderSummaryDialog from "components/BuyplanReport/StockModal/V2/OrderSummaryDialog.jsx";

const SubmitOrderContainer = ({ isOpen, closeModalCallback }) => {
  const dispatch = useDispatch();
  const stockModelId = useSelector(stockModelIdSelector);
  const warehouseId = useSelector(warehouseIdSelector);
  const isLoading = useSelector(isLoadingSelector);
  const hasErrors = useSelector(hasErrorsSelector);
  const invalidQtyProductsData = useSelector(invalidQtyProductsDataSelector);
  const areAllProductQtsValid = invalidQtyProductsData.size === 0;
  const buyplan = useSelector(orderBuyplanSelector);

  React.useEffect(() => {
    isOpen && dispatch(getInvalidOrders(stockModelId, warehouseId));

    return () => dispatch(resetInvalidQtyProducts());
  }, [dispatch, isOpen, stockModelId, warehouseId]);

  return areAllProductQtsValid ? (
    buyplan.get('enable_pay_now') ?
    (<V2OrderSummaryDialog
      isOpen={isOpen}
      closeModalCallback={closeModalCallback}
    />) :
    (<OrderSummaryDialog
      isOpen={isOpen}
      closeModalCallback={closeModalCallback}
    />)
    ) : (
    <InvalidQtyProductsDialog
      isLoading={isLoading}
      hasErrors={hasErrors}
      invalidQtyProductsData={invalidQtyProductsData}
      isOpen={isOpen}
      closeModalCallback={closeModalCallback}
    />
  );
};

export default React.memo(SubmitOrderContainer);
