import { takeLatest, put } from "redux-saga/effects";

import { bannerInfoActions } from "actions/bannerInfoActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestBannerInfo = function*(action) {
  const { releaseId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/releases/${releaseId}/banner_info.json`,
    actions: bannerInfoActions,
    params
  });
};

export const watchRequestBannerInfo = function*() {
  yield takeLatest(bannerInfoActions.request, requestBannerInfo);
};
