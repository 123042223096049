import { fromJS } from "immutable";

import { generateShortUrlActions } from "actions/generateShortUrlActions.js";

const initialState = fromJS({
  isLoading: false,
  hasErrors: false,
  data: {
    short_link: null
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case generateShortUrlActions.startLoader:
      return state.set("isLoading", true);
    case generateShortUrlActions.reset:
      return initialState;
    case generateShortUrlActions.success:
      return state.merge({
        isLoading: false,
        hasErrors: false,
        data: fromJS(action.payload.data)
      });
    case generateShortUrlActions.failure:
      return state.merge({
        isLoading: false,
        hasErrors: true,
        data: fromJS(action.payload.data)
      });
    default:
      return state;
  }
};
