import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/browser";

import * as serviceWorker from "./serviceWorker.js";

import store from "./store.js";
import { apiBaseUrl } from "apis/apiHelpers.js";

import App from "components/App/App.jsx";
import "localisation/i18n";
import { Suspense } from "react";

Sentry.init({
  dsn: "https://6603975fc1e34f7897a1c729f44addee@sentry.io/2258210"
});

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        key="index-css"
        href={`${apiBaseUrl}/webslidemenu/index.css`}
      />
    </Helmet>
    <Suspense fallback="...is loading">
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("react-app-root")
);

serviceWorker.unregister();

window.onbeforeunload = () => {
  sessionStorage.removeItem("authToken");
};
