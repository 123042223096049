import { takeLatest, put } from "redux-saga/effects";

import { warehouseActions } from "actions/warehouseActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestWarehouse = function*(action) {
  const { buyplanId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/buyplans/${buyplanId}/warehouse_info`,
    actions: warehouseActions,
    params
  });
};

export const watchRequesWarehouse = function*() {
  yield takeLatest(warehouseActions.request, requestWarehouse);
};

export const requestWarehouseForStockModel = function*(action) {
  const { stockModalId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/stock_models/${stockModalId}/warehouse_info`,
    actions: warehouseActions,
    params
  });
};

export const watchRequesWarehouseForStockModel = function*() {
  yield takeLatest(warehouseActions.requestForStockModel, requestWarehouseForStockModel);
};
