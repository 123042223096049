import React from "react";
import styled from "styled-components";

import MonthReportTableHead from "components/BuyplanReport/MonthReportTableHead.jsx";
import MonthReportTableBody from "components/BuyplanReport/MonthReportTableBody.jsx";

const MonthReportTable = ({
  headerText, shouldCenterAlignHeader, totalsObj, className
}) => {
  return (
    <table className={ `${ className } table month_report` }>
      <thead>
        <tr>
          <MonthReportTableHead
            headerText={ headerText }
            shouldCenterAlignHeader={ shouldCenterAlignHeader }
          />
        </tr>
      </thead>

      <tbody>
        {
          totalsObj && totalsObj.entrySeq().map(([ name, obj ]) => {
            return (
              <MonthReportTableBody
                key={ name }
                name={ name }
                obj={ obj }
              />
            )
          })
        }
      </tbody>
    </table>
  );
};

const StyledMonthReportTable = styled(MonthReportTable)`
  thead {

    th {
      padding: 0 10px;
      border-top: 0 none;
      border-bottom-width: 1px;

      h3 {
        color: var(--bs-dark-navy);
        font-size: 18px;
        line-height: 40px;
        font-weight: bold;
        margin: 10px 0;

        @media (max-width: 575.98px) {
          font-size: 14px;
          line-height: 18px;
          margin: 0 0 4px;
        }
      }
    }
  }

  tbody {

    tr {
      border: 1px solid #ddd;

      &:first-child {

        th{
          padding: 7px 20px;

          @media (max-width: 575.98px) {
            padding: 5px 20px;
          }
        }
      }
    }

    th {
      padding: 5px 20px;

      h4, h5, h6 {
        font-weight: bold;
        margin: 0;
      }

      h4 {
        font-size: 14px;
        line-height: 24px;

        @media (max-width: 575.98px) {
          font-size: 13px;
          line-height: 17px;
        }
      }

      h5 {
        font-size: 13px;
        line-height: 20px;

        @media (max-width: 575.98px) {
          font-size: 12px;
          line-height: 16px;
        }
      }

      h6 {
        color: var(--bs-dark-black);
        font-size: 13px;
        line-height: 20px;

        @media (max-width: 575.98px) {
          font-size: 11px;
          line-height: 15px;
        }
      }

      &:last-child h4, &:last-child h5, &:last-child h6 {
        text-align: right;
      }
    }
  }
`;

export default React.memo(StyledMonthReportTable);
