import { fromJS } from "immutable";

import { orderDetailsActions } from "actions/orderDetailsActions.js";
import { buyplanTotalsActions } from "actions/buyplanTotalsActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    invoice: {
      is_discounted: null,
      original_price_formatted: null,
      total_price_formatted: null,
      total_quantity: null,
      totals_header: null,
      category_discount_formatted: null,
      total_price_with_category_discount_formatted: null,
      order_discount_formatted: null,
      order_discount_percent: null,
      monthwise_postage: {},
      monthwise_postage_formatted: {},
      category_rules: [
        {
          name: null,
          original_price_formatted: null,
          discount_percent: null,
          discounted_amount_formatted: null,
          discounted_price_formatted: null,
        },
      ],
    },
    buyplan: {
      shipping_code_required: null,
      order_note: null,
      show_delivery_instructions: null,
      delivery_instructions: null,
      customer_purchase_order: null,
      company_name: null,
      retailer_name: null,
      accept_payments: null,
      payment_enabled: null,
      show_shipping_methods: null,
      shipping_methods: [],
      selected_shipping_method: null,
      agreement_acceptance_data: [
        {
          agreement_acceptance: null,
          agreement_text: null,
          agreement_pdf: null
        }
      ],
      sales_person: null,
      sales_person_list: [],
      is_buyplan_ownership_enforced: null
    },
    billing_address: {
      id: null,
      addr_type: null,
      status: null,
      line1: null,
      line2: null,
      suburb_town: null,
      state: null,
      country: null,
      post_code: null,
      has_shipping_code: null,
    },
    shipping_address_default: {
      id: null,
      addr_type: null,
      status: null,
      line1: null,
      line2: null,
      suburb_town: null,
      state: null,
      country: null,
      post_code: null,
      has_shipping_code: null,
    },
    shipping_address_alt1: {
      id: null,
      addr_type: null,
      status: null,
      line1: null,
      line2: null,
      suburb_town: null,
      state: null,
      country: null,
      post_code: null,
      has_shipping_code: null,
    },
    shipping_address_alt2: {
      id: null,
      addr_type: null,
      status: null,
      line1: null,
      line2: null,
      suburb_town: null,
      state: null,
      country: null,
      post_code: null,
      has_shipping_code: null,
    },
    order_minimums: {
      apply_order_minimums: null,
      enforce_order_minimums: null,
      enforce_order_not_met_message: null,
      minimum_order_amount: null,
      is_min_order_criteria_met: null,
      is_acknowledged: null
    },
    is_order_approval_required: null,
    order_approval_rule_msg: null
  }
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case orderDetailsActions.startLoader:
      return state.set("isLoading", true);
    case orderDetailsActions.success:
      let orderMinimums = payload.data.order_minimums;

      if (orderMinimums && !orderMinimums.is_acknowledged) {
        orderMinimums.is_acknowledged = false;
      }

      return state
        .merge({
          hasErrors: false,
          isLoading: false,
        })
        .set("data", fromJS(payload.data))
        .mergeIn(
          ["data"],
          fromJS({
            selectedShippingAddrId: +payload.data.shipping_address_default.id,
          })
        );
    case orderDetailsActions.failure:
      return state
        .merge({
          hasErrors: true,
          isLoading: false,
        })
        .set("data", fromJS(payload.data));
    case orderDetailsActions.setSelectedShippingAddress:
      return state.mergeIn(
        ["data"],
        fromJS({
          selectedShippingAddrId: payload.selectedShippingAddrId,
        })
      );
    case orderDetailsActions.toggleMinOrderConfirmationAck:
      return state.setIn(
        ["data", "order_minimums", "is_acknowledged"],
        payload.isAcknowledged
      );
    case buyplanTotalsActions.success:
      return state.mergeIn(
        ["data", "order_minimums"],
        fromJS(payload.data.order_minimums)
      );
    case orderDetailsActions.setSalesPersonInOrderDetails:
      if (state.getIn(["data", "stock_model"])) {
        return state.setIn(["data", "stock_model", "sales_person"], fromJS(payload))
      } else {
        return state.setIn(["data", "buyplan", "sales_person"], fromJS(payload))
      }
    case orderDetailsActions.reset:
      return initialState
    default:
      return state;
  }
};
