export const orderValidationsActions = {
  startLoader: "START_ORDER_VALIDATIONS_LOADER",
  request: "REQUEST_ORDER_VALIDATIONS",
  requestStockModalValidations: "REQUEST_STOCK_MODAL_ORDER_VALIDATIONS",
  success: "ORDER_VALIDATIONS_SUCCESS",
  failure: "ORDER_VALIDATIONS_FAILURE",
  reset: "RESET_ORDER_VALIDATIONS",
};

export const getOrderValidations = (buyplanId, { buyplan }) => {
  return {
    type: orderValidationsActions.request,
    payload: {
      buyplanId,
      buyplan
    },
  };
};

export const getStockModalOrderValidations = (stockModalId, { stock_model }) => {
  return {
    type: orderValidationsActions.requestStockModalValidations,
    payload: {
      stockModalId,
      stock_model
    },
  };
};

export const getOrderValidationsSuccess = (payload) => {
  return {
    type: orderValidationsActions.success,
    payload,
  };
};

export const getOrderValidationsFailure = (errorPayload) => {
  return {
    type: orderValidationsActions.failure,
    payload: errorPayload,
  };
};
