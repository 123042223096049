import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";

import { updateActiveTab } from "actions/filterActions.js";

import ProductReleaseContainer from "containers/ProductReleaseContainer.jsx";
import QuantityGridContainer from "containers/QuantityGridContainer.jsx";
import ViewAtsContainer from "containers/ViewAtsContainer.jsx";

import BsNavPills from "components/BsNavPills.js";

const TabsList = ({ tabsConfig }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const { hash } = useLocation();
  const tabIds = useMemo(() => Object.keys(tabsConfig), [tabsConfig]);
  const defaultTabId = ["#buyplan_detail", "#qfill_detail"].includes(hash)
    ? tabIds[1]
    : tabIds[0];
  const [activeTabId, setActiveTabId] = useState(defaultTabId);
  const [tabBody, setTabBody] = useState();

  const changeActiveTab = evt => {
    const selectedTabId = evt.target.dataset.tabId;
    setActiveTabId(selectedTabId);
  };

  useEffect(() => {
    const component = activeTabId && tabsConfig[activeTabId].body;
    dispatch(updateActiveTab(activeTabId));
    setTabBody(component);
  }, [dispatch, activeTabId, tabsConfig]);

  return (
    <>
      <BsNavPills>
        {tabIds.map(k => {
          return (
            <NavItem key={k}>
              <NavLink
                className={activeTabId === k ? "active" : ""}
                data-tab-id={k}
                onClick={changeActiveTab}
              >
                {tabsConfig[k].title}
              </NavLink>
            </NavItem>
          );
        })}
      </BsNavPills>

      <TabContent activeTab={activeTabId}>
        <TabPane tabId={activeTabId}>
          {tabBody}

          <Switch>
            <Route
              path={`${path}/:productReleaseId/quick-view`}
              exact
              component={ProductReleaseContainer}
            />

            <Route
              path={`${path}/:productReleaseId/quantity-grid`}
              exact
              component={QuantityGridContainer}
            />

            <Route
              path={`${path}/:productId/ats-view`}
              exact
              component={ViewAtsContainer}
            />
          </Switch>
        </TabPane>
      </TabContent>
    </>
  );
};

export default React.memo(TabsList);
