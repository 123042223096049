import React from "react";
import styled from "styled-components";

const CategoryHeader = ({
  appliedCategories,
  onHomeClick,
  onLevel1Click,
  onLevel2Click
}) => {
  return (
    <div className="mb-2">
      <StyledCategoryHeader>
        <span
          onClick={onHomeClick}
          href="#menu_item"
          style={{ cursor: "pointer" }}
        >
          Home
        </span>
      </StyledCategoryHeader>
      {appliedCategories.get("level1") && (
        <StyledCategoryHeader>
          &nbsp; > &nbsp;
          <a onClick={onLevel1Click} href="#menu_item">
            {appliedCategories.get("level1")}
          </a>
        </StyledCategoryHeader>
      )}
      {appliedCategories.get("level2") && (
        <StyledCategoryHeader>
          &nbsp; > &nbsp;
          <a
            href="#menu_item"
            onClick={onLevel2Click}
            data-level1={appliedCategories.get("level1")}
          >
            {appliedCategories.get("level2")}
          </a>
        </StyledCategoryHeader>
      )}
      {appliedCategories.get("level3") && (
        <StyledCategoryHeader>
          &nbsp; > &nbsp;
          {appliedCategories.get("level3")}
        </StyledCategoryHeader>
      )}
    </div>
  );
};

const StyledCategoryHeader = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: var(--bs-dark-black);

  > a {
    color: var(--bs-dark-black);
  }
`;

export default React.memo(CategoryHeader);
