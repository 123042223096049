import React from "react";
import styled from "styled-components";
import Zoom from "components/ProductCard/Actions/Zoom/Zoom.jsx";
const SurroundView = ({ isThreeSixty, tenantId, brandScopeTag }) => {
  const baseUrl = process.env.REACT_APP_ORB_API_BASE_URL;
  const orbTagKey = process.env.REACT_APP_ORB_TAG_KEY;
  const orbParams = new URLSearchParams({
    tenant: tenantId,
    endpoint: "tag",
    key: orbTagKey,
    q: brandScopeTag,
    split: ",",
    duration: 5000,
    zoom: true,
    allowFullscreen: true,
    playMode: "continuous",
    hideControls: true,
    hideAudioControls: false
  });
  const OrbUrl = `${baseUrl}/?${orbParams.toString()};`;
  return (
    <>
      <Zoom
        isThreeSixty={isThreeSixty}
        tenantId={tenantId}
        brandScopeTag={brandScopeTag}
        OrbUrl={OrbUrl}
      />
    </>
  );
};

const StyledSurroundView = styled(SurroundView)`
  &.modal-preview {
    @media (max-width: 575.98px) {
      padding-top: 60px;
      height: calc(100vh - 60px);
    }

    .modal-content {
      border-radius: 0;

      @media (max-width: 575.98px) {
        height: calc(100vh - 60px);
      }
    }
  }

  button.close {
    position: absolute;
    top: 13px;
    right: 4px;
    color: #000;
    opacity: 1;

    span {
      display: block;
      font-size: 70px;
      line-height: 40px;
    }
  }

  .modal-body {
    height: 100vh;
    @media (max-width: 575.98px) {
      display: flex;
      align-items: center;
    }

    > img {
      height: 100vh;
      width: auto;
      margin-top: 0;

      @media (max-width: 575.98px) {
        width: 100%;
        height: auto;
      }
    }
  }
  margin-bottom: 1ex;
  cursor: pointer;

  svg {
    width: 1.6em;
    height: auto;
    stroke: #bdbdbd;
  }

  #surroundingFrame {
    display: block;
    margin: 0 auto;
  }
`;

export default React.memo(StyledSurroundView);
