import React from "react";

const CategorySubMenu = ({
  level1Menu,
  level2Menu,
  menuList,
  onLevel2Click,
  onLevel3Click
}) => {
  return (
    <div className="col-lg-6 col-md-12">
      <ul className="wstliststy06 clearfix">
        <li className="wstheading clearfix" key={level2Menu}>
          <a href="#menu_item" onClick={onLevel2Click} data-level1={level1Menu}>
            {level2Menu}
          </a>
        </li>

        {menuList.sort().map((menu, index) => {
          return (
            <li key={index}>
              <a
                href="#menu_item"
                onClick={onLevel3Click}
                data-level2={level2Menu}
                data-level1={level1Menu}
              >
                {menu}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default React.memo(CategorySubMenu);
