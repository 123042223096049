import React from "react";

const InvalidQtyProductsDialogBody = ({ invalidQtyProductsData }) => (
  <table className="w-100">
    <thead className="table-active">
      <tr>
        <th className="pl-2">Product</th>
        <th>SKU</th>
      </tr>
    </thead>

    <tbody>
      {invalidQtyProductsData.size === 0 && (
        <div className="pl-2">No products to display</div>
      )}
      {invalidQtyProductsData.map(invalidProduct => (
        <tr key={invalidProduct.get("product_sku")}>
          <td className="pl-2 text-uppercase">
            {invalidProduct.get("product_name")}
          </td>

          <td>{invalidProduct.get("product_sku")}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default React.memo(InvalidQtyProductsDialogBody);
