import { createSelector } from "reselect";

export const bannerInfoReducerSelector = createSelector(
  state => state.get("bannerInfoReducer"),
  bannerInfoReducer => bannerInfoReducer
);

export const isLoadingSelector = createSelector(
  bannerInfoReducerSelector,
  bannerInfoReducer => bannerInfoReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  bannerInfoReducerSelector,
  bannerInfoReducer => bannerInfoReducer.get("hasErrors")
);

export const bannerInfoDataSelector = createSelector(
  bannerInfoReducerSelector,
  bannerInfoReducer => bannerInfoReducer.get("data")
);
