import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import TableHead from "components/QuantityGrid/TableHead.jsx";
import TableBody from "components/QuantityGrid/TableBody.jsx";
import TableFooter from "components/QuantityGrid/TableFooter.jsx";

import { updateQuantityGrid } from "actions/quantityGridActions.js";

import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";
import { showSizeLevelPricingSelector } from "selectors/quantityGridSelector.js";

import "./QtyGridForm.css";
import { showPreOrderMonthSelector, preOrderCustomTextSelector } from "selectors/buyplanInfoSelector";

export const BrandContext = React.createContext(true);

const QtyGridForm = ({
  deliveryMonths,
  attrsList,
  headers,
  sizeMinimums,
  filledMonths,
  intendedMonths,
  qtyGridProductRelease,
  is_pre_order_product,
  pre_order_month_and_date,
  pre_order_message,
  preOrderIntendedMonths,
  preOrderFilledMonths,
  bpsbToHighlight
}) => {
  const { brandId } = useParams();
  const productRequirements = qtyGridProductRelease.get("product_requirements");
  const dispatch = useDispatch();
  const buyplanId = useSelector(buyplanIdSelector);
  const warehouseId = useSelector(warehouseIdSelector);
  const isPricingDetailsVisible = useSelector(showSizeLevelPricingSelector);
  const showPreOrderMonth = useSelector(showPreOrderMonthSelector);
  const preOrderCustomText = useSelector(preOrderCustomTextSelector);

  const isMinOrderCheckApplicable = () => {
    const is_check_flag_on = qtyGridProductRelease.get("is_min_order_check_applicable")
    const is_value_positive = deliveryMonths?.first()?.get("month_min_order_qty") > 0
    return is_check_flag_on && is_value_positive
  }

  const onSubmitCallback = useCallback(
    ({ sizeBreakId, quantity }) => {
      dispatch(
        updateQuantityGrid({
          buyplanId,
          sizeBreakId: sizeBreakId,
          warehouse_id: warehouseId,
          quantity: quantity
        })
      );
    },
    [dispatch, buyplanId, warehouseId]
  );

  return (
    <form className="quantity-grid-form table-responsive">
      <div className="attribute_table_wrap">
        <table className="attribute_table pricing_opened attribute_opened">
          <BrandContext.Provider value={!!brandId}>
            <TableHead
              headers={headers}
              sizeMinimums={sizeMinimums}
              isMinOrderCheckApplicable={isMinOrderCheckApplicable()}
            />

            <tbody>
              <TableBody
                deliveryMonths={deliveryMonths}
                headers={headers}
                filledMonths={filledMonths}
                intendedMonths={intendedMonths}
                onSubmitCallback={onSubmitCallback}
                attrsList={attrsList}
                isMinOrderCheckApplicable={isMinOrderCheckApplicable()}
                bpsbToHighlight={bpsbToHighlight}
              />
            </tbody>

            {is_pre_order_product && (
              <tfoot className="leadTimeDetails">
                <tr>
                  <td colSpan="1500">{pre_order_message}</td>
                </tr>

                <TableBody
                  deliveryMonths={pre_order_month_and_date}
                  headers={headers}
                  filledMonths={preOrderFilledMonths}
                  intendedMonths={preOrderIntendedMonths}
                  onSubmitCallback={onSubmitCallback}
                  attrsList={attrsList}
                  showPreOrderMonth={showPreOrderMonth}
                  preOrderCustomText={preOrderCustomText}
                  isMinOrderCheckApplicable={false}
                  bpsbToHighlight={bpsbToHighlight}
                />
              </tfoot>
            )}

            {isPricingDetailsVisible && (
              <TableFooter
                deliveryMonths={deliveryMonths}
                attrsList={attrsList}
                headers={headers}
                isMinOrderCheckApplicable={isMinOrderCheckApplicable()}
              />
            )}
          </BrandContext.Provider>
        </table>
      </div>
      <StlyedPoRequirements className="po-requirements text-wrap">
        {productRequirements.map(productRequirement => {
          return `* ${productRequirement} `;
        })}
      </StlyedPoRequirements>
    </form>
  );
};

const StlyedPoRequirements = styled.div`
  width: 100%;
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 20px;
`;

export default React.memo(QtyGridForm);
