import React from "react";
import { Row, Col, Label, Input } from 'reactstrap';


const CopyAsTemplateBody = ({onCopyNameBlurCallback}) => {

  return (
    <Row>
      <Col sm={6}>
        <Label for="name">Template Name</Label>
      </Col>
      <Col sm={6}>
        <Input
          type="text"
          name="copy_name"
          onBlur={ onCopyNameBlurCallback }
        />
      </Col>
    </Row>
  );
};

export default React.memo(CopyAsTemplateBody);
