import React from "react";

const ProductSpecifications = ({ specifications }) => (
  <>
    <section className="product-information">
      <h6 className="font-weight-bold">Product Information</h6>

      <ul>
        {specifications &&
          specifications.size > 0 &&
          specifications.map(val => {
            return <li key={val}>{val}</li>;
          })}
      </ul>
    </section>

    <hr />
  </>
);

export default React.memo(ProductSpecifications);
