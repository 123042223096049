import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { removeSizeBreak } from "actions/sizeBreakActions.js";

import Thumbnail from "components/ProductCard/Thumbnail/Thumbnail.jsx";
import Caption from "components/ProductCard/Caption/Caption.jsx";
import Quantity from "components/ProductCard/Actions/Quantity/Quantity.jsx";
import BsTrash from "components/BsTrash.jsx";
import { currentUserDataSelector } from "selectors/currentUserSelector";
import { productReleaseAbilitiesSelector } from "selectors/productReleaseSelector";
import { showDiscountPercentSelector } from "selectors/productsSelector";

const BuyplanSummaryItemCard = ({
  className,
  buyplanId,
  buyplanItem,
  releaseId,
  isPreOrder = false
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserDataSelector);
  const abilities = useSelector(productReleaseAbilitiesSelector);
  const buyplanItemId = buyplanItem.get("id");
  const productReleaseId = buyplanItem.get("product_release_id");
  const sizeBreak = buyplanItem.get("selected_month");
  const showDiscountPercent = useSelector(showDiscountPercentSelector);
  const handleBuyplanItemRemoval = useCallback(() => {
    dispatch(
      removeSizeBreak(buyplanId, buyplanItemId, productReleaseId, sizeBreak, isPreOrder, {
        refreshBuyplanSummary: true,
        releaseId
      })
    );
  }, [dispatch, buyplanId, buyplanItemId, productReleaseId, sizeBreak, isPreOrder, releaseId]);

  return (
    <div className={`${className} buyplan-item-card`} data-buyplan-item-id={buyplanItemId}>
      <Thumbnail
        buyplanId={buyplanId}
        productReleaseId={buyplanItem.get("product_release_id")}
        imgSrc={buyplanItem.get("primary_image")}
        productCallout={buyplanItem.get("callout_label")}
        calloutBgColor={buyplanItem.get("callout_bg_color")}
        calloutBrColor={buyplanItem.get("callout_br_color")}
        calloutTxtColor={buyplanItem.get("callout_txt_color")}
        calloutIcon={buyplanItem.get("callout_icon")}
      />

      {sizeBreak && (
        <>
          <Quantity
            className="qty-box"
            productReleaseId={buyplanItem.get("product_release_id")}
            totalQty={buyplanItem.get("total_quantity")}
            buyplanItemId={buyplanItem.get("id")}
          />

          <BsTrash onClickHandler={handleBuyplanItemRemoval} />
        </>
      )}

      <Caption
        name={buyplanItem.get("name")}
        color={buyplanItem.get("colour")}
        hideWholesalePrice={currentUser.get("hide_ws_price")}
        retailPriceFormatted={buyplanItem.get("retail_price_formatted")}
        displayUnitRetailPrice={buyplanItem.get("unit_retail_price")}
        isDiscounted={buyplanItem.get("is_discounted")}
        pbDiscounted={buyplanItem.get("pb_discounted")}
        effectiveDisplayWholesalePrice={buyplanItem.get("discounted_wholesale_price")}
        discountedDisplayWholesaleRange={buyplanItem.get("discounted_display_wholesale_range")}
        discountedDisplayWholesalePrice={buyplanItem.get("discounted_wholesale_price")}
        showWsRange={abilities.get("can_view_wholesale_range")}
        displayWholesalePrice={buyplanItem.get("wholesale_price")}
        displayWholesaleRange={buyplanItem.get("display_wholesale_range")}
        pbPercentOff={buyplanItem.get("pb_percent_off")}
        showDiscountPercent={showDiscountPercent}
      />
    </div>
  );
};

const StyledBuyplanSummaryItemCard = styled(BuyplanSummaryItemCard)`
  position: relative;
  display: inline-grid;
  width: 12em;
  margin: 1em 2em;

  .qty-box {
    position: absolute;
    top: 12%;
    right: -15%;
  }

  .trash-box {
    position: absolute;
    top: 26%;
    right: -15%;
  }
`;

export default React.memo(StyledBuyplanSummaryItemCard);
