import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TabPane } from "reactstrap";
import Select from "react-select";

import Loader, { LoaderIcon } from "components/Loader/Loader.jsx";
import CopyToClipboard from "components/CopyToClipboard.jsx";

import { listShortUrls } from "actions/listShortUrlsActions.js";
import {
  overwriteShortUrl,
  resetShortUrl
} from "actions/overwriteShortUrlActions.js";

import {
  isLoadingSelector,
  listShortUrlsDataSelector
} from "selectors/listShortUrlsSelector.js";

import {
  isLoadingSelector as isUpdaingShortUrlSelector,
  overwriteShortUrlDataSelector
} from "selectors/overwriteShortUrlSelector.js";
import i18next from "i18next";

const ListMarketingLinks = () => {
  const dispatch = useDispatch();
  const { releaseId, brandId } = useParams();

  useEffect(() => {
    dispatch(listShortUrls({ release_id: releaseId, brand_id: brandId }));

    return () => {
      dispatch(resetShortUrl());
    };
  }, [dispatch, releaseId, brandId]);

  const isLoading = useSelector(isLoadingSelector);
  const isUpdaingShortUrl = useSelector(isUpdaingShortUrlSelector);
  const availableShortLinks = useSelector(listShortUrlsDataSelector);
  const updatedShortLinkData = useSelector(overwriteShortUrlDataSelector);
  const updatedShortLink =
    updatedShortLinkData && updatedShortLinkData.get("short_link");

  const options = useMemo(() => {
    return availableShortLinks?.map(filterObj => ({
      value: filterObj.get("code"),
      label: filterObj.get("name")
    }));
  }, [availableShortLinks]);

  const handleFormSubmission = evt => {
    evt.preventDefault();

    const formData = new FormData(evt.target);

    dispatch(
      overwriteShortUrl({
        code: formData.get("selected-filter")
      })
    );
  };

  if (isLoading) {
    return <Loader className="h1 mt-4" />;
  }

  return (
    <TabPane tabId="overwrite-existing-link">
      <form
        className="overwrite-marketing-links d-flex mt-4 mb-4"
        onSubmit={handleFormSubmission}
      >
        <Select
          options={options}
          className="d-inline-block w-100"
          name="selected-filter"
          placeholder={i18next.t("select.default")}
          noOptionsMessage={() => i18next.t("select.noOptions")}
        />

        <button
          type="submit"
          className="btn btn-primary ml-2"
          disabled={isUpdaingShortUrl}
        >
          {isUpdaingShortUrl ? (
            <LoaderIcon />
          ) : (
            i18next.t("sidebar.marketingLinks.update")
          )}
        </button>
      </form>

      {updatedShortLink && (
        <>
          <h6>{`${i18next.t("sidebar.marketingLinks.sharableUrl")}:`}</h6>

          <CopyToClipboard defaultValue={updatedShortLink} />
        </>
      )}
    </TabPane>
  );
};

export default React.memo(ListMarketingLinks);
