import { fromJS } from "immutable";

import { warehouseActions } from "actions/warehouseActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  error: null,
  data: {
    multiple_warehouses: false,
    warehouse_id: null,
    warehouses: []
  }
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case warehouseActions.startLoader:
      return state.set("isLoading", true);
    case warehouseActions.success:
      return state
        .merge({
          hasErrors: false,
          isLoading: false,
          error: null
        })
        .set("data", fromJS(payload.data));
    case warehouseActions.update:
      let data = state.get("data")
      return state
        .merge({
          data: data.merge({ warehouse_id: payload.warehouseId })
        });
    case warehouseActions.failure:
      return state
        .merge({
          hasErrors: true,
          isLoading: false
        })
        .set("error", fromJS(payload.data));
    default:
      return state;
  }
};
