export const orderActions = {
  startLoader: "START_ORDER_SUBMISSION_LOADER",
  request: "REQUEST_ORDER_SUBMISSION",
  consignment_request: "REQUEST_CONSIGNMENT_REQUEST",
  requestStockModalOrderSubmission: "REQUEST_STOCK_MODAL_ORDER_SUBMISSION",
  success: "ORDER_SUBMISSION_SUCCESS",
  failure: "ORDER_SUBMISSION_FAILURE",
};

export const getOrder = (buyplanId, { buyplan }) => {
  return {
    type: orderActions.request,
    payload: {
      buyplanId,
      buyplan,
    },
  };
};

export const getConsignmentRequest = (buyplanId, { buyplan }) => {
  return {
    type: orderActions.consignment_request,
    payload: {
      buyplanId,
      buyplan,
    },
  };
};

export const getStockModalOrder = (stockModalId, { buyplan, stock_model }) => {
  return {
    type: orderActions.requestStockModalOrderSubmission,
    payload: {
      stockModalId,
      buyplan,
      stock_model,
    },
  };
};

export const getOrderSuccess = (payload) => {
  return {
    type: orderActions.success,
    payload,
  };
};

export const getOrderFailure = (errorPayload) => {
  return {
    type: orderActions.failure,
    payload: errorPayload,
  };
};
