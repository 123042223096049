import { fromJS } from "immutable";

import { buyplanSummaryActions } from "actions/buyplanSummaryActions.js";
import { buyplanTotalsActions } from "actions/buyplanTotalsActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    abilities: {
      can_edit_buyplan: null
    },
    brand: {
      is_cumulative_ats: null,
      is_default_ats_zero: null
    },
    buyplan: {
      id: null,
      state: null,
      total_price: null,
      total_price_formatted: null,
      total_items_count: null
    },
    buyplan_items: {}
  }
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case buyplanSummaryActions.startLoader:
      return state.set("isLoading", true);
    case buyplanSummaryActions.success:
      return state
        .merge({
          hasErrors: false,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case buyplanSummaryActions.failure:
      return state
        .merge({
          hasErrors: true,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case buyplanSummaryActions.update:
      const item = state.getIn([
        "data",
        "buyplan_items",
        payload.sourceDroppableId,
        "items",
        payload.sourceItemIndex
      ]);
      let items = state.getIn([
        "data",
        "buyplan_items",
        payload.destinationDroppableId,
        "items"
      ]);
      items = items && items.splice(payload.destinationItemIndex, 0, item);
      return state
        .deleteIn([
          "data",
          "buyplan_items",
          payload.sourceDroppableId,
          "items",
          payload.sourceItemIndex
        ])
        .setIn(
          ["data", "buyplan_items", payload.destinationDroppableId, "items"],
          items
        );
    case buyplanTotalsActions.success:
      return state.mergeIn(["data", "buyplan"], {
        total_price: payload.data.total_price,
        total_price_formatted: payload.data.total_price_formatted,
        total_items_count: payload.data.total_items_count
      });
    default:
      return state;
  }
};
