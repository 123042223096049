import React, { useState } from "react";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import styled from "styled-components";
const ImageGallery = ({ images, OrbUrl }) => {
  const [index, changeIndex] = useState(0);
  //const length = images.size;
  let imageList = images.toList().toJS();

  OrbUrl && imageList.unshift(OrbUrl);
  const length = imageList.size;

  return (
    <>
      <div className="row">
        <div className="col-1 align-self-center">
          {index !== 0 && (
            <span
              onClick={() => {
                changeIndex(index - 1);
              }}
            >
              <FaLessThan />
            </span>
          )}
        </div>
        <div className="col-10">
          {OrbUrl && index === 0 ? (
             <iframe
             id = "surroundingFrame"
             title="Orb 360"
             width="96%"
             height="100%"
             frameBorder="0"
             allowFullScreen
             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
             src= {imageList[index]}
           ></iframe>
          ) :(
          <StyledImg
            className="img-fluid"
            src={imageList[index]}
            alt=""
          />)}

        </div>
        <div className="col-1 align-self-center">
          {index !== length - 1 && (
            <span
              onClick={() => {
                changeIndex(index + 1);
              }}
            >
              <FaGreaterThan />
            </span>
          )}
        </div>
      </div>
    </>
  );
};

const StyledImg = styled.img`
  max-height: 99vh;
`;

export default React.memo(ImageGallery);
