import React, { useReducer, useMemo, useContext } from "react";
import { useSelector } from "react-redux";

import { ATS_STATUSES } from "constants.js";
import { addNotification } from "notificationStore.js";
import { isStateConsignmentRequest } from "utils.js";

import { isBadgeSelected } from "selectors/productsSelector.js";
import { buyplanStateSelector } from "selectors/buyplanIdSelector.js";
import { getInitialState, reducer } from "reducers/sizeBreakInputReducer";

import { BrandContext } from "components/QuantityGrid/QtyGridForm.jsx";

import StyledBadge from "components/CustomBadge/index.jsx";
import SizeBreakInput from "components/QuantityGrid/SizeBreakInput.jsx";
import BsDeliveryIcon from "components/BsDeliveryIcon.js";
import greenTick from "assets/images/tick.png";
import redCross from "assets/images/cross.png";

const TableBody = ({
  deliveryMonths,
  headers,
  intendedMonths,
  filledMonths,
  onSubmitCallback,
  attrsList,
  showPreOrderMonth = true,
  preOrderCustomText,
  isMinOrderCheckApplicable,
  bpsbToHighlight
}) => {
  const isBrandContext = useContext(BrandContext);
  const initialReducerState = useMemo(() => getInitialState(), []);
  const [state, dispatch] = useReducer(reducer, initialReducerState);

  const handleOnBlur = evt => _handleOnBlur(evt, onSubmitCallback, state);
  const errorMsgForToastr = state.get("errorMsg");
  const buyplanState = useSelector(buyplanStateSelector);

  React.useEffect(() => {
    errorMsgForToastr &&
      addNotification({
        type: "danger",
        message: errorMsgForToastr
      });
  }, [errorMsgForToastr]);

  return (
    attrsList &&
    deliveryMonths &&
    deliveryMonths.map(deliveryMonthObj => {
      const deliveryMonthShort = deliveryMonthObj.get("delivery_month_short");
      const isPastMonth = deliveryMonthObj.get("is_past_month");
      const showPoTruck = deliveryMonthObj.get("show_po_truck");
      const isFilled = isBadgeSelected(filledMonths, deliveryMonthShort);
      const isIntended = isBadgeSelected(intendedMonths, deliveryMonthShort);
      const onlyHeaders = headers.slice(1, headers.length);
      const currentMonthQty = deliveryMonthObj.get("current_month_qty");
      const monthMinOrderQty = deliveryMonthObj.get("month_min_order_qty");
      const monthMinConditionSatisfied = currentMonthQty >= monthMinOrderQty

      return (
        deliveryMonthObj &&
        attrsList.map((attrRowKey, attrRowIndex) => {
          const attrRowObj = deliveryMonthObj.getIn(["attr_rows", attrRowKey]);
          return (
            <tr key={`tr-${attrRowKey}-${deliveryMonthShort}`}>
              <td className="row-header">
                <div className="att_icon">
                  {attrRowIndex === 0 && !isBrandContext && showPoTruck && <BsDeliveryIcon />}

                  {attrRowIndex === 0 && !isBrandContext && (
                    <StyledBadge
                      className="ml-2"
                      disabled={isPastMonth || isStateConsignmentRequest(buyplanState)}
                      data-is-filled={isFilled}
                      data-is-intended={isIntended}
                      data-is-secondary={!isPastMonth}
                      show_pre_order_month={showPreOrderMonth.toString()}
                    >
                      {showPreOrderMonth ? deliveryMonthShort : preOrderCustomText}
                    </StyledBadge>
                  )}
                </div>
              </td>

              <td className="attribute_col">{attrRowKey}</td>

              {attrRowObj && onlyHeaders.map(key => {
                const obj = attrRowObj.getIn([key]);

                if (!obj) return <td key={key} />;

                const inputIndex = obj && obj.get("input_index");
                const bpsbId = obj && obj.get("bpsb_id");
                const qty = obj && obj.get("quantity");
                const placeHolderValue = obj && obj.get("placeholder");
                const isDisabled = obj && obj.get("is_disabled");
                const titleText = obj && obj.get("tooltip");
                const isQtyExceeded = [
                  ATS_STATUSES.overQty,
                  ATS_STATUSES.cumulativeOverQty,
                  ATS_STATUSES.orderQtyBlock,
                  ATS_STATUSES.sizeMinOrderQty,
                  ATS_STATUSES.monthMinOrderQty
                ].includes(obj.get("ats_status"));

                return (
                  <td key={key}>
                    <div>
                      <SizeBreakInput
                        key={qty}
                        inputIndex={inputIndex}
                        bpsbId={bpsbId}
                        placeholderText={placeHolderValue}
                        isDisabled={isDisabled || isStateConsignmentRequest(buyplanState)}
                        qty={qty}
                        titleText={titleText}
                        dispatch={dispatch}
                        onSubmitCallback={handleOnBlur}
                        lowQty={obj.get("ats_status") === ATS_STATUSES.lowQty}
                        error={obj.get("ats_status") === ATS_STATUSES.soldOut}
                        qtyExceeded={isQtyExceeded}
                        highlightBox={bpsbId == bpsbToHighlight}
                      />
                    </div>
                  </td>
                );
              })}
              { isMinOrderCheckApplicable &&
                <>
                  <td className="table-last-col"> {currentMonthQty}/{monthMinOrderQty}
                    { monthMinConditionSatisfied ?
                      <img
                        className='month-min-sign'
                        src={ greenTick }
                        alt="Month Min Fulfill"
                      /> :
                      <img
                        className='month-min-sign'
                        src={ redCross }
                        alt="Month Min MisMatch"
                      />
                    }
                  </td>
                </>
              }
            </tr>
          );
        })
      );
    })
  );
};

const _handleOnBlur = (evt, onSubmitCallback, state) => {
  if (!state.get("errorMsg")) {
    const { name, value } = evt.target;
    const sizeBreakId = name.split("_")[1];
    const quantity = value.trim() === "" ? "0" : value;

    onSubmitCallback({ sizeBreakId, quantity });
  }
};

export default React.memo(TableBody);
