import { createSelector } from "reselect";

export const groupedProductsReducerSelector = createSelector(
  state => state.get("groupedProductsReducer"),
  groupedProductsReducer => groupedProductsReducer
);

export const isLoadingSelelctor = createSelector(
  groupedProductsReducerSelector,
  groupedProductsReducer => groupedProductsReducer.get("isLoading")
);

export const errorSelector = createSelector(
  groupedProductsReducerSelector,
  groupedProductsReducer => groupedProductsReducer.get("error")
);

export const groupedProductsDataSelector = createSelector(
  groupedProductsReducerSelector,
  groupedProductsReducer => groupedProductsReducer.get("data")
);

export const selectedGroupByFilterSelector = createSelector(
  groupedProductsDataSelector,
  groupedProductsData => groupedProductsData.get("selectedGroupByFilter")
);

export const groupedProductsSelector = createSelector(
  groupedProductsDataSelector,
  groupedProductsData => groupedProductsData.get("prs")
);
