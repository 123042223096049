export const productsActions = {
  startLoader: "START_PRODUCTS_LOADERS",
  init: "INITIATE_PRODUCTS_SAGA",
  request: "PRODUCTS_REQUEST",
  requestQuickFillProducts: "REQUEST_QUICK_FILL_PRODUCTS",
  requestStockModalProducts: "REQUEST_STOCK_MODAL_PRODUCTS",
  success: "PRODUCTS_SUCCESS",
  failure: "PRODUCTS_FAILURE",
  updatePr : "PRODUCT_RELEASE_UPDATE                                      "
};

export const getProducts = (
  releaseId,
  limit = 10,
  offset = 0,
  filters,
  override = false,
  type = null,
  buyplanId,
  warehouseId,
  category = null,
  subcategory = null
) => {
  return {
    type: productsActions.init,
    payload: {
      releaseId,
      limit,
      offset,
      filters,
      override,
      type,
      buyplan_id: buyplanId,
      warehouse_id: warehouseId,
      category: category,
      subcategory: subcategory
    }
  };
};

export const getQuickFillProducts = (
  brandId,
  limit = 10,
  offset = 0,
  filters,
  override = false,
  buyplanId
) => {
  return {
    type: productsActions.requestQuickFillProducts,
    payload: {
      brandId,
      limit,
      offset,
      filters,
      override,
      buyplan_id: buyplanId
    }
  };
};

export const getStockModalProducts = (
  releaseId,
  stockModalId,
  limit = 10,
  offset = 0,
  filters,
  override = false
) => {
  return {
    type: productsActions.requestStockModalProducts,
    payload: {
      releaseId,
      stockModalId,
      limit,
      offset,
      filters,
      override
    }
  };
};

export const getProductsSuccess = payload => {
  return {
    type: productsActions.success,
    payload
  };
};

export const getProductsFailure = errorPayload => {
  return {
    type: productsActions.failure,
    errorPayload
  };
};

export const updateProduct = (payload) => {
  return {
    type: productsActions.updatePr,
    payload,
  };
};
