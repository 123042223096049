import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { redirectTo } from "apis/apiHelpers.js";

import { getCurrentUser } from "actions/currentUserActions.js";
import { getBuyplanIdForQfill } from "actions/buyplanIdActions.js";
import { getWarehouses } from "actions/warehouseActions.js";
import { resetOrderDetails } from "actions/orderDetailsActions.js";

import { isLoadingSelector, currentUserDataSelector } from "selectors/currentUserSelector.js";
import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";

import ScrollToTop from "components/ScrollToTop.jsx";
import TabsList from "components/Tabs/TabsList.jsx";

import GlobalMenuContainer from "containers/GlobalMenuContainer";
import QuickFillBannerContainer from "containers/QuickFill/QuickFillBannerContainer.jsx";
import ConfirmationDialogContainer from "containers/ConfirmationDialogContainer.jsx";
import Sidebar from "components/Sidebar/index.jsx";
import SubmitOrderBtn from "components/SubmitOrderBtn.jsx";

import CategoryFilterContainer from "containers/QuickFill/CategoryFilterContainer";
import SubmitOrderContainer from "containers/SubmitOrderContainer.jsx";

import ProductsListContainer from "containers/QuickFill/ProductsListContainer.jsx";
import BuyplanDetailsContainer from "containers/BuyplanDetailsContainer.jsx";
import BuyplanReportContainer from "containers/BuyplanReportContainer.jsx";
import { activeTabSelector } from "selectors/filtersSelector.js";

import { AGENT_ADMIN, BRANDSCOPE_ADMIN, SALES_REP, SUPER_SUPPLIER_ADMIN } from "constants.js";
import { buyplanAwaitingApprovalSelector } from "selectors/buyplanInfoSelector";
import DeclineOrderBtn from "components/DeclineOrderBtn";
import DeclineOrderDialog from "components/BuyplanReport/DeclineOrderDialog";
import i18next from "i18next";

const getTabsConfig = (isAdminUser, forBuyplan, tabsConfigLoclisationData) => {
  let tabsConfig = {
    range: {
      id: "RANGE_PAGE_TAB",
      title: tabsConfigLoclisationData.range,
      body: <ProductsListContainer />
    },
    buyplanDetails: {
      id: "BUYPLAN_DETAILS_TAB",
      title: tabsConfigLoclisationData.buyPlan,
      body: <BuyplanDetailsContainer />
    },
    report: {
      id: "REPORT",
      title: tabsConfigLoclisationData.report,
      body: <BuyplanReportContainer />
    }
  };

  if (isAdminUser && !forBuyplan) {
    delete tabsConfig["buyplanDetails"];
    delete tabsConfig["report"];

    return { ...tabsConfig };
  }

  return tabsConfig;
};

const BrandLayout = ({ className }) => {
  const [isModalOpen, setModalState] = useState(false);
  const toggleModalFn = useCallback(() => {
    dispatch(resetOrderDetails())
    setModalState(!isModalOpen);
  }, [isModalOpen]);

  const dispatch = useDispatch();
  const isCurrentUserLoading = useSelector(isLoadingSelector);
  const currentUser = useSelector(currentUserDataSelector);
  const isAdminUser = currentUser && currentUser.get("role") === BRANDSCOPE_ADMIN;
  const isAgentAdmin = currentUser && currentUser.get("role") === AGENT_ADMIN;
  const isSalesRep = currentUser && currentUser.get("role") === SALES_REP;
  const isSuperSupplierAdmin = currentUser && currentUser.get("role") === SUPER_SUPPLIER_ADMIN;
  const { brandId, buyplanId } = useParams();
  const forBuyplan = buyplanId ? true : false;
  const activeTab = useSelector(activeTabSelector);
  const confirmedBuyplanId = useSelector(buyplanIdSelector);
  const isAwaitingApproval = useSelector(buyplanAwaitingApprovalSelector);
  let tabsConfigLoclisationData = {
    range: i18next.t("tabsConfig.range"),
    buyPlan: i18next.t("tabsConfig.buyPlan"),
    report: i18next.t("tabsConfig.report")
  };
  const showDeclineOrderBtn = () => {
    return (isAgentAdmin || isSalesRep || isSuperSupplierAdmin) && isAwaitingApproval;
  };

  const [isDeclineModalOpen, setDeclineModalOpen] = useState(false);
  const toggleDeclineModel = () => {
    setDeclineModalOpen(!isDeclineModalOpen);
  };

  useEffect(() => {
    if (confirmedBuyplanId) dispatch(getCurrentUser());
  }, [dispatch, confirmedBuyplanId]);

  useEffect(() => {
    if (isAdminUser && !buyplanId) redirectTo("/showroom");
  }, [isAdminUser, buyplanId]);

  useEffect(() => {
    if (forBuyplan) {
      dispatch(getBuyplanIdForQfill({ brandId, buyplan_id: buyplanId }));
    } else {
      dispatch(getBuyplanIdForQfill({ brandId }));
    }
  }, [dispatch, forBuyplan, brandId, buyplanId]);

  useEffect(() => {
    if (confirmedBuyplanId) {
      dispatch(getWarehouses({ buyplanId: confirmedBuyplanId }));
    }
  }, [dispatch, confirmedBuyplanId]);

  if ((isAdminUser && !buyplanId) || !confirmedBuyplanId || isCurrentUserLoading) {
    return null;
  }

  return (
    <>
      <GlobalMenuContainer />

      <div className={`${className}`}>
        <Sidebar />

        <main className={`main ${activeTab === "report" && "w-100"}`}>
          <QuickFillBannerContainer />

          {activeTab !== "report" && <CategoryFilterContainer />}

          <section>
            <div className="btn-wrap float-right">
              {!isAdminUser && (
                <SubmitOrderBtn className="btn btn-success" onClick={toggleModalFn}>
                  {showDeclineOrderBtn()
                    ? i18next.t("buttons.approveOrder")
                    : i18next.t("buttons.submitOrder")}
                </SubmitOrderBtn>
              )}

              {showDeclineOrderBtn() && (
                <DeclineOrderBtn
                  type="button"
                  className="btn btn-danger"
                  onClick={toggleDeclineModel}
                >
                  {i18next.t("buttons.declineOrder")}
                </DeclineOrderBtn>
              )}

              {!isAdminUser && <ConfirmationDialogContainer />}
            </div>
          </section>

          <TabsList
            tabsConfig={getTabsConfig(isAdminUser, forBuyplan, tabsConfigLoclisationData)}
          />

          {!isAdminUser && (
            <SubmitOrderContainer isOpen={isModalOpen} closeModalCallback={toggleModalFn} />
          )}

          {confirmedBuyplanId && showDeclineOrderBtn() && (
            <DeclineOrderDialog
              isOpen={isDeclineModalOpen}
              closeModalCallback={toggleDeclineModel}
              buyplanId={confirmedBuyplanId}
            />
          )}
        </main>

        <ScrollToTop />
      </div>
    </>
  );
};

const StyledBrandLayout = styled(BrandLayout)`
  display: flex;
  width: 100%;
  padding: 20px 15px 0;

  @media (max-width: 575.98px) {
    flex-direction: column;
  }

  .sidebar {
    width: var(--sidebar-width);
    min-width: var(--sidebar-min-width);

    @media (max-width: 575.98px) {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .main {
    width: var(--main-width);
    padding-left: 15px;

    @media (max-width: 575.98px) {
      width: 100%;
      padding-left: 0;
    }
  }

  .btn-wrap {
    padding: 11.5px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    min-height: 55px;
    display: flex;

    @media (max-width: 575.98px) {
      float: none !important;
      margin-bottom: -1px;
      text-align: right;
    }
  }

  .brand-banner {
    margin: 0 0 10px;
  }
`;

export default React.memo(StyledBrandLayout);
