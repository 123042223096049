import { takeLatest, put } from "redux-saga/effects";

import { myNoteActions } from "actions/myNoteActions.js";

import { PUT_DATA } from "actions/apiOperations.js";

export const updateMyNoteSaga = function*(action) {
  const { productId, ...params } = action.payload;

  yield put({
    type: PUT_DATA,
    path: `/api/products/${productId}/notes.json`,
    actions: myNoteActions,
    params,
  });
};

export const watchUpdateMyNote = function*() {
  yield takeLatest(myNoteActions.requestNoteUpdation, updateMyNoteSaga);
};
