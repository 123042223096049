import { Map, List, fromJS } from "immutable";

import { productsActions } from "actions/productsActions.js";
import { filterActions } from "actions/filterActions.js";
import { warehouseActions } from "actions/warehouseActions.js";
import { buyplanItemActions } from "actions/likeDislikeActions.js";
import { sizeBreakActions } from "actions/sizeBreakActions.js";
import { quantityGridActions } from "actions/quantityGridActions.js";
import { qtyGridActions } from "actions/StockModal/qtyGridActions.js";
import { reOrderBuyplanMonthsActions } from "actions/reOrderBuyplanMonthsActions.js";
import { buyplanDetailsActions } from "actions/buyplanDetailsActions";
import { buyplanSummaryActions } from "actions/buyplanSummaryActions";
import { groupByProductsActions } from "actions/groupByProductsActions";

const initialState = Map({
  isLoading: true,
  hasErrors: false,
  data: Map({
    buyplan_items_count: 0,
    count: 0,
    buyplan_id: null,
    warehouse_id: null,
    display_ws_range: false,
    show_discount_percent: false,
    allow_barcode_scanning_for_bp: false,
    allow_barcode_scanning_for_cnf: false,
    pr: List([]),
    group_by_pr: {},
    stock_model: {
      id: null,
      state: null
    }
  })
});

const getProductIndexFromProductReleaseId = (state, productReleaseId) => {
  return state.getIn(["data", "pr"]).findIndex(productRelease => {
    return productRelease.id === productReleaseId;
  });
};

const getProductFromStockModalItemId = (state, stockModalItemId) => {
  return state
    .getIn(["data", "pr"])
    .findIndex(
      product =>
        parseInt(product.stock_model_item.id) === parseInt(stockModalItemId)
    );
};

export default (state = initialState, action) => {
  const payload = action.payload;
  let productIndex,
    productReleaseId,
    items = 0;

  switch (action.type) {
    case filterActions.applyFilters:
      if (payload.category !== "") return state.set("isLoading", true);
      return state;
    case filterActions.removeFilters:
    case warehouseActions.update:
      return state.set("isLoading", true);
    case productsActions.success:
      let {
        buyplan_items_count,
        count,
        buyplan_id,
        warehouse_id,
        display_ws_range,
        show_discount_percent,
        allow_barcode_scanning_for_bp,
        allow_barcode_scanning_for_cnf,
        pr,
        stock_model,
        tenant_id
      } = payload.data;

      let newState = state.mergeDeep({
        isLoading: false,
        hasErrors: false,
        data: {
          buyplan_items_count,
          count,
          buyplan_id,
          warehouse_id,
          display_ws_range,
          show_discount_percent,
          allow_barcode_scanning_for_bp,
          allow_barcode_scanning_for_cnf,
          stock_model,
          tenant_id
        }
      });

      if (payload.override) {
        return newState
          .setIn(["data", "mostRecentPrSize"], pr.length)
          .setIn(["data", "pr"], List(pr));
      } else {
        let existingPrSize = state.getIn(["data", "pr"]).size;
        let existinglastPrIndex = existingPrSize - 1;
        let existingLastPr = state.getIn(["data", "pr", existinglastPrIndex]);
        let newFirstPr = pr[0];

        if (existingLastPr && newFirstPr) {
          newState = newState.setIn(
            ["data", "pr", existinglastPrIndex, "next_product_release_id"],
            newFirstPr.id
          );
          newFirstPr.prev_product_release_id = existingLastPr.id;
        }

        return newState
          .setIn(["data", "mostRecentPrSize"], pr.length)
          .mergeIn(["data", "pr"], List(pr));
      }
    case productsActions.failure:
      return state.mergeDeep({
        isLoading: false,
        hasErrors: true,
        data: payload.data
      });
    case buyplanItemActions.success:
      const buyplanItem = payload.data;

      productIndex = getProductIndexFromProductReleaseId(
        state,
        buyplanItem.product_release_id
      );

      return state
        .mergeIn(
          ["data", "pr", productIndex, "buyplan_item"],
          fromJS({
            id: buyplanItem.id,
            total_quantity: 0
          })
        )
        .mergeIn(
          ["data", "pr", productIndex, "stock_model_item"],
          fromJS({
            id: buyplanItem.id,
            total_quantity: 0
          })
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "intended_months"],
          initialState.getIn([
            "data",
            "pr",
            productIndex,
            "buyplan_item",
            "intended_months"
          ])
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "filled_months"],
          initialState.getIn([
            "data",
            "pr",
            productIndex,
            "buyplan_item",
            "filled_months"
          ])
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "pre_order_intended_months"],
          initialState.getIn([
            "data",
            "pr",
            productIndex,
            "buyplan_item",
            "pre_order_intended_months"
          ])
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "pre_order_filled_months"],
          initialState.getIn([
            "data",
            "pr",
            productIndex,
            "buyplan_item",
            "pre_order_filled_months"
          ])
        );
    case sizeBreakActions.success:
      productReleaseId = payload.data.product_release_id;
      productIndex = getProductIndexFromProductReleaseId(
        state,
        productReleaseId
      );

      return state
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "intended_months"],
          payload.data.intended_months
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "filled_months"],
          payload.data.filled_months
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "pre_order_intended_months"],
          payload.data.pre_order_intended_months
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "pre_order_filled_months"],
          payload.data.pre_order_filled_months
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "total_quantity"],
          payload.data.total_quantity
        );
    case quantityGridActions.updateSuccess:
    case reOrderBuyplanMonthsActions.success:
      productReleaseId = payload.data.product_release.id;
      productIndex = getProductIndexFromProductReleaseId(
        state,
        productReleaseId
      );

      return state
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "intended_months"],
          payload.data.buyplan_item.intended_months
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "filled_months"],
          payload.data.buyplan_item.filled_months
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "pre_order_intended_months"],
          payload.data.buyplan_item.pre_order_intended_months
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "pre_order_filled_months"],
          payload.data.buyplan_item.pre_order_filled_months
        )
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "total_quantity"],
          payload.data.buyplan_item.total_quantity
        );
    case qtyGridActions.updateQtySuccess:
      const { stock_model_item_id, total_quantity } = payload.data;

      productIndex = getProductFromStockModalItemId(state, stock_model_item_id);

      if (![undefined, null, -1].includes(productIndex)) {
        return state
          .setIn(
            ["data", "pr", productIndex, "buyplan_item", "total_quantity"],
            total_quantity
          )
          .setIn(
            ["data", "pr", productIndex, "stock_model_item", "total_quantity"],
            total_quantity
          );
      } else {
        return state;
      }
    case qtyGridActions.qtyGridSuccess:
      productReleaseId = payload.data.product_release.id;
      productIndex = getProductIndexFromProductReleaseId(
        state,
        productReleaseId
      );

      return state
        .setIn(
          ["data", "pr", productIndex, "buyplan_item", "id"],
          payload.data.stock_model_item.id
        )
        .setIn(
          ["data", "pr", productIndex, "stock_model_item", "id"],
          payload.data.stock_model_item.id
        );
    case buyplanDetailsActions.success:
      if (payload.data.buyplan_items) {
        return state.setIn(
          ["data", "count"],
          payload.data.buyplan.total_buyplan_items_count
        );
      } else {
        return state.setIn(
          ["data", "count"],
          payload.data.stock_model_items.length
        );
      }
    case buyplanSummaryActions.success:
      fromJS(payload.data.buyplan_items).map(
        (value) => (items += value.get("items").size)
      );
      return state.setIn(["data", "count"], items);
    case groupByProductsActions.success:
      return state
        .mergeIn(["data", "pr"], List(action.payload.data.pr))
        .set("isLoading", false);
    default:
      return state;
  }
};
