import React from "react";
import styled from "styled-components";

const LikeDislike = ({ className, liked, toggleLiked, isDisabled = false }) => {
  const onClickHandler = isDisabled ? null : toggleLiked;
  const tooltip = isDisabled ? "Operation not permitted!" : "";

  return (
    <div className={`${className}`}>
      <div
        className={`like ${liked ? "liked" : ""}`}
        onClick={onClickHandler}
        title={tooltip}
      >
        {liked ? (
          <img src="/like_button_on.png" alt="liked" />
        ) : (
          <img src="/like_button_off.png" alt="disliked" />
        )}
      </div>
    </div>
  );
};

const StyledLikeDislike = styled(LikeDislike)`
  margin-bottom: 1ex;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  svg {
    width: 2em;
    height: auto;
    fill: #2f8be2;
  }
`;

export default React.memo(StyledLikeDislike);
