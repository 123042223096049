import { createSelector } from "reselect";

export const orderReducerSelector = createSelector(
  state => state.get("orderReducer"),
  orderReducer => orderReducer
);

export const isLoadingSelector = createSelector(
  orderReducerSelector,
  orderReducer => orderReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  orderReducerSelector,
  orderReducer => orderReducer.get("hasErrors")
);

export const orderDataSelector = createSelector(
  orderReducerSelector,
  orderReducer => orderReducer.get("data")
);
