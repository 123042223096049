import { takeLatest, put } from "redux-saga/effects";

import { agentNoteActions } from "actions/agentNoteActions.js";

import { PUT_DATA } from "actions/apiOperations.js";

export const updateAgentNoteSaga = function*(action) {
  const { buyplanId, buyplanItemId, ...params } = action.payload;

  yield put({
    type: PUT_DATA,
    path: `/api/buyplans/${buyplanId}/items/${buyplanItemId}/notes.json`,
    actions: agentNoteActions,
    params,
  });
};

export const watchUpdateAgentNote = function*() {
  yield takeLatest(agentNoteActions.requestNoteUpdation, updateAgentNoteSaga);
};
