import { takeLatest, takeEvery, put, take, race, select } from "redux-saga/effects";

import { qtyGridActions } from "actions/StockModal/qtyGridActions.js";
import {
  addStockModalItem,
  buyplanItemActions
} from "actions/likeDislikeActions";
import { getStockModalBuyplanTotals } from "actions/buyplanTotalsActions.js";

import { GET_DATA, PUT_DATA } from "actions/apiOperations.js";

import { findByProductReleaseId } from "selectors/productsSelector.js";
import { buyplanDetailsDataSelector } from "selectors/buyplanDetailsSelector.js";
import { addNotification } from "notificationStore";
import { ATS_STATUSES } from "constants.js";
import i18next from "i18next";

export const requestQtyGridAsync = function*(action) {
  const {
    stockModalId,
    stockModalItemId,
    productReleaseId,
    ...params
  } = action.payload;

  if (
    stockModalItemId &&
    stockModalItemId !== "undefined" &&
    stockModalItemId !== "null"
  ) {
    yield put({
      type: GET_DATA,
      path: `/api/stock_models/${stockModalId}/items/${stockModalItemId}/qty_grid.json`,
      actions: {
        startLoader: qtyGridActions.startLoader,
        success: qtyGridActions.qtyGridSuccess,
        failure: qtyGridActions.qtyGridFailure
      },
      params
    });
  } else {
    yield put(addStockModalItem(stockModalId, productReleaseId));

    const { success } = yield race({
      success: take(buyplanItemActions.success),
      error: take(buyplanItemActions.failure)
    });

    if (success) {
      const productRelease = yield select(
        findByProductReleaseId(productReleaseId)
      );
      const stockModalItemId = productRelease.stock_model_item.id;

      yield put({
        type: GET_DATA,
        path: `/api/stock_models/${stockModalId}/items/${stockModalItemId}/qty_grid.json`,
        actions: {
          startLoader: qtyGridActions.startLoader,
          success: qtyGridActions.qtyGridSuccess,
          failure: qtyGridActions.qtyGridFailure
        },
        params
      });
    }
  }
};

export const watchRequestQtyGrid = function*() {
  yield takeLatest(qtyGridActions.requestQtyGrid, requestQtyGridAsync);
};

export const requestUpdateQtyAsync = function*(action) {
  const {
    stockModalId,
    sizeBreakId,
    options,
    ...params
  } = action.payload;

  yield put({
    type: PUT_DATA,
    path: `/api/stock_models/${stockModalId}/size_breaks/${sizeBreakId}/update_quantity.json`,
    actions: {
      success: qtyGridActions.updateQtySuccess,
      failure: qtyGridActions.updateQtyFailure
    },
    params
  });

  const { success } = yield race({
    success: take(qtyGridActions.updateQtySuccess),
    error: take(qtyGridActions.updateQtyFailure)
  });

  if (success) {
    const {
      ats_status: atsStatus,
      order_qty_block: orderQtyBlock,
    } = success.payload.data;

    switch (atsStatus) {
      case ATS_STATUSES.overQty:
        let exceededQtyloclisationData = i18next.t(
          "qtyGrid.sizeBreak.inputValidations.outOfRange"
        );
        addNotification({
          type: "error",
          message: exceededQtyloclisationData
        });
        break;
      case ATS_STATUSES.orderQtyBlock:
        let orderQtyBlockloclisationData = i18next.t(
          "qtyGrid.sizeBreak.inputValidations.qtyMultiplesMismatch",
          { orderQtyBlock: orderQtyBlock }
        );
        addNotification({
          type: "error",
          message: orderQtyBlockloclisationData
        });
        break;
      case ATS_STATUSES.sizeMinOrderQty:
        let sizeMinQtyloclisationData = i18next.t(
          "qtyGrid.sizeBreak.inputValidations.orderMinQtyMismatch"
        );
        addNotification({
          type: "error",
          message: sizeMinQtyloclisationData
        });
        break;
      case ATS_STATUSES.cumulativeOverQty:
        let cumulativeOverQtyloclisationData = i18next.t(
          "qtyGrid.sizeBreak.inputValidations.cumulativeExceededQty"
        );
        addNotification({
          type: "error",
          message: cumulativeOverQtyloclisationData
        });
        break;
      default:
      // do nothing
    }

    if (options.shouldFetchTotals) {
      const stockModelBuyplanItems = yield select(buyplanDetailsDataSelector);
      const stockModelBuyplanItemIds = stockModelBuyplanItems
        .get("stock_model_items")
        .map(key => key.get("id"))
        .toArray();

      yield put(
        getStockModalBuyplanTotals(stockModalId, stockModelBuyplanItemIds)
      );
    }
  }
};

export const watchRequestUpdateQty = function*() {
  yield takeEvery(qtyGridActions.requestUpdateQty, requestUpdateQtyAsync);
};
