import React from "react";
import styled from "styled-components";
import { FaPencilAlt } from "react-icons/fa";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";

import { titleize } from "utils.js";

const StyledFaPencilAlt = styled(FaPencilAlt)`
  fill: #368ee0;
  width: 0.8em;
  cursor: pointer;
`;

const StyledPopover = styled(Popover)`
  & > .popover {
    max-width: unset;
  }
`;

const StyledSubmitBtn = styled.button`
  background-color: var(--brandscope-blue);
  border-color: var(--brandscope-blue);
`;

const MyNote = ({
  title,
  canEditNote,
  note,
  isPopoverOpen,
  togglePopover,
  placement = "auto",
  onSubmit,
  onCancel
}) => {
  const id = titleize(title);

  return (
    <section className={id}>
      <h6 className="font-weight-bold">
        {title}

        {canEditNote && (
          <>
            <StyledFaPencilAlt id={id} className="ml-2" />

            <StyledPopover
              trigger="click"
              placement={placement}
              target={id}
              isOpen={isPopoverOpen}
              toggle={togglePopover}
            >
              <PopoverHeader>Enter Note</PopoverHeader>

              <PopoverBody>
                <form className="form-inline editableform" onSubmit={onSubmit}>
                  <div className="control-group">
                    <div className="editable-input">
                      <textarea
                        className="input-large"
                        rows="7"
                        cols="40"
                        autoFocus
                        name={id}
                        defaultValue={note}
                      />
                    </div>

                    <div className="editable-buttons">
                      <StyledSubmitBtn
                        type="submit"
                        className="btn btn-primary editable-submit"
                      >
                        <i className="icon-ok icon-white" />
                        Save Note
                      </StyledSubmitBtn>

                      <button
                        type="button"
                        className="btn editable-cancel ml-1"
                        onClick={onCancel}
                      >
                        <i className="icon-remove" />
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </PopoverBody>
            </StyledPopover>
          </>
        )}
      </h6>

      {note}
    </section>
  );
};

export default React.memo(MyNote);
