import { createSelector } from "reselect";

export const orderDetailsReducerSelector = createSelector(
  state => state.get("orderDetailsReducer"),
  orderDetailsReducer => orderDetailsReducer
);

export const isLoadingSelector = createSelector(orderDetailsReducerSelector, orderDetailsReducer =>
  orderDetailsReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(orderDetailsReducerSelector, orderDetailsReducer =>
  orderDetailsReducer.get("hasErrors")
);

export const orderDetailsDataSelector = createSelector(
  orderDetailsReducerSelector,
  orderDetailsReducer => orderDetailsReducer.get("data")
);

export const orderDetailsErrorsSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData.get("errors")
);

export const orderInvoiceSelector = createSelector(orderDetailsDataSelector, orderDetailsData =>
  orderDetailsData.get("invoice")
);

export const invoiceCategoryRulesSelector = createSelector(orderInvoiceSelector, orderInvoice =>
  orderInvoice.get("category_rules")
);

export const orderMinimumsByMonthSelector = createSelector(orderDetailsDataSelector, orderInvoice =>
  orderInvoice.getIn(["invoice", "order_minimums_by_month"])
);

export const orderBuyplanSelector = createSelector(orderDetailsDataSelector, orderDetailsData => {
  return orderDetailsData.get("buyplan") || orderDetailsData.get("stock_model");
});

export const orderBillingAddressSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData.get("billing_address")
);

export const orderShippingAddressDefaultSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData.get("shipping_address_default")
);

export const orderShippingAddressAlt1Selector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData.get("shipping_address_alt1")
);

export const orderShippingAddressAlt2Selector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData.get("shipping_address_alt2")
);

export const isNetsuiteOrderIntegratedSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData.get("is_netsuite_order_integrated")
);

export const netsuiteOrderTypesSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData.get("netsuite_order_types")
);

export const isBrightPearlOrderIntegratedSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData.get("is_brightpearl_order_integrated")
);

export const isDaiwaBrandSelector = createSelector(orderDetailsDataSelector, orderDetailsData =>
  orderDetailsData.get("is_daiwa_brand")
);

export const brightpearlAddressesSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData.get("brightpearl_addresses")
);

export const selectedShippingAddrIdSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData.get("selectedShippingAddrId")
);

export const deliveryDatesSelector = createSelector(orderDetailsDataSelector, orderDetailsData =>
  orderDetailsData.getIn(["buyplan", "delivery_dates"]) || orderDetailsData.getIn(["stock_model", "delivery_dates"])
);

export const agreementAcceptanceDataSelector = createSelector(
  orderBuyplanSelector,
  orderBuyplanData => {
    return orderBuyplanData && orderBuyplanData.get("agreement_acceptance_data");
  }
);

export const areTermsRequiredSelector = createSelector(
  agreementAcceptanceDataSelector,
  agreementAcceptanceData => {
    return agreementAcceptanceData && agreementAcceptanceData.get("agreement_acceptance");
  }
);

export const orderMinumumsSelector = createSelector(orderDetailsDataSelector, orderDetailsData => {
  return orderDetailsData?.get("order_minimums");
});

export const isBuyplanOwnershipEnforcedSelector = createSelector(
  orderBuyplanSelector,
  orderBuyplanData => {
    return orderBuyplanData && orderBuyplanData?.get("is_buyplan_ownership_enforced")
  }
);

export const salesPersonSelector = createSelector(
  orderBuyplanSelector,
  orderBuyplanData => {
    return orderBuyplanData && orderBuyplanData?.get("sales_person")
  }
);

export const salesPersonListSelector = createSelector(
  orderBuyplanSelector,
  orderBuyplanData => {
    return orderBuyplanData && orderBuyplanData?.get("sales_person_list")
  }
);

export const isOrderApprovalRequiredSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => {
    return orderDetailsData && orderDetailsData?.get("is_order_approval_required")
  }
);

export const orderApprovalRuleMsgSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => {
    return orderDetailsData && orderDetailsData?.get("order_approval_rule_msg")
  }
);

export const poCharacterLimitSelector = createSelector(
  orderDetailsDataSelector,
  orderDetailsData => orderDetailsData && orderDetailsData.get("po_character_limit")
);

export const allowCustomShippingAddressSelector = createSelector(
  orderBuyplanSelector,
  orderBuyplan => orderBuyplan.get("allow_custom_shipping_address")
);

export const countriesSelector = createSelector(
  orderBuyplanSelector,
  orderBuyplan => orderBuyplan.get("countries")
);
