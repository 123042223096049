import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useLocation } from "react-router";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import {
  CarouselProvider,
  Slider,
  Slide,
  ImageWithZoom,
  Dot
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { updateMyNote } from "actions/myNoteActions.js";
import { updateAgentNote } from "actions/agentNoteActions.js";

import { buyplanItemSelector } from "selectors/quantityGridSelector.js";
import { buyplanStateSelector } from "selectors/buyplanIdSelector.js";
import { currentUserDataSelector } from "selectors/currentUserSelector.js";
import { bannerInfoDataSelector } from "selectors/bannerInfoSelector.js";

import ProductSpecifications from "components/ProductModal/ProductSpecifications.jsx";
import Loader from "components/Loader/Loader.jsx";
import LikeDislikeContainer from "containers/LikeDislikeContainer.jsx";
import QuantityGridContainer from "containers/QuantityGridContainer.jsx";
import Months from "components/ProductCard/Months/Months.jsx";
import MyNote from "components/MyNote/index.jsx";
import PrevNextBtn from "components/PrevNextBtn.jsx";
import BsNavPills from "components/BsNavPills.js";
import Thumbnail from "components/ProductCard/Thumbnail/Thumbnail.jsx";
import ProductInfo from "components/BuyplanDetails/ProductInfo.jsx";
import SurroundView from "components/ProductCard/Actions/SurroundView/SurroundView.jsx";
import { isStateConsignmentRequest, isQtyGridDisabled } from "utils.js";
import DiscountedDisplay from "./DiscountedDisplay";

const ProductModal = ({
  isLoading,
  hasErrors,
  error,
  productReleaseId,
  buyplanItemId,
  isOpen,
  closeModal,
  captionName,
  captionSku,
  captionColor,
  productSpecification1Key,
  productSpecification1Value,
  productImages,
  badges,
  wholesalePrice,
  wholesaleRange,
  discountedDisplayWholesalePrice,
  discountedDisplayWholesaleRange,
  retailPriceFormatted,
  unitWholesalePrice,
  unitRetailPrice,
  abilities,
  specifications,
  changes,
  myNote,
  agentNote,
  product,
  showMonths,
  showLikeDislike,
  prevProductReleaseId,
  nextProductReleaseId,
  buyplanId,
  stockModalId,
  releaseId,
  brandId,
  isStockModal,
  discounted,
  pbDiscounted,
  isSoldOut,
  isThreeSixty,
  tenantId,
  brandScopeTag,
  pbPercentOff,
  isPreOrderProduct
}) => {
  const dispatch = useDispatch();
  const [popoverState, setPopoverState] = useState(false);
  const [agentNotePopoverState, setAgentNotePopoverState] = useState(false);

  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);
  const defaultTab = +queryParams.get("defaultTab") || 1;
  const [activeTab, setActiveTab] = useState(defaultTab);
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const buyplanItem = useSelector(buyplanItemSelector);
  const productId = product?.get("id");
  const buyplanState = useSelector(buyplanStateSelector);
  const totalImages = productImages.size;
  const currentPath = window.location.pathname;

  const currentUser = useSelector(currentUserDataSelector);
  const currentUserRole = currentUser && currentUser.get("role");
  const bannerInfoData = useSelector(bannerInfoDataSelector);
  const releaseStatus = bannerInfoData.get("release_status");
  const isQuantityGridDisabled = isQtyGridDisabled(
    currentUserRole,
    isSoldOut,
    releaseStatus,
    buyplanState,
    buyplanId,
    isPreOrderProduct
  );

  const [prevLink, nextLink] = prevNextQuickViewPath(
    currentPath,
    brandId,
    stockModalId,
    releaseId,
    buyplanId,
    prevProductReleaseId,
    nextProductReleaseId
  );

  const [currentIndex, changeCurrentIndex] = useState(0);

  const togglePopover = () => {
    setPopoverState(!popoverState);
  };

  const onCancel = useCallback(evt => {
    evt.preventDefault();

    setPopoverState(false);
  }, []);

  const onSubmit = useCallback(
    evt => {
      evt.preventDefault();

      const formData = new FormData(evt.target);
      const newNote = formData.get("my-notes");

      if (newNote !== myNote) {
        dispatch(
          updateMyNote({
            productId,
            note: newNote
          })
        );
      }

      setPopoverState(false);
    },
    [dispatch, productId, myNote]
  );

  const toggleAgentNotePopover = () => {
    setAgentNotePopoverState(!agentNotePopoverState);
  };

  const onAgentNoteCancel = useCallback(evt => {
    evt.preventDefault();

    setAgentNotePopoverState(false);
  }, []);

  const onAgentNoteSubmit = useCallback(
    evt => {
      evt.preventDefault();

      const formData = new FormData(evt.target);
      const newNote = formData.get("agent-notes");

      if (newNote !== agentNote) {
        dispatch(
          updateAgentNote({
            buyplanId,
            buyplanItemId,
            note: newNote
          })
        );
      }

      setAgentNotePopoverState(false);
    },
    [dispatch, buyplanId, buyplanItemId, agentNote]
  );
  if (isLoading) {
    return (
      <StyledModal isOpen={isOpen} backdrop={true} toggle={closeModal}>
        <ModalHeader
          toggle={closeModal}
          className="h4 align-items-center"
        ></ModalHeader>

        <ModalBody>
          <Loader />
        </ModalBody>
      </StyledModal>
    );
  } else if (hasErrors) {
    return (
      <StyledModal isOpen={isOpen} backdrop={true} toggle={closeModal}>
        <ModalHeader
          toggle={closeModal}
          className="h4 align-items-center"
        ></ModalHeader>

        <ModalBody>
          <div className="error-msg">{error.message}</div>;
        </ModalBody>
      </StyledModal>
    );
  } else {
    return (
      <StyledModal isOpen={isOpen} backdrop={true} toggle={closeModal}>
        <ModalHeader toggle={closeModal} className="h4 align-items-center">
          {`${captionName} : ${captionSku}`}
        </ModalHeader>

        <ModalBody>
          <BsNavPills>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 1 })}
                onClick={() => {
                  toggle(1);
                }}
              >
                Quick View
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === 2,
                  disabled: isQuantityGridDisabled
                })}
                onClick={() => {
                  toggle(2);
                }}
              >
                Qty Grid
              </NavLink>
            </NavItem>
          </BsNavPills>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              {activeTab === 1 && (
                <Row className="no-gutters">
                  <Col md={4} className="carousel-block">
                    <CarouselProvider
                      naturalSlideWidth={95}
                      naturalSlideHeight={121}
                      totalSlides={totalImages}
                    >
                      <Slider>
                        {productImages.toList().map((imgSrc, index) => {
                          return (
                            <Slide key={imgSrc} index={index}>
                              <ImageWithZoom src={imgSrc} alt="" />
                            </Slide>
                          );
                        })}
                      </Slider>
                      {productImages.size > 1 && (
                        <section className="carousel-navigation-actions">
                          {productImages
                            .toList()
                            .slice(
                              returnIndex("prev", currentIndex, totalImages),
                              returnIndex("next", currentIndex, totalImages)
                            )
                            .map((imgSrc, index) => {
                              const thumbSrc = imgSrc.replace(
                                "f_auto",
                                "f_auto,w_120,h_180"
                              );
                              return (
                                <Dot
                                  key={thumbSrc}
                                  className="mt-2 mb-2 mr-2 bg-white"
                                  slide={
                                    index +
                                    returnIndex(
                                      "prev",
                                      currentIndex,
                                      totalImages
                                    )
                                  }
                                  onClick={() => changeCurrentIndex(index)}
                                >
                                  <img
                                    src={thumbSrc}
                                    width="34"
                                    height="45"
                                    alt=""
                                  />
                                </Dot>
                              );
                            })}
                          {isThreeSixty && (
                            <button className={`zoom`}>
                              <SurroundView
                                brandScopeTag={brandScopeTag}
                                isThreeSixty={isThreeSixty}
                                tenantId={tenantId}
                              />
                            </button>
                          )}
                        </section>
                      )}
                      {productImages.size === 1 && isThreeSixty && (
                        <section className="carousel-navigation-actions mt-2 mb-2 mr-2 bg-white">
                          <button className={`zoom`}>
                            <SurroundView
                              brandScopeTag={brandScopeTag}
                              isThreeSixty={isThreeSixty}
                              tenantId={tenantId}
                            />
                          </button>
                        </section>
                      )}
                    </CarouselProvider>
                  </Col>
                  <Col md={8} className="basic-info-block">
                    <section className="basic-info">
                      <div
                        className="delivery"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "6em auto"
                        }}
                      >
                        <span>Delivery:</span>
                        <span>
                          {badges.map(badgeText => badgeText).join(", ")}
                        </span>
                      </div>

                      {abilities.get("can_view_wholesale_prices") && (
                        <div className="wholesale">
                          <span>Wholesale:</span>
                          <DiscountedDisplay
                            pbDiscounted={pbDiscounted}
                            discounted={discounted}
                            wholesaleRange={wholesaleRange}
                            discountedDisplayWholesaleRange={discountedDisplayWholesaleRange}
                            discountedDisplayWholesalePrice={discountedDisplayWholesalePrice}
                            abilities={abilities}
                            wholesalePrice={wholesalePrice}
                            unitWholesalePrice={unitWholesalePrice}
                            overrideFontSize="11px"
                            pbPercentOff={pbPercentOff}
                          />
                        </div>
                      )}

                      <div className="retail">
                        <span>Retail:</span>
                        <span>
                          {retailPriceFormatted}
                          &nbsp;
                          {unitRetailPrice && `(Ea. ${unitRetailPrice})`}
                        </span>
                      </div>

                      <div className="color">
                        <span>Colour:</span>
                        <span>{captionColor}</span>
                      </div>

                      {productSpecification1Key && productSpecification1Value && (
                        <div className="specification">
                          <span>{productSpecification1Key}:</span>
                          <span>{productSpecification1Value}</span>
                        </div>
                      )}
                    </section>

                    <hr />

                    <ProductSpecifications specifications={specifications} />

                    {changes && changes.size > 0 && (
                      <>
                        <section className="product-information">
                          <h6 className="font-weight-bold">Product Changes</h6>

                          <ul>
                            {changes.map(val => {
                              return <li key={val}>{val}</li>;
                            })}
                          </ul>
                        </section>

                        <hr />
                      </>
                    )}

                    {abilities.get("can_view_my_notes") && (
                      <>
                        <MyNote
                          title="My Notes"
                          canEditNote={abilities.get("can_edit_my_notes")}
                          note={myNote}
                          isPopoverOpen={popoverState}
                          togglePopover={togglePopover}
                          placement="right"
                          onSubmit={onSubmit}
                          onCancel={onCancel}
                        />

                        <hr />
                      </>
                    )}

                    {abilities.get("can_view_agent_notes") && (
                      <>
                        <MyNote
                          title="Agent Notes"
                          canEditNote={abilities.get("can_edit_agent_notes")}
                          note={agentNote}
                          isPopoverOpen={agentNotePopoverState}
                          togglePopover={toggleAgentNotePopover}
                          placement="right"
                          onSubmit={onAgentNoteSubmit}
                          onCancel={onAgentNoteCancel}
                        />

                        <hr />
                      </>
                    )}
                  </Col>
                </Row>
              )}
            </TabPane>

            <TabPane tabId={2}>
              {activeTab === 2 && (
                <div className="d-flex">
                  <Thumbnail
                    buyplanId={buyplanId}
                    productReleaseId={productReleaseId}
                    imgSrc={product.get("images")?.first()}
                    isClickable={false}
                    productCallout={product.get("callout_label")}
                    calloutBgColor={product.get("callout_bg_color")}
                    calloutBrColor={product.get("callout_br_color")}
                    calloutTxtColor={product.get("callout_txt_color")}
                    calloutIcon={product.get("callout_icon")}
                  />

                  <StyledProductInfo
                    name={product.get("name")}
                    sku={product.get("sku")}
                    displayColor={product.get("color")}
                    status={buyplanItem.get("available_status")}
                    displayUnitRetailPrice={unitRetailPrice}
                    unitRetailPrice={unitRetailPrice}
                    productSpecification1Key={product.get("product_specification_key")}
                    productSpecification1Value={product.get("product_specification_value")}
                    wholesalePrice={wholesalePrice}
                    retailPriceFormatted={retailPriceFormatted}
                    totalQty={buyplanItem.get("total_quantity")}
                    totalPrice={buyplanItem.get("total_price_formatted")}
                    buyplanId={buyplanId}
                    releaseId={releaseId}
                    brandId={brandId}
                    buyplanItemId={buyplanItemId || buyplanItem.get("id")}
                    isDiscounted={buyplanItem.get("is_discounted")}
                    pbDiscounted={pbDiscounted}
                    discountedDisplayWholesaleRange={buyplanItem.get(
                      "discounted_display_wholesale_range"
                    )}
                    discountedDisplayWholesalePrice={buyplanItem.get(
                      "discounted_display_wholesale_price"
                    )}
                    wholesaleRange={wholesaleRange}
                    isStateConsignmentRequest={isStateConsignmentRequest(
                      buyplanState
                    )}
                    isRemovalAllowed={false}
                    isStockModal={isStockModal}
                    abilities={abilities}
                    pbPercentOff={pbPercentOff}
                  />

                  <QuantityGridContainer buyplanState={buyplanState} />
                </div>
              )}
            </TabPane>
          </TabContent>
        </ModalBody>

        <ModalFooter className="justify-content-start my-auto">
          {showLikeDislike && (
            <LikeDislikeContainer
              key={buyplanItemId}
              productReleaseId={productReleaseId}
              buyplanItemId={buyplanItemId}
              isModalContext={true}
              isDisabled={activeTab === 2}
            />
          )}

          {showMonths && (
            <Months
              productId={productId}
              productReleaseId={productReleaseId}
              releaseId={releaseId}
            />
          )}

          <PrevNextBtn
            prevProductReleaseId={prevProductReleaseId}
            nextProductReleaseId={nextProductReleaseId}
            prevQuickViewPath={prevLink}
            nextQuickViewPath={nextLink}
          />
        </ModalFooter>
      </StyledModal>
    );
  }
};

const StyledModal = styled(Modal)`
  min-width: 70%;
  max-width: 90%;

  @media (max-width: 575.98px) {
    padding-top: 60px;
  }

  .modal-content {
    height: 90vh;
  }

  .modal-header {
    align-items: center;
  }

  button.close {
    font-size: 1.5em;
  }

  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;

    @media (max-width: 575.98px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 68vh;
    overflow: auto;

    .carousel-block {
      max-width: 312px;
    }

    .basic-info-block {
      padding-left: 30px;
    }

    .carousel__slider {
      box-shadow: 0 0 10px #ddd;
      border: 1px solid #ddd;
      max-width: 305px;
      max-height: 360px;
    }

    .carousel__inner-slide {
      padding: 6px;
    }

    .carousel__image--with-background {
      background-size: 100% 355px !important;
      background-position: center top;
    }

    .carousel-navigation-actions {
      > button {
        padding: 0px 3px;
      }

      > button:not(:disabled) {
        border-color: #ddd !important;
        border-style: solid;
      }

      > button:disabled {
        border-color: #666666 !important;
        border-style: solid;
      }
    }

    .basic-info {
      > div {
        display: flex;
      }

      span:first-child {
        float: left;
        width: 6em;
      }
    }

    hr {
      margin: 20px 0;
      border-top-color: #eee;
    }

    .my-notes {
      h6 {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }
    }
  }

  .modal-footer {
    @media (max-width: 575.98px) {
      flex-direction: column;
      align-items: flex-start;

      > :not(:last-child) {
        margin: 0;
      }
    }

    .sc-kAzzGY {
      margin: 0 4px 0 0;

      @media (max-width: 575.98px) {
        margin: 0;
      }
    }

    .months-wrapper {
      flex: 1;
      justify-content: flex-start;
      border-bottom: 0 none;
      padding: 4px 12px 0;
      min-height: inherit;
      margin: 0;

      @media (max-width: 575.98px) {
        padding: 8px 0;
      }

      .badge {
        font-size: 12px;
        margin-bottom: 4px;
        padding: 8px 6px;
        width: auto;
      }
    }
  }
`;

const StyledProductInfo = styled(ProductInfo)`
  min-width: 11%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

const brandQuickViewPath = (
  brandId,
  productReleaseId,
  queryString,
  forBuyplan,
  buyplanId
) => {
  if (forBuyplan) {
    return `/brands/${brandId}/products/for/${buyplanId}/${productReleaseId}/quick-view${queryString}`;
  }

  return `/brands/${brandId}/products/${productReleaseId}/quick-view${queryString}`;
};

const releaseQuickViewPath = (
  releaseId,
  productReleaseId,
  queryString,
  forBuyplan,
  buyplanId
) => {
  if (forBuyplan) {
    return `/releases/${releaseId}/products/for/${buyplanId}/${productReleaseId}/quick-view${queryString}`;
  }

  return `/releases/${releaseId}/products/${productReleaseId}/quick-view${queryString}`;
};

const stockModelQuickViewPath = (releaseId, stockModalId, productReleaseId) => {
  // Need prev & next stockModalItemId here
  return `/releases/${releaseId}/stock_models/${stockModalId}/${productReleaseId}/quick-view?stockModalItemId=`;
};

const prevNextQuickViewPath = (
  currentPath,
  brandId,
  stockModalId,
  releaseId,
  buyplanId,
  prevProductReleaseId,
  nextProductReleaseId
) => {
  const forBuyplan = currentPath && currentPath.includes("/for/");
  const queryString = `?buyplanId=${buyplanId}`;

  if (brandId) {
    return [
      brandQuickViewPath(
        brandId,
        prevProductReleaseId,
        queryString,
        forBuyplan,
        buyplanId
      ),
      brandQuickViewPath(
        brandId,
        nextProductReleaseId,
        queryString,
        forBuyplan,
        buyplanId
      )
    ];
  } else if (stockModalId) {
    return [
      stockModelQuickViewPath(releaseId, stockModalId, prevProductReleaseId),
      stockModelQuickViewPath(releaseId, stockModalId, nextProductReleaseId)
    ];
  } else if (releaseId) {
    return [
      releaseQuickViewPath(
        releaseId,
        prevProductReleaseId,
        queryString,
        forBuyplan,
        buyplanId
      ),
      releaseQuickViewPath(
        releaseId,
        nextProductReleaseId,
        queryString,
        forBuyplan,
        buyplanId
      )
    ];
  }
};

const returnIndex = (param, currentIndex, totalImages) => {
  if (param === "prev") {
    if (totalImages <= 6) return 0;
    else {
      if (totalImages - currentIndex > 6) return currentIndex;
      return totalImages - 6;
    }
  } else {
    if (totalImages <= 6) return totalImages;
    else {
      if (totalImages - currentIndex > 6) return currentIndex + 6;
      return totalImages;
    }
  }
};

export default React.memo(ProductModal);
