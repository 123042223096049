export const buyplanIdActions = {
  startLoader: "START_BUYPLAN_ID_LOADER",
  request: "REQUEST_BUYPLAN_ID",
  requestForQfill: "REQUEST_BUYPLAN_ID_FOR_QFILL",
  success: "BUYPLAN_ID_SUCCESS",
  failure: "BUYPLAN_ID_FAILURE"
};

export const getBuyplanId = payload => {
  return {
    type: buyplanIdActions.request,
    payload
  };
};

export const getBuyplanIdForQfill = payload => {
  return {
    type: buyplanIdActions.requestForQfill,
    payload
  };
};
