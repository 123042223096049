import React from "react";

import { ATS_STATUSES } from "constants.js";

import SizeBreakInput from "components/QuantityGrid/StockModal/SizeBreakInput.jsx";
import greenTick from "assets/images/tick.png";
import redCross from "assets/images/cross.png";

const StockModalBody = ({
  quantities,
  qtyLabels,
  sizeSequences,
  sizeBreaks,
  updateSizeBreakQty,
  handleSubmission,
  sizeBreakKey,
  isMinOrderCheckApplicable
}) => {
  return (
    <>
      {quantities &&
        quantities.map((qtyType) => {
          const currentMonthQty = sizeBreaks.first().getIn([qtyType, "current_month_qty"])
          const monthMinOrderQty = sizeBreaks.first().getIn([qtyType, "month_min_order_qty"])
          const monthMinConditionSatisfied = currentMonthQty >= monthMinOrderQty

          return (
            <tr key={qtyType}>
              <td className="attribute_col">
                {sizeBreakKey} {qtyLabels.get(qtyType)}
              </td>

              {sizeSequences.map((size) => {
                const qty = sizeBreaks.getIn([size, qtyType, "quantity"]);
                const classNames = sizeBreaks.getIn([size, qtyType, "class"]);
                const placeholder = sizeBreaks.getIn([
                  size,
                  qtyType,
                  "placeholder",
                ]);
                const tooltip = sizeBreaks.getIn([size, qtyType, "tooltip"]);
                const atsStatus = sizeBreaks.getIn([
                  size,
                  qtyType,
                  "ats_status",
                ]);
                const isQtyExceeded = [
                  ATS_STATUSES.overQty,
                  ATS_STATUSES.cumulativeOverQty,
                  ATS_STATUSES.orderQtyBlock,
                  ATS_STATUSES.sizeMinOrderQty,
                  ATS_STATUSES.monthMinOrderQty,
                ].includes(sizeBreaks.getIn([size, qtyType, "ats_status"]));

                return (
                  <td key={size}>
                    <div>
                      <SizeBreakInput
                        className={classNames}
                        placeholderText={placeholder}
                        title={tooltip}
                        sizeBreakId={sizeBreaks.getIn([size, qtyType, "id"])}
                        sizeBreakType={qtyType}
                        sizeBreakSize={size}
                        sizeBreakKey={sizeBreakKey}
                        isDisabled={sizeBreaks.getIn([
                          size,
                          qtyType,
                          "disabled",
                        ])}
                        isReadonly={sizeBreaks.getIn([
                          size,
                          qtyType,
                          "readonly",
                        ])}
                        defaultValue={qty}
                        lowQty={atsStatus === ATS_STATUSES.lowQty}
                        markRed={atsStatus === ATS_STATUSES.soldOut}
                        qtyExceeded={isQtyExceeded}
                        updateSizeBreakQty={updateSizeBreakQty}
                        handleSubmission={handleSubmission}
                      />
                    </div>
                  </td>
                );
              })}
              { isMinOrderCheckApplicable &&
                <>
                  { qtyType === "fill_quantity" ?
                    <>
                      <td className="table-last-col"> {currentMonthQty}/{monthMinOrderQty}
                        { monthMinConditionSatisfied ?
                          <img
                            className='month-min-sign'
                            src={ greenTick }
                            alt="Month Min Fulfill"
                          /> :
                          <img
                            className='month-min-sign'
                            src={ redCross }
                            alt="Month Min MisMatch"
                          />
                        }
                      </td>
                    </>
                  : <> <td className="table-last-col"/> </>
                  }
                </>
              }
            </tr>
          );
        })}
    </>
  );
};

export default React.memo(StockModalBody);
