import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import i18next from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import BarcodeScannerContainer from "containers/BarcodeScannerContainer";
import BuyplanDetailsContainer from "containers/StockModal/BuyplanDetailsContainer.jsx";
import BuyplanReportContainer from "containers/StockModal/BuyplanReportContainer.jsx";
import ProductsListContainer from "containers/StockModal/ProductsListContainer.jsx";
import ReleaseBannerContainer from "containers/ReleaseBannerContainer.jsx";
import SubmitOrderContainer from "containers/StockModal/SubmitOrderContainer.jsx";
import GlobalMenuContainer from "containers/GlobalMenuContainer";

import CopyAsTemplateBtn from "components/CopyAsTemplateBtn.jsx";
import CopyAsTemplateDialog from "components/BuyplanReport/StockModal/CopyAsTemplateDialog.jsx";
import ScrollToTop from "components/ScrollToTop.jsx";
import Sidebar from "components/Sidebar/index.jsx";
import SubmitOrderBtn from "components/SubmitOrderBtn.jsx";
import TabsList from "components/StockModal/TabsList.jsx";

import { getCurrentUser } from "actions/currentUserActions.js";
import { getStockModelId } from "actions/stockModelIdActions.js";
import { resetOrderDetails } from "actions/orderDetailsActions.js";
import { getWarehousesForStockModel } from "actions/warehouseActions.js";
import { stockModelRequestQtyGrid } from "actions/StockModal/barcodeScanningActions.js";

import { activeTabSelector } from "selectors/filtersSelector.js";
import { bannerInfoDataSelector } from "selectors/bannerInfoSelector.js";
import { buyplanInfoDataSelector } from "selectors/buyplanInfoSelector.js";
import { currentUserDataSelector } from "selectors/currentUserSelector.js";
import { stockModelIdSelector } from "selectors/stockModelIdSelector";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";
import { showBarcodeScanForCnfSelector } from "selectors/productsSelector.js";

import { AGENT_ADMIN, BRANDSCOPE_ADMIN, RETAILER_EMPLOYEE } from "constants.js";
import { isMarketingRelease } from "utils.js";


const getTabsConfig = (isStockModalTemplate, tabsConfigLoclisationData) => {
  let tabsConfig = {
    range: {
      id: "RANGE_PAGE_TAB",
      title: tabsConfigLoclisationData.range,
      body: <ProductsListContainer />
    },
    buyplanDetails: {
      id: "BUYPLAN_DETAILS_TAB",
      title: tabsConfigLoclisationData.buyPlan,
      body: <BuyplanDetailsContainer />
    },
    report: {
      id: "REPORT",
      title: tabsConfigLoclisationData.report,
      body: <BuyplanReportContainer />
    }
  };

  if (isStockModalTemplate) {
    delete tabsConfig["report"];

    return { ...tabsConfig };
  }
  return tabsConfig;
};

const StockModalLayout = ({ className }) => {
  const activeTab = useSelector(activeTabSelector);

  const [isModalOpen, setModalState] = useState(false);

  const toggleModalFn = useCallback(() => {
    dispatch(resetOrderDetails())
    setModalState(!isModalOpen);
  }, [isModalOpen]);

  const [isCopyModalOpen, setCopyModalState] = useState(false);

  const toggleCopyModalFn = useCallback(() => {
    setCopyModalState(!isCopyModalOpen);
  }, [isCopyModalOpen]);

  const { stockModalId, releaseId } = useParams();
  const confirmedStockModalId = useSelector(stockModelIdSelector);
  const stockModalInfo = useSelector(buyplanInfoDataSelector);
  const warehouseId = useSelector(warehouseIdSelector);
  const showBarcodeScanForCnf = useSelector(showBarcodeScanForCnfSelector)
  const isStockModalTemplate = stockModalInfo.get("state") === "template";

  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserDataSelector);
  const bannerInfoData = useSelector(bannerInfoDataSelector);
  const isAdminUser = currentUser && currentUser.get("role") === BRANDSCOPE_ADMIN;
  const isAgentUser = currentUser && currentUser.get("role") === AGENT_ADMIN;
  const isRetailerEmployee = currentUser && currentUser.get("role") === RETAILER_EMPLOYEE;
  const isMarketingrelease = isMarketingRelease(bannerInfoData.get("release_status"));
  let tabsConfigLoclisationData = {
    range: i18next.t("tabsConfig.range"),
    buyPlan: i18next.t("tabsConfig.buyPlan"),
    report: i18next.t("tabsConfig.report")
  };

  const showSubmitBtn = () => {
    if (isRetailerEmployee || isAdminUser || isStockModalTemplate) return false;
    if (isAgentUser && isMarketingrelease) return false;
    return true;
  };

  useEffect(() => {
    if (confirmedStockModalId) dispatch(getCurrentUser());
  }, [dispatch, confirmedStockModalId]);

  useEffect(() => {
    dispatch(getStockModelId({ releaseId: releaseId, template_id: stockModalId }));
  }, [dispatch, releaseId, stockModalId]);

  useEffect(() => {
    if (confirmedStockModalId) {
      dispatch(getWarehousesForStockModel({ stockModalId: confirmedStockModalId }));
    }
  }, [dispatch, confirmedStockModalId]);

  if (!confirmedStockModalId) return null;

  const handleBarcodeSubmit = (barcode) => {
    dispatch(
      stockModelRequestQtyGrid({
        releaseId,
        stockModalId: confirmedStockModalId,
        warehouseId,
        barcode,
      })
    );
  }

  return (
    <>
      <GlobalMenuContainer />

      <div className={`${className}`}>
        <Sidebar />

        <main className={`main ${activeTab === "report" && "w-100"}`}>
          <ReleaseBannerContainer />

          <section>
            <div className="btn-wrap float-right">
              {(activeTab === "range") && showBarcodeScanForCnf &&
                <BarcodeScannerContainer
                  isAdminUser={isAdminUser}
                  isTemplate={isStockModalTemplate}
                  isAgentAdmin={isAgentUser}
                  isMarketingrelease={isMarketingrelease}
                  handleBarcodeSubmit={handleBarcodeSubmit}
                  isStockModel={true}
                />
              }

              {isAgentUser && !isStockModalTemplate && (
                <CopyAsTemplateBtn
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={toggleCopyModalFn}
                >
                  Copy As Template
                </CopyAsTemplateBtn>
              )}
              {showSubmitBtn() && (
                <SubmitOrderBtn type="button" className="btn" onClick={toggleModalFn}>
                  Submit Order
                </SubmitOrderBtn>
              )}
            </div>

            <TabsList tabsConfig={getTabsConfig(isStockModalTemplate, tabsConfigLoclisationData)} />
          </section>

          {!isAdminUser && !isStockModalTemplate && (
            <SubmitOrderContainer isOpen={isModalOpen} closeModalCallback={toggleModalFn} />
          )}

          {!isAdminUser && !isStockModalTemplate && (
            <CopyAsTemplateDialog isOpen={isCopyModalOpen} closeModalCallback={toggleCopyModalFn} />
          )}
        </main>

        <ScrollToTop />
      </div>
    </>
  );
};

const StyledStockModalLayout = styled(StockModalLayout)`
  display: flex;
  width: 100%;
  padding: 20px 15px 0;

  @media (max-width: 575.98px) {
    flex-direction: column;
  }

  .sidebar {
    width: var(--sidebar-width);
    min-width: var(--sidebar-min-width);

    @media (max-width: 575.98px) {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .main {
    width: var(--main-width);
    padding-left: 15px;

    @media (max-width: 575.98px) {
      width: 100%;
      padding-left: 0;
    }
  }

  .btn-wrap {
    padding: 11.5px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    @media (max-width: 575.98px) {
      float: none !important;
      margin-bottom: -1px;
      text-align: right;
    }
  }

  .release-banner {
    margin: 0 0 10px;
  }
`;

export default React.memo(StyledStockModalLayout);
