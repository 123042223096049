import { takeLatest, put } from "redux-saga/effects";

import { quickFillBannerInfoActions } from "actions/quickFillBannerInfoActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestQuickFillBannerInfo = function*(action) {
  const { brandId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/brands/${brandId}/banner_info.json`,
    actions: quickFillBannerInfoActions,
    params
  });
};

export const watchQuickFillBannerInfo = function*() {
  yield takeLatest(
    quickFillBannerInfoActions.request,
    requestQuickFillBannerInfo
  );
};
