import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { reportInvoiceSelector } from "selectors/buyplanReportSelector.js";
import i18next from "i18next";

const OrderDetails = ({ className }) => {
  const invoice = useSelector(reportInvoiceSelector);

  return (
    <div className={`${className} order-details`}>
      <label>{`${i18next.t("invoice.orderTotal")}:`}</label>
      <StyledOrderAmount>
        {invoice && invoice.get("is_discounted") && (
          <>
            <del>{invoice.get("original_price_formatted")}</del>
            &nbsp;
          </>
        )}
        {invoice && invoice.get("total_price_formatted")}
      </StyledOrderAmount>

      <label>{`${i18next.t("invoice.numberOfItems")}:`}</label>
      <StyledOrderItemsCount>
        {invoice && invoice.get("total_quantity")}
      </StyledOrderItemsCount>
    </div>
  );
};

const StyledOrderAmount = styled.span`
  border-right: 1px solid #bbb;
  margin-right: 0.5em;
  padding-right: 0.5em;
  padding-left: 5px;
  font-size: 20px;
  font-weight: 700;
  color: #90979e;
`;

const StyledOrderItemsCount = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #90979e;
  padding-left: 5px;
`;

const StyledOrderDetails = styled(OrderDetails)`
  margin: 0 0 18px;

  > label {
    color: var(--bs-dark-navy);
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 575.98px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

export default React.memo(StyledOrderDetails);
