import { fromJS } from "immutable";

import { stripePaymentActions } from "actions/stripePaymentActions.js";

const initialState = fromJS({
  isLoading: false,
  hasErrors: false,
  data: {
    redirect_url: null
  },
  error: null
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case stripePaymentActions.startLoader:
      return state.set("isLoading", true);
    case stripePaymentActions.success:
      return state
        .merge({
          hasErrors: false,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case stripePaymentActions.failure:
      return state
        .merge({
          hasErrors: true,
          isLoading: false
        })
        .set("error", fromJS(payload.data.error));
    default:
      return state;
  }
};
