import React from "react";
import { Button } from "reactstrap";
import LineIcon from "react-lineicons";

import CustomModal from "components/CustomModal/index.jsx";
import ModalBody from "components/MarketingLinks/ModalBody.jsx";
import i18next from "i18next";

const MarketingLinksContainer = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const openModal = React.useCallback(() => setIsModalOpen(true), []);
  const closeModal = React.useCallback(() => setIsModalOpen(false), []);

  return (
    <Button
      className="w-75"
      size="sm"
      style={{ marginLeft: "20px", marginTop: "10px" }}
      color="primary"
      onClick={openModal}
      title={i18next.t("sidebar.marketingLinks.buttonTitle")}
    >
      <LineIcon name="link" />
      &nbsp; {i18next.t("sidebar.marketingLinks.campaign")}
      {isModalOpen && (
        <CustomModal
          isOpen={true}
          title={i18next.t("sidebar.marketingLinks.campaignLinks")}
          body={<ModalBody />}
          modalSize="md"
          closeModalCallback={closeModal}
        />
      )}
    </Button>
  );
};

export default React.memo(MarketingLinksContainer);
