import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TabPane } from "reactstrap";

import CopyToClipboard from "components/CopyToClipboard.jsx";
import { LoaderIcon } from "components/Loader/Loader.jsx";

import { generateShortUrl } from "actions/generateShortUrlActions.js";

import {
  isLoadingSelector,
  shortUrlSelector
} from "selectors/generateShortUrlSelector.js";
import i18next from "i18next";

const GenerateLinkForm = () => {
  const dispatch = useDispatch();
  const { releaseId, brandId } = useParams();

  const isLoading = useSelector(isLoadingSelector);
  const shortLink = useSelector(shortUrlSelector);

  const handleFormSubmission = evt => {
    evt.preventDefault();

    const formData = new FormData(evt.target);

    dispatch(
      generateShortUrl({
        name: formData.get("name"),
        release_id: releaseId,
        brand_id: brandId
      })
    );
  };

  return (
    <TabPane tabId="create-new-link">
      <form
        className="generate-link-form mt-4 mb-4"
        onSubmit={handleFormSubmission}
      >
        <div className="input-group">
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder={i18next.t("sidebar.marketingLinks.inputPlaceholder")}
            required
          />
          <button
            type="submit"
            className="btn btn-primary ml-2"
            disabled={isLoading}
          >
            {isLoading ? (
              <LoaderIcon />
            ) : (
              i18next.t("sidebar.marketingLinks.generate")
            )}
          </button>
        </div>
      </form>

      {shortLink && (
        <>
          <h6>{`${i18next.t("sidebar.marketingLinks.sharableUrl")}:`}</h6>

          <CopyToClipboard defaultValue={shortLink} />
        </>
      )}
    </TabPane>
  );
};

export default React.memo(GenerateLinkForm);
