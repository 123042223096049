export const sizeBreakActions = {
  locallyUpdateStockQty: "LOCALLY_UPDATE_STOCK_QUANTITY",
  locallyUpdateCountQty: "LOCALLY_UPDATE_COUNT_QUANTITY",
  locallyUpdateFillQty: "LOCALLY_UPDATE_FILL_QUANTITY"
};

export const locallyUpdateStockQty = ({
  stockModalItemId,
  sizeBreakSize,
  newQty,
  sizeBreakKey = ""
}) => {
  return {
    type: sizeBreakActions.locallyUpdateStockQty,
    payload: {
      stockModalItemId,
      sizeBreakSize,
      newQty,
      sizeBreakKey
    }
  };
};

export const locallyUpdateCountQty = ({
  stockModalItemId,
  sizeBreakSize,
  newQty,
  sizeBreakKey = ""
}) => {
  return {
    type: sizeBreakActions.locallyUpdateCountQty,
    payload: {
      stockModalItemId,
      sizeBreakSize,
      newQty,
      sizeBreakKey
    }
  };
};

export const locallyUpdateFillQty = ({
  stockModalItemId,
  sizeBreakSize,
  newQty,
  sizeBreakKey = ""
}) => {
  return {
    type: sizeBreakActions.locallyUpdateFillQty,
    payload: {
      stockModalItemId,
      sizeBreakSize,
      newQty,
      sizeBreakKey
    }
  };
};
