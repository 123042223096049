import { takeLatest, put } from "redux-saga/effects";

import { buyplanInfoActions } from "actions/buyplanInfoActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestBuyplanInfo = function*(action) {
  const { buyplanId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/buyplans/${buyplanId}/info.json`,
    actions: buyplanInfoActions,
    params
  });
};

export const watchRequestBuyplanInfo = function*() {
  yield takeLatest(buyplanInfoActions.request, requestBuyplanInfo);
};

export const requestStockModalInfo = function*(action) {
  const { stockModalId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/stock_models/${stockModalId}/info.json`,
    actions: buyplanInfoActions,
    params
  });
};

export const watchRequestStockModalInfo = function*() {
  yield takeLatest(
    buyplanInfoActions.requestStockModalInfo,
    requestStockModalInfo
  );
};
