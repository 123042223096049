import React from "react";
import styled from "styled-components";

import { apiBaseUrl } from "apis/apiHelpers.js";

import BannerImg from "components/ReleaseBanner/BannerImg.jsx";

const QuickFillBanner = ({ imgUrl, altText, assignedTo }) => {
  return (
    <div className="brand-banner text-center">
      <BannerImg srcUrl={imgUrl} altText={altText} />

      <StyledQfillName className="caption w-100 mb-4 img-fluid">
        QuickFill for {assignedTo}
        &nbsp;-&nbsp;
        <a href={`${apiBaseUrl}/qfills`}>Back to QuickFill list</a>
      </StyledQfillName>
    </div>
  );
};

const StyledQfillName = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--bs-dark-black);
  letter-spacing: 0.5px;
  margin-top: 15px;
`;

export default React.memo(QuickFillBanner);

QuickFillBanner.defaultProps = {
  altText: "quick fill banner img"
};
