export const buyplanSummaryActions = {
  startLoader: "START_BUYPLAN_SUMMARY_LOADER",
  request: "REQUEST_BUYPLAN_SUMMARY",
  update: "UPDATE_BUYPLAN_SUMMARY",
  success: "BUYPLAN_SUMMARY_SUCCESS",
  failure: "BUYPLAN_SUMMARY_FAILURE",
};

export const getBuyplanSummary = (buyplanId, filters) => {
  return {
    type: buyplanSummaryActions.request,
    payload: {
      buyplanId,
      filters,
    },
  };
};

export const getBuyplanSummarySuccess = (payload) => {
  return {
    type: buyplanSummaryActions.success,
    payload,
  };
};

export const getBuyplanSummaryFailure = (errorPayload) => {
  return {
    type: buyplanSummaryActions.failure,
    payload: errorPayload,
  };
};

export const updateBuyplanItemsLocally = (payload) => {
  return {
    type: buyplanSummaryActions.update,
    payload,
  };
};
