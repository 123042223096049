import React from "react";
import styled, { css } from "styled-components";

import Thumbnail from "components/ProductCard/Thumbnail/Thumbnail.jsx";
import Caption from "components/ProductCard/Caption/Caption.jsx";

const ProductList = ({ className, product, index, textDisappear, scale }) => {
  return (
    <div
      key={index}
      className={`${className} product`}
      data-product-id={product.id}
      id={"procuct_card"}
      scale={scale}
    >
      <Thumbnail
        productId={product.id}
        isSoldOut={false}
        imgSrc={product.product.primary_image}
        altText={product.product.name}
        height='auto'
      />
      <div
        className="buyplan text-center"
        data-ats-visible="false"
        data-sold="true"
      ></div>
      {!textDisappear && (
        <Caption
          name={product.product.name}
          color={product.product.display_colour}
        />
      )}
    </div>
  );
};

const StyledProductList = styled(ProductList)`
  display: inline-block;
  ${props =>
    props.scale &&
    css`
      width: calc(128px * (${props.scale} / 100));
      font-size: calc(1rem * (${props.scale} / 100));
      margin-right: calc(0.5rem * (${props.scale} / 100));
      margin-bottom: calc(0.5rem * (${props.scale} / 100));
    `};
`;

export default React.memo(StyledProductList);
