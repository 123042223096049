import React from "react";
import { useSelector } from "react-redux";

import CustomModal from "components/CustomModal/index.jsx";

import { agreementAcceptanceDataSelector } from "selectors/orderDetailsSelector.js";

const OrderTermsDialog = ({ className, isOpen, closeModalCallback }) => {
  const agreementAcceptanceData = useSelector(agreementAcceptanceDataSelector);
  const agreementText = agreementAcceptanceData.get("agreement_text");
  const agreementPdf = agreementAcceptanceData.get("agreement_pdf");

  return (
    <CustomModal
      className={className}
      isOpen={isOpen}
      title="Terms and Conditions"
      body={
        <>
          <p>{agreementText}</p>

          <a href={agreementPdf} target={`_blank`}>
            View More
          </a>
        </>
      }
      footer={
        <button className="btn btn-primary" onClick={closeModalCallback}>
          I Understand
        </button>
      }
      closeModalCallback={closeModalCallback}
    />
  );
};

export default React.memo(OrderTermsDialog);
