import React, { useState, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CustomModal = ({
  className,
  isOpen,
  title,
  body,
  modalSize,
  footer,
  closeModalCallback,
  dismissible = true,
  showCloseBtn = true
}) => {
  const [isModalOpen, setModalState] = useState(isOpen);

  const closeModalFn = useCallback(() => {
    closeModalCallback ? closeModalCallback() : setModalState(false);
  }, [closeModalCallback]);

  return (
    <Modal
      className={className}
      isOpen={isModalOpen}
      toggle={closeModalFn}
      backdrop={dismissible ? true : "static"}
      size={modalSize || "lg"}
      centered={true}
    >
      {(showCloseBtn && <ModalHeader toggle={closeModalFn}>{title}</ModalHeader>) || (
        <ModalHeader>{title}</ModalHeader>
      )}

      <ModalBody>{body}</ModalBody>

      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};

export default React.memo(CustomModal);
