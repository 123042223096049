import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { logSelector } from "selectors/quantityGridSelector";
import DefaultProductImage from "assets/images/default-product-image-128x160.jpg";

const ScannerLogs = ({className}) => {
  const logs = useSelector(logSelector);
  return (
    <>
      {logs.size > 0 && (
        <div className={`${className}`}>
          <table className="w-100 table-bordered text-center">
            <thead>
              <tr>
                <th></th>
                <th>Product</th>
                <th>Size</th>
                <th>Barcode</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {logs.map((log, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <img
                        src={
                          log["product_image"]
                            ? log["product_image"]
                            : DefaultProductImage
                        }
                        title="product thumbnail"
                        alt=""
                        width="30"
                      />
                    </td>
                    <td>{log["product_name"]}</td>
                    <td>{log["size"]}</td>
                    <td>{log["barcode"]}</td>
                    <td>{log["type"] == "success" ? "+1" : "Error"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

const StyledScannerLogs = styled(ScannerLogs)`
  overflow-y: auto;
  max-height: 180px;
  min-height: 170px;
`;

export default React.memo(StyledScannerLogs);
