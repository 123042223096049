export const buyplanInfoActions = {
  startLoader: "START_BUYPLAN_INFO_LOADER",
  request: "REQUEST_BUYPLAN_INFO",
  requestStockModalInfo: "REQUEST_STOCK_MODAL_INFO",
  success: "BUYPLAN_INFO_SUCESS",
  failure: "BUYPLAN_INFO_FAILURE",
  reset: "RESET_BUYPLAN_INFO",
  setSalesPersonInBuyplanInfo: "SET_SALES_PERSON_IN_BUYPLAN_INFO"
};

export const getBuyplanInfo = (buyplanId) => {
  return {
    type: buyplanInfoActions.request,
    payload: { buyplanId },
  };
};

export const getStockModalInfo = (stockModalId) => {
  return {
    type: buyplanInfoActions.requestStockModalInfo,
    payload: { stockModalId },
  };
};

export const getBuyplanInfoSuccess = (payload) => {
  return {
    type: buyplanInfoActions.success,
    payload,
  };
};

export const getBuyplanInfoFailure = (errorPayload) => {
  return {
    type: buyplanInfoActions.failure,
    payload: errorPayload,
  };
};

export const setSalesPersonInBuyplanInfo = payload => {
  return {
    type: buyplanInfoActions.setSalesPersonInBuyplanInfo,
    payload
  }
}
