import { BULK_PRODUCT_ACTION_THRESHOLD } from "constants.js";

export default {
  order: {
    success: orderSuccessMsg,
    consignment: {
      success: "Consignment request placed successfully!"
    },
    enforceOrderMinimumsMsg,
    orderMinAmtPrefix: "Please Note: Your Minimum Order Amount is"
  },
  reOrder: {
    buyplanMonths: {
      success: "Buy Plan item updated successfully!"
    }
  },
  stockModal: {
    order: {
      success: orderSuccessMsg
    },
    copyAsTemplate: {
      success: "Count & Fill copied as Count & Fill Template successfully!"
    }
  },
  buyplanItem: {
    add: {
      success: "Buy Plan item added successfully!"
    },
    remove: {
      success: "Buy Plan item removed successfully!"
    }
  },
  stockModalItem: {
    add: {
      success: "Buy Plan item added successfully!"
    },
    remove: {
      success: "Buy Plan item removed successfully!",
      removeItemFromStaticCnf:
        "Are you sure you want to remove this item from the buyplan, you will not be able to add it back!"
    }
  },
  note: {
    update: {
      success: "Note updated successfully!"
    }
  },
  shippingMethod: {
    required: "Please select Shipping Method"
  },
  dateValidation: {
    fail: "Cancel Date needs to be a date after selected Delivery date"
  },
  qtyGrid: {
    updateQty: {
      success: "Qty grid updated successfully!"
    },
    sizeBreak: {
      inputValidations: {
        nonNumericValue: "Please enter numeric value",
        outOfRange: "Please enter Qtys between 0 - 99999",
        exceededQty: `
          <p>You have entered more Qtys than are available.</p>
          <p>Please check the blue Qty boxes and enter a less amount to submit your order</p>
        `,
        qtyMultiplesMismatch: qtyMultiplesMismatchMsg,
        cumulativeExceededQty: `
          <p>Brand is cumulative.</p>
          <p>You have entered more Qtys than are available across all months.</p>
          <p>Please check the blue Qty boxes and enter a less amount to submit your order</p>
        `
      },
      add: {
        success: "Size break added successfully!"
      },
      remove: {
        success: "Size break removed successfully!"
      }
    },
    updateStockModalQty: {
      success: "Qty grid updated successfully!"
    }
  },
  shortUrl: {
    copyToClipboard: "Short URL copied to clipbaord!"
  },
  bulkSelectThresholdExceeded: `
    More than ${BULK_PRODUCT_ACTION_THRESHOLD} products cannot be added to your Buy Plan.
    Please refine your filters to add multiple products.
  `,
  bulkDeselectThresholdExceeded: `
    More than ${BULK_PRODUCT_ACTION_THRESHOLD} products cannot be removed from your Buy Plan.
    Please refine your filters to remove multiple products.
  `,
  timeout: "Request timed out. Try again after some time."
};

function orderSuccessMsg(orderNumber) {
  return `
    <p>Order placed successfully!</p>
    <p>Order number: ${orderNumber}</p>
  `;
}

function qtyMultiplesMismatchMsg(orderQtyBlock) {
  return `
    <p>Item can only be ordered in multiples of ${orderQtyBlock}.</p>
    <p>Please update the Qtys in the blue boxes</p>
  `;
}

function enforceOrderMinimumsMsg(amount) {
  return `You cannot submit this order. Your order minimum is ${amount}.`;
}
