import { takeLeading, put } from "redux-saga/effects";

import { stripePaymentActions } from "actions/stripePaymentActions.js";

import { POST_DATA } from "actions/apiOperations.js";

export const requestPaymentLink = function*(action) {
  const { ...params } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/stripe/checkout_session`,
    actions: stripePaymentActions,
    params
  });
};

export const watchRequestPayment = function*() {
  yield takeLeading(stripePaymentActions.request, requestPaymentLink);
};
