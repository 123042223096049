import React from "react";

import Select from "react-select";
import styled from "styled-components";
import H5 from "components/H5.js";
import i18next from "i18next";

const WarehouseFilter = ({ warehouses, onChange, className }) => {
  const options = warehouses.map(wh => ({
    value: wh.get("id"),
    label: wh.get("name")
  }));

  return (
    <div className={`${className}`}>
      <H5>{i18next.t("sidebar.warehouse")}</H5>
      <span className="filter-item">
        <Select
          defaultValue={options.get?.(0) || options[0]}
          options={options}
          onChange={onChange}
          theme={theme => ({
            ...theme,
            borderRadius: 0
          })}
          placeholder={i18next.t("select.default")}
          noOptionsMessage={() => i18next.t("select.noOptions")}
        />
      </span>
    </div>
  );
};

const StyledWarehouseFilter = styled(WarehouseFilter)`
  margin: 0 0 8px;

  > details {
    &:focus {
      outline: none;
    }

    .filter-item {
      border: 1px solid #e5e5e5;
      border-top: 0 none;
      padding: 10px;
    }
  }
`;

export default React.memo(StyledWarehouseFilter);
