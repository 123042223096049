import { Map, Set, OrderedSet } from "immutable";

import { productReleaseActions } from "actions/productReleaseActions.js";
import { myNoteActions } from "actions/myNoteActions.js";
import { agentNoteActions } from "actions/agentNoteActions.js";
import { buyplanItemActions } from "actions/likeDislikeActions.js";
import { sizeBreakActions } from "actions/sizeBreakActions.js";

const initialState = Map({
  isLoading: true,
  hasErrors: false,
  error: null,
  data: Map({
    product_release: Map({
      id: null,
      delivery_months: Set(),
      wholesale_range: null,
      wholesale_price: null,
      unit_wholesale_price: null,
      retail_price: null,
      unit_retail_price: null
    }),
    abilities: Map({
      can_view_wholesale_prices: null,
      can_view_wholesale_range: null,
      can_view_my_notes: null,
      can_edit_my_notes: null,
      show_discount_percent: false,
    }),
    my_notes: null,
    product: Map({
      id: null,
      name: null,
      sku: null,
      color: null,
      images: Set(),
      specifications: Set(),
      changes: Set()
    }),
    buyplan_item: Map({
      id: null,
      intended_months: Set(),
      filled_months: Set(),
      pre_order_intended_months: Set(),
      pre_order_filled_months: Set()
    })
  }),
  ui: Map()
});

export default (state = initialState, action) => {
  const { data } = action.payload || {};

  switch (action.type) {
    case productReleaseActions.init:
      return state.set("isLoading", true);
    case productReleaseActions.success:
      let {
        product_release,
        abilities,
        my_notes,
        agent_notes,
        product,
        buyplan_item
      } = data;

      let newSuccessState = state.mergeDeep({
        isLoading: false,
        hasErrors: false,
        error: null
      });

      return newSuccessState.set(
        "data",
        Map({
          product_release: Map(product_release).set(
            "delivery_months",
            Set(product_release.delivery_months)
          ),
          abilities: Map(abilities),
          my_notes,
          agent_notes,
          product: Map(product)
            .set("images", OrderedSet(product.images))
            .set("specifications", OrderedSet(product.specifications))
            .set("changes", Set(product.changes)),
          buyplan_item: Map(buyplan_item).set("id", buyplan_item.id)
        })
      );
    case productReleaseActions.failure:
      let newErrorState = state.mergeDeep({
        isLoading: false,
        hasErrors: true,
        error: data
      });

      return newErrorState.set("data", initialState.data);
    case productReleaseActions.clearStore:
      return initialState;
    case myNoteActions.success:
      return state
        .merge({
          isLoading: false,
          hasErrors: false,
          error: null
        })
        .setIn(["data", "my_notes"], data.my_notes);
    case myNoteActions.failure:
    case agentNoteActions.failure:
        return state
        .merge({
          isLoading: false,
          hasErrors: true,
          error: data
        })
        .set("data", initialState.data);
    case agentNoteActions.success:
      return state
        .merge({
          isLoading: false,
          hasErrors: false,
          error: null
        })
        .setIn(["data", "agent_notes"], data.agent_notes);
    case buyplanItemActions.success:
      return state
        .setIn(["data", "buyplan_item", "id"], data.id)
        .setIn(
          ["data", "buyplan_item", "intended_months"],
          initialState.getIn(["data", "buyplan_item", "intended_months"])
        )
        .setIn(
          ["data", "buyplan_item", "pre_order_intended_months"],
          initialState.getIn(["data", "buyplan_item", "pre_order_intended_months"])
        )
    case sizeBreakActions.success:
      const { intended_months, pre_order_intended_months } = data;

      return state
        .setIn(["data", "buyplan_item", "intended_months"], intended_months)
        .setIn(["data", "buyplan_item", "pre_order_intended_months"], pre_order_intended_months);

    default:
      return state;
  }
};
