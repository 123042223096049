export const bulkDeselectProductsActions = {
  startLoader: "START_BULK_DESELECT_PRODUCTS_LOADER",
  init: "REQUEST_BULK_DESELECT_PRODUCTS",
  success: "BULK_DESELECT_PRODUCTS_SUCCESS",
  failure: "BULK_DESELECT_PRODUCTS_FAILURE",
  reset: "BULK_DESELECT_PRODUCTS_RESET"
};

export const requestBulkDeselectProducts = releaseId => {
  return {
    type: bulkDeselectProductsActions.init,
    payload: {
      releaseId
    }
  };
};

export const bulkDeselectProductsSucceeded = payload => {
  return {
    type: bulkDeselectProductsActions.success,
    payload
  };
};

export const bulkDeselectProductsFailed = errorPayload => {
  return {
    type: bulkDeselectProductsActions.failure,
    errorPayload
  };
};

export const resetBulkDeselectProducts = () => {
  return {
    type: bulkDeselectProductsActions.reset
  };
};
