import { createSelector } from "reselect";

export const generateShortUrlReducerSelector = createSelector(
  state => state.get("generateShortUrlReducer"),
  generateShortUrlReducer => generateShortUrlReducer
);

export const isLoadingSelector = createSelector(
  generateShortUrlReducerSelector,
  generateShortUrlReducer => generateShortUrlReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  generateShortUrlReducerSelector,
  generateShortUrlReducer => generateShortUrlReducer.get("hasErrors")
);

export const generateShortUrlDataSelector = createSelector(
  generateShortUrlReducerSelector,
  generateShortUrlReducer => generateShortUrlReducer.get("data")
);

export const shortUrlSelector = createSelector(
  generateShortUrlDataSelector,
  generateShortUrlData => generateShortUrlData.get("short_link")
);
