import { takeLatest, put, race, take } from "redux-saga/effects";

import { buyplanDetailsActions } from "actions/buyplanDetailsActions.js";
import {
  getBuyplanTotals,
  getStockModalBuyplanTotals
} from "actions/buyplanTotalsActions.js";

import { POST_DATA } from "actions/apiOperations.js";

export const requestBuyplanDetails = function*(action) {
  const { buyplanId, ...params } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/buyplans/${buyplanId}/details.json`,
    actions: buyplanDetailsActions,
    params
  });
  const { success } = yield race({
    success: take(buyplanDetailsActions.success),
    error: take(buyplanDetailsActions.failure)
  });

  if (success) {
    if(params.offset === 0) {
      yield put(getBuyplanTotals(buyplanId));
    }
  }
};

export const watchRequestBuyplanDetails = function*() {
  yield takeLatest(buyplanDetailsActions.request, requestBuyplanDetails);
};

export const requestStockModalBuyplanDetails = function*(action) {
  const { stockModalId, ...params } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/stock_models/${stockModalId}/list_view.json`,
    actions: buyplanDetailsActions,
    params
  });

  const { success } = yield race({
    success: take(buyplanDetailsActions.success),
    error: take(buyplanDetailsActions.failure)
  });

  if (success) {
    const stockModelBuyplanItemIds = success.payload.data.stock_model_items.map(
      item => item.id
    );
    yield put(
      getStockModalBuyplanTotals(stockModalId, stockModelBuyplanItemIds)
    );
  }
};

export const watchRequestStockModalBuyplanDetails = function*() {
  yield takeLatest(
    buyplanDetailsActions.requestStockModal,
    requestStockModalBuyplanDetails
  );
};
