import React from "react";
import { useSelector } from "react-redux";

import { reportCategoriesTotalSelector } from "selectors/buyplanReportSelector.js";

import MonthReportTable from "components/BuyplanReport/MonthReportTable.jsx";
import i18next from "i18next";

const CategoryTotals = () => {
  const categoriesTotals = useSelector(reportCategoriesTotalSelector);

  return (
    <MonthReportTable
      headerText={i18next.t("invoice.categoryTotals")}
      totalsObj={categoriesTotals}
    />
  );
};

export default React.memo(CategoryTotals);
