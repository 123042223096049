// third party plugins
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import "assets/stylesheets/transition.css";
import "assets/stylesheets/filters.css";

// selectors
import {
  isLoadingSelector,
  hasErrorsSelector,
  errorSelector,
  fetchedCommonFiltersSelector,
  appliedCommonFiltersSelector,
  activeTabSelector
} from "selectors/filtersSelector.js";
import { currentUserDataSelector } from "selectors/currentUserSelector.js";

// actions
import { applyFilters, searchFilters } from "actions/filterActions.js";

// custom utils
import { humanize } from "utils";
import {
  BRANDSCOPE_ADMIN,
  SUPER_SUPPLIER_ADMIN,
  SUPPLIER_ADMIN,
  AGENT_ADMIN,
  SALES_REP
} from "constants.js";

// container components
import WarehouseFilterContainer from "containers/WarehouseFilterContainer.jsx";
import MarketingLinksContainer from "containers/MarketingLinksContainer.jsx";

// presentational components
import H5 from "components/H5.js";
import SearchForm from "components/Filters/SearchForm";
import PriceRangeFilter from "components/Filters/PriceRangeFilter";
import FilterRow from "components/Filters/List/FilterRow.jsx";
import FiltersList from "components/Filters/List/List.jsx";
import i18next from "i18next";

const getFiltersSkeleton = () => (
  <img src="/range_filters.svg" alt="filter panel placeholder" />
);

const FiltersListContainer = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);
  const hasErrors = useSelector(hasErrorsSelector);
  const error = useSelector(errorSelector);
  const fetchedCommonFilters = useSelector(fetchedCommonFiltersSelector);
  const appliedCommonFilters = useSelector(appliedCommonFiltersSelector);
  const currentUser = useSelector(currentUserDataSelector);
  const activeTab = useSelector(activeTabSelector);
  const history = useHistory();
  const isDndAccessible = currentUser.get("is_dnd_accessible");
  const onSearch = searchKeyword => {
    if (fetchedCommonFilters.get("search") !== searchKeyword) {
      dispatch(applyFilters({ search: searchKeyword }));
    }
  };

  const onPriceChange = ([minPrice, maxPrice]) => {
    if (
      appliedCommonFilters.get("min_price") !== minPrice ||
      appliedCommonFilters.get("max_price") !== maxPrice
    ) {
      dispatch(applyFilters({ min_price: minPrice, max_price: maxPrice }));
    }
  };

  const onCheckboxToggle = evt => {
    const {
      checked: isChecked,
      dataset: { segmentId, divisionId, collectionId, categoryId, filterId }
    } = evt.target;

    dispatch(
      applyFilters({
        others: {
          isChecked,
          categoryId,
          filterId,
          dataset: {
            name: filterId,
            segment_id: segmentId,
            division_id: divisionId,
            collection_id: collectionId
          }
        }
      })
    );
  };

  const onFilterCategoryReset = evt => {
    const categoryId = evt.currentTarget.dataset.category;

    dispatch(
      applyFilters({
        others: {
          isChecked: false,
          categoryId,
          filterId: "All"
        }
      })
    );
  };

  const onFilterSearch = evt => {
    const {
      value: keyword,
      dataset: { categoryId }
    } = evt.currentTarget;

    dispatch(
      searchFilters({
        keyword,
        categoryId
      })
    );
  };

  const min_price = fetchedCommonFilters.get("min_price");
  const max_price = fetchedCommonFilters.get("max_price");
  const launchDnd = () => {
    const path = history.location.pathname.split("/");
    const index = path.indexOf("products");
    return history.push(path.slice(0, index + 1).join("/") + "/DND");
  };
  return (
    <>
      {isLoading && getFiltersSkeleton()}

      {hasErrors && <div className="error-msg">{error.message}</div>}

      <CSSTransition
        classNames="fade"
        timeout={1200}
        in={!isLoading && !hasErrors}
        unmountOnExit
      >
        <div className="d-flex flex-column">
          {isDndAccessible && (
            <div className="editWrap">
              <H5
                className="mt-4 mb-4"
                style={{ borderBottom: "1px solid #ddd", paddingBottom: "2ex" }}
              >
                {i18next.t("sidebar.options")}
              </H5>
                <FiEdit className={`editDnd mt-4 mb-4`} onClick={launchDnd} />
            </div>
          )}
          <SearchForm
            handleSubmit={onSearch}
            searchKeyword={appliedCommonFilters.get("search")}
          />

          <WarehouseFilterContainer />

          <PriceRangeFilter
            key={`${min_price}-${max_price}`}
            minPrice={min_price}
            maxPrice={max_price}
            appliedMinPrice={appliedCommonFilters.get("min_price")}
            appliedMaxPrice={appliedCommonFilters.get("max_price")}
            onChange={onPriceChange}
          />

          {fetchedCommonFilters.get("filter_sequence").map(filterName => {
            const appliedFilterValue = appliedCommonFilters.get(filterName);

            if (
              ![
                "min_price",
                "max_price",
                "currency",
                "currency_symbol"
              ].includes(filterName)
            ) {
              return (
                <FilterRow
                  key={`${filterName}`}
                  category={`${filterName}`}
                  filterValue={`${humanize(filterName)}`}
                  isChecked={appliedFilterValue}
                  onToggle={onCheckboxToggle}
                />
              );
            }

            return null;
          })}

          {currentUser &&
            [
              BRANDSCOPE_ADMIN,
              SUPER_SUPPLIER_ADMIN,
              SUPPLIER_ADMIN,
              AGENT_ADMIN,
              SALES_REP
            ].includes(currentUser.get("role")) &&
            activeTab === "range" && <MarketingLinksContainer />}

          <H5 className="mt-4 mb-4">{i18next.t("sidebar.filterBy")}</H5>
          <FiltersList
            onToggle={onCheckboxToggle}
            onFilterCategoryReset={onFilterCategoryReset}
            onFilterSearch={onFilterSearch}
          />
        </div>
      </CSSTransition>
    </>
  );
};

export default React.memo(FiltersListContainer);
