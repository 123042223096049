import { fromJS } from "immutable";

import { invalidQtyProductsActions } from "actions/invalidQtyProductsActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    buyplan_item_id: null,
    product_name: null,
    product_sku: null,
    invalid_data: {}
  }
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case invalidQtyProductsActions.startLoader:
      return state.set("isLoading", true);
    case invalidQtyProductsActions.reset:
      return initialState;
    case invalidQtyProductsActions.success:
      return state
        .merge({
          hasErrors: false,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case invalidQtyProductsActions.failure:
      return state
        .merge({
          hasErrors: true,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    default:
      return state;
  }
};
