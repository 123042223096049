import React from "react";
import "./AtsTable.css";
import i18next from "i18next";
import warehouseImg from "assets/images/warehouse.png";

const AtsTable = ({ atsData, sizes, attrSizes }) => {

  // {"warehouse"=>{""=>{"S"=>{"2023-04-01"=>100}, "L"=>{"2023-04-01"=>102}},
  //               "attr_size"=>{"M"=>{"2023-04-01"=>101}}},
  // "warehouse2"=>{}}

  return (
    atsData.entrySeq().map(([wareName, warehouseObj]) => {
      return (<>
        { warehouseObj.size === 0 ?
          <>
            { wareName && <div className="d-flex">
              <img
                className='warehouse-sign'
                src={ warehouseImg }
                alt="warehouse"
              />
              <h6>{wareName}</h6>
            </div> }
            <div className="no_ats"> { i18next.t("ats.unavailable") } </div>
          </> :
          <>
            <div className="d-flex">
              <img
                className='warehouse-sign'
                src={ warehouseImg }
                alt="warehouse"
              />
              <h6>{wareName}</h6>
            </div>
            <table className="ats_table">
              <thead>
                <tr>
                  <th className="attribute_col"> Attribute/Size </th>
                  {sizes.map((size) => {
                    return( <th key={size}> {size} </th>)
                  })}
                </tr>
              </thead>
              <tbody>
                {warehouseObj && attrSizes.map((attrRowKey, attrRowIndex) => {
                  const attrRowObj = warehouseObj.getIn([attrRowKey]);
                  return (
                    <tr key={attrRowIndex}>
                      <th className="attribute_col">{attrRowKey}</th>

                      {attrRowObj && sizes.map(size => {
                        const poObj = attrRowObj.getIn([size]);
                        if (!poObj) {
                          return <td key={size} />;
                        }
                        else {
                          return (<>
                            <td key={size}>
                              {poObj.entrySeq().map(([po, qty]) =>{
                                return (
                                  <div key={po}>{po}: {qty} </div>
                                )
                              })}
                            </td></>
                          )
                        }
                      })}
                    </tr>
                  )}
                )}
              </tbody>
            </table>
          </>
        }
      </>);
    })
  );
};

export default React.memo(AtsTable);
