import { createSelector } from "reselect";

export const viewAtsReducerSelector = createSelector(
  state => state.get("viewAtsReducer"),
  viewAtsReducer => viewAtsReducer
);

export const isLoadingSelector = createSelector(
  viewAtsReducerSelector,
  viewAtsReducer => viewAtsReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  viewAtsReducerSelector,
  viewAtsReducer => viewAtsReducer.get("hasErrors")
);

export const productAtsDataSelector = createSelector(
  viewAtsReducerSelector,
  viewAtsReducer => viewAtsReducer.get("ats_data")
);

export const productSizesDataSelector = createSelector(
  viewAtsReducerSelector,
  viewAtsReducer => viewAtsReducer.get("sizes")
);

export const productAttrSizesDataSelector = createSelector(
  viewAtsReducerSelector,
  viewAtsReducer => viewAtsReducer.get("attr_sizes")
);

export const productNameSelector = createSelector(
  viewAtsReducerSelector,
  viewAtsReducer => viewAtsReducer.get("product_name")
);

export const productSkuSelector = createSelector(
  viewAtsReducerSelector,
  viewAtsReducer => viewAtsReducer.get("product_sku")
);
