export const invalidQtyProductsActions = {
  startLoader: "START_INVALID_QTY_PRODUCTS_LOADER",
  request: "REQUEST_INVALID_QTY_PRODUCTS",
  success: "INVALID_QTY_PRODUCTS_SUCCESS",
  failure: "INVALID_QTY_PRODUCTS_FAILURE",
  reset: "RESET_INVALID_QTY_PRODUCTS",
};

export const getInvalidOrders = (buyplanId, warehouseId) => {
  return {
    type: invalidQtyProductsActions.request,
    payload: { buyplanId, warehouse_id: warehouseId },
  };
};

export const getInvalidOrdersSuccess = (payload) => {
  return {
    type: invalidQtyProductsActions.success,
    payload,
  };
};

export const getInvalidOrdersFailure = (errorPayload) => {
  return {
    type: invalidQtyProductsActions.failure,
    payload: errorPayload,
  };
};

export const resetInvalidQtyProducts = () => ({
  type: invalidQtyProductsActions.reset,
});
