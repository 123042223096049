import {
  CONSIGNMENT_REQUEST,
  MONTH_NUM_TO_ABBR_MAPPING,
  BRANDSCOPE_ADMIN,
  AGENT_ADMIN,
  MARKETING,
  TEMPLATE
} from "constants.js";

export const isPastMonthFn = dateStr => {
  const currentDate = new Date();
  const receivedDate = new Date(dateStr);

  // if (receivedDate.getFullYear() < currentDate.getFullYear()) return true;

  // if (receivedDate.getFullYear() === currentDate.getFullYear()) {
  //   return receivedDate.getMonth() < currentDate.getMonth();
  // }
  return receivedDate < currentDate ? true : false;
  //return false;
};

export const isFutureMonthFn = dateStr => {
  //const currentMonth = new Date().getMonth();
  //const receivedDateMonth = new Date(dateStr).getMonth();
  const currentDate = new Date();

  const receivedDate = new Date(dateStr);

  return receivedDate > currentDate ? true : false;
  //return !isPastMonthFn(dateStr) && receivedDateMonth !== currentMonth;
};

export const randomStrGenerator = () => {
  return Math.random()
    .toString(36)
    .substr(2, 9);
};

export const humanize = str => {
  if (str) {
    return str
      .split("_")
      .map(word => word[0].toUpperCase() + word.slice(1, word.length))
      .join(" ");
  }
  return "";
};

export const titleize = str => {
  if (str) {
    try {
      return str.toLowerCase().replaceAll(/\s+/g, "-");
    } catch (err) {
      if (err instanceof TypeError)
        return str.toLowerCase().replace(/\s+/g, "-");
    }
  }

  return "";
};

export const debounce = (callback, wait) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(this, args), wait);
  };
};

export const getShortDate = dateStr => {
  const dateObj = new Date(dateStr);
  const month = dateObj.getUTCMonth() + 1; // 0-11
  const monthAbbr = MONTH_NUM_TO_ABBR_MAPPING[month];
  const twoDigitYear = dateObj
    .getUTCFullYear()
    .toString()
    .slice(2);

  return `${monthAbbr}${twoDigitYear}`;
};

export const isValidDateForMonth = (date, month) => {
  const numericDate = +date;
  const numericMonth = +month;

  if (
    isNaN(numericDate) ||
    numericDate < 1 ||
    isNaN(numericMonth) ||
    numericMonth > 12
  )
    return false;

  switch (numericMonth) {
    case 2: // Feb has 28 days
      return numericDate <= 28;
    case 4:
    case 6:
    case 9:
    case 11: // Apr, Jun, Sep, Nov have 30 days
      return numericDate <= 30;
    default:
      // all other months have 31 days
      return numericDate <= 31;
  }
};

export const isAdminUser = role => role === "Brandscope Admin";

export const isStateConsignmentRequest = buyplanState => {
  return buyplanState === CONSIGNMENT_REQUEST;
};

export const isTemplateStateBuyplan = buyplanState => {
  return buyplanState === TEMPLATE;
};

export const isMarketingRelease = releaseStatus => {
  return releaseStatus === MARKETING;
}

export const isRequestForStockModal = url => {
  return url.indexOf("/stock_models/") !== -1;
};

export const isQtyGridDisabled = (
  role,
  isSoldOut,
  releaseStatus,
  buyplanState,
  buyplanId,
  isPreOrderProduct = false
) => {
  return (
    (role === BRANDSCOPE_ADMIN ||
      isSoldOut ||
      (role === AGENT_ADMIN &&  isMarketingRelease(releaseStatus)) ||
      isStateConsignmentRequest(buyplanState) ||
      (isTemplateStateBuyplan(buyplanState) && !buyplanId)) &&
    !isPreOrderProduct
  );
};

export const isObjectEmpty = obj => (
  obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype // because Object.keys(new Date()).length === 0
);
