import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import {
  isBuyplanOwnershipEnforcedSelector,
  orderBuyplanSelector,
  poCharacterLimitSelector
} from "selectors/orderDetailsSelector.js";
import NetsuiteOrderType from "components/BuyplanReport/NetsuiteOrderType.jsx";
import SalesPersonSelect, {
  getSalesPersonOptions
} from "components/SalesPersonSelect/SalesPersonSelect";
import i18next from "i18next";

const shippingMethodOptions = shippingMethods => {
  return shippingMethods.map(method => ({
    value: method.get("code"),
    label: method.get("label")
  }));
};

const DaiwaOrderInfo = ({
  handleShippingMethodChange,
  handleNetsuiteOrderTypeChange,
  handleDeliveryDateChange,
  handleCancelDateChange,
  onPurchaseOrderBlurCallback,
  onDeliveryInstructionsBlurCallback,
  handleSalesPersonChange,
  deliveryDate,
  cancelDate
}) => {
  const buyplan = useSelector(orderBuyplanSelector);
  const showShippingMethods = buyplan && buyplan.get("show_shipping_methods");
  const customerPurchaseOrder =
    buyplan && buyplan.get("customer_purchase_order");
  const deliveryInstructions = buyplan && buyplan.get("delivery_instructions");
  const shippingMethods = buyplan && buyplan.get("shipping_methods");
  const salesPersonList = buyplan && buyplan.get("sales_person_list");
  const selectedSalesPerson = buyplan && buyplan.get("sales_person");
  const isBuyplanOwnershipEnforced = useSelector(
    isBuyplanOwnershipEnforcedSelector
  );
  const selectedShippingMethod =
    buyplan && buyplan.get("selected_shipping_method");
  const poCharacterLimit = useSelector(poCharacterLimitSelector);

  const salesPersonOptions = useMemo(() => {
    return salesPersonList && getSalesPersonOptions(salesPersonList);
  }, [salesPersonList]);

  const defaultShippingMethod = {
    value: selectedShippingMethod?.get("code") || "",
    label: selectedShippingMethod?.get("label") || i18next.t("select.default")
  };

  return (
    <div className="order-info">
      <Form>
        <Row>
          <Col md={4}>
            <Row form>
              <Col>
                <FormGroup row>
                  <Label for="order_number" sm={4}>
                    {i18next.t("general.id")}
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="buyplan[order_detail_attributes][order_number]"
                      id="order_number"
                      disabled="disabled"
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col>
                <FormGroup row>
                  <Label for="company_id" sm={4}>
                    {i18next.t("general.buyer")}
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="buyplan[company_id]"
                      id="company_id"
                      defaultValue={buyplan.get("company_name")}
                      disabled="disabled"
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col>
                <FormGroup row>
                  <Label for="retailer_id" sm={4}>
                    {i18next.t("general.retailer")}
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="buyplan[retailer_id]"
                      id="retailer_id"
                      defaultValue={buyplan.get("retailer_name")}
                      disabled="disabled"
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>

            {salesPersonList &&
              selectedSalesPerson &&
              isBuyplanOwnershipEnforced && (
                <SalesPersonSelect
                  salesPersonOptions={salesPersonOptions}
                  salesPerson={selectedSalesPerson}
                  handleSalesPersonChange={handleSalesPersonChange}
                  name="buyplan[sales_person_id]"
                  id="buyplan_sales_person_id"
                />
              )}
          </Col>

          <Col md={4}>
            {showShippingMethods && (
              <Row form>
                <Col>
                  <FormGroup row>
                    <Label for="shipping_methods" sm={4}>
                      {i18next.t("order.shippingMethods")}
                    </Label>

                    <Col sm={6}>
                      <Select
                        options={shippingMethodOptions(shippingMethods)}
                        name="buyplan[order_detail_attributes][shipping_method]"
                        id="shipping_methods"
                        onChange={handleShippingMethodChange}
                        defaultValue={defaultShippingMethod}
                        placeholder={i18next.t("select.default")}
                        noOptionsMessage={() => i18next.t("select.noOptions")}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            )}

            <Row form>
              <Col>
                <FormGroup row>
                  <Label for="customer_purchase_order" sm={4}>
                    {i18next.t("order.purchaseOrder")}
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="buyplan[order_detail_attributes][customer_purchase_order]"
                      id="customer_purchase_order"
                      onBlur={onPurchaseOrderBlurCallback}
                      defaultValue={customerPurchaseOrder}
                      maxLength={poCharacterLimit}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>

            {buyplan.get("show_delivery_instructions") && (
              <Row form>
                <Col>
                  <FormGroup row>
                    <Label for="delivery_instructions" sm={4}>
                      {i18next.t("order.deliveryInstructions")}
                    </Label>

                    <Col sm={6}>
                      <Input
                        type="textarea"
                        name="buyplan[order_detail_attributes][delivery_instructions]"
                        id="delivery_instructions"
                        onBlur={onDeliveryInstructionsBlurCallback}
                        rows={5}
                        cols={3}
                        defaultValue={deliveryInstructions}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            )}

            <Row form>
              <Col>
                <NetsuiteOrderType
                  handleNetsuiteOrderTypeChange={handleNetsuiteOrderTypeChange}
                />
              </Col>
            </Row>
          </Col>

          <Col md={4}>
            <Row form>
              <Col>
                <FormGroup row>
                  <Label for="delivery_date" sm={4}>
                    {i18next.t("order.daiwa.deliveryDate")}
                  </Label>

                  <Col sm={6}>
                    <DatePicker
                      className="form-control"
                      name="buyplan[delivery_date]"
                      minDate={new Date()}
                      selected={deliveryDate}
                      onChange={handleDeliveryDateChange}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col>
                <FormGroup row>
                  <Label for="cancel_date" sm={4}>
                    {i18next.t("order.daiwa.cancelDate")}
                  </Label>
                  <Col sm={6}>
                    <DatePicker
                      className="form-control dateInput"
                      name="buyplan[cancel_date]"
                      minDate={deliveryDate}
                      selected={cancelDate}
                      onChange={handleCancelDateChange}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>

          {buyplan.get("order_note") && (
            <Col md={10}>
              <Row>
                <Col>
                  <FormGroup row>
                    <Label for="order_note" sm={2}>
                      {i18next.t("order.orderNotes")}
                    </Label>

                    <Col lg={9}>
                      <Input
                        type="textarea"
                        name="buyplan[order_detail_attributes][order_notes]"
                        id="order_notes"
                        disabled="disabled"
                        defaultValue={buyplan.get("order_note")}
                        rows={5}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default React.memo(DaiwaOrderInfo);
