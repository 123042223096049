import { takeLatest, put, select } from "redux-saga/effects";

import { CATALOGUE_ORDER } from "constants.js";

import { overwriteShortUrlActions } from "actions/overwriteShortUrlActions.js";

import { POST_DATA } from "actions/apiOperations.js";

import { appliedFiltersSelector } from "selectors/filtersSelector.js";
import { selectedGroupByFilterSelector } from "selectors/groupedProductsSelector.js";

export const requestOverwriteShortUrl = function*(action) {
  const appliedFilters = yield select(appliedFiltersSelector);
  const selectedGroupByFilter = yield select(selectedGroupByFilterSelector);
  const appliedFiltersWithStoryTelling = appliedFilters.set(
    "tell_story_by",
    selectedGroupByFilter?.get("value") || CATALOGUE_ORDER
  );

  const { code, ...payload } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/filters/${code}/update.json`,
    actions: overwriteShortUrlActions,
    params: {
      ...payload,
      filters: appliedFiltersWithStoryTelling,
    },
  });
};

export const watchRequestOverwriteShortUrl = function*() {
  yield takeLatest(overwriteShortUrlActions.request, requestOverwriteShortUrl);
};
