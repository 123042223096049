import React from "react";
import styled from "styled-components";

const ConfirmationDialog = ({
  className,
  btnText,
  dialogText,
  acceptCallback,
  rejectCallback
}) => {
  const handleClick = () => {
    // eslint-disable-next-line no-alert
    let hasConfirmed = window && window.confirm(dialogText);

    if (hasConfirmed === true) {
      acceptCallback && acceptCallback();
    } else {
      rejectCallback && rejectCallback();
    }
  };

  return (
    <button className={`${className} btn btn-danger`} onClick={handleClick}>
      {btnText}
    </button>
  );
};

const StyledConfirmationDialog = styled(ConfirmationDialog)`
  background-color: #ff4433;
  color: #ffffff;
  text-shadow: none;
  filter: none;
  border-radius: 0;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  box-shadow: none;
  padding: 5px 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  margin-right: 20px;
  border-right: 1px solid #DDDDDD;
`;

export default React.memo(StyledConfirmationDialog);