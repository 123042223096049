import React from "react";

const MonthReportTableHead = ({
  headerText, shouldAlignCenter = false, colSpan = 3
}) => {
  return (
    <th
      colSpan={ colSpan }
      style={
        shouldAlignCenter ?
        { width: "33.33%", textAlign: "center" } :
        { width: "33.33%" }
      }
    >
      <h3>
        { headerText }
      </h3>
    </th>
  );
};

export default React.memo(MonthReportTableHead);
