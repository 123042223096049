import { customShippingAddressActions } from "actions/customShippingAddressActions";
import { fromJS } from "immutable";

const initialState = fromJS({});

export const getInitialState = () => initialState;

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case customShippingAddressActions.updateForm:
      return state.set(payload.name, payload.value);
    default:
      return state;
  }
};
