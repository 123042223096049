import React from "react";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { findByProductReleaseId } from "selectors/productsSelector.js";
import { currentUserDataSelector } from "selectors/currentUserSelector.js";
import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";
import { buyplanIdSelector, buyplanStateSelector } from "selectors/buyplanIdSelector.js";
import { bannerInfoDataSelector } from "selectors/bannerInfoSelector.js";

import QuantityCount from "components/ProductCard/Actions/Quantity/QuantityCount.jsx";

import { CONSIGNMENT } from "constants.js";
import { isQtyGridDisabled } from "utils.js"
import { BsCartPlus } from "react-icons/bs";
import { IconContext } from "react-icons";

const Quantity = ({
  className,
  productReleaseId,
  isSoldOut,
  totalQty,
  buyplanItemId,
  isPreOrderProduct
}) => {
  const stockModalId = useSelector(stockModelIdSelector);
  const bannerInfoData = useSelector(bannerInfoDataSelector);
  const { url } = useRouteMatch();
  const { releaseId } = useParams();

  const productRelease = useSelector(findByProductReleaseId(productReleaseId));
  const stockModalItemId = productRelease && productRelease.stock_model_item.id;

  const currentUser = useSelector(currentUserDataSelector);
  const currentUserRole = currentUser && currentUser.get("role");
  const buyplanId = useSelector(buyplanIdSelector);
  const buyplanState = useSelector(buyplanStateSelector);
  const releaseStatus = bannerInfoData.get("release_status");

  if (!totalQty) {
    if (stockModalItemId && url.includes("stock_models")) {
      totalQty =
        productRelease && productRelease.stock_model_item.total_quantity;
    } else {
      totalQty = productRelease && productRelease.buyplan_item.total_quantity;
    }
  }

  let linkTo = null;

  if (isQtyGridDisabled(currentUserRole, isSoldOut, releaseStatus, buyplanState, buyplanId, isPreOrderProduct)) {
    linkTo = "#";
  } else if (stockModalId) {
    linkTo =
      "/releases/" +
      releaseId +
      "/stock_models/" +
      stockModalId +
      "/" +
      productReleaseId +
      "/quick-view?defaultTab=2&stockModalItemId=" +
      stockModalItemId;
  } else if (buyplanState === CONSIGNMENT) {
    linkTo = `${url}/${productReleaseId}/quick-view?buyplanId=${buyplanId}&type=consignment&buyplanItemId=${buyplanItemId}`;
  } else {
    linkTo = `${url}/${productReleaseId}/quick-view?buyplanId=${buyplanId}&defaultTab=2&buyplanItemId=${buyplanItemId}`;
  }

  return (
    <div className={`${className} item-info`}>
      <div className="item-quantity release-product">
        <span>
          <Link to={linkTo}>
            {totalQty && totalQty > 0 ? (
              <QuantityCount totalQty={totalQty} />
            ) : (
              <IconContext.Provider value={{ className: "cart-icon" }}>
                <BsCartPlus fill = "#bdbdbd" size = {23}/>
              </IconContext.Provider>
            )}
          </Link>
        </span>
      </div>
    </div>
  );
};

const StyledQuantity = styled(Quantity)`
  cursor: pointer;
  margin: 0 auto;

  .item-quantity {
    background-color: #ffffff;
    position: static;
    border: solid 1.5px #e5e5e5;
    border-radius: 3px;
    color: #c1c1c1;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    width: 28px;
    height: 28px;
  }

  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
  }

  .cart-icon{
    margin-top : 1px;
  }
`;

export default React.memo(StyledQuantity);
