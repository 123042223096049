import { createSelector } from "reselect";

export const buyplanInfoReducerSelector = createSelector(
  state => state.get("buyplanInfoReducer"),
  buyplanInfoReducer => buyplanInfoReducer
);

export const isLoadingSelector = createSelector(
  buyplanInfoReducerSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  buyplanInfoReducerSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("hasErrors")
);

export const buyplanInfoDataSelector = createSelector(
  buyplanInfoReducerSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("data")
);

export const buyplanStateSelector = createSelector(
  buyplanInfoDataSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("state")
);

export const buyplanIdSelector = createSelector(
  buyplanInfoDataSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("id")
);

export const stockModelCnfTypeSelector = createSelector(
  buyplanInfoDataSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("cnf_type")
);

export const buyplanNameSelector = createSelector(
  buyplanInfoDataSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("name")
);

export const buyplanCurrencySelector = createSelector(
  buyplanInfoDataSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("currency")
);

export const buyplanAwaitingApprovalSelector = createSelector(
  buyplanInfoDataSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("awaiting_approval")
);

export const buyplanOrderApprovalRuleMsgSelector = createSelector(
  buyplanInfoDataSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("order_approval_rule_msg")
);

export const showPreOrderMonthSelector = createSelector(
  buyplanInfoDataSelector, 
  buyplanInfoReducer => buyplanInfoReducer.get("show_pre_order_month")
);

export const preOrderCustomTextSelector = createSelector(
  buyplanInfoDataSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("pre_order_custom_text")
);

export const releaseVisibleToRetailersSelector = createSelector(
  buyplanInfoDataSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("release_visible_to_retailers")
);

export const smTypeSelector = createSelector(
  buyplanInfoDataSelector,
  buyplanInfoReducer => buyplanInfoReducer.get("sm_type")
);
