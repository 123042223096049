import { fromJS } from "immutable";

import { quickFillBannerInfoActions } from "actions/quickFillBannerInfoActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    brand_id: null,
    brand_name: null,
    banner_url: null
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case quickFillBannerInfoActions.startLoader:
      return state.set("isLoading", true);
    case quickFillBannerInfoActions.reset:
      return initialState;
    case quickFillBannerInfoActions.success:
      return state.merge({
        isLoading: false,
        hasErrors: false,
        data: fromJS(action.payload.data)
      });
    case quickFillBannerInfoActions.failure:
      return state.merge({
        isLoading: false,
        hasErrors: true,
        data: fromJS(action.payload.data)
      });
    default:
      return state;
  }
};
