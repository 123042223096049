import React, { useMemo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import ScanNowBtn from "components/BarcodeScanner/button.jsx";
import ScanSettings from "components/ScanSettings";
import ScanDetails from "containers/BarcodeScannerContainer/ScanDetailsContainer.jsx";
import { resetScanLogs } from "actions/StockModal/barcodeScanningActions.js";

const BarcodeScannerContainer = ({
  isAdminUser,
  isTemplate,
  isAgentAdmin,
  isMarketingrelease,
  handleBarcodeSubmit,
  isStockModel,
}) => {
  const dispatch = useDispatch();
  const [isScanning, setIsScanning] = useState(false);

  const showScannerModeBtn = useMemo(() => {
    if (isAdminUser || isTemplate) return false;
    if (isAgentAdmin && isMarketingrelease) return false;
    return true;
  }, [
    isAdminUser,
    isTemplate,
    isAgentAdmin,
    isMarketingrelease,
  ]);

  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    window.location.reload(); 
  };

  const resetScannerLogs = () => {
    dispatch(resetScanLogs());
  };

  const successCallbackFn = useCallback(
    (barcode) => {
      handleBarcodeSubmit(barcode)
    },[]
  );

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const formData = new FormData(evt.target);
    const barcode = formData.get("manual-barcode");

    successCallbackFn(barcode);

    evt.target.reset();
  };

  const [isCameraScan, setIsCameraScan] = useState(false);
  const [isManualMode, setIsManualMode] = useState(!isStockModel);

  const setScanType = (evt) => {
    setIsCameraScan(evt.target.value === "cameraScanner");
  };

  const setScanMode = (evt) => {
    setIsManualMode(evt.target.value === "manualMode");
  };

  return (
    showScannerModeBtn && (
      <>
        <ScanNowBtn onClick={openModal} className="btn" type="button" >
          Scan Now
        </ScanNowBtn>

        {isModalOpen && (
          <Modal
            className={"modal-container mw-100 w-75"}
            isOpen={isModalOpen}
            toggle={closeModal}
            onExit={resetScannerLogs}
            backdrop={true}
            centered
            autoFocus={false}
          >
            <ModalBody className="w-100">
              <div className="d-flex justify-content-between mt-2">
                <h6>Barcode Scanning</h6>

                <Button onMouseDown={closeModal} color="primary">
                  Exit
                </Button>
              </div>
              <hr />
              <ScanSettings
                setScanType={setScanType}
                isCameraScan={isCameraScan}
                setScanMode={setScanMode}
                isManualMode={isManualMode}
                isStockModel={isStockModel}
                isScanning={isScanning}
                setIsScanning={setIsScanning}
                successCallbackFn={successCallbackFn}
                barcodeSubmit={handleSubmit}
              />
              <hr />
              <ScanDetails
                isManualMode={isManualMode}
              />
            </ModalBody>
            <ModalFooter className="mt-4"></ModalFooter>
          </Modal>
        )}
      </>
    )
  );
};

export default React.memo(BarcodeScannerContainer);
