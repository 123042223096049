import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import BadgeList from "components/ProductCard/Actions/BadgeList/BadgeList.jsx";

import {
  findByProductId,
  isBadgeSelected
} from "selectors/productsSelector.js";

import {
  buyplanItemIdSelector,
  intendedMonthsSelector,
  filledMonthsSelector,
  deliveryMonthsSelector,
  preOrderIntendedMonthsSelector,
  preOrderFilledMonthsSelector
} from "selectors/productReleaseSelector.js";

import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";

import { addSizeBreak, removeSizeBreak } from "actions/sizeBreakActions.js";
import { showPreOrderMonthSelector, preOrderCustomTextSelector } from "selectors/buyplanInfoSelector";

const Months = ({ className, productId, productReleaseId, releaseId }) => {
  const dispatch = useDispatch();
  const buyplanId = useSelector(buyplanIdSelector);
  const memoizedProductRelease = useSelector(
    findByProductId(productId, releaseId)
  );

  const buyplanItemIdFromStore = useSelector(buyplanItemIdSelector);
  const intendedMonthsFromStore = useSelector(intendedMonthsSelector);
  const filledMonthsFromStore = useSelector(filledMonthsSelector);
  const preOrderIntendedMonthsFromStore = useSelector(preOrderIntendedMonthsSelector);
  const preOrderFilledMonthsFromStore = useSelector(preOrderFilledMonthsSelector);
  const deliveryMonthsFromStore = useSelector(deliveryMonthsSelector);
  const showPreOrderMonth = useSelector(showPreOrderMonthSelector);
  const preOrderCustomText = useSelector(preOrderCustomTextSelector);

  let buyplanItemId,
    intendedMonths,
    filledMonths,
    preOrderIntendedMonths,
    preOrderFilledMonths,
    deliveryMonths,
    badges,
    is_pre_order_enabled,
    pre_order_month_and_date;

  buyplanItemId = memoizedProductRelease
    ? memoizedProductRelease.buyplan_item.id
    : buyplanItemIdFromStore;

  intendedMonths = memoizedProductRelease
    ? memoizedProductRelease.buyplan_item.intended_months
    : intendedMonthsFromStore;

  filledMonths = memoizedProductRelease
    ? memoizedProductRelease.buyplan_item.filled_months
    : filledMonthsFromStore;

  preOrderIntendedMonths = memoizedProductRelease
    ? memoizedProductRelease.buyplan_item.pre_order_intended_months
    : preOrderIntendedMonthsFromStore;

  preOrderFilledMonths = memoizedProductRelease
    ? memoizedProductRelease.buyplan_item.pre_order_filled_months
    : preOrderFilledMonthsFromStore;

  deliveryMonths = memoizedProductRelease
    ? memoizedProductRelease.month_with_year
    : deliveryMonthsFromStore;

  is_pre_order_enabled = memoizedProductRelease?.product?.is_pre_order_enabled || false;

  pre_order_month_and_date = memoizedProductRelease?.product?.pre_order_month_and_date || false;

  badges = deliveryMonths.map(str => {
    return {
      id: str,
      text: str,
      monthValue: str,
      isIntended: isBadgeSelected(intendedMonths, str),
      isFilled: isBadgeSelected(filledMonths, str),
      is_pre_order_enabled: 'false',
    };
  });

  if (is_pre_order_enabled && pre_order_month_and_date) {
    badges.push({
      id: `PRE_${pre_order_month_and_date.month}`,
      text: showPreOrderMonth ? pre_order_month_and_date.month : preOrderCustomText,
      monthValue: pre_order_month_and_date.month,
      isIntended: isBadgeSelected(preOrderIntendedMonths, pre_order_month_and_date.month),
      isFilled: isBadgeSelected(preOrderFilledMonths, pre_order_month_and_date.month),
      is_pre_order_enabled: is_pre_order_enabled.toString(),
    });
  }

  const toggleSizeBreak = useCallback(
    evt => {
      const { isIntended, isFilled, monthValue, isPreOrder } = evt.target.dataset;

      if (isIntended === "true" || isFilled === "true") {
        dispatch(
          removeSizeBreak(
            buyplanId,
            buyplanItemId,
            productReleaseId,
            monthValue,
            isPreOrder
          )
        );
      } else {
        dispatch(
          addSizeBreak(buyplanId, buyplanItemId, productReleaseId, monthValue, isPreOrder)
        );
      }
    },
    [dispatch, buyplanId, buyplanItemId, productReleaseId]
  );
  return (
    <div className={`months-wrapper ${className}`}>
      <BadgeList badges={badges} toggleSizeBreak={toggleSizeBreak} />
    </div>
  );
};

const StyledMonths = styled(Months)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding-top: 10px;
  min-height: 28px;

  .badge {
    margin-bottom: 4px;
  }

  .pre-order-badge {
    box-shadow: 0 0 0 1px red;
  }
`;

export default StyledMonths;
