import { createSelector } from "reselect";

export const buyplanSummaryReducerSelector = createSelector(
  state => state.get("buyplanSummaryReducer"),
  buyplanSummaryReducer => buyplanSummaryReducer
);

export const isLoadingSelector = createSelector(
  buyplanSummaryReducerSelector,
  buyplanSummaryReducer => buyplanSummaryReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  buyplanSummaryReducerSelector,
  buyplanSummaryReducer => buyplanSummaryReducer.get("hasErrors")
);

export const buyplanSummaryDataSelector = createSelector(
  buyplanSummaryReducerSelector,
  buyplanSummaryReducer => buyplanSummaryReducer.get("data")
);

export const buyplanSummaryAbilitiesSelector = createSelector(
  buyplanSummaryDataSelector,
  buyplanSummaryData => buyplanSummaryData.get("abilities")
);

export const buyplanSummaryBrandSelector = createSelector(
  buyplanSummaryDataSelector,
  buyplanSummaryData => buyplanSummaryData.get("brand")
);

export const buyplanSummaryBuyplanSelector = createSelector(
  buyplanSummaryDataSelector,
  buyplanSummaryData => buyplanSummaryData.get("buyplan")
);

export const buyplanSummaryBuyplanItemsSelector = createSelector(
  buyplanSummaryDataSelector,
  buyplanSummaryData => buyplanSummaryData.get("buyplan_items")
);
