import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { addNotification } from "notificationStore.js";
import { requestUpdateStockModalQty } from "actions/StockModal/qtyGridActions.js";
import {
  locallyUpdateStockQty,
  locallyUpdateCountQty,
  locallyUpdateFillQty
} from "actions/StockModal/sizeBreakActions.js";

import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";
import {
  showSizeLevelPricingSelector,
  errorSelector
} from "selectors/quantityGridSelector.js";

import StockModalHeader from "components/QuantityGrid/StockModal/Header.jsx";
import StockModalBody from "components/QuantityGrid/StockModal/Body.jsx";
import StockModalFooter from "components/QuantityGrid/StockModal/Footer.jsx";

export const BrandContext = React.createContext(true);

const handleSubmission = evt => evt.preventDefault();

const QtyGridForm = ({
  quantities,
  qtyLabels,
  sizeBreaks,
  attributeSeq,
  sizeSequences,
  sizeMinimums,
  productRelease,
  stockModalItemId,
  shouldFetchTotals
}) => {
  const dispatch = useDispatch();
  const { brandId } = useParams();
  const stockModalId = useSelector(stockModelIdSelector);
  const warehouseId = useSelector(warehouseIdSelector);
  const productRequirements = productRelease.get("product_requirements");
  const isPricingDetailsVisible = useSelector(showSizeLevelPricingSelector);
  const errorMsgForToastr = useSelector(errorSelector);

  useEffect(() => {
    errorMsgForToastr &&
      addNotification({
        type: "danger",
        message: errorMsgForToastr
      });
  }, [errorMsgForToastr]);


  const isMinOrderCheckApplicable = () => {
    const is_check_flag_on = productRelease.get("is_min_order_check_applicable")
    const is_value_positive = sizeBreaks?.first()?.first()?.getIn(["fill_quantity", "month_min_order_qty"]) > 0
    return is_check_flag_on && is_value_positive
  }

  const updateSizeBreakQty = useCallback(
    evt => {
      const { sizeBreakType, sizeBreakSize, sizeBreakKey } = evt.target.dataset;
      const targetValue = evt.target.value;

      let newQty;

      if (targetValue.startsWith("-") && sizeBreakType === "count_quantity")
        newQty = targetValue;
      else {
        let qty = targetValue.replace(/\D/g, "");
        newQty = isNaN(parseInt(qty)) ? "" : parseInt(qty);
      }

      switch (sizeBreakType) {
        case "stock_quantity":
          return dispatch(
            locallyUpdateStockQty({
              stockModalItemId,
              sizeBreakSize,
              newQty,
              sizeBreakKey
            })
          );
        case "count_quantity":
          return dispatch(
            locallyUpdateCountQty({
              stockModalItemId,
              sizeBreakSize,
              newQty,
              sizeBreakKey
            })
          );
        case "fill_quantity":
          return dispatch(
            locallyUpdateFillQty({
              stockModalItemId,
              sizeBreakSize,
              newQty,
              sizeBreakKey
            })
          );
        default:
          return null;
      }
    },
    [dispatch, stockModalItemId]
  );

  const handleBlur = useCallback(
    evt => {
      if (!errorMsgForToastr) {
        const { sizeBreakId, sizeBreakSize, sizeBreakKey } = evt.target.dataset;
        const sizeBreak = sizeBreaks.getIn([sizeBreakKey, sizeBreakSize]);

        dispatch(
          requestUpdateStockModalQty({
            stockModalId,
            stockModalItemId,
            warehouseId,
            sizeBreakId,
            stockQuantity: sizeBreak.getIn(["stock_quantity", "quantity"]),
            countQuantity: sizeBreak.getIn(["count_quantity", "quantity"]),
            fillQuantity: sizeBreak.getIn(["fill_quantity", "quantity"]),
            options: { shouldFetchTotals }
          })
        );
      }
    },
    [
      dispatch,
      stockModalId,
      stockModalItemId,
      sizeBreaks,
      warehouseId,
      shouldFetchTotals
    ]
  );
  return (
    <BrandContext.Provider value={!!brandId}>
      <form
        className="quantity-grid-form table-responsive"
        onSubmit={handleSubmission}
      >
        <div className="attribute_table_wrap">
          <table className="attribute_table pricing_opened attribute_opened">
            <StockModalHeader
              sizeSequences={sizeSequences}
              sizeMinimums={sizeMinimums}
              isMinOrderCheckApplicable={isMinOrderCheckApplicable()}
            />

            <tbody>
              {sizeBreaks &&
                sizeBreaks.keySeq().map(key => {
                  return (
                    <StockModalBody
                      key={key}
                      sizeBreakKey={key}
                      quantities={quantities}
                      qtyLabels={qtyLabels}
                      sizeSequences={sizeSequences}
                      sizeBreaks={sizeBreaks.get(key)}
                      updateSizeBreakQty={updateSizeBreakQty}
                      handleSubmission={handleBlur}
                      isMinOrderCheckApplicable={isMinOrderCheckApplicable()}
                    />
                  );
                })}
            </tbody>

            {(isPricingDetailsVisible) && (
              <StockModalFooter
                sizeBreaks={sizeBreaks}
                attrsList={attributeSeq}
                headers={sizeSequences}
                isMinOrderCheckApplicable={isMinOrderCheckApplicable()}
              />
            )}
          </table>
        </div>

        <StlyedPoRequirements className="po-requirements text-wrap">
          {productRequirements.map(productRequirement => {
            return (
              <span key={productRequirement}>
                * {productRequirement} &nbsp;
              </span>
            );
          })}
        </StlyedPoRequirements>
      </form>
    </BrandContext.Provider>
  );
};

const StlyedPoRequirements = styled.div`
  width: max-content;
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 30px;
`;

export default React.memo(QtyGridForm);
