export const buyplanDetailsActions = {
  startLoader: "START_BUYPLAN_DETAILS_LOADER",
  request: "REQUEST_BUYPLAN_DETAILS",
  requestStockModal: "REQUEST_STOCK_MODAL_BUYPLAN_DETAILS",
  success: "BUYPLAN_DETAILS_SUCCESS",
  failure: "BUYPLAN_DETAILS_FAILURE"
};

export const getBuyplanDetails = (buyplanId, warehouseId, filters, limit, offset, override = false) => {
  return {
    type: buyplanDetailsActions.request,
    payload: {
      buyplanId,
      warehouse_id: warehouseId,
      filters,
      limit,
      offset,
      override
    }
  };
};

export const getStockModalBuyplanDetails = (stockModalId, filters, warehouseId) => {
  return {
    type: buyplanDetailsActions.requestStockModal,
    payload: {
      stockModalId,
      filters,
      warehouse_id: warehouseId
    }
  };
};

export const getBuyplanDetailsSuccess = payload => {
  return {
    type: buyplanDetailsActions.success,
    payload
  };
};

export const getBuyplanDetailsFailure = errorPayload => {
  return {
    type: buyplanDetailsActions.failure,
    errorPayload
  };
};
