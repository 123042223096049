export const warehouseActions = {
  startLoader: "START_FETCH_WAREHOUSE_LOADER",
  request: "REQUEST_FETCH_WAREHOUSE",
  requestForStockModel: "REQUEST_FETCH_WAREHOUSE_FOR_CNF",
  success: "FETCH_WAREHOUSE_SUCCESS",
  failure: "FETCH_WAREHOUSE_FAILURE",
  update: "UPDATE_WAREHOUSE_ID"
};

export const getWarehouses = payload => {
  return {
    type: warehouseActions.request,
    payload
  };
};

export const getWarehousesForStockModel = payload => {
  return {
    type: warehouseActions.requestForStockModel,
    payload
  };
};

export const updateWarehouse = payload => {
  return {
    type: warehouseActions.update,
    payload
  };
};
