import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setSelectedShippingAddrId } from "actions/orderDetailsActions.js";

import {
  orderBillingAddressSelector,
  orderShippingAddressDefaultSelector,
  orderShippingAddressAlt1Selector,
  orderShippingAddressAlt2Selector,
  allowCustomShippingAddressSelector
} from "selectors/orderDetailsSelector.js";

import InvoiceFrom from "components/Invoice/InvoiceFrom.jsx";
import InvoiceTo from "components/Invoice/InvoiceTo.jsx";
import i18next from "i18next";

const renderAddress = (addressName, addressObj) => {
  switch (addressName) {
    case "billingAddress":
      return (
        (addressObj.get("line1") || addressObj.get("line2")) &&
        addressObj.get("suburb_town") &&
        addressObj.get("state") &&
        addressObj.get("country") &&
        addressObj.get("post_code")
      );
    case "shippingAddressAlt1":
      return (
        (addressObj.get("line1") || addressObj.get("line2")) &&
        addressObj.get("suburb_town") &&
        addressObj.get("state") &&
        addressObj.get("country") &&
        addressObj.get("post_code")
      );
    case "shippingAddressAlt2":
      return (
        (addressObj.get("line1") || addressObj.get("line2")) &&
        addressObj.get("suburb_town") &&
        addressObj.get("state") &&
        addressObj.get("country") &&
        addressObj.get("post_code")
      );
    default:
      return false;
  }
};

const Addresses = ({ isChecked = false, customShippingAddressFormHandler = null }) => {
  const billingAddress = useSelector(orderBillingAddressSelector);
  const shippingAddrDefault = useSelector(orderShippingAddressDefaultSelector);
  const shippingAddrAlt1 = useSelector(orderShippingAddressAlt1Selector);
  const shippingAddrAlt2 = useSelector(orderShippingAddressAlt2Selector);
  const customShippingAddr = useSelector(allowCustomShippingAddressSelector);

  const dispatch = useDispatch();

  const setSelectedShippingAddress = useCallback(
    ({ target: { value: selectedShippingAddressId } }) => {
      dispatch(setSelectedShippingAddrId(selectedShippingAddressId ? +selectedShippingAddressId : null));
    },
    [dispatch]
  );

  return (
    <>
      {renderAddress("billingAddress", billingAddress) && (
        <InvoiceFrom
          title={i18next.t("address.billingAddress")}
          addrLine1={billingAddress.get("line1")}
          addrLine2={billingAddress.get("line2")}
          suburbTown={billingAddress.get("suburb_town")}
          state={billingAddress.get("state")}
          country={billingAddress.get("country")}
          postalCode={billingAddress.get("post_code")}
        />
      )}

      {shippingAddrDefault.get("id") && (
        <InvoiceTo
          title={i18next.t("address.shippingAddressWithType", {
            type: i18next.t("address.type.default")
          })}
          addrLine1={shippingAddrDefault.get("line1")}
          addrLine2={shippingAddrDefault.get("line2")}
          suburbTown={shippingAddrDefault.get("suburb_town")}
          state={shippingAddrDefault.get("state")}
          country={shippingAddrDefault.get("country")}
          postalCode={shippingAddrDefault.get("post_code")}
          isChecked={true}
          value={shippingAddrDefault.get("id")}
          setSelectedShippingAddress={setSelectedShippingAddress}
        />
      )}

      {renderAddress("shippingAddressAlt1", shippingAddrAlt1) && (
        <InvoiceTo
          title={i18next.t("address.shippingAddressWithType", {
            type: i18next.t("address.type.alternate1")
          })}
          addrLine1={shippingAddrAlt1.get("line1")}
          addrLine2={shippingAddrAlt1.get("line2")}
          suburbTown={shippingAddrAlt1.get("suburb_town")}
          state={shippingAddrAlt1.get("state")}
          country={shippingAddrAlt1.get("country")}
          postalCode={shippingAddrAlt1.get("post_code")}
          isChecked={isChecked}
          value={shippingAddrAlt1.get("id")}
          setSelectedShippingAddress={setSelectedShippingAddress}
        />
      )}

      {renderAddress("shippingAddressAlt2", shippingAddrAlt2) && (
        <InvoiceTo
          title={i18next.t("address.shippingAddressWithType", {
            type: i18next.t("address.type.alternate2")
          })}
          addrLine1={shippingAddrAlt2.get("line1")}
          addrLine2={shippingAddrAlt2.get("line2")}
          suburbTown={shippingAddrAlt2.get("suburb_town")}
          state={shippingAddrAlt2.get("state")}
          country={shippingAddrAlt2.get("country")}
          postalCode={shippingAddrAlt2.get("post_code")}
          isChecked={isChecked}
          value={shippingAddrAlt2.get("id")}
          setSelectedShippingAddress={setSelectedShippingAddress}
        />
      )}

      {customShippingAddr && (
        <InvoiceTo
          title={i18next.t("address.customShippingAddress")}
          isChecked={false}
          setSelectedShippingAddress={setSelectedShippingAddress}
          isCustomAddressInput={true}
          customShippingAddressFormHandler={customShippingAddressFormHandler}
        />
      )}
    </>
  );
};

export default React.memo(Addresses);
