import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isImmutable, fromJS } from "immutable";
import { CSSTransition } from "react-transition-group";
import camelCase from "lodash/camelCase";

import "assets/stylesheets/transition.css";

import { randomStrGenerator } from "utils.js";

import { apiBaseUrl } from "apis/apiHelpers.js";

import { getGlobalMenu } from "actions/globalMenuActions.js";

import {
  isLoadingSelector,
  hasErrorsSelector,
  globalMenuDataSelector
} from "selectors/globalMenuSelector.js";
import { currentUserDataSelector } from "selectors/currentUserSelector";
const getMegaMenuSkeleton = () => (
  <div style={{ height: "60px", width: "100vw", backgroundColor: "#222f3e" }} />
);

const handleScriptLoad = () => {
  // wait for animation to complete
  setTimeout(() => window.webslidemenu.onLoad(), 500);
};

const GlobalMenuContainer = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);
  const hasErrors = useSelector(hasErrorsSelector);
  const globalMenuJson = useSelector(globalMenuDataSelector);
  const currentUser = useSelector(currentUserDataSelector);
  window.currentUser_id = currentUser.get("id");

  useEffect(() => {
    dispatch(getGlobalMenu());
  }, [dispatch]);

  useEffect(() => {
    if (isLoading === false && !hasErrors) {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://cdn.headwayapp.co/widget.js";
      document.head.appendChild(script);
      var config = {
        selector: ".headway_widget",
        // eslint-disable-next-line no-undef
        account: process.env.REACT_APP_HEADWAY_ACCOUNT
      };
      script.onload = function() {
        setTimeout(() => window.Headway.init(config), 500);
      };
      // Load javascript required to handle modal dialogs of global menu
      let scriptTag = document.createElement("script");
      scriptTag.type = "text/javascript";
      scriptTag.src = `${apiBaseUrl}/webslidemenu/index.js`;
      scriptTag.async = true;
      scriptTag.onload = handleScriptLoad;
      document.body.appendChild(scriptTag);

      return () => document.body.removeChild(scriptTag);
    }
  }, [isLoading, hasErrors]);

  return (
    <>
      {isLoading && getMegaMenuSkeleton()}

      {hasErrors && getMegaMenuSkeleton()}

      <CSSTransition
        classNames="fade"
        timeout={450}
        in={!isLoading && !hasErrors}
        unmountOnExit
      >
        <header>{buildMenuTree(globalMenuJson)}</header>
      </CSSTransition>
    </>
  );
};

const attributes = [
  "class",
  "id",
  "href",
  "src",
  "height",
  "width",
  "style",
  "target",
  "aria-haspopup",
  "wrap-tag",
  "data-id",
  "data-name",
  "data-dismiss",
  "data-toggle",
  "data-method",
  "data-rails-domain",
  "data-dynamic-url",
  "data-static-url",
  "data-url",
  "data-display-name",
  "rel",
  "__content__",
  "role",
  "title",
  "input",
  "action",
  "method",
  "name",
  "type",
  "value",
  "disabled",
  "aria-expanded",
  "defaultvalue",
  "data-content"
];

// Add the text content for <option> tag from webslidemenu/index.js on rails side.
const elementsWithoutChildren = ["img", "hr", "option"];

const createHref = href => {
  return (
    href &&
    (href.startsWith("#") ||
    href.startsWith("http") ||
    href.startsWith("mailto:")
      ? href
      : `${apiBaseUrl}${href}`)
  );
};

const createSrc = src => {
  return src && `${apiBaseUrl}${src}`;
};

const createStylesHsh = stylesStr => {
  let stylesHsh = {};

  if (stylesStr) {
    stylesStr.split("; ").forEach(styleStr => {
      const [styleKey, styleValue] = styleStr.split(": ");

      stylesHsh[camelCase(styleKey)] = styleValue.replace(";", "");
    });
  }

  return stylesHsh;
};

const createAttrsHash = attrsObj => {
  const ignoreList = ["href", "src", "style", "class", "defaultvalue"];

  let attrsHash = {
    key: randomStrGenerator(),
    href: createHref(attrsObj.get("href")),
    src: createSrc(attrsObj.get("src")),
    style: createStylesHsh(attrsObj.get("style")),
    className: attrsObj.get("class"),
    defaultValue: attrsObj.get("defaultvalue")
  };

  attributes.forEach(attr => {
    if (!ignoreList.includes(attr)) {
      if (attr.startsWith("data_")) {
        attrsHash[attr.replace("_", "-")] = attrsObj.get(attr);
      } else {
        attrsHash[attr] = attrsObj.get(attr);
      }
    }
  });

  return attrsHash;
};

const createReactElm = (parentElm, obj) => {
  let textContent;

  if (!isImmutable(obj)) {
    if (typeof obj !== "string") {
      return null;
    } else {
      textContent = obj;
      obj = fromJS({});
    }
  }
  return React.createElement(
    parentElm,
    createAttrsHash(obj),
    textContent
      ? textContent
      : elementsWithoutChildren.includes(parentElm)
      ? null
      : buildMenuTree(obj)
  );
};

const buildMenuTree = json => {
  return json
    .filterNot((_, key) => attributes.includes(key))
    .entrySeq()
    .map(([elm, attrs]) => {
      if (attrs === null) {
        return React.createElement(elm, { key: randomStrGenerator() });
      } else if (attrs._root === null) {
        return attrs.map(obj => {
          return createReactElm(elm, obj);
        });
      } else {
        return createReactElm(elm, attrs);
      }
    });
};

export default React.memo(GlobalMenuContainer);
