import React, { memo } from "react";
import styled from "styled-components";

const RangeSlider = ({ className, parentVal, setParentVal }) => {
  const sliderProps = {
    min: 10,
    max: 100,
    value: parentVal,
    step: 2,
    label: ""
  };
  const changeCallback = e => {
    setParentVal(e.target.value);
  };
  return (
    <div className={`${className}`}>
      <input
        type="range"
        {...sliderProps}
        value={parentVal}
        className={`slider`}
        id="myRange"
        onChange={changeCallback}
      />
    </div>
  );
};
//

const StyledRangeSlider = styled(memo(RangeSlider))`
  display: flex;
  margin-right: 2%;
`;
export default StyledRangeSlider;
