import React from "react";
import styled from "styled-components";
import classnames from "classnames";

const CheckboxWithLabel = ({
  className,
  includeCheckbox = true,
  id,
  isChecked,
  onToggle,
  labelText,
  textClasses,
  ...rest
}) => {
  return (
    <div className={`${className} custom-control custom-checkbox custom-checkbox-filter`}>
      {includeCheckbox && (
        <input
          id={id}
          type="checkbox"
          checked={isChecked}
          onChange={onToggle}
          className="custom-control-input"
          {...rest}
        />
      )}

      {labelText && (
        <label
          htmlFor={id}
          className={classnames({ "custom-control-label": includeCheckbox }, "ml-0", "text-left")}
          >
          <span className={`${textClasses}`}>{labelText}</span>
        </label>
      )}
    </div>
  );
};

const StyledCheckbox = styled(CheckboxWithLabel)`
  label > span {
    position: relative;
    top: 2px;
    color: var(--bs-light-grey);
    font-weight: 400;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--brandscope-blue);
    border-color: var(--brandscope-blue);
  }
`;

export default React.memo(StyledCheckbox);
