export const stripePaymentActions = {
  startLoader: "START_PAYMENT_ACTION_LOADER",
  request: "REQUEST_PAYMENT_ACTIONS",
  success: "PAYMENT_SUCCESS",
  failure: "PAYMENT_FAILURE"
};

export const getPayment = payload => {
  return {
    type: stripePaymentActions.request,
    payload: {
      payload
    },
  };
};

export const getPaymentSuccess = payload => {
  return {
    type: stripePaymentActions.success,
    payload,
  };
};

export const getPaymentFailure = errorPayload => {
  return {
    type: stripePaymentActions.failure,
    payload: errorPayload,
  };
};
