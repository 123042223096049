import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { buyplanInfoDataSelector } from "selectors/buyplanInfoSelector";
import SalesPersonSelect from "components/SalesPersonSelect/SalesPersonSelect";
import { getSalesPersonOptions } from "components/SalesPersonSelect/SalesPersonSelect";
import { useRouteMatch } from "react-router";
import {
  getUpdateBuyplanSalesPerson,
  getUpdateStockModelSalesPerson,
} from "actions/updateSalesPersonActions";
import { setSalesPersonInBuyplanInfo } from "actions/buyplanInfoActions";
import { setSalesPersonInOrderDetails } from "actions/orderDetailsActions";

const getAction = (path, buyplanId, userId) => {
  return path.includes("/stock_models")
    ? getUpdateStockModelSalesPerson(buyplanId, userId)
    : getUpdateBuyplanSalesPerson(buyplanId, userId);
};

const SalesPersonSelectContainer = () => {
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch();
  const buyplanInfo = useSelector(buyplanInfoDataSelector);
  const buyplanId = buyplanInfo.get("id");
  const salesPersonList = buyplanInfo.get("sales_person_list");
  const selectedSalesPerson = buyplanInfo.get("sales_person");
  const isBuyplanOwnershipEnforced = buyplanInfo.get(
    "is_buyplan_ownership_enforced"
  );

  const salesPersonOptions = useMemo(() => {
    return salesPersonList && getSalesPersonOptions(salesPersonList);
  }, [salesPersonList]);

  const handleSalesPersonChange = (selectedSalesPerson) => {
    dispatch(getAction(routeMatch?.path, buyplanId, selectedSalesPerson.value));
    dispatch(
      setSalesPersonInBuyplanInfo({
        id: selectedSalesPerson.value,
        name: selectedSalesPerson.label,
      })
    );
    dispatch(
      setSalesPersonInOrderDetails({
        id: selectedSalesPerson.value,
        name: selectedSalesPerson.label,
      })
    );
  };

  if (buyplanInfo && selectedSalesPerson && isBuyplanOwnershipEnforced) {
    return (
      <SalesPersonSelect
        salesPersonOptions={salesPersonOptions}
        salesPerson={selectedSalesPerson}
        handleSalesPersonChange={handleSalesPersonChange}
        sideBar
      />
    );
  }
};

export default React.memo(SalesPersonSelectContainer);
