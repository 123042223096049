import { takeLatest, put } from "redux-saga/effects";

import { invalidQtyProductsActions } from "actions/StockModal/invalidQtyProductsActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestInvalidStockModelQtyProducts = function*(action) {
  const { stockModelId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/stock_models/${stockModelId}/invalid_quantity_products.json`,
    actions: invalidQtyProductsActions,
    params
  });
};

export const watchRequestInvalidStockModelQtyProducts = function*() {
  yield takeLatest(
    invalidQtyProductsActions.request,
    requestInvalidStockModelQtyProducts
  );
};
