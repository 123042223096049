export const filterActions = {
  startLoader: "START_FILTERS_LOADER",
  init: "INITIATE_FILTERS_SAGA",
  requestQuickFillFilters: "REQUEST_QUICK_FILL_FILTERS",
  requestStockModalFilters: "REQUEST_STOCK_MODAL_FILTERS",
  success: "FILTERS_SUCCESS",
  failure: "FILTERS_FAILURE",
  applyFilters: "APPLY_FILTERS",
  removeFilters: "REMOVE_FILTERS",
  searchFilters: "SEARCH_FILTERS",
  setActiveTab: "SET_ACTIVE_TAB",
  toggleFilterGroupState: "TOGGLE_FILTER_GROUP_STATE",
};

export const getFilters = (releaseId, type, buyplanId, code) => {
  return {
    type: filterActions.init,
    payload: {
      releaseId,
      type,
      buyplan_id: buyplanId,
      code,
    },
  };
};

export const getQuickFillFilters = (brandId, buyplanId, type, code) => {
  return {
    type: filterActions.requestQuickFillFilters,
    payload: {
      brandId,
      buyplan_id: buyplanId,
      type,
      code,
    },
  };
};

export const getStockModalFilters = (releaseId, stockModalId, type) => {
  return {
    type: filterActions.requestStockModalFilters,
    payload: {
      releaseId,
      stockModalId,
      type,
    },
  };
};

export const getFiltersSuccess = (payload) => {
  return {
    type: filterActions.success,
    payload,
  };
};

export const getFiltersFailure = (errorPayload) => {
  return {
    type: filterActions.failure,
    payload: errorPayload,
  };
};

export const updateActiveTab = (activeTab) => {
  return {
    type: filterActions.setActiveTab,
    payload: {
      activeTab,
    },
  };
};

export const applyFilters = ({
  search,
  min_price,
  max_price,
  category,
  level,
  others,
}) => {
  let payload = {};

  if (search !== undefined) payload["search"] = search;
  if (min_price !== undefined) payload["min_price"] = min_price;
  if (max_price !== undefined) payload["max_price"] = max_price;

  if (category !== undefined) {
    payload["category"] = category;
    payload["level"] = level;
  }

  if (others !== undefined) {
    const { isChecked, ...rest } = others;

    if (isChecked === false) {
      return {
        type: filterActions.removeFilters,
        payload: rest,
      };
    } else {
      return {
        type: filterActions.applyFilters,
        payload: rest,
      };
    }
  }

  return {
    type: filterActions.applyFilters,
    payload,
  };
};

export const searchFilters = ({ keyword, categoryId }) => {
  return {
    type: filterActions.searchFilters,
    payload: {
      keyword,
      categoryId,
    },
  };
};

export const toggleFilterGroupState = ({ groupName, isOpen }) => {
  return {
    type: filterActions.toggleFilterGroupState,
    payload: {
      groupName,
      isOpen,
    },
  };
};
