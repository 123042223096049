import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { addNotification } from "notificationStore.js";

import { isValidDateForMonth } from "utils.js";

import {
  orderValidationsActions,
  getStockModalOrderValidations
} from "actions/orderValidationsActions.js";
import { getStockModalOrder } from "actions/orderActions.js";

import {
  validationsSelector,
  warningsSelector
} from "selectors/orderValidationsSelector.js";
import {
  orderBuyplanSelector,
  isDaiwaBrandSelector,
  selectedShippingAddrIdSelector,
  orderBillingAddressSelector
} from "selectors/orderDetailsSelector.js";
import { orderDataSelector } from "selectors/orderSelector.js";
import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";

import { haveValidationsChanged } from "reducers/orderValidationsReducer.js";

import CustomModal from "components/CustomModal/index.jsx";
import OrderDialogBody from "components/BuyplanReport/StockModal/OrderDialogBody.jsx";
import OrderDialogFooter from "components/BuyplanReport/OrderDialogFooter.jsx";
import { getUpdateStockModelSalesPerson } from "actions/updateSalesPersonActions";
import { setSalesPersonInOrderDetails } from "actions/orderDetailsActions";
import { setSalesPersonInBuyplanInfo } from "actions/buyplanInfoActions";
import i18next from "i18next";
import customShippingAddressReducer, { getInitialState } from "reducers/customShippingAddressReducer";
import { updateForm } from "actions/customShippingAddressActions";

let submitOrderParams = null;

/* Note: Always Sync V2 - src/components/BuyplanReport/StockModal/V2/OrderSummaryDialog.jsx */

const OrderSummaryDialog = ({ className, isOpen, closeModalCallback }) => {
  const dispatch = useDispatch();
  const stockModalId = useSelector(stockModelIdSelector);
  const validations = useSelector(validationsSelector);
  const warnings = useSelector(warningsSelector);
  const buyplan = useSelector(orderBuyplanSelector);
  const orderData = useSelector(orderDataSelector);
  const orderNumber = orderData && orderData.get("order_number");
  const showShippingMethods = buyplan && buyplan.get("show_shipping_methods");
  const isDaiwaBrand = useSelector(isDaiwaBrandSelector);
  const billingAddress = useSelector(orderBillingAddressSelector);
  const selectedShippingAddrId = useSelector(selectedShippingAddrIdSelector);
  const [custPurchaseOrder, setCustPurchaseOrder] = useState(null);
  const onPurchaseOrderBlurCallback = useCallback(evt => {
    setCustPurchaseOrder(evt.target.value);
  }, []);

  const [deliveryInstructions, setdeliveryInstructions] = useState(null);
  const onDeliveryInstructionsBlurCallback = useCallback(evt => {
    setdeliveryInstructions(evt.target.value);
  }, []);

  const [netsuiteOrderType, setNetsuiteOrderType] = useState(null);
  const handleNetsuiteOrderTypeChange = selectedNetsuiteOrderType => {
    setNetsuiteOrderType(selectedNetsuiteOrderType.value);
  };

  const [shippingAddress, setShippingAddress] = useState(null);
  const handleShippingAddressChange = selectedShippingAddress => {
    setShippingAddress(selectedShippingAddress.value);
  };

  const [shippingMethod, setShippingMethod] = useState(null);
  const handleShippingMethodChange = selectedShippingMethod => {
    setShippingMethod(selectedShippingMethod.value);
  };

  const [deliveryDate, setDeliveryDate] = useState(null);
  const handleDeliveryDateChange = selectedDeliveryDate => {
    setDeliveryDate(selectedDeliveryDate);
  };

  const [cancelDate, setCancelDate] = useState(null);
  const handleCancelDateChange = selectedCancelDate => {
    setCancelDate(selectedCancelDate);
  };

  const [customShippingAddress, dispatchCustomShippingAddress] = useReducer(customShippingAddressReducer, getInitialState());

  const handleSalesPersonChange = selectedSalesPerson => {
    dispatch(
      getUpdateStockModelSalesPerson(stockModalId, selectedSalesPerson.value)
    );
    dispatch(
      setSalesPersonInOrderDetails({
        id: selectedSalesPerson.value,
        name: selectedSalesPerson.label
      })
    );
    dispatch(
      setSalesPersonInBuyplanInfo({
        id: selectedSalesPerson.value,
        name: selectedSalesPerson.label
      })
    );
  };

  const [deliveryDates, setDeliveryDates] = useState({});
  const handleDeliveryDatesChange = ({
    target: {
      name,
      value,
      dataset: { month }
    }
  }) => {
    const deliveryMonth = name.match(/[A-Z]{3}[\d]{2}/)?.[0];

    if (value && !isValidDateForMonth(value, month)) {
      addNotification({
        type: "danger",
        message: i18next.t("order.errors.incorrectDeliveryMonth", {
          value: value,
          deliveryMonth: deliveryMonth
        })
      });
    }

    const tmpDeliveryDates = { ...deliveryDates, [deliveryMonth]: value };
    setDeliveryDates(tmpDeliveryDates);
  };

  const submitOrderCallback = useCallback(() => {
    submitOrderParams = {
      stock_model: {
        company_id: buyplan.get("company_name"),
        retailer_id: buyplan.get("retailer_name"),
        order_detail_attributes: {
          order_number: null,
          customer_purchase_order: custPurchaseOrder,
          delivery_instructions: deliveryInstructions,
          netsuite_order_type: netsuiteOrderType,
          shipping_address: shippingAddress,
          shipping_method: shippingMethod,
          delivery_days: deliveryDates,
          delivery_date: deliveryDate?.toDateString(),
          cancel_date: cancelDate?.toDateString(),
          billing_address_id: billingAddress.get("id"),
          shipping_address_id: selectedShippingAddrId,
          custom_shipping_address: customShippingAddress.toJS()
        }
      }
    };

    if (showShippingMethods) {
      if (!shippingMethod) {
        addNotification({
          type: "danger",
          message: i18next.t("shippingMethod.required")
        });
      } else {
        if (isDaiwaBrand) {
          if (cancelDate && deliveryDate > cancelDate) {
            addNotification({
              type: "danger",
              message: i18next.t("dateValidation.fail")
            });
          } else {
            dispatch(getStockModalOrderValidations(stockModalId, submitOrderParams));
          }
        } else {
          dispatch(getStockModalOrderValidations(stockModalId, submitOrderParams));
        }
      }
    } else {
      dispatch(getStockModalOrderValidations(stockModalId, submitOrderParams));
    }
  }, [
    dispatch,
    stockModalId,
    showShippingMethods,
    shippingMethod,
    cancelDate,
    deliveryDate,
    isDaiwaBrand,
    billingAddress,
    buyplan,
    custPurchaseOrder,
    customShippingAddress,
    deliveryDates,
    deliveryInstructions,
    netsuiteOrderType,
    selectedShippingAddrId,
    shippingAddress
  ]);

  useEffect(() => {
    let haveValidationsPassed = false;
    let haveWarningsPassed = false;

    if (haveValidationsChanged(validations)) {
      const failingValidations = validations.filter(validation => {
        return validation.get("status") !== "pass";
      });

      if (failingValidations.size === 0) {
        haveValidationsPassed = true;
      } else {
        failingValidations.forEach(validation => {
          addNotification({
            type: "error",
            message: validation.get("name")
          });
        });
      }
    }

    if (!warnings || warnings.size === 0) {
      haveWarningsPassed = true;
    } else {
      warnings.forEach(warning => {
        addNotification({
          type: "warning",
          message: warning
        });
      });
    }

    if (haveValidationsPassed && haveWarningsPassed) {
      dispatch(
        getStockModalOrder(stockModalId, submitOrderParams)
      );
    }

    return () => dispatch({ type: orderValidationsActions.reset });
  }, [
    validations,
    warnings,
    dispatch,
    stockModalId,
    buyplan,
    custPurchaseOrder,
    deliveryInstructions,
    netsuiteOrderType,
    shippingAddress,
    shippingMethod,
    deliveryDates,
    deliveryDate,
    cancelDate,
    billingAddress,
    selectedShippingAddrId,
    customShippingAddress
  ]);

  useEffect(() => {
    if (orderNumber) {
      addNotification({
        message: i18next.t("stockModal.order.success", { orderNumber: orderNumber })
      });
    }
  }, [orderNumber]);

  return (
    isOpen && (
      <CustomModal
        className={className}
        isOpen={isOpen}
        title={i18next.t("order.orderDetails")}
        body={
          <OrderDialogBody
            onPurchaseOrderBlurCallback={onPurchaseOrderBlurCallback}
            onDeliveryInstructionsBlurCallback={
              onDeliveryInstructionsBlurCallback
            }
            handleNetsuiteOrderTypeChange={handleNetsuiteOrderTypeChange}
            handleShippingAddressChange={handleShippingAddressChange}
            handleShippingMethodChange={handleShippingMethodChange}
            handleDeliveryDatesChange={handleDeliveryDatesChange}
            handleDeliveryDateChange={handleDeliveryDateChange}
            handleCancelDateChange={handleCancelDateChange}
            handleSalesPersonChange={handleSalesPersonChange}
            deliveryDate={deliveryDate}
            cancelDate={cancelDate}
            onCustomShippingAddressChangeCallback={(evt) => dispatchCustomShippingAddress(updateForm(evt))}
          />
        }
        footer={
          <OrderDialogFooter
            closeModalFn={closeModalCallback}
            onClickCallback={submitOrderCallback}
          />
        }
        closeModalCallback={closeModalCallback}
      />
    )
  );
};

const StyledOrderSummaryDialog = styled(OrderSummaryDialog)`
  min-width: 90%;

  @media (max-width: 575.98px) {
    padding-top: 60px;
  }

  .modal-header {
    align-items: center;
  }

  .modal-title {
    font-size: 24.5px;
    line-height: 30px;
    font-weight: 700;
  }

  .device-info {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 575.98px) {
      .invoice-from,
      .invoice-to {
        width: 100%;
      }
    }
  }

  .invoice-to {
    margin: 0 0 0 50px;

    @media (max-width: 575.98px) {
      margin: 0;
    }
  }

  .modal-footer {
    .btn {
      font-size: 14px;
      line-height: 18px;
      border-radius: 0;
      padding: 5px 9px;

      &.btn-success {
        background-color: #40bf40;
        border-color: #40bf40;
      }
    }
  }
`;

export default React.memo(StyledOrderSummaryDialog);
