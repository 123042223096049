import React from "react";
import LineIcon from "react-lineicons";

const BsDeliveryIcon = () => (
  <LineIcon name="delivery" style={{
    color: "var(--bs-light-grey)",
    fontSize: "18px"
  }} />
);

export default React.memo(BsDeliveryIcon);
