import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import List from "@researchgate/react-intersection-list";
import { CSSTransition } from "react-transition-group";

import { getBuyplanDetails } from "actions/buyplanDetailsActions.js";
import { getFilters } from "actions/filterActions.js";
import { getQuickFillFilters } from "actions/filterActions.js";

import {
  buyplanIdSelector,
  buyplanStateSelector
} from "selectors/buyplanIdSelector.js";
import {
  isLoadingSelector,
  buyplanDetailsDataSelector
} from "selectors/buyplanDetailsSelector.js";
import { isDataLoadingSelector, appliedFiltersSelector } from "selectors/filtersSelector";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";

import Loader from "components/Loader/Loader.jsx";
import Thumbnail from "components/ProductCard/Thumbnail/Thumbnail.jsx";
import ProductInfo from "components/BuyplanDetails/ProductInfo.jsx";
import BuyplanTotals from "components/BuyplanDetails/BuyplanTotals.jsx";

import { BUYPLAN } from "constants.js";
import { isStateConsignmentRequest } from "utils.js";

import QtyGridForm from "components/QuantityGrid/QtyGridForm.jsx";
import { buyplanDetailsAbilitiesSelector } from "selectors/buyplanDetailsSelector";
import { BPI_PER_PAGE } from "constants.js"
import i18next from "i18next";

const itemsRenderer = (items, ref) => {
  return <div ref={ref}>{items}</div>;
};

const getBuyplanDetailListLoader = () => <Loader />;

let prevOffset = 0;

const BuyplanDetailsContainer = ({ className }) => {
  const dispatch = useDispatch();
  const { releaseId, brandId } = useParams();

  const buyplanId = useSelector(buyplanIdSelector);
  const warehouseId = useSelector(warehouseIdSelector);
  const isLoading = useSelector(isLoadingSelector);
  const buyplanDetails = useSelector(buyplanDetailsDataSelector);
  const appliedFilters = useSelector(appliedFiltersSelector);
  const buyplanState = useSelector(buyplanStateSelector);
  const abilities = useSelector(buyplanDetailsAbilitiesSelector);
  const isDataLoading = useSelector(isDataLoadingSelector);

  useEffect(() => {
    if (buyplanId) {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");

      dispatch(
        brandId
          ? getQuickFillFilters(brandId, buyplanId, BUYPLAN, code)
          : getFilters(releaseId, BUYPLAN, buyplanId, code)
      );
    }
  }, [dispatch, brandId, buyplanId, releaseId]);

  useEffect(() => {
    prevOffset = 0;

    buyplanId && isDataLoading === false &&
      dispatch(
        getBuyplanDetails(
          buyplanId,
          warehouseId,
          appliedFilters.toJS(),
          BPI_PER_PAGE,
          0,
          true
        )
      );
  }, [dispatch, buyplanId, appliedFilters, warehouseId, isDataLoading]);

  const itemRenderer = (index) => {
    const buyplanItem = buyplanDetails.getIn(['buyplan_items', index]);
    let preOrderDeliveryMonth = buyplanItem.getIn([
      "qty_grid",
      "pre_order_delivery_month"
    ]);

    if (preOrderDeliveryMonth) {
      preOrderDeliveryMonth = [preOrderDeliveryMonth];
    }

    return (
      <div
        key={buyplanItem.get("id")}
        className={`${className} box-details`}
      >
        <Thumbnail
          buyplanId={buyplanId}
          productReleaseId={buyplanItem.get("product_release_id")}
          imgSrc={buyplanItem.get("primary_image")}
          productCallout={buyplanItem.get("callout_label")}
          calloutBgColor={buyplanItem.get("callout_bg_color")}
          calloutBrColor={buyplanItem.get("callout_br_color")}
          calloutTxtColor={buyplanItem.get("callout_txt_color")}
          calloutIcon={buyplanItem.get("callout_icon")}
        />

        <ProductInfo
          name={buyplanItem.get("name")}
          sku={buyplanItem.get("sku")}
          displayColor={buyplanItem.get("colour_truncated")}
          status={buyplanItem.get("available_status")}
          productSpecification1Key={buyplanItem.get("product_specification_key")}
          productSpecification1Value={buyplanItem.get("product_specification_value")}
          displayUnitRetailPrice={buyplanItem.get(
            "display_unit_retail_price"
          )}
          unitRetailPrice={buyplanItem.get("unit_retail_price")}
          wholesalePrice={buyplanItem.get("display_wholesale_price")}
          retailPriceFormatted={buyplanItem.get("retail_price_formatted")}
          totalQty={buyplanItem.get("total_quantity")}
          totalPrice={buyplanItem.get("total_price_formatted")}
          buyplanId={buyplanId}
          releaseId={releaseId}
          brandId={brandId}
          buyplanItemId={buyplanItem.get("id")}
          isDiscounted={buyplanItem.get("is_discounted")}
          pbDiscounted={buyplanItem.get("pb_discounted")}
          discountedDisplayWholesaleRange={buyplanItem.get(
            "discounted_display_wholesale_range"
          )}
          discountedDisplayWholesalePrice={buyplanItem.get(
            "discounted_display_wholesale_price"
          )}
          wholesaleRange={buyplanItem.get("wholesale_range")}
          isStateConsignmentRequest= {isStateConsignmentRequest(buyplanState)}
          abilities={abilities}
          pbPercentOff={buyplanItem.get("pb_percent_off")}
          is_pre_order_product={buyplanItem.get("is_pre_order_product")}
          pre_order_month_and_date={preOrderDeliveryMonth}
          pre_order_message={buyplanItem.get("pre_order_message")}
        />

        <QtyGridForm
          deliveryMonths={buyplanItem.getIn([
            "qty_grid",
            "delivery_months"
          ])}
          attrsList={buyplanItem.getIn([
            "qty_grid",
            "attribute_sequence"
          ])}
          headers={buyplanItem.getIn(["qty_grid", "headers"])}
          sizeMinimums={buyplanItem.getIn(["qty_grid", "size_mins"])}
          attributeSeq={buyplanItem.getIn([
            "qty_grid",
            "attribute_sequence"
          ])}
          filledMonths={buyplanItem.get("filled_months")}
          intendedMonths={buyplanItem.get("intended_months")}
          qtyGridProductRelease={buyplanItem.getIn([
            "qty_grid",
            "product_release"
          ])}
          buyplanItemId={buyplanItem.get("id")}
          is_pre_order_product={buyplanItem.get("is_pre_order_product")}
          pre_order_month_and_date={preOrderDeliveryMonth} //get("pre_order_month_and_date")}
          pre_order_message={buyplanItem.get("pre_order_message")}
          preOrderFilledMonths={buyplanItem.get("pre_order_filled_months")}
          preOrderIntendedMonths={buyplanItem.get("pre_order_intended_months")}
          bpsbToHighlight={buyplanItem.get("bpsb_to_highlight")}
        />
      </div>
    );
  }

  const buyplanItems = buyplanDetails && buyplanDetails.get("buyplan_items");
  const lastLoadedBPCount = buyplanDetails.get("last_loaded_bp_count");
  const loadedBuyplanDetailsCount = buyplanItems.size
  const awaitMoreBuyplan = () => (lastLoadedBPCount%BPI_PER_PAGE === 0 && lastLoadedBPCount !== 0);

  const onIntersection = size => {
    let newOffset = prevOffset + BPI_PER_PAGE;

    if (size !== prevOffset) { 
      dispatch(
        getBuyplanDetails(
          buyplanId,
          warehouseId,
          appliedFilters.toJS(),
          BPI_PER_PAGE,
          newOffset,
          false
        )
      );
    } else {
      newOffset -= BPI_PER_PAGE;
    }

    prevOffset = newOffset;
  };

  return (
    <>
      {isLoading && getBuyplanDetailListLoader()}

      {!isLoading && loadedBuyplanDetailsCount === 0 && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <p>{i18next.t("buyplanItem.listing")}</p>
        </div>
      )}

      <CSSTransition
        timeout={1200}
        in={!isLoading && buyplanItems.size > 0}
        unmountOnExit
      >
        <>
          <List
            pageSize={BPI_PER_PAGE}
            itemCount={loadedBuyplanDetailsCount}
            itemsRenderer={itemsRenderer}
            renderItem={itemRenderer}
            onIntersection={onIntersection}
            threshold="95%"
            awaitMore={awaitMoreBuyplan()}
          />

          {awaitMoreBuyplan() && getBuyplanDetailListLoader()}
        </>
      </CSSTransition>

      {
        <BuyplanTotals
          totalPriceFormatted={
            buyplanDetails &&
            buyplanDetails.getIn(["buyplan", "total_price_formatted"])
          }
          totalItemsCount={
            buyplanDetails &&
            buyplanDetails.getIn(["buyplan", "total_items_count"])
          }
          isBuyplanTab={true}
        />
      }
    </>
  );
};

const StyledBuyplanDetailsContainer = styled(BuyplanDetailsContainer)`
  display: grid;
  grid-template-columns: 110px 14% 1fr;
  grid-column-gap: 20px;

  @media (max-width: 575.98px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-template-columns: unset;
    grid-column-gap: unset;

    .quantity-grid-form {
      padding-top: 16px;
    }
  }

  + .box-details {
    padding: 15px 0 0;
    border-top: 1px dotted #ddd;

    @media (max-width: 575.98px) {
      margin-top: 15px;

      + .buyplan-totals {
        margin-top: 15px;
      }
    }
  }

  .thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 138px;
    border: none;
    overflow: hidden;

    @media (max-width: 575.98px) {
      align-self: flex-start;
      margin-right: 16px;
    }
  }
`;

export default React.memo(StyledBuyplanDetailsContainer);
