import React from "react";
import { Nav } from "reactstrap";
import styled from "styled-components";

const BsNavPills = ({ className, children, ...rest }) => (
  <Nav pills className={className} {...rest}>
    {children}
  </Nav>
);

const StyledBsNavPills = styled(BsNavPills)`
  padding: 0;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin: 0 0 20px;

  @media (max-width: 575.98px) {
    justify-content: center;
  }

  .nav-item {
    cursor: pointer;
  }

  .nav-item > .nav-link {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    padding: 18px 20px;
    color: #222f3e;
    margin-right: 2px;
    border-radius: 0;
    border-bottom: 2px solid #f9f9f9;

    &:hover {
      color: #ffffff;
      background-color: #222f3e;
      border-bottom: 2px solid #222f3e;
    }

    &.active {
      background-color: var(--body-bg-color);
      color: #368ee0;
      cursor: not-allowed;
      font-weight: 500;
      border-bottom: 3px solid #368ee0;
    }
  }
`;

export default React.memo(StyledBsNavPills);
