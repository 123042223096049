import { takeLatest, put } from "redux-saga/effects";

import { listShortUrlsActions } from "actions/listShortUrlsActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestShortUrls = function*(action) {
  yield put({
    type: GET_DATA,
    path: `/api/filters/list.json`,
    actions: listShortUrlsActions,
    params: {
      ...action.payload
    }
  });
};

export const watchRequestShortUrls = function*() {
  yield takeLatest(listShortUrlsActions.request, requestShortUrls);
};
