import React from "react";
import StyledBadge from "components/CustomBadge/index.jsx";

const BadgeList = ({ badges, toggleSizeBreak }) => {
  return (
    badges &&
    badges.map((badge, index) => {
      return (
        <StyledBadge
          key={badge.id || index}
          className="mr-1"
          data-is-intended={badge.isIntended}
          data-is-filled={badge.isFilled}
          data-month-value={badge.monthValue}
          clickable="true"
          onClick={toggleSizeBreak}
          data-is-pre-order={badge.is_pre_order_enabled}
          is_pre_order_enabled={badge.is_pre_order_enabled }
        >
          {badge.text}
        </StyledBadge>
      );
    })
  );
};

export default React.memo(BadgeList);
