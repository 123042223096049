export const agentNoteActions = {
  requestNoteUpdation: "UPDATE_AGENT_NOTE",
  success: "AGENT_NOTE_SUCCESS",
  failure: "AGENT_NOTE_FAILURE",
};

export const updateAgentNote = (payload) => {
  return {
    type: agentNoteActions.requestNoteUpdation,
    payload,
  };
};

export const getAgentNoteSuccess = (payload) => {
  return {
    type: agentNoteActions.success,
    payload,
  };
};

export const getAgentNoteFailure = (errorPayload) => {
  return {
    type: agentNoteActions.failure,
    payload: errorPayload,
  };
};
