import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormGroup, Label, Col } from "reactstrap";
import Select from "react-select";

import {
  isNetsuiteOrderIntegratedSelector,
  netsuiteOrderTypesSelector
} from "selectors/orderDetailsSelector.js";
import i18next from "i18next";

const getNetsuiteOrderTypeOptions = (orderTypes) => {
  return orderTypes.map((orderType) => ({
    value: orderType,
    label: orderType,
  }));
};

const NetsuiteOrderType = ({ handleNetsuiteOrderTypeChange }) => {
  const isNetsuiteOrderIntegrated = useSelector(
    isNetsuiteOrderIntegratedSelector
  );

  const netsuiteOrderTypes = useSelector(netsuiteOrderTypesSelector);
  const netsuiteOrderTypeOptions = useMemo(() => {
    return (
      netsuiteOrderTypes &&
      getNetsuiteOrderTypeOptions(netsuiteOrderTypes.toArray())
    );
  }, [netsuiteOrderTypes]);

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (isNetsuiteOrderIntegrated && netsuiteOrderTypeOptions?.length > 0) {
      if (!selectedOption) {
        const defaultOption = netsuiteOrderTypeOptions[0];
        setSelectedOption(defaultOption);
        handleNetsuiteOrderTypeChange(defaultOption);
      }
    }
  }, [
    isNetsuiteOrderIntegrated,
    netsuiteOrderTypeOptions,
    handleNetsuiteOrderTypeChange,
    selectedOption,
  ]);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    handleNetsuiteOrderTypeChange(selected);
  };

  if (!isNetsuiteOrderIntegrated || netsuiteOrderTypeOptions?.length === 0)
    return null;

  return (
    <FormGroup row>
      <Label for="netsuite-order-type" sm={4}>
        {i18next.t("order.netsuite.orderType")}
      </Label>

      <Col sm={6}>
        <Select
          name="buyplan[order_detail_attributes][netsuite_order_type]"
          id="netsuite-order-type"
          options={netsuiteOrderTypeOptions}
          value={selectedOption}
          onChange={handleChange}
          placeholder={i18next.t("select.default")}
          noOptionsMessage={() => i18next.t("select.noOptions")}
        />
      </Col>
    </FormGroup>
  );
};

export default React.memo(NetsuiteOrderType);
