import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import H5 from "components/H5.js";

import { totalProductsCountSelector } from "selectors/productsSelector.js";
import i18next from "i18next";

const ProductsCount = ({ className, children }) => {
  const totalProductsCount = useSelector(totalProductsCountSelector);

  return (
    <H5 className={`${className}`}>
      {i18next.t("sidebar.products")} ({totalProductsCount}){children}
    </H5>
  );
};

const StyledProductsCount = styled(ProductsCount)`
  text-rendering: optimizelegibility;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
  margin: 10px 0 20px;
`;

export default React.memo(StyledProductsCount);
