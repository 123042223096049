import React from "react";
import styled from "styled-components";

const MonthReportTable = ({ name, obj, hTag = "h6" }) => {
  return (
    <tr>
      <StyledTh hTag={ hTag }>
        <StyledHeading as={ hTag }>{ name }</StyledHeading>
      </StyledTh>

      <StyledTh className="item-total" centerAlign hTag={ hTag }>
        <StyledHeading as={ hTag }>
          { obj.get("quantity") }
        </StyledHeading>
      </StyledTh>

      <StyledTh className="item-total" centerAlign hTag={ hTag }>
        <StyledHeading as={ hTag }>
          { obj.get("total_price_formatted") }
        </StyledHeading>
      </StyledTh>
    </tr>
  );
};

const StyledTh = styled.th`
  width: 33.33%;
  ${ props => props.centerAlign && "text-align: center;" };
  ${
    props => {
      switch ( props.hTag ) {
        case "h4":
          return "background-color: var(--bs-dark-navy); color: #ffffff";
        case "h5":
          return "background-color: #DDDDDD; color: #222222";
        default:
          return null;
      }
    }
  };
`;

const StyledHeading = styled.h6``;

export default React.memo(MonthReportTable);
