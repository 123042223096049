import React from "react";
import { useSelector } from "react-redux";
import PriceText from "components/PriceText";
import { showDiscountPercentSelector } from "selectors/quantityGridSelector";

const TableFooter = ({ deliveryMonths, attrsList, headers, isMinOrderCheckApplicable }) => {
  return (
    <tfoot className="s_pricing_wrap" style={{ display: "table-footer-group" }}>
      <AttrWiseRows
        deliveryMonths={deliveryMonths}
        attrsList={attrsList}
        headers={headers}
        isMinOrderCheckApplicable={isMinOrderCheckApplicable}
      />
    </tfoot>
  );
};

const AttrWiseRows = ({ deliveryMonths, attrsList, headers, isMinOrderCheckApplicable }) => {
  return attrsList?.map(attrName => (
    <tr key={attrName} className="s_pricing_row">
      <td className="row-header"></td>
      <td className="attribute_col" style={{ display: "table-cell" }}>
        {attrName}
      </td>

      <AttrbuteWisePrices
        deliveryMonths={deliveryMonths}
        attrName={attrName}
        headers={headers}
      />
     { isMinOrderCheckApplicable && <td className="table-last-col"></td> }
    </tr>
  ));
};

const AttrbuteWisePrices = ({ deliveryMonths, attrName, headers }) => {
  const showDiscountPercent = useSelector(showDiscountPercentSelector);
  const attrRow = deliveryMonths?.getIn([0, "attr_rows", attrName]);
  const attrSizes = headers.remove(0);

  if (!attrSizes || !attrRow) return <></>;

  return attrSizes.map(sizeName => {
    const bpsbId = attrRow.getIn([sizeName, "bpsb_id"]);
    const isDiscounted = attrRow.getIn([sizeName, "is_discounted"]);
    const pbDiscounted = attrRow.getIn([sizeName, "pb_discounted"]);
    const staticDiscounted = attrRow.getIn([sizeName, "static_discounted"]);
    const pbPercentOff = attrRow.getIn([sizeName, "pb_percent_off"]);
    const displayWholesalePrice = attrRow.getIn([sizeName, "display_wholesale_price"]);
    const displayEffectiveWholesalePrice = attrRow.getIn([
      sizeName,
      "display_effective_wholesale_price"
    ]);
    const retailPriceFormatted = attrRow.getIn([sizeName, "retail_price_formatted"]);
    const discountedPriceClass = pbDiscounted ? "c_green" : staticDiscounted ? "c_blue" : "";

    return (
      <td key={bpsbId}>
        <div className="price_value">
          <div>
            <PriceText
              isDiscounted={isDiscounted}
              pbDiscounted={pbDiscounted}
              pbPercentOff={pbPercentOff}
              showWsRange={false}
              displayWholesalePrice={displayWholesalePrice}
              discountedDisplayWholesalePrice={displayEffectiveWholesalePrice}
              discountedPriceClass={discountedPriceClass}
              showDiscountPercent={showDiscountPercent}
            />
          </div>
          <div>{retailPriceFormatted && `(${retailPriceFormatted})`}</div>
        </div>
      </td>
    );
  });
};

export default React.memo(TableFooter);
