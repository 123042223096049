import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { buyplanCurrencySelector } from "selectors/buyplanInfoSelector.js";

import BannerImg from "components/ReleaseBanner/BannerImg.jsx";

const BannerInfo = ({
  imgUrl,
  altText,
  releaseName,
  currencyCode,
  className
}) => {
  const buyplanCurrencyCode = useSelector(buyplanCurrencySelector);

  return (
    <div className={`${className} banner-info mx-auto`}>
      <BannerImg srcUrl={imgUrl} altText={altText} className="mx-auto" />

      <StyledReleaseName>
        {releaseName} ({buyplanCurrencyCode || currencyCode})
      </StyledReleaseName>
    </div>
  );
};

const StyledBannerInfo = styled(BannerInfo)`
  min-height: 150px;
`;

const StyledReleaseName = styled.h2`
  font-weight: 500;
  font-size: 20px;
  margin: 18px 0 3px;
  color: var(--bs-dark-navy);
  letter-spacing: 0.5px;
`;

export default React.memo(StyledBannerInfo);
