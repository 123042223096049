import { takeEvery, put } from "redux-saga/effects";

import { buyplanTotalsActions } from "actions/buyplanTotalsActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestBuyplanTotals = function*(action) {
  const { buyplanId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/buyplans/${buyplanId}/totals.json`,
    actions: buyplanTotalsActions,
    params
  });
};

export const watchRequestBuyplanTotals = function*() {
  yield takeEvery(buyplanTotalsActions.request, requestBuyplanTotals);
};

export const requestStockModalBuyplanTotals = function*(action) {
  const { stockModalId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/stock_models/${stockModalId}/totals.json`,
    actions: buyplanTotalsActions,
    params
  });
};

export const watchRequestStockModalBuyplanTotals = function*() {
  yield takeEvery(
    buyplanTotalsActions.requestStockModalTotals,
    requestStockModalBuyplanTotals
  );
};
