import { takeLatest, put, race, take, select, delay } from "redux-saga/effects";

import { appliedFiltersSelector } from "selectors/filtersSelector.js";

import { buyplanSummaryActions } from "actions/buyplanSummaryActions.js";
import { getBuyplanTotals } from "actions/buyplanTotalsActions.js";

import { POST_DATA } from "actions/apiOperations.js";

export const requestBuyplanSummary = function* (action) {
  const { buyplanId, ...params } = action.payload;
  const appliedFilters = yield select(appliedFiltersSelector);

  if (!params.filters) {
    params.filters = appliedFilters.toJS();
  }

  yield put({
    type: POST_DATA,
    path: `/api/buyplans/${buyplanId}/summary.json`,
    actions: buyplanSummaryActions,
    params
  });

  const { success } = yield race({
    success: take(buyplanSummaryActions.success),
    error: take(buyplanSummaryActions.failure)
  });

  if (success) {
    yield delay(300);
    yield put(getBuyplanTotals(buyplanId));
  }
};

export const watchRequestBuyplanSummary = function* () {
  yield takeLatest(buyplanSummaryActions.request, requestBuyplanSummary);
};
