import { takeLeading, put, select, race, take } from "redux-saga/effects";

import { appliedFiltersSelector } from "selectors/filtersSelector.js";
import { buyplanIdSelector, buyplanStateSelector } from "selectors/buyplanIdSelector";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";

import { bulkDeselectProductsActions } from "actions/bulkDeselectProductsActions.js";
import { getProducts } from "actions/productsActions.js";

import { DELETE_DATA } from "actions/apiOperations.js";

import { PER_PAGE as PRODUCTS_PER_PAGE } from "constants.js";

export const requestBulkDeselectProducts = function*(action) {
  const { releaseId, ...otherParams } = action.payload;
  const buyplanId = yield select(buyplanIdSelector);
  const buyplanState = yield select(buyplanStateSelector);
  const warehouseId = yield select(warehouseIdSelector);
  const appliedFilters = yield select(appliedFiltersSelector);
  const filters = {
    common_filters: appliedFilters.get("common_filters"),
    dynamic_filters: appliedFilters.get("dynamic_filters")
  };

  yield put({
    type: DELETE_DATA,
    path: `/api/releases/${releaseId}/bulk_remove_items.json`,
    actions: bulkDeselectProductsActions,
    params: {
      ...otherParams,
      buyplan_id: buyplanId,
      warehouse_id: warehouseId,
      filters
    }
  });

  const { success } = yield race({
    success: take(bulkDeselectProductsActions.success),
    error: take(bulkDeselectProductsActions.failure)
  });

  if (success) {
    yield put(
      getProducts(
        releaseId,
        PRODUCTS_PER_PAGE,
        0,
        filters,
        true,
        buyplanState,
        buyplanId,
        warehouseId
      )
    );
  }
};

export const watchRequestBulkDeselectProducts = function*() {
  yield takeLeading(bulkDeselectProductsActions.init, requestBulkDeselectProducts);
};
