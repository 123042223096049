import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import BsTrash from "components/BsTrash.jsx";

import {
  removeBuyplanItem,
  removeStockModalItem
} from "actions/likeDislikeActions.js";
import DiscountedDisplay from "components/ProductModal/DiscountedDisplay";
import i18next from "i18next";

const ProductInfo = ({
  name,
  sku,
  displayColor,
  status,
  productSpecification1Key,
  productSpecification1Value,
  unitRetailPrice,
  wholesalePrice,
  retailPriceFormatted,
  totalQty,
  totalPrice,
  className,
  buyplanId,
  releaseId,
  buyplanItemId,
  displayUnitRetailPrice,
  brandId,
  isStockModal,
  isDiscounted,
  pbDiscounted,
  discountedDisplayWholesaleRange,
  discountedDisplayWholesalePrice,
  abilities,
  wholesaleRange,
  isRemovalAllowed = true,
  pbPercentOff
}) => {
  const dispatch = useDispatch();

  const handleBuyplanItemRemoval = useCallback(() => {
    dispatch(
      isStockModal
        ? removeStockModalItem(buyplanId, buyplanItemId, {
            refreshBuyplanDetails: true,
            releaseId
          })
        : removeBuyplanItem(buyplanId, buyplanItemId, {
            refreshBuyplanDetails: true,
            releaseId,
            brandId
          })
    );
  }, [dispatch, buyplanId, buyplanItemId, releaseId, isStockModal, brandId]);

  return (
    <div className={`${className} product-info`}>
      <h6 title={name}>{name}</h6>

      <div className="grid-row">
        <label>{i18next.t("itemDetails.sku")}</label>
        <span className="label-value">{sku}</span>
      </div>

      <div className="grid-row">
        <label>{i18next.t("itemDetails.style")}</label>
        <span className="label-value text-truncate" title={displayColor}>
          {displayColor}
        </span>
      </div>

      {status && (
        <div className="grid-row">
          <label>{i18next.t("itemDetails.status")}</label>
          <span className="label-value">{status}</span>
        </div>
      )}

      {productSpecification1Key && productSpecification1Value &&(
        <div className="grid-row">
          <label>{productSpecification1Key + ":"}</label>
          <span className="label-value">{productSpecification1Value}</span>
        </div>
      )}

      {displayUnitRetailPrice && (
        <div className="grid-row">
          <label>{i18next.t("itemDetails.ea_rr")}</label>
          <span className="label-value">{unitRetailPrice}</span>
        </div>
      )}

      <div className="grid-row">
        <label>{i18next.t("itemDetails.ws")}</label>
        <span className="label-value price_value">
          <DiscountedDisplay
            pbDiscounted={pbDiscounted}
            discounted={isDiscounted}
            wholesaleRange={wholesaleRange}
            wholesalePrice={wholesalePrice}
            discountedDisplayWholesaleRange={discountedDisplayWholesaleRange}
            discountedDisplayWholesalePrice={discountedDisplayWholesalePrice}
            abilities={abilities}
            pbPercentOff={pbPercentOff}
          />
        </span>
      </div>

      <div className="grid-row">
        <label>{i18next.t("itemDetails.rr")}</label>
        <span className="label-value">{retailPriceFormatted}</span>
      </div>

      <div className="grid-row">
        <label>{i18next.t("itemDetails.qty")}</label>
        <span className="label-value">{totalQty}</span>
      </div>

      <div className="grid-row">
        <label>{i18next.t("itemDetails.total")}</label>
        <span className="label-value">{totalPrice}</span>
      </div>

      <div className="remove-link">
        {isRemovalAllowed && (
          <BsTrash className="mt-2" onClickHandler={handleBuyplanItemRemoval} />
        )}
      </div>
    </div>
  );
};

const StyledProductInfo = styled(ProductInfo)`
  .grid-row {
    display: grid;
    grid-template-columns: 50px 1fr;
  }

  h6 {
    color: var(--bs-dark-navy);
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;
    margin: 0;
    word-wrap: break-word;
    letter-spacing: 0.4px !important;
  }

  label,
  .label-value {
    color: var(--bs-dark-black);
    font-size: 11px;
    letter-spacing: 0.2px;
  }

  label {
    width: 50px;
    line-height: 15px;
    font-weight: 700;
    margin: 0;
    text-align: left;
  }

  .label-value {
    line-height: 15px;
    font-weight: 400;
    word-break: break-word;
  }
`;

export default StyledProductInfo;
