import i18next from "i18next";
import React from "react";

const StockModalHeader = ({ sizeSequences, sizeMinimums, isMinOrderCheckApplicable }) => {
  return (
    <thead>
      <tr>
        <th className="attribute_col" />

        {sizeSequences &&
          sizeSequences.map((sizeSeq, index) => {
            return (
              <th key={`th-${sizeSeq}-${index}`} className="rotated_col">
                <div>
                  <span>
                    <b>{sizeSeq}</b>
                  </span>
                </div>
              </th>
            );
          })}
        { isMinOrderCheckApplicable &&
          <th className="rotated_col table-last-rotated-col">
            <div>
              <span>
                <b>
                  { i18next.t("qtyGrid.minimums") }
                </b>
              </span>
            </div>
          </th> }
      </tr>
      { sizeMinimums && sizeMinimums.size > 0 &&
        <tr className="size_min_header">
          <th className="attribute_col" />
          {sizeMinimums.entrySeq().map(([index, sizeMin]) => {
            return (
              <td key={`attr-rows-th-${sizeMin}-${index}`}>
                <div>
                  <span>
                    <b>min. {sizeMin}</b>
                  </span>
                </div>
              </td>
            );
           })}
        </tr>
      }
    </thead>
  );
};

export default React.memo(StockModalHeader);
