import { Badge } from "reactstrap";
import styled from "styled-components";

export default styled(Badge)`
  background-color: ${props => {
    if (props.disabled) return "var(--badge-past-bg-color)";
    if (props["data-is-filled"]) return "var(--badge-filled-bg-color)";
    if (props["data-is-intended"]) return "var(--badge-intended-bg-color)";
    if (props["data-is-secondary"]) return "var(--badge-secondary-bg-color)";
    return "var(--badge-default-bg-color)";
  }};
  border-radius: 3px;
  font-size: 10px;
  letter-spacing: 0.75px;
  padding: 3px;
  cursor: ${props => {
    return props.disabled
      ? "not-allowed"
      : props.clickable
      ? "pointer"
      : "unset";
  }};
  text-align: center;
  font-weight: 500;
  ${({show_pre_order_month}) => show_pre_order_month === 'true' && `width: 43px;`}
  margin: 0 1px;
  text-shadow: none;
  display: inline-block;
  line-height: 14px;
  color: #ffffff;
  white-space: nowrap;
  vertical-align: baseline;
  box-shadow: ${props => props.is_pre_order_enabled === 'true' && "0 0 0 1px red"};
`;
