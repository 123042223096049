import { takeEvery } from "redux-saga/effects";

import { POST_DATA } from "actions/apiOperations.js";

import { postJSON } from "apis/apiHelpers.js";

import sharedApiSaga from "sagas/sharedApiSaga.js";

export default function* watchPostData() {
  yield takeEvery(POST_DATA, postData);
}

export const postData = function*({ path, actions, params }) {
  return yield* sharedApiSaga(postJSON, path, actions, params);
};
