import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { addNotification } from "notificationStore.js";
import { isValidDateForMonth } from "utils.js";

import {
  orderValidationsActions,
  getOrderValidations
} from "actions/orderValidationsActions.js";
import { getOrder, getConsignmentRequest } from "actions/orderActions.js";

import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import { buyplanStateSelector } from "selectors/buyplanInfoSelector.js";
import {
  validationsSelector,
  warningsSelector
} from "selectors/orderValidationsSelector.js";
import { orderBuyplanSelector, isDaiwaBrandSelector, orderInvoiceSelector } from "selectors/orderDetailsSelector.js";
import { orderDataSelector } from "selectors/orderSelector.js";
import {
  orderBillingAddressSelector,
  selectedShippingAddrIdSelector
} from "selectors/orderDetailsSelector.js";

import { haveValidationsChanged } from "reducers/orderValidationsReducer.js";

import CustomModal from "components/CustomModal/index.jsx";
import OrderDialogBody from "components/BuyplanReport/OrderDialogBody.jsx";
import OrderDialogFooter from "components/BuyplanReport/OrderDialogFooter.jsx";

import { CONSIGNMENT, PAST_MONTH_QTY_WARNING } from "constants.js";
import { getUpdateBuyplanSalesPerson } from "actions/updateSalesPersonActions";
import { setSalesPersonInOrderDetails } from "actions/orderDetailsActions";
import { setSalesPersonInBuyplanInfo } from "actions/buyplanInfoActions";
import i18next from "i18next";
import customShippingAddressReducer, { getInitialState } from "reducers/customShippingAddressReducer";
import { updateForm } from "actions/customShippingAddressActions";

let submitOrderParams = null;

/* Note: Always Sync V2 - src/components/BuyplanReport/OrderSummaryDialog.jsx */

const OrderSummaryDialog = ({ className, isOpen, closeModalCallback }) => {
  const dispatch = useDispatch();
  const buyplanId = useSelector(buyplanIdSelector);
  const buyplanState = useSelector(buyplanStateSelector);
  const validations = useSelector(validationsSelector);
  const warnings = useSelector(warningsSelector);
  const buyplan = useSelector(orderBuyplanSelector);
  const orderData = useSelector(orderDataSelector);
  const invoice = useSelector(orderInvoiceSelector);
  const orderNumber = orderData && orderData.get("order_number");
  const orderState = orderData && orderData.get("buyplan_state");
  const pastMonthQtyWarningsRegExp = new RegExp(PAST_MONTH_QTY_WARNING);
  const showShippingMethods = buyplan && buyplan.get("show_shipping_methods");
  const isDaiwaBrand = useSelector(isDaiwaBrandSelector);
  const billingAddress = useSelector(orderBillingAddressSelector);
  const selectedShippingAddrId = useSelector(selectedShippingAddrIdSelector);
  let shippingMethodLoclisationData = i18next.t("shippingMethod.required");
  let dateValidationLoclisationData = i18next.t("dateValidation.fail");
  let consignmentLoclisationData = i18next.t("order.consignment.success");
  let orderSuccessLoclisationData = i18next.t("order.success", { orderNumber: orderNumber });
  const [custPurchaseOrder, setCustPurchaseOrder] = useState(null);
  const onPurchaseOrderBlurCallback = useCallback(evt => {
    setCustPurchaseOrder(evt.target.value);
  }, []);

  const [deliveryInstructions, setdeliveryInstructions] = useState(null);
  const onDeliveryInstructionsBlurCallback = useCallback(evt => {
    setdeliveryInstructions(evt.target.value);
  }, []);

  const [shippingMethod, setShippingMethod] = useState(null);
  const handleShippingMethodChange = selectedShippingMethod => {
    setShippingMethod(selectedShippingMethod.value);
  };

  const [deliveryDate, setDeliveryDate] = useState(null);
  const handleDeliveryDateChange = selectedDeliveryDate => {
    setDeliveryDate(selectedDeliveryDate);
  };

  const [cancelDate, setCancelDate] = useState(null);
  const handleCancelDateChange = selectedCancelDate => {
    setCancelDate(selectedCancelDate);
  };

  const [netsuiteOrderType, setNetsuiteOrderType] = useState(null);
  const handleNetsuiteOrderTypeChange = selectedNetsuiteOrderType => {
    setNetsuiteOrderType(selectedNetsuiteOrderType.value);
  };

  const [shippingAddress, setShippingAddress] = useState(null);
  const handleShippingAddressChange = selectedShippingAddress => {
    setShippingAddress(selectedShippingAddress.value);
  };

  const [customShippingAddress, dispatchCustomShippingAddress] = useReducer(customShippingAddressReducer, getInitialState());

  const handleSalesPersonChange = selectedSalesPerson => {
    dispatch(getUpdateBuyplanSalesPerson(buyplanId, selectedSalesPerson.value));
    dispatch(
      setSalesPersonInOrderDetails({
        id: selectedSalesPerson.value,
        name: selectedSalesPerson.label
      })
    );
    dispatch(
      setSalesPersonInBuyplanInfo({
        id: selectedSalesPerson.value,
        name: selectedSalesPerson.label
      })
    );
  };

  const [deliveryDates, setDeliveryDates] = useState({});
  const handleDeliveryDatesChange = ({
    target: {
      name,
      value,
      dataset: { month }
    }
  }) => {
    const deliveryMonth = name.match(/[A-Z]{3}[\d]{2}/)?.[0];

    if (value && !isValidDateForMonth(value, month)) {
      addNotification({
        type: "danger",
        message: i18next.t("order.errors.incorrectDeliveryMonth", {
          value: value,
          deliveryMonth: deliveryMonth
        })
      });
    }

    const tmpDeliveryDates = { ...deliveryDates, [deliveryMonth]: value };
    setDeliveryDates(tmpDeliveryDates);
  };

  const submitOrderCallback = useCallback(() => {
    submitOrderParams = {
      buyplan: {
        company_id: buyplan.get("company_name"),
        retailer_id: buyplan.get("retailer_name"),
        id: buyplanId,
        buyplan_state: buyplanState,
        order_detail_attributes: {
          order_number: null,
          customer_purchase_order: custPurchaseOrder,
          delivery_instructions: deliveryInstructions,
          shipping_method: shippingMethod,
          netsuite_order_type: netsuiteOrderType,
          shipping_address: shippingAddress,
          delivery_days: deliveryDates,
          delivery_date: deliveryDate?.toDateString(),
          cancel_date: cancelDate?.toDateString(),
          billing_address_id: billingAddress.get("id"),
          shipping_address_id: selectedShippingAddrId,
          total_price: invoice.get("total_price"),
          custom_shipping_address: customShippingAddress.toJS()
        }
      }
    };

    if (showShippingMethods) {
      if (!shippingMethod) {
        addNotification({
          type: "danger",
          message: shippingMethodLoclisationData
        });
      } else {
        if (isDaiwaBrand) {
          if (cancelDate && deliveryDate > cancelDate) {
            addNotification({
              type: "danger",
              message: dateValidationLoclisationData
            });
          } else {
            dispatch(getOrderValidations(buyplanId, submitOrderParams));
          }
        } else {
          dispatch(getOrderValidations(buyplanId, submitOrderParams));
        }
      }
    } else {
      dispatch(getOrderValidations(buyplanId, submitOrderParams));
    }

  }, [
    dispatch,
    buyplanId,
    showShippingMethods,
    shippingMethod,
    cancelDate,
    deliveryDate,
    isDaiwaBrand,
    billingAddress,
    buyplan,
    buyplanState,
    custPurchaseOrder,
    deliveryDates,
    deliveryInstructions,
    netsuiteOrderType,
    selectedShippingAddrId,
    shippingAddress,
    invoice,
    dateValidationLoclisationData,
    shippingMethodLoclisationData,
    customShippingAddress
  ]);

  useEffect(() => {
    let haveValidationsPassed = false;
    let haveWarningsPassed = false;

    if (haveValidationsChanged(validations)) {
      const failingValidations = validations.filter(validation => {
        return validation.get("status") !== "pass";
      });

      if (failingValidations.size === 0) {
        haveValidationsPassed = true;
      } else {
        failingValidations.forEach(validation => {
          addNotification({
            type: "error",
            message: validation.get("name")
          });
        });
      }
    }

    if (!warnings || warnings.size === 0) {
      haveWarningsPassed = true;
    } else {
      let pastMonthQtyWarnings = 0;

      warnings.forEach(warning => {
        if (pastMonthQtyWarningsRegExp.test(warning)) {
          pastMonthQtyWarnings += 1;
        }

        addNotification({ type: "warning", message: warning });
      });

      if (pastMonthQtyWarnings === warnings.size) {
        haveWarningsPassed = true;
      }
    }

    if (haveValidationsPassed && haveWarningsPassed) {
      if (buyplanState === CONSIGNMENT) {
        dispatch(getConsignmentRequest(buyplanId, submitOrderParams));
      } else {
        dispatch(getOrder(buyplanId, submitOrderParams));
      }
    }

    return () => dispatch({ type: orderValidationsActions.reset });
  }, [
    validations,
    warnings,
    dispatch,
    buyplanId,
    buyplan,
    custPurchaseOrder,
    deliveryInstructions,
    shippingMethod,
    netsuiteOrderType,
    shippingAddress,
    deliveryDates,
    pastMonthQtyWarningsRegExp,
    buyplanState,
    cancelDate,
    deliveryDate
  ]);

  useEffect(() => {
    if (orderNumber) {
      addNotification({
        message: orderSuccessLoclisationData
      });
    }
  }, [orderNumber, orderSuccessLoclisationData]);

  useEffect(() => {
    if (orderState === "consignment_request") {
      addNotification({ message: consignmentLoclisationData });
    }
  }, [orderState, consignmentLoclisationData]);

  return (
    isOpen && (
      <CustomModal
        className={className}
        isOpen={isOpen}
        title={i18next.t("order.orderDetails")}
        body={
          <OrderDialogBody
            onPurchaseOrderBlurCallback={onPurchaseOrderBlurCallback}
            onDeliveryInstructionsBlurCallback={
              onDeliveryInstructionsBlurCallback
            }
            handleShippingMethodChange={handleShippingMethodChange}
            handleNetsuiteOrderTypeChange={handleNetsuiteOrderTypeChange}
            handleShippingAddressChange={handleShippingAddressChange}
            handleDeliveryDatesChange={handleDeliveryDatesChange}
            handleDeliveryDateChange={handleDeliveryDateChange}
            handleCancelDateChange={handleCancelDateChange}
            handleSalesPersonChange={handleSalesPersonChange}
            onCustomShippingAddressChangeCallback={(evt) => dispatchCustomShippingAddress(updateForm(evt))}
            deliveryDate={deliveryDate}
            cancelDate={cancelDate}
          />
        }
        footer={
          <OrderDialogFooter
            closeModalFn={closeModalCallback}
            onClickCallback={submitOrderCallback}
          />
        }
        closeModalCallback={closeModalCallback}
      />
    )
  );
};

const StyledOrderSummaryDialog = styled(OrderSummaryDialog)`
  min-width: 90%;

  @media (max-width: 575.98px) {
    padding-top: 60px;
  }

  .modal-header {
    align-items: center;
  }

  .modal-title {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.2px;
    font-weight: 500;
    color: var(--bs-dark-navy);

    @media (max-width: 575.98px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .device-info {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 575.98px) {
      .invoice-from,
      .invoice-to {
        width: 100%;
      }
    }
  }

  .invoice-to {
    margin: 0 0 0 50px;

    @media (max-width: 575.98px) {
      margin: 0;
    }
  }

  .modal-footer {
    .btn {
      font-size: 14px;
      line-height: 18px;
      border-radius: 4px;
      padding: 5px 9px;

      &.btn-success {
        background-color: #40bf40;
        border-color: #40bf40;
      }
    }
  }
`;

export default React.memo(StyledOrderSummaryDialog);
