export const categoryFilterActions = {
  applyFilter: "APPLY_FILTER"
};

export const applyCategoryFilter = ({ level1, level2, level3 }) => {
  return {
    type: categoryFilterActions.applyFilter,
    payload: {
      level1,
      level2,
      level3
    }
  };
};
