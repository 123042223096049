import React from "react";

import CategoryMenu from "components/QuickFillCategoryFilter/CategoryMenu.jsx";

const CategoryMenuList = ({
  productCategories,
  onLevel1Click,
  onLevel2Click,
  onLevel3Click
}) => {
  return (
    <div className="webslidemenu">
      <div className="wsmenucontainer">
        <div className="headerfull">
          <div className="wsmain clearfix p-0 m-0 mw-100">
            <div className="wsmain clearfix p-0 mw-100">
              <nav className="wsmenu clearfix">
                <div className="overlapblackbg" />
                <ul className="wsmenu-list">
                  {productCategories &&
                    productCategories
                      .keySeq()
                      .toList()
                      .map((level1Menu, index) => {
                        const subMenu = productCategories.get(level1Menu);
                        return (
                          <CategoryMenu
                            key={index}
                            level1Menu={level1Menu}
                            subMenu={subMenu}
                            onLevel1Click={onLevel1Click}
                            onLevel2Click={onLevel2Click}
                            onLevel3Click={onLevel3Click}
                          />
                        );
                      })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CategoryMenuList);
