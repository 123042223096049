import { createSelector } from "reselect";
import { fromJS } from "immutable";

export const filtersReducerSelector = createSelector(
  state => state.get("filtersReducer"),
  filtersReducer => filtersReducer
);

export const isLoadingSelector = createSelector(
  filtersReducerSelector,
  filtersReducer => filtersReducer.get("isLoading")
);

export const isDataLoadingSelector = createSelector(
  filtersReducerSelector,
  filtersReducer => filtersReducer.get("isDataLoading")
);

export const hasErrorsSelector = createSelector(
  filtersReducerSelector,
  filtersReducer => filtersReducer.get("hasErrors")
);

export const errorSelector = createSelector(
  filtersReducerSelector,
  filtersReducer => filtersReducer.get("error")
);

export const activeTabSelector = createSelector(
  filtersReducerSelector,
  filtersReducer => filtersReducer.get("activeTab")
);

// Fetched filters
export const fetchedFiltersSelector = createSelector(
  filtersReducerSelector,
  filtersReducer => filtersReducer.get("fetchedFilters")
);

export const showSegmentFilterSelector = createSelector(
  fetchedFiltersSelector,
  fetchedFilters => fetchedFilters.get("show_segment_filter")
);

export const segmentFilterLabelSelector = createSelector(
  fetchedFiltersSelector,
  fetchedFilters => fetchedFilters.get("segment_filter_label")
);

// Fetched Common filters
export const fetchedCommonFiltersSelector = createSelector(
  fetchedFiltersSelector,
  fetchedFilters => fetchedFilters.get("common_filters")
);

// Fetched Dynamic filters
export const fetchedDynamicFiltersSelector = createSelector(
  fetchedFiltersSelector,
  fetchedFilters => fetchedFilters.get("dynamic_filters")
);

export const fetchedProductCategories = createSelector(
  fetchedDynamicFiltersSelector,
  fetchedDynamicFilters => {
    let productCategories = {};

    fetchedDynamicFilters.get("category1") &&
      fetchedDynamicFilters.get("category1").forEach(category1 => {
        productCategories[category1.get("name")] = {};

        fetchedDynamicFilters
          .get("category2")
          .filter(
            category2 => category2.get("parent_id") === category1.get("name")
          )
          .forEach(category2 => {
            productCategories[category1.get("name")][
              category2.get("name")
            ] = {};

            fetchedDynamicFilters
              .get("category3")
              .filter(
                category3 =>
                  category3.get("parent_id") === category2.get("name")
              )
              .forEach(category3 => {
                productCategories[category1.get("name")][category2.get("name")][
                  category3.get("name")
                ] = [];

                // fetchedDynamicFilters
                //   .get("category4")
                //   .filter(
                //     category4 =>
                //       category4.get("parent_id") === category3.get("name")
                //   )
                //   .forEach(category4 => {
                //     productCategories[category1.get("name")][
                //       category2.get("name")
                //     ][category3.get("name")].push(category4.get("name"));
                //   });
              });
          });
      });

    return fromJS(productCategories);
  }
);

export const filterSequenceSelector = createSelector(
  fetchedDynamicFiltersSelector,
  fetchedDynamicFilters => fetchedDynamicFilters.get("filter_sequence")
);

export const fetchedSegmentsSelector = createSelector(
  fetchedDynamicFiltersSelector,
  fetchedDynamicFilters => fetchedDynamicFilters.get("segments")
);

export const fetchedDivisionsSelector = createSelector(
  fetchedDynamicFiltersSelector,
  fetchedDynamicFilters => fetchedDynamicFilters.get("divisions")
);

export const fetchedCollections = createSelector(
  fetchedDynamicFiltersSelector,
  fetchedDynamicFilters => fetchedDynamicFilters.get("collections")
);

export const fetchedSecondaryFiltersSelector = createSelector(
  fetchedDynamicFiltersSelector,
  fetchedDynamicFilters => fetchedDynamicFilters.get("secondary_filters")
);

// Applied filters
export const appliedFiltersSelector = createSelector(
  filtersReducerSelector,
  filtersReducer => filtersReducer.get("appliedFilters")
);

// Fetched Common filters
export const appliedCommonFiltersSelector = createSelector(
  appliedFiltersSelector,
  appliedFilters => appliedFilters.get("common_filters")
);

// Applied Dynamic filters
export const appliedDynamicFiltersSelector = createSelector(
  appliedFiltersSelector,
  appliedFilters => appliedFilters.get("dynamic_filters")
);

export const appliedSegmentsSelector = createSelector(
  appliedDynamicFiltersSelector,
  appliedDynamicFilters => appliedDynamicFilters.get("segments")
);

export const appliedDivisionsSelector = createSelector(
  appliedDynamicFiltersSelector,
  appliedDynamicFilters => appliedDynamicFilters.get("divisions")
);

export const appliedCollections = createSelector(
  appliedDynamicFiltersSelector,
  appliedDynamicFilters => appliedDynamicFilters.get("collections")
);

export const appliedSecondaryFiltersSelector = createSelector(
  appliedDynamicFiltersSelector,
  appliedDynamicFilters => appliedDynamicFilters.get("secondary_filters")
);
