import { takeLatest, put, race, take } from "redux-saga/effects";
import { PATCH_DATA } from "actions/apiOperations.js";
import { updateSalesPersonActions } from "actions/updateSalesPersonActions";
import { addNotification } from "notificationStore";

export const requestBuyplanSalesPersonUpdate = function*(action) {
  const { buyplanId, userId } = action.payload;

  yield put({
    type: PATCH_DATA,
    path: `/api/buyplans/${buyplanId}/update_sales_person.json`,
    actions: updateSalesPersonActions,
    params: { user_id: userId },
  });

  const { success, failure } = yield race({
    success: take(updateSalesPersonActions.success),
    failure: take(updateSalesPersonActions.failure),
  });

  if (success) {
    addNotification({ message: success?.payload?.data?.message });
  }
  if (failure) {
    addNotification({ type: "danger", message: failure?.payload?.errors[0] });
  }
};

export const watchRequestBuyplanSalesPersonUpdate = function*() {
  yield takeLatest(
    updateSalesPersonActions.requestBuyplanSalesPersonUpdate,
    requestBuyplanSalesPersonUpdate
  );
};

export const requestStockModelSalesPersonUpdate = function*(action) {
  const { stockModelId, userId } = action.payload;

  yield put({
    type: PATCH_DATA,
    path: `/api/stock_models/${stockModelId}/update_sales_person.json`,
    actions: updateSalesPersonActions,
    params: { user_id: userId },
  });

  const { success, failure } = yield race({
    success: take(updateSalesPersonActions.success),
    failure: take(updateSalesPersonActions.failure),
  });

  if (success) {
    addNotification({ message: success?.payload?.data?.message });
  }
  if (failure) {
    addNotification({ type: "danger", message: failure?.payload?.errors[0] });
  }
};

export const watchRequestStockModelSalesPersonUpdate = function*() {
  yield takeLatest(
    updateSalesPersonActions.requestStockModelSalesPersonUpdate,
    requestStockModelSalesPersonUpdate
  );
};
