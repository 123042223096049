import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import LikeDislikeContainer from "containers/LikeDislikeContainer.jsx";
import Zoom from "components/ProductCard/Actions/Zoom/Zoom.jsx";
import Resources from "components/ProductCard/Actions/Resources/Resources.jsx";
import Quantity from "components/ProductCard/Actions/Quantity/Quantity.jsx";
import ViewAts from "components/ProductCard/Actions/ViewAts/ViewAts.jsx";
import SurroundView from "components/ProductCard/Actions/SurroundView/SurroundView.jsx";
import { currentUserDataSelector } from "selectors/currentUserSelector";
import { buyplanStateSelector } from "selectors/buyplanIdSelector.js";
import { bannerInfoDataSelector } from "selectors/bannerInfoSelector.js";
import {
  isStateConsignmentRequest,
  isMarketingRelease
} from "utils.js";

const Actions = ({
  className,
  productReleaseId,
  buyplanItemId,
  isSoldOut,
  buyplanId,
  stockModalId,
  isThreeSixty,
  tenantId,
  brandScopeTag,
  isPreOrderProduct,
  productId
}) => {
  const currentUser = useSelector(currentUserDataSelector);
  const isAdminUser = currentUser && currentUser.get("role") === "Brandscope Admin";
  const isAgentAdmin = currentUser && currentUser.get("role") === "Agent Admin";

  const buyplanState = useSelector(buyplanStateSelector);
  const bannerInfoData = useSelector(bannerInfoDataSelector);
  const releaseStatus = bannerInfoData.get("release_status");

  const showUpdateQtyActions = () => {
    if (!buyplanId && !stockModalId) return false;
    if (isAdminUser || isStateConsignmentRequest(buyplanState))
      return false;
    if (isAgentAdmin && isMarketingRelease(releaseStatus)) return false;
    return true;
  };

  return (
    <div className={`actions ${className}`}>
      { showUpdateQtyActions() &&
        <LikeDislikeContainer
          productReleaseId={productReleaseId}
          buyplanItemId={buyplanItemId}
        />
      }

      <Zoom productReleaseId={productReleaseId} buyplanId={buyplanId} />

      <Resources productReleaseId={productReleaseId} />

      { showUpdateQtyActions() &&
        <Quantity
          productReleaseId={productReleaseId}
          isSoldOut={isSoldOut}
          buyplanItemId={buyplanItemId}
          isPreOrderProduct={isPreOrderProduct}
        />
      }

      {isThreeSixty && (
        <SurroundView
          tenantId={tenantId}
          brandScopeTag={brandScopeTag}
          isThreeSixty={isThreeSixty}
        />
      )}

      {!stockModalId &&
        <ViewAts
          productId={productId}
        />
      }
    </div>
  );
};

const StyledActions = styled(Actions)`
  overflow: visible;
  position: absolute;
  top: 5px;
  right: 2px;
`;

export default React.memo(StyledActions);
