import { fromJS } from "immutable";
import { sizeBreakActions } from "actions/sizeBreakActions.js";
import i18next from "i18next";

export const getInitialState = () => {
  let initialState = {
    errorMsg: null
  };

  return fromJS(initialState);
};

export const reducer = (state, action) => {
  const payload = action.payload;
  const { bpsbId, newQuantity } = payload;
  const qtyPath = [bpsbId, "quantity"];

  switch (action.type) {
    case sizeBreakActions.updateQty:
      const processedNewQty = newQuantity === "" ? "0" : newQuantity;

      const [isQtyValid, qtyErrMsg] = validateQty(processedNewQty);

      if (isQtyValid) {
        let newState = state.setIn(qtyPath, newQuantity).set("errorMsg", null);

        return newState;
      } else {
        return state
          .set("errorMsg", qtyErrMsg)
          .setIn(qtyPath, isNaN(newQuantity) ? "" : newQuantity);
      }
    default:
      return state;
  }
};

const validateQty = newQty => {
  newQty = parseInt(newQty);
  let nonNumericValueloclisationData = i18next.t("qtyGrid.sizeBreak.inputValidations.nonNumericValue");
  let outOfRangeloclisationData = i18next.t("qtyGrid.sizeBreak.inputValidations.outOfRange");
  if (isNaN(newQty)) {
    return [false, nonNumericValueloclisationData];
  } else if (newQty < 0 || newQty > 99999) {
    return [false, outOfRangeloclisationData];
  } else {
    return [true, null];
  }
};
