import { createSelector } from "reselect";

export const productReleaseReducerSelector = createSelector(
  state => state.get("productReleaseReducer"),
  productReleaseReducer => productReleaseReducer
);

export const isLoadingSelector = createSelector(
  productReleaseReducerSelector,
  productReleaseReducer => productReleaseReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  productReleaseReducerSelector,
  productReleaseReducer => productReleaseReducer.get("hasErrors")
);

export const errorSelector = createSelector(
  productReleaseReducerSelector,
  productReleaseReducer => productReleaseReducer.get("error")
);

export const productReleaseDataSelector = createSelector(
  productReleaseReducerSelector,
  productReleaseReducer => productReleaseReducer.get("data")
);

export const productReleaseSelector = createSelector(
  productReleaseDataSelector,
  productReleaseData => productReleaseData.get("product_release")
);

export const deliveryMonthsSelector = createSelector(
  productReleaseSelector,
  productRelease => productRelease.get("delivery_months")
);

export const productReleaseAbilitiesSelector = createSelector(
  productReleaseDataSelector,
  productReleaseData => productReleaseData.get("abilities")
);

export const productReleaseNotesSelector = createSelector(
  productReleaseDataSelector,
  productReleaseData => productReleaseData.get("my_notes")
);

export const agentNotesSelector = createSelector(
  productReleaseDataSelector,
  productReleaseData => productReleaseData.get("agent_notes")
);

export const productSelector = createSelector(
  productReleaseDataSelector,
  productReleaseData => productReleaseData.get("product")
);

export const productImagesSelector = createSelector(productSelector, product =>
  product.get("images")
);

export const productSpecificationsSelector = createSelector(
  productSelector,
  product => product.get("specifications")
);

export const productChangesSelector = createSelector(productSelector, product =>
  product.get("changes")
);

export const buyplanItemIdSelector = createSelector(
  productReleaseDataSelector,
  productReleaseData => productReleaseData.getIn(["buyplan_item", "id"])
);

export const intendedMonthsSelector = createSelector(
  productReleaseDataSelector,
  productReleaseData =>
    productReleaseData.getIn(["buyplan_item", "intended_months"])
);

export const filledMonthsSelector = createSelector(
  productReleaseDataSelector,
  productReleaseData =>
    productReleaseData.getIn(["buyplan_item", "filled_months"])
);

export const preOrderIntendedMonthsSelector = createSelector(
  productReleaseDataSelector,
  productReleaseData => productReleaseData.getIn(["buyplan_item", "pre_order_intended_months"])
);

export const preOrderFilledMonthsSelector = createSelector(
  productReleaseDataSelector,
  productReleaseData => productReleaseData.getIn(["buyplan_item", "pre_order_filled_months"])
);
