export const overwriteShortUrlActions = {
  startLoader: "START_OVERWRITE_SHORT_URL_LOADER",
  request: "REQUEST_OVERWRITE_SHORT_URL",
  success: "OVERWRITE_SHORT_URL_SUCCESS",
  failure: "OVERWRITE_SHORT_URL_FAILURE",
  reset: "RESET_OVERWRITE_SHORT_URL",
};

export const overwriteShortUrl = (payload) => {
  return {
    type: overwriteShortUrlActions.request,
    payload,
  };
};

export const overwriteShortUrlSuccess = (payload) => {
  return {
    type: overwriteShortUrlActions.success,
    payload,
  };
};

export const overwriteShortUrlFailure = (errorPayload) => {
  return {
    type: overwriteShortUrlActions.failure,
    payload: errorPayload,
  };
};

export const resetShortUrl = () => {
  return { type: overwriteShortUrlActions.reset };
};
