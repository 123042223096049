import { createSelector } from "reselect";

export const overwriteShortUrlReducerSelector = createSelector(
  state => state.get("overwriteShortUrlReducer"),
  overwriteShortUrlReducer => overwriteShortUrlReducer
);

export const isLoadingSelector = createSelector(
  overwriteShortUrlReducerSelector,
  overwriteShortUrlReducer => overwriteShortUrlReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  overwriteShortUrlReducerSelector,
  overwriteShortUrlReducer => overwriteShortUrlReducer.get("hasErrors")
);

export const overwriteShortUrlDataSelector = createSelector(
  overwriteShortUrlReducerSelector,
  overwriteShortUrlReducer => overwriteShortUrlReducer.get("data")
);
