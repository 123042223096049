import { createSelector } from "reselect";

export const orderValidationsReducerSelector = createSelector(
  state => state.get("orderValidationsReducer"),
  orderValidationsReducer => orderValidationsReducer
);

export const isLoadingSelector = createSelector(
  orderValidationsReducerSelector,
  orderValidationsReducer => orderValidationsReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  orderValidationsReducerSelector,
  orderValidationsReducer => orderValidationsReducer.get("hasErrors")
);

export const orderValidationsDataSelector = createSelector(
  orderValidationsReducerSelector,
  orderValidationsReducer => orderValidationsReducer.get("data")
);

export const validationsSelector = createSelector(
  orderValidationsDataSelector,
  orderValidationsData => orderValidationsData.get("validatations")
);

export const warningsSelector = createSelector(
  orderValidationsDataSelector,
  orderValidationsData => orderValidationsData.get("warnings")
);
