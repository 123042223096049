import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { PiTruck } from "react-icons/pi";
import { IconContext } from "react-icons";

const ViewAts = ({
  className,
  productId
}) => {
  const { url } = useRouteMatch();
  
  const linkTo = `${url}/${productId}/ats-view`;

  return (
    <div className={className}>
      <div className="item-ats">
        <span>
          <Link to={linkTo}>
            <IconContext.Provider value={{ className: "truck-icon" }}>
              <PiTruck fill = "#bdbdbd" size = {23}/>
            </IconContext.Provider>
          </Link>
        </span>
      </div>
    </div>
  );
};

const StyledViewAts = styled(ViewAts)`
  cursor: pointer;
  margin: 0 auto;

  .item-ats {
    background-color: #ffffff;
    position: static;
    border: solid 1.5px #e5e5e5;
    border-radius: 3px;
    color: #c1c1c1;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    width: 28px;
    height: 28px;
    margin-top: 1ex;
  }

  .truck-icon{
    margin-top : 1px;
  }
`;

export default React.memo(StyledViewAts);
