import { createSelector } from "reselect";

export const quickFillBannerInfoReducerSelector = createSelector(
  state => state.get("quickFillBannerInfoReducer"),
  quickFillBannerInfoReducer => quickFillBannerInfoReducer
);

export const isLoadingSelector = createSelector(
  quickFillBannerInfoReducerSelector,
  quickFillBannerInfoReducer => quickFillBannerInfoReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  quickFillBannerInfoReducerSelector,
  quickFillBannerInfoReducer => quickFillBannerInfoReducer.get("hasErrors")
);

export const quickFillBannerInfoDataSelector = createSelector(
  quickFillBannerInfoReducerSelector,
  quickFillBannerInfoReducer => quickFillBannerInfoReducer.get("data")
);
