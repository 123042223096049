import React from "react";
import styled from "styled-components";
import StyledTitle from "./StyledTitle";

const Invoice = ({
  title, addrLine1, addrLine2, suburbTown, state, country, postalCode
}) => {
  return (
    <>
      <StyledTitle>{ title }</StyledTitle>

      <StyledAddress>
        { addrLine1 }
        <br/>
        { addrLine2 }
        <br/>
        { suburbTown }, { state }
        <br/>
        { country }
        <br/>
        { postalCode }
      </StyledAddress>
    </>
  );
};

const StyledAddress = styled.address`
  font-size: 12px;
  font-weight: 400;
  color: var(--bs-dark-black);
`;

export default React.memo(Invoice);
