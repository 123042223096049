import React from "react";
import PriceText from "components/PriceText";
const DiscountedDisplay = ({
  pbDiscounted,
  discounted,
  wholesaleRange,
  discountedDisplayWholesaleRange,
  discountedDisplayWholesalePrice,
  abilities,
  wholesalePrice,
  unitWholesalePrice,
  overrideFontSize = null,
  pbPercentOff
}) => {

  return (
    <span className="label-value price_value" style={ overrideFontSize ? { fontSize: overrideFontSize } : {}}>{/* Overriding the font-size in price_value class */}
      {pbDiscounted && discounted ? (
        <>
          <PriceText
            pbDiscounted={pbDiscounted}
            pbPercentOff={pbPercentOff}
            showWsRange={abilities.get("can_view_wholesale_range")}
            showDiscountPercent={abilities.get("show_discount_percent")}
            displayWholesalePrice={wholesalePrice}
            displayWholesaleRange={wholesaleRange}
            discountedDisplayWholesaleRange={discountedDisplayWholesaleRange}
            discountedDisplayWholesalePrice={discountedDisplayWholesalePrice}
            unitWholesalePrice={unitWholesalePrice}
            discountedPriceClass={"c_green"}
          />
        </>
      ) : !pbDiscounted && discounted ? (
        <>
          <del>
            {abilities.get("can_view_wholesale_range")
              ? wholesaleRange
              : wholesalePrice}
          </del>
          &nbsp;
          <span className="c_blue">
            {abilities.get("can_view_wholesale_range")
              ? discountedDisplayWholesaleRange
              : discountedDisplayWholesalePrice}
          </span>
          &nbsp;
          {unitWholesalePrice && `(Ea. ${unitWholesalePrice})`}
        </>
      ) : (
        <>
          <span className="c_light_gray">
            {abilities.get("can_view_wholesale_range")
              ? wholesaleRange
              : wholesalePrice}{" "}
          </span>
          &nbsp;
          {unitWholesalePrice && `(Ea. ${unitWholesalePrice})`}
        </>
      )}
    </span>
  );
};

export default React.memo(DiscountedDisplay);
