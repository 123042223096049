import { takeLatest, put, race, take } from "redux-saga/effects";

import { reOrderBuyplanMonthsActions } from "actions/reOrderBuyplanMonthsActions.js";
import { getBuyplanSummary } from "actions/buyplanSummaryActions.js";

import { PATCH_DATA } from "actions/apiOperations.js";

export const requestReOrderBuyplanMonths = function*(action) {
  const { buyplanId, buyplanItemId, ...params } = action.payload;

  yield put({
    type: PATCH_DATA,
    path: `/api/buyplans/${buyplanId}/items/${buyplanItemId}/update_month.json`,
    actions: reOrderBuyplanMonthsActions,
    params
  });

  const { success, error } = yield race({
    success: take(reOrderBuyplanMonthsActions.success),
    error: take(reOrderBuyplanMonthsActions.failure)
  });

  if (success || error) {
    yield put(getBuyplanSummary(buyplanId));
  }
};

export const watchRequestReOrderBuyplanMonths = function*() {
  yield takeLatest(
    reOrderBuyplanMonthsActions.request,
    requestReOrderBuyplanMonths
  );
};
