import { Map } from "immutable";

import { productReorderActions } from "actions/productReorderActions.js";

const initialState = Map({
  isLoading: true,
  hasErrors: false,
  error: null,
  data: Map({})
});

export default (state = initialState, action) => {
  const { data } = action.payload || {};

  switch (action.type) {
    case productReorderActions.init:
      return state.set("isLoading", true);
    case productReorderActions.success:
      let newSuccessState = state.mergeDeep({
        isLoading: false,
        hasErrors: false,
        error: null
      });

      return newSuccessState.set("data", Map(data));
    case productReorderActions.failure:
      let newErrorState = state.mergeDeep({
        isLoading: false,
        hasErrors: true,
        error: data
      });

      return newErrorState.set("data", initialState.data);
    default:
      return state;
  }
};
