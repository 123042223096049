export const declineOrderActions = {
  startLoader: "START_DECLINE_ORDER_LOADER",
  request: "REQUEST_DECLINE_ORDER",
  success: "DECLINE_ORDER_SUCCESS",
  failure: "DECLINE_ORDER_FAILURE",
  reset: "DECLINE_ORDER_RESET",
};

export const getDeclineOrderRequest = (buyplanId, message) => {
  return {
    type: declineOrderActions.request,
    payload: { buyplanId, message },
  };
};

export const getDeclineOrderSuccess = (payload) => {
  return {
    type: declineOrderActions.success,
    payload,
  };
};

export const getDeclineOrderFailure = (payload) => {
  return {
    type: declineOrderActions.failure,
    payload,
  };
};

export const getDeclineOrderReset = (payload) => {
  return {
    type: declineOrderActions.reset,
    payload,
  };
};
