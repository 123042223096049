import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge } from "reactstrap";
import { IoMdCloseCircle } from "react-icons/io";
import styled from "styled-components";

// utils
import { getShortDate } from "utils.js";

// constants
import { DELIVERY_MONTHS } from "constants.js";

import { applyFilters } from "actions/filterActions.js";

import { appliedDynamicFiltersSelector } from "selectors/filtersSelector.js";

const clearFilter = (dispatch, filterType, filterObj) => {
  dispatch(
    applyFilters({
      others: {
        isChecked: false,
        categoryId: filterType,
        filterId: filterObj.get("name"),
        dataset: {
          name: filterObj.get("name"),
          segment_id: filterObj.get("segment_id"),
          division_id: filterObj.get("division_id"),
          collection_id: filterObj.get("collection_id")
        }
      }
    })
  );
};

const FilterPillContainer = () => {
  const dispatch = useDispatch();
  const appliedFilters = useSelector(appliedDynamicFiltersSelector);
  const filterPills = [];

  appliedFilters.forEach((filters, filterType) => {
    filters.forEach(filterObj => {
      filterPills.push({
        filterType,
        filterObj
      });
    });
  });

  return (
    <div className="filter-pills mb-4 text-nowrap overflow-auto">
      {filterPills.map(filterPill => {
        return (
          <StyledBadge
            color="info"
            pill
            className="mb-1 p-2"
            key={`${filterPill.filterType}-${filterPill.filterObj.get("name")}`}
          >
            <span className="align-middle" style={{ fontSize: "0.9rem" }}>
              {filterPill.filterType === DELIVERY_MONTHS
                ? getShortDate(filterPill.filterObj.get("name"))
                : filterPill.filterObj.get("name")}
            </span>

            <IoMdCloseCircle
              className="ml-1"
              size="2em"
              onClick={() =>
                clearFilter(
                  dispatch,
                  filterPill.filterType,
                  filterPill.filterObj
                )
              }
            />
          </StyledBadge>
        );
      })}
    </div>
  );
};

const StyledBadge = styled(Badge)`
  background-color: #e7f1f8;
  color: #3b90dd;
  margin-right: 10px;
`;

export default React.memo(FilterPillContainer);
