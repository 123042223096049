import React from "react";
import styled from "styled-components";
import PriceText from "components/PriceText";

const Caption = ({
  className,
  name,
  color,
  price,
  hideWholesalePrice,
  retailPriceFormatted,
  sku,
  releaseName,
  displayUnitRetailPrice,
  isDiscounted,
  pbDiscounted,
  effectiveDisplayWholesalePrice,
  discountedDisplayWholesaleRange,
  discountedDisplayWholesalePrice,
  showWsRange,
  displayWholesalePrice,
  displayWholesaleRange,
  pbPercentOff,
  showDiscountPercent
}) => {
  return (
    <div
      className={`
        ${className} caption d-flex flex-column text-center text-truncate
      `}
    >
      <div className="name d-block text-truncate" title={name}>
        {name}
      </div>

      <div className="color text-truncate">{color}</div>

      <div className="sku text-truncate">{sku}</div>

      <div className="release-name text-truncate">{releaseName}</div>

      <div
        className={`price text-truncate ${
          isDiscounted
            ? pbDiscounted
              ? "badge badge-success text-white"
              : "c_blue font-weight-bold"
            : ""
        }`}
      >
        {price ||
          (isDiscounted ? (
            <PriceText
              isDiscounted={isDiscounted}
              pbDiscounted={pbDiscounted}
              pbPercentOff={pbPercentOff}
              showWsRange={showWsRange}
              displayWholesalePrice={displayWholesalePrice}
              displayWholesaleRange={displayWholesaleRange}
              discountedDisplayWholesaleRange={discountedDisplayWholesaleRange}
              discountedDisplayWholesalePrice={discountedDisplayWholesalePrice}
              displayRetailPrice={retailPriceFormatted}
              showWsPrice={showDiscountPercent && pbDiscounted}
              showDiscountPercent={showDiscountPercent}
            />
          ) : (
            <>
              {hideWholesalePrice ? (
                retailPriceFormatted
              ) : (
                <>
                  {showWsRange ? displayWholesaleRange : effectiveDisplayWholesalePrice}
                  &nbsp; ({retailPriceFormatted})
                </>
              )}

              {displayUnitRetailPrice && (
                <>&nbsp;- Ea. R/R {displayUnitRetailPrice}</>
              )}
            </>
          ))}
      </div>
    </div>
  );
};

const StyledCaption = styled(Caption)`
  padding-right: 4px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  line-height: 17px;
  margin-top: 15px;
  color: #414141;
  letter-spacing: 0.2px;

  & > .name,
  .color,
  .sku,
  .price,
  .release-name {
    color: var(--bs-light-grey);
  }

  & > .price.c_blue {
    color: #3a90dd;
  }

  & > .name {
    font-weight: 400;
    letter-spacing: 0.4px;
  }
`;

export default React.memo(StyledCaption);
