import { fromJS } from "immutable";
import i18next from "i18next";
import { quantityGridActions } from "actions/quantityGridActions.js";
import { qtyGridActions } from "actions/StockModal/qtyGridActions.js";
import { buyplanDetailsActions } from "actions/buyplanDetailsActions.js";
import { sizeBreakActions } from "actions/StockModal/sizeBreakActions.js";
import { qtyGridBarcodeScanningActions } from "actions/StockModal/barcodeScanningActions.js";

const initialState = fromJS({
  isLoading: true,
  error: null,
  data: {
    ats: {},
    headers: {},
    size_mins: [],
    attribute_sequence: [],
    brand: {
      is_cumulative_ats: null,
      is_default_ats_zero: null,
      amount_visible_offset: null,
      low_quantity_offset: null
    },
    abilities: {
      can_edit_buyplan: null,
      show_discount_percent: false
    },
    po_dates: {},
    product_release: {
      id: null,
      is_ats_visible: null,
      order_qty_block: null,
      product_requirements: []
    },
    buyplan_item: {
      id: null,
      buyplan_id: null,
      intended_months: [],
      filled_months: [],
      pre_order_intended_months: [],
      pre_order_filled_months: [],
      total_quantity: null,
      total_price: null
    },
    delivery_months: [
      {
        delivery_month_short: null,
        month_date: null,
        index: null,
        attr_rows: {}
      }
    ],
    pre_order_delivery_month:
      {
        delivery_month_short: null,
        month_date: null,
        index: null,
        attr_rows: {}
      },
      is_pre_order_product : null,
      pre_order_message : null,
      pre_order_month_and_date : null,
  },
  scanLogs: [],
  barcodeScanningFailed: false
});

export default (state = initialState, action) => {
  let sizeBreakSize, newQty, sizeBreakKey, isQtyValid, qtyErrMsg;

  switch (action && action.type) {
    case quantityGridActions.startLoader:
    case qtyGridActions.startLoader:
    case qtyGridBarcodeScanningActions.startLoader:
      return state.set("isLoading", true);
    case quantityGridActions.reset:
    case qtyGridActions.reset:
      return initialState;
    case quantityGridActions.updateSuccess:
      return state.setIn(["data"], fromJS(action.payload.data));
    case quantityGridActions.success:
    case qtyGridActions.qtyGridSuccess:
    case qtyGridBarcodeScanningActions.buyplanQtyGridSuccess:
    case qtyGridBarcodeScanningActions.stockModelQtyGridSuccess:
      return state
        .merge({
          isLoading: false,
          error: null
        })
        .set("data", fromJS(action.payload.data));
    case qtyGridBarcodeScanningActions.buyplanQtyGridFailure:
      return state
        .merge({
          isLoading: false,
          error: action.payload.data
        })
        .set("data", initialState.get("data"))
        .set("barcodeScanningFailed", true);
    case quantityGridActions.failure:
    case qtyGridActions.failure:
    case qtyGridBarcodeScanningActions.stockModelQtyGridFailure:
      return state
        .merge({
          isLoading: false,
          error: action.payload.data
        })
        .set("data", initialState.get("data"));
    case quantityGridActions.updateFailure:
    case qtyGridActions.updateQtyFailure:
      return state.set("error", action.payload.data);
    case qtyGridActions.updateQtySuccess:
      return state.mergeIn(
        ["data"],
        fromJS(action.payload.data.stock_model_item_data.qty_grid
      ))
      .mergeIn(
        ["data", "stock_model_item"],
        fromJS({
          total_price: action.payload.data.total_price,
          total_price_formatted: action.payload.data.total_price_formatted,
          total_quantity: action.payload.data.total_quantity
        })
      );
    case buyplanDetailsActions.success:
      return state.mergeIn(
        ["data"],
        fromJS({
          brand: action.payload.data.brand,
          abilities: action.payload.data.abilities
        })
      );
    case sizeBreakActions.locallyUpdateStockQty:
      sizeBreakSize = action.payload.sizeBreakSize;
      newQty = action.payload.newQty;
      sizeBreakKey = action.payload.sizeBreakKey || "";

      const countQty = state.getIn([
        "data",
        "size_breaks",
        sizeBreakKey,
        sizeBreakSize,
        "count_quantity",
        "quantity"
      ]);

      const newFillQuantity = newQty - countQty > 0 ? newQty - countQty : 0;

      [isQtyValid, qtyErrMsg] = validateQty(newQty);

      return state
        .set("error", isQtyValid ? null : qtyErrMsg)
        .setIn(
          [
            "data",
            "size_breaks",
            sizeBreakKey,
            sizeBreakSize,
            "stock_quantity",
            "quantity"
          ],
          isQtyValid ? newQty : ""
        )
        .setIn(
          [
            "data",
            "size_breaks",
            sizeBreakKey,
            sizeBreakSize,
            "fill_quantity",
            "quantity"
          ],
          isQtyValid ? newFillQuantity : ""
        );
    case sizeBreakActions.locallyUpdateCountQty:
      sizeBreakSize = action.payload.sizeBreakSize;
      newQty = action.payload.newQty;
      sizeBreakKey = action.payload.sizeBreakKey || "";

      const stockQty = state.getIn([
        "data",
        "size_breaks",
        sizeBreakKey,
        sizeBreakSize,
        "stock_quantity",
        "quantity"
      ]);
      const newFillQty = stockQty - newQty > 0 ? stockQty - newQty : 0;

      [isQtyValid, qtyErrMsg] = validateQty(newQty);

      return state
        .set("error", isQtyValid ? null : qtyErrMsg)
        .setIn(
          [
            "data",
            "size_breaks",
            sizeBreakKey,
            sizeBreakSize,
            "count_quantity",
            "quantity"
          ],
          isQtyValid ? newQty : ""
        )
        .setIn(
          [
            "data",
            "size_breaks",
            sizeBreakKey,
            sizeBreakSize,
            "fill_quantity",
            "quantity"
          ],
          isQtyValid ? newFillQty : ""
        );
    case sizeBreakActions.locallyUpdateFillQty:
      sizeBreakSize = action.payload.sizeBreakSize;
      newQty = action.payload.newQty;
      sizeBreakKey = action.payload.sizeBreakKey || "";

      const countQuantity = state.getIn([
        "data",
        "size_breaks",
        sizeBreakKey,
        sizeBreakSize,
        "count_quantity",
        "quantity"
      ]);

      const newStockQty = newQty + countQuantity;

      [isQtyValid, qtyErrMsg] = validateQty(newQty);

      return state
        .set("error", isQtyValid ? null : qtyErrMsg)
        .setIn(
          [
            "data",
            "size_breaks",
            sizeBreakKey,
            sizeBreakSize,
            "stock_quantity",
            "quantity"
          ],
          isQtyValid ? newStockQty : ""
        )
        .setIn(
          [
            "data",
            "size_breaks",
            sizeBreakKey,
            sizeBreakSize,
            "fill_quantity",
            "quantity"
          ],
          isQtyValid ? newQty : ""
        );
    case qtyGridBarcodeScanningActions.storeScanLogs:
      const updatedLogs = state.get("scanLogs").merge(action.payload.newLog)
      return state.set("scanLogs", updatedLogs);
    case qtyGridBarcodeScanningActions.resetScanLogs:
      return initialState;
    default:
      return state;
  }
};

const validateQty = (newQty) => {
  newQty = parseInt(newQty);
  let nonNumericValueloclisationData = i18next.t("qtyGrid.sizeBreak.inputValidations.nonNumericValue");
  let outOfRangeloclisationData = i18next.t("qtyGrid.sizeBreak.inputValidations.outOfRange");
  if (isNaN(newQty)) {
    return [false, nonNumericValueloclisationData];
  } else if (newQty < 0 || newQty > 99999) {
    return [false, outOfRangeloclisationData];
  } else {
    return [true, null];
  }
};
