import { createSelector } from "reselect";

export const currentUserReducerSelector = createSelector(
  state => state.get("currentUserReducer"),
  currentUserReducer => currentUserReducer
);

export const isLoadingSelector = createSelector(
  currentUserReducerSelector,
  currentUserReducer => currentUserReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  currentUserReducerSelector,
  currentUserReducer => currentUserReducer.get("hasErrors")
);

export const currentUserDataSelector = createSelector(
  currentUserReducerSelector,
  currentUserReducer => currentUserReducer.get("data")
);
