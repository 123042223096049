import { takeLatest, put, select } from "redux-saga/effects";

import { fetchedDynamicFiltersSelector } from "selectors/filtersSelector.js";

import { productReorderListActions } from "actions/productReorderListActions.js";

import { POST_DATA } from "actions/apiOperations.js";

export const requestProductReorderList = function*(action) {
  const { releaseId, ...params } = action.payload;
  const fetchedDynamicFilters = yield select(fetchedDynamicFiltersSelector);

  if (params.subcategory) {
    params.subcategory = fetchedDynamicFilters
      .getIn([params.category])
      .find(obj => obj.get("name") === params.subcategory);
  }
  yield put({
    type: POST_DATA,
    path: `/api/releases/${releaseId}/reordered_products.json`,
    actions: productReorderListActions,
    params
  });
};
export const watchRequestProductReorderList = function*() {
  yield takeLatest(productReorderListActions.init, requestProductReorderList);
};
