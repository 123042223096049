export const quickFillBannerInfoActions = {
  startLoader: "START_QUICK_FILL_BANNER_INFO_LOADER",
  request: "REQUEST_QUICK_FILL_BANNER_INFO",
  success: "QUICK_FILL_BANNER_INFO_SUCESS",
  failure: "QUICK_FILL_BANNER_INFO_FAILURE",
  reset: "RESET_QUICK_FILL_BANNER_INFO",
};

export const getQuickFillBannerInfo = (brandId) => {
  return {
    type: quickFillBannerInfoActions.request,
    payload: { brandId },
  };
};

export const getQuickFillBannerInfoSuccess = (payload) => {
  return {
    type: quickFillBannerInfoActions.success,
    payload,
  };
};

export const getQuickFillBannerInfoFailure = (errorPayload) => {
  return {
    type: quickFillBannerInfoActions.failure,
    payload: errorPayload,
  };
};
