import React from "react";
import styled from "styled-components";

import { sizeBreakActions } from "actions/sizeBreakActions.js";

const SizeBreakInput = ({
  className,
  inputIndex,
  bpsbId,
  placeholderText,
  isDisabled,
  qty,
  titleText,
  dispatch,
  onSubmitCallback
}) => {
  const handleOnChange = React.useCallback(
    evt => _handleOnChange(evt, dispatch, bpsbId),
    [dispatch, bpsbId]
  );

  const handleOnKeyUp = (evt) => {
    const evt_value = evt.target.value

    if (evt_value > 99999){
      evt.preventDefault()
      evt.stopPropagation()
      evt.target.value = ""
    }
  }

  return (
    <input
      type="text"
      inputmode='numeric'
      pattern='[0-9]*'
      className={`${className}`}
      name={`${inputIndex}_${bpsbId}`}
      placeholder={placeholderText}
      disabled={isDisabled}
      defaultValue={qty || ""}
      title={titleText}
      onKeyUp={handleOnKeyUp}
      onChange={handleOnChange}
      onBlur={onSubmitCallback}
    />
  );
};

const StyledSizeBreakInput = styled(SizeBreakInput)`
  width: 24px;
  height: 22px;
  font-size: 10px;
  padding: 0 1px;
  text-align: center;
  color: #000000;
  font-weight: 400;
  background-color: #f9f9f9;
  box-shadow: none;
  border-radius: 0;
  font-family: "Roboto";
  margin: 0;
  line-height: 20px;
  border: 1px solid #ccc;
  vertical-align: middle;
  display: inline-block;
  cursor: ${props => (props.isDisabled === true ? "not-allowed" : "unset")};
  ${props => props.lowQty && "border: 1px solid var(--low-qty-border-color)"};
  ${props => props.error && "border: 1px solid var(--error-border-color)"};
  ${props =>
    props.qtyExceeded && "border: 2px solid var(--qty-exceeded-border-color)"};

  ::placeholder {
    color: #d5d5d5;
  }

  border-color: ${props => {
    if (props.highlightBox) return "var(--bs-dark-black)";
  }};

  &:focus {
    border-color: var(--bs-dark-black);
  }
`;

const _handleOnChange = (evt, dispatch, bpsbId) => {
  const { value } = evt.target;

  if (isNaN(value)) {
    evt.target.value = "";
    return;
  }

  dispatch({
    type: sizeBreakActions.updateQty,
    payload: {
      bpsbId,
      newQuantity: value
    }
  });
};

export default React.memo(StyledSizeBreakInput);
