import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import "assets/stylesheets/transition.css";

import { getQuickFillBannerInfo } from "actions/quickFillBannerInfoActions.js";
import { getBuyplanInfo } from "actions/buyplanInfoActions.js";

import {
  isLoadingSelector,
  quickFillBannerInfoDataSelector
} from "selectors/quickFillBannerInfoSelector.js";

import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import { buyplanInfoDataSelector } from "selectors/buyplanInfoSelector.js";

import QuickFillBanner from "components/QuickFillBanner/QuickFillBanner.jsx";

const getBannerSkeleton = () => (
  <img
    src="/range_banner_info.svg"
    alt="release banner placeholder"
    className="w-100"
    style={{ minHeight: "215px", marginX: "auto" }}
  />
);

const QuickFillBannerContainer = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const bannerInfoData = useSelector(quickFillBannerInfoDataSelector);
  const buyplanId = useSelector(buyplanIdSelector);
  const buyplanInfo = useSelector(buyplanInfoDataSelector);

  const { brandId } = useParams();

  // get banner info
  useEffect(() => {
    dispatch(getQuickFillBannerInfo(brandId));
  }, [dispatch, brandId]);

  useEffect(() => {
    if (buyplanId) {
      dispatch(getBuyplanInfo(buyplanId));
    }
  }, [dispatch, buyplanId]);

  return (
    <>
      {isLoading && getBannerSkeleton()}

      <CSSTransition
        classNames="fade"
        timeout={1200}
        in={!isLoading}
        unmountOnExit
      >
        <QuickFillBanner
          imgUrl={bannerInfoData.get("banner_url")}
          assignedTo={buyplanInfo.get("assigned_to")}
        />
      </CSSTransition>
    </>
  );
};

export default React.memo(QuickFillBannerContainer);
