import { fromJS } from "immutable";

import { categoryFilterActions } from "actions/categoryFilterActions.js";

const initialState = fromJS({
  level1: null,
  level2: null,
  level3: null
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case categoryFilterActions.applyFilter:
      return state.merge({
        level1: payload["level1"],
        level2: payload["level2"],
        level3: payload["level3"]
      });
    default:
      return state;
  }
};
