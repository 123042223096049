import { fromJS } from "immutable";

import { globalMenuActions } from "actions/globalMenuActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {}
});

export default (state = initialState, action) => {
  switch (action.type) {
    case globalMenuActions.startLoader:
      return state.set("isLoading", true);
    case globalMenuActions.reset:
      return initialState;
    case globalMenuActions.success:
      return state.merge({
        isLoading: false,
        hasErrors: false,
        data: fromJS(action.payload.data)
      });
    case globalMenuActions.failure:
      return state.merge({
        isLoading: false,
        hasErrors: true,
        data: fromJS(action.payload.data)
      });
    default:
      return state;
  }
};
