import { takeLeading, call, cancelled, put } from "redux-saga/effects";

import { authTokenActions, getTokenSuccess } from "actions/authTokenActions.js";
import { getJSON, redirectToLogin } from "apis/apiHelpers";

export default function* watchFetchToken() {
  yield takeLeading(authTokenActions.request, fetchToken);
}

export const fetchToken = function*() {
  const abortController = new AbortController();

  try {
    let rawResponse = yield call(
      getJSON,
      "/api/token",
      null,
      abortController.signal,
      { credentials: "include" }
    );

    if (rawResponse.ok) {
      let apiResponse = yield call([rawResponse, rawResponse.json]);
      let token = apiResponse.data.token;

      yield put(getTokenSuccess(token));
      yield call([sessionStorage, "setItem"], "authToken", token);

      return token;
    } else {
      if (rawResponse.status === 401) {
        yield call(redirectToLogin);
      }

      throw new Error(rawResponse.statusText);
    }
  } finally {
    if (yield cancelled()) {
      // eslint-disable-next-line no-console
      console.debug("Aborting API call for fetchToken");

      abortController.abort();
    }
  }
};
