import { createSelector } from "reselect";

export const stripePaymentsReducerSelector = createSelector(
  state => state.get("stripePaymentsReducer"),
  stripePaymentsReducer => stripePaymentsReducer
);

export const isLoadingSelector = createSelector(
  stripePaymentsReducerSelector,
  stripePaymentsReducer => stripePaymentsReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
    stripePaymentsReducerSelector,
    stripePaymentsReducer => stripePaymentsReducer.get("hasErrors")
);

export const stripePaymentsDataSelector = createSelector(
  stripePaymentsReducerSelector,
  stripePaymentsReducer => stripePaymentsReducer.get("data")
);

export const stripePaymentsErrorSelector = createSelector(
  stripePaymentsReducerSelector,
  stripePaymentsReducer => stripePaymentsReducer.get("error")
);
