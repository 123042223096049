import {
  takeLatest,
  takeEvery,
  put,
  select,
  race,
  take
} from "redux-saga/effects";

import { putJSON } from "apis/apiHelpers.js";
import sharedApiSaga from "sagas/sharedApiSaga.js";

import { ATS_STATUSES } from "constants.js";
import { addNotification } from "notificationStore.js";
import { quantityGridActions } from "actions/quantityGridActions.js";
import { addBuyplanItem, buyplanItemActions } from "actions/likeDislikeActions";
import { getBuyplanSummary } from "actions/buyplanSummaryActions.js";
import { getBuyplanTotals } from "actions/buyplanTotalsActions.js";

import { GET_DATA } from "actions/apiOperations.js";

import {
  appliedFiltersSelector,
  activeTabSelector
} from "selectors/filtersSelector.js";

import { buyplanItemIdSelector } from "selectors/productReleaseSelector.js";
import i18next from "i18next";

export const requestQuantityGrid = function*(action) {
  const {
    buyplanId,
    buyplanItemId,
    productReleaseId,
    ...params
  } = action.payload;

  if (buyplanItemId && !["null", "undefined"].includes(buyplanItemId)) {
    yield put({
      type: GET_DATA,
      path: `/api/buyplans/${buyplanId}/items/${buyplanItemId}/qty_grid.json`,
      actions: quantityGridActions,
      params
    });
  } else {
    yield put(addBuyplanItem(buyplanId, productReleaseId));

    const { success } = yield race({
      success: take(buyplanItemActions.success),
      error: take(buyplanItemActions.failure)
    });

    const newBuyplanItemId = yield select(buyplanItemIdSelector);

    if (success) {
      yield put({
        type: GET_DATA,
        path: `/api/buyplans/${buyplanId}/items/${newBuyplanItemId}/qty_grid.json`,
        actions: quantityGridActions,
        params
      });
    }
  }
};

export const watchRequestQuantityGrid = function*() {
  yield takeLatest(quantityGridActions.request, requestQuantityGrid);
};

export const requestUpdateQuantityGrid = function*(action) {
  const activeTab = yield select(activeTabSelector);
  const shouldFetchSummary = activeTab === "summary";
  const shouldFetchTotals = activeTab === "buyplanDetails";
  const { buyplanId, sizeBreakId, ...params } = action.payload;

  const { apiResponse } = yield* sharedApiSaga(
    putJSON,
    `/api/buyplans/${buyplanId}/size_breaks/${sizeBreakId}/update_quantity.json`,
    {
      success: quantityGridActions.updateSuccess,
      failure: quantityGridActions.updateFailure
    },
    params
  );
  let exceededQtyloclisationData = i18next.t(
    "qtyGrid.sizeBreak.inputValidations.outOfRange"
  );
  let cumulativeOverQtyloclisationData = i18next.t(
    "qtyGrid.sizeBreak.inputValidations.cumulativeExceededQty"
  );
  if (apiResponse) {
    const {
      ats_error_msg: atsStatus,
      product_release: { order_qty_block: orderQtyBlock }
    } = apiResponse.data;

    switch (atsStatus) {
      case ATS_STATUSES.overQty:
        addNotification({
          type: "error",
          message: exceededQtyloclisationData
        });
        break;
      case ATS_STATUSES.orderQtyBlock:
        let orderQtyBlockloclisationData = i18next.t(
          "qtyGrid.sizeBreak.inputValidations.qtyMultiplesMismatch",
          { orderQtyBlock: orderQtyBlock }
        );
        addNotification({
          type: "error",
          message: orderQtyBlockloclisationData
        });
        break;
      case ATS_STATUSES.cumulativeOverQty:
        addNotification({
          type: "error",
          message: cumulativeOverQtyloclisationData
        });
        break;
      default:
      // do nothing
    }

    if (shouldFetchTotals) {
      yield put(getBuyplanTotals(buyplanId));
    }

    if (shouldFetchSummary) {
      const appliedFilters = yield select(appliedFiltersSelector);
      yield put(getBuyplanSummary(buyplanId, appliedFilters));
    }
  }
};

export const watchRequestUpdateQuantityGrid = function*() {
  yield takeEvery(quantityGridActions.requestUpdate, requestUpdateQuantityGrid);
};
