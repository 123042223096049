export const customShippingAddressActions = {
  updateForm: "CUST_SHIPPING_ADDR_UPDATE_FORM"
};

export const updateForm = evt => {
  let name, value;
  if ("label" in evt && "value" in evt) {
    name = "country";
    value = evt.value;
  } else {
    name = evt.target.name.replace("custom_shipping_addr_", "");
    value = evt.target.value;
  }
  return {
    type: customShippingAddressActions.updateForm,
    payload: { name, value }
  };
};
