import { takeLatest, put } from "redux-saga/effects";

import { filterActions } from "actions/filterActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestFilters = function*(action) {
  const { releaseId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/releases/${releaseId}/filters.json`,
    actions: filterActions,
    params
  });
};

export const requestQuickFillFilters = function*(action) {
  const { brandId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/brands/${brandId}/filters.json`,
    actions: filterActions,
    params: {
      ...params,
      metadata: {
        rangeType: "quickFill"
      }
    }
  });
};

export const requestStockModalFilters = function*(action) {
  const { releaseId, stockModalId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/releases/${releaseId}/stock_models/${stockModalId}/filters.json`,
    actions: filterActions,
    params
  });
};

export const watchRequestFilters = function*() {
  yield takeLatest(filterActions.init, requestFilters);
};

export const watchQuickFillRequestFilters = function*() {
  yield takeLatest(
    filterActions.requestQuickFillFilters,
    requestQuickFillFilters
  );
};

export const watchStockModalRequestFilters = function*() {
  yield takeLatest(
    filterActions.requestStockModalFilters,
    requestStockModalFilters
  );
};
