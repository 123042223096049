export const productReleaseActions = {
  init: "INITIATE_PRODUCT_RELEASE_SAGA",
  request: "PRODUCT_RELEASE_REQUEST",
  success: "PRODUCT_RELEASE_SUCCESS",
  failure: "PRODUCT_RELEASE_FAILURE",
  clearStore: "CLEAR_PRODUCT_RELEASE_STORE",
};

export const getProductRelease = (payload) => {
  return {
    type: productReleaseActions.init,
    payload,
  };
};

export const getProductReleaseSuccess = (payload) => {
  return {
    type: productReleaseActions.success,
    payload,
  };
};

export const getProductReleaseFailure = (errorPayload) => {
  return {
    type: productReleaseActions.failure,
    payload: errorPayload,
  };
};

export const clearProductReleaseStore = () => {
  return {
    type: productReleaseActions.clearStore,
  };
};
