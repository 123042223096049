import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { reportInvoiceSelector } from "selectors/buyplanReportSelector";
import i18next from "i18next";
const StyledH6 = styled.h6`
  font-size: 12px;
  font-weight: bold;
  padding-left: 20px;
`;

const PriceBreakdown = () => {
  const invoice = useSelector(reportInvoiceSelector);
  const original_price_formatted = invoice.get("original_price_formatted");
  const category_discount = invoice.get("category_discount");
  const category_discount_formatted = invoice.get(
    "category_discount_formatted"
  );
  const total_price_with_category_discount_formatted = invoice.get(
    "total_price_with_category_discount_formatted"
  );
  const order_discount = invoice.get("order_discount");
  const order_discount_percent = invoice.get("order_discount_percent");
  const order_discount_formatted = invoice.get("order_discount_formatted");
  const total_price_formatted = invoice.get("total_price_formatted");
  const totals_header = invoice.get("totals_header");

  return (
    <table
      className="table month_report table-bordered table-sm"
      style={{ fontSize: "1rem" }}
    >
      <thead
        style={{
          backgroundColor: "var(--bs-dark-navy)",
          color: "var(--body-bg-color)"
        }}
      >
        <tr className="month-header">
          <th className="border-right-0 border-bottom-0"></th>
          <th className="month_report_total border-left-0 border-bottom-0 text-right">
            {totals_header}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th className="border-right-0">
            <StyledH6>{i18next.t("invoice.originalTotal")}</StyledH6>
          </th>
          <th className="month_report_total border-left-0 text-right">
            <StyledH6>{original_price_formatted}</StyledH6>
          </th>
        </tr>
        {category_discount === 0 ? null : (
          <tr>
            <th className="border-right-0">
              <StyledH6>{i18next.t("invoice.categoryDiscount")}</StyledH6>
            </th>
            <th className="month_report_total border-left-0 text-right">
              <StyledH6>{category_discount_formatted}</StyledH6>
            </th>
          </tr>
        )}
        {category_discount === 0 ? null : (
          <tr>
            <th className="border-right-0">
              <StyledH6></StyledH6>
            </th>
            <th className="month_report_total border-left-0 text-right">
              <StyledH6>
                {total_price_with_category_discount_formatted}
              </StyledH6>
            </th>
          </tr>
        )}
        <tr>
          <th className="border-right-0"></th>
          <th className="border-left-0 text-right">
            <StyledH6>&nbsp;</StyledH6>
          </th>
        </tr>
        {order_discount === 0 ? null : (
          <tr>
            <th className="border-right-0">
              <StyledH6>
                {i18next.t("invoice.orderDiscount", {
                  value: order_discount_percent
                })}
              </StyledH6>
            </th>
            <th className="month_report_total border-left-0 text-right">
              <StyledH6>{order_discount_formatted}</StyledH6>
            </th>
          </tr>
        )}
        <tr>
          <th className="border-right-0">
            <StyledH6>{i18next.t("invoice.total")}</StyledH6>
          </th>
          <th className="month_report_total border-left-0 text-right">
            <StyledH6>{total_price_formatted}</StyledH6>
          </th>
        </tr>
      </tbody>
    </table>
  );
};

export default React.memo(PriceBreakdown);
