import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import Select from "react-select";

import { getCurrentUser } from "actions/currentUserActions.js";
import { getWarehouses } from "actions/warehouseActions.js";
import { getBuyplanId } from "actions/buyplanIdActions.js";
import { changeSelectedGroupByFilter } from "actions/groupByProductsActions.js";
import { buyplanRequestQtyGrid } from "actions/StockModal/barcodeScanningActions.js";
import { resetOrderDetails } from "actions/orderDetailsActions.js";

import { currentUserDataSelector } from "selectors/currentUserSelector.js";
import { buyplanIdSelector, buyplanStateSelector } from "selectors/buyplanIdSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";
import {
  activeTabSelector,
  filterSequenceSelector,
  fetchedDynamicFiltersSelector,
  segmentFilterLabelSelector
} from "selectors/filtersSelector.js";
import { selectedGroupByFilterSelector } from "selectors/groupedProductsSelector.js";
import { bannerInfoDataSelector } from "selectors/bannerInfoSelector.js";
import { showBarcodeScanForBpSelector } from "selectors/productsSelector.js";
import ScrollToTop from "components/ScrollToTop.jsx";
import Sidebar from "components/Sidebar/index.jsx";
import TabsList from "components/Tabs/TabsList.jsx";
import SubmitOrderBtn from "components/SubmitOrderBtn.jsx";
import Alert from "components/Alert";

import GlobalMenuContainer from "containers/GlobalMenuContainer";
import ReleaseBannerContainer from "containers/ReleaseBannerContainer.jsx";
import SubmitOrderContainer from "containers/SubmitOrderContainer.jsx";
import BulkProductActionsContainer from "containers/BulkProductActionsContainer.jsx";
import BarcodeScannerContainer from "containers/BarcodeScannerContainer";

import {
  CONSIGNMENT,
  TEMPLATE,
  CATALOGUE_ORDER,
  CONSIGNMENT_REQUEST,
  AGENT_ADMIN
} from "constants.js";
import {
  humanize,
  isStateConsignmentRequest,
  isTemplateStateBuyplan,
  isMarketingRelease
} from "utils.js";

import RangeContainer from "containers/RangeContainer.jsx";
import BuyplanDetailsContainer from "containers/BuyplanDetailsContainer.jsx";
import BuyplanSummaryContainer from "containers/BuyplanSummaryContainer.jsx";
import BuyplanReportContainer from "containers/BuyplanReportContainer.jsx";
import {
  buyplanAwaitingApprovalSelector,
  releaseVisibleToRetailersSelector
} from "selectors/buyplanInfoSelector";
import DeclineOrderBtn from "components/DeclineOrderBtn";
import DeclineOrderDialog from "components/BuyplanReport/DeclineOrderDialog";
import i18next from "i18next";

const detailTabName = (buyplanState, tabsConfigLoclisationData) => {
  switch (buyplanState) {
    case CONSIGNMENT:
      return tabsConfigLoclisationData.consignment;
    case TEMPLATE:
      return tabsConfigLoclisationData.template;
    case CONSIGNMENT_REQUEST:
      return tabsConfigLoclisationData.consignment;
    default:
      return tabsConfigLoclisationData.buyPlan;
  }
};

const tabsConfig = (buyplanState, tabsConfigLoclisationData) => {
  return {
    range: {
      id: "RANGE_PAGE_TAB",
      title: tabsConfigLoclisationData.range,
      body: <RangeContainer />
    },
    buyplanDetails: {
      id: "BUYPLAN_DETAILS_TAB",
      title: detailTabName(buyplanState, tabsConfigLoclisationData),
      body: <BuyplanDetailsContainer />
    },
    summary: {
      id: "SUMMARY",
      title: tabsConfigLoclisationData.summary,
      body: <BuyplanSummaryContainer />
    },
    report: {
      id: "REPORT",
      title: tabsConfigLoclisationData.report,
      body: <BuyplanReportContainer />
    }
  };
};

const getTabsConfig = (
  isAdminUser,
  isAgentUser,
  isSalesRep,
  isSalesStaff,
  isSuperSupplierAdmin,
  isSupplierAdmin,
  forBuyplan,
  buyplanState,
  tabsConfigLoclisationData
) => {
  let pageTabsConfig = tabsConfig(buyplanState, tabsConfigLoclisationData);

  if (buyplanState === CONSIGNMENT || buyplanState === CONSIGNMENT_REQUEST) {
    delete pageTabsConfig["summary"];
  }

  if (
    (isAgentUser ||
      isAdminUser ||
      isSuperSupplierAdmin ||
      isSalesRep ||
      isSalesStaff ||
      isSupplierAdmin) &&
    !forBuyplan
  ) {
    delete pageTabsConfig["buyplanDetails"];
    delete pageTabsConfig["summary"];
    delete pageTabsConfig["report"];

    return { ...pageTabsConfig };
  }

  return { ...pageTabsConfig };
};

const AppLayout = ({ className }) => {
  const [isModalOpen, setModalState] = useState(false);
  const toggleModalFn = useCallback(() => {
    dispatch(resetOrderDetails())
    setModalState(!isModalOpen);
  }, [isModalOpen]);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const buyplanType = queryParams.get("type");
  const currentUser = useSelector(currentUserDataSelector);
  const buyplanState = useSelector(buyplanStateSelector);
  const confirmedBuyplanId = useSelector(buyplanIdSelector);
  const activeTab = useSelector(activeTabSelector);
  const filtersSequence = useSelector(filterSequenceSelector);
  const segmentFilterLabel = useSelector(segmentFilterLabelSelector);
  const dynamicFilters = useSelector(fetchedDynamicFiltersSelector);
  const selectedGroupByFilter = useSelector(selectedGroupByFilterSelector).toJS();
  const bannerInfoData = useSelector(bannerInfoDataSelector);
  const isAwaitingApproval = useSelector(buyplanAwaitingApprovalSelector);
  const isReleaseVisibleToRetailer = useSelector(releaseVisibleToRetailersSelector);
  const warehouseId = useSelector(warehouseIdSelector);
  const showBarcodeScanForBp = useSelector(showBarcodeScanForBpSelector);
  const isStateConsignment = buyplanType === CONSIGNMENT;
  const isAdminUser = currentUser && currentUser.get("role") === "Brandscope Admin";
  const isAgentUser = currentUser && currentUser.get("role") === AGENT_ADMIN;
  const isSalesRep = currentUser && currentUser.get("role") === "Salesrep";
  const isRetailerEmployee = currentUser && currentUser.get("role") === "Retailer Employee";
  const isSupplierAdmin = currentUser && currentUser.get("role") === "Supplier Admin";
  const isSalesStaff = currentUser && currentUser.get("role") === "Sales Staff";
  const isSuperSupplierAdmin = currentUser && currentUser.get("role") === "Super Supplier Admin";
  const { buyplanId, releaseId } = useParams();
  const forBuyplan = buyplanId ? true : false;
  const releaseStatus = bannerInfoData.get("release_status");
  const isMarketingrelease = isMarketingRelease(releaseStatus);
  const isBpTemplate = isTemplateStateBuyplan(buyplanState)
  const [isDeclineModalOpen, setDeclineModalOpen] = useState(false);
  let tabsConfigLoclisationData = {
    range: i18next.t("tabsConfig.range"),
    summary: i18next.t("tabsConfig.summary"),
    report: i18next.t("tabsConfig.report"),
    consignment: i18next.t("tabsConfig.detailTabName.consignment"),
    template: i18next.t("tabsConfig.detailTabName.template"),
    buyPlan: i18next.t("tabsConfig.buyPlan")
  };
  const toggleDeclineModel = () => {
    setDeclineModalOpen(!isDeclineModalOpen);
  };

  const showSubmitBtn = () => {
    if (isRetailerEmployee || isAdminUser || isBpTemplate || isStateConsignmentRequest(buyplanState))
      return false;
    if (isAgentUser && isMarketingrelease) return false;
    return true;
  };

  const showDeclineOrderBtn = () => {
    return (isAgentUser || isSalesRep || isSuperSupplierAdmin) && isAwaitingApproval;
  };

  const submitHeader = isStateConsignment
    ? i18next.t("buttons.submitRequest")
    : showDeclineOrderBtn()
    ? i18next.t("buttons.approveOrder")
    : i18next.t("buttons.submitOrder");

  function getOptions() {
    let options = [{ label: humanize(CATALOGUE_ORDER), value: CATALOGUE_ORDER }];

    filtersSequence.forEach(key => {
      const val = dynamicFilters.get(key);

      if (val.size > 0) {
        if (key === "filter_tags") {
          options.push({ label: humanize("categories"), value: key });
        } else if (key === "segments") {
          options.push({ label: segmentFilterLabel, value: key });
        } else {
          options.push({ label: humanize(key), value: key });
        }
      }
    });

    return options;
  }

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (forBuyplan) {
      dispatch(getBuyplanId({ releaseId, buyplan_id: buyplanId, type: buyplanType }));
    } else {
      dispatch(getBuyplanId({ releaseId, type: buyplanType }));
    }
  }, [dispatch, forBuyplan, releaseId, buyplanId, buyplanType]);

  useEffect(() => {
    if (
      (isAdminUser || isSalesRep || isSuperSupplierAdmin || isSalesStaff || isSupplierAdmin) &&
      !forBuyplan
    ) {
      dispatch(getWarehouses({ buyplanId: null, release_id: releaseId }));
    } else if (confirmedBuyplanId) {
      dispatch(getWarehouses({ buyplanId: confirmedBuyplanId }));
    }
  }, [
    dispatch,
    isAdminUser,
    isSalesRep,
    isSuperSupplierAdmin,
    isSalesStaff,
    isSupplierAdmin,
    forBuyplan,
    releaseId,
    confirmedBuyplanId
  ]);

  function handleGroupByFilterChange(selectedGroupFilter) {
    dispatch(changeSelectedGroupByFilter(selectedGroupFilter));
  }

  if (
    !confirmedBuyplanId &&
    !isAdminUser &&
    !isAgentUser &&
    !isSuperSupplierAdmin &&
    !isSalesRep &&
    !isSalesStaff &&
    !isSupplierAdmin
  ) {
    return null;
  }

  const styles = {
    option: (style, state) => ({
      ...style,
      backgroundColor: state.isSelected ? "var(--bs-accent-color-main-blue)" : null
    })
  };

  const showVisibilityWarningBanner = (isAgentUser || isSalesRep || isSuperSupplierAdmin) && !isReleaseVisibleToRetailer

  const handleBarcodeSubmit = (barcode) => {
    dispatch(
      buyplanRequestQtyGrid({
        releaseId,
        buyplanId: confirmedBuyplanId,
        warehouseId,
        barcode,
      })
    );
  }

  return (
    <>
      <GlobalMenuContainer />

      <div className={`${className}`}>
        <Sidebar />

        <main className={`main ${activeTab === "report" && "w-100"}`}>
          { showVisibilityWarningBanner &&
            <Alert variant="info"> { i18next.t("retailerVisibility.info") } </Alert>
          }

          <ReleaseBannerContainer />

          <section>
            <div className="btn-wrap float-right pl-3">
              {(activeTab === "range") && showBarcodeScanForBp &&
                <BarcodeScannerContainer
                  isAdminUser={isAdminUser}
                  isTemplate={isBpTemplate}
                  isAgentAdmin={isAgentUser}
                  isMarketingrelease={isMarketingrelease}
                  handleBarcodeSubmit={handleBarcodeSubmit}
                  isStockModel={false}
                />
              }
              {showDeclineOrderBtn() && (
                  <DeclineOrderBtn
                    type="button"
                    className="btn btn-danger"
                    onClick={toggleDeclineModel}
                  >
                    {i18next.t("buttons.declineOrder")}
                  </DeclineOrderBtn>

              )}

              {showSubmitBtn() && (
                  <SubmitOrderBtn type="button" className="btn btn-success" onClick={toggleModalFn}>
                    {submitHeader}
                  </SubmitOrderBtn>
              )}
            </div>

            <BulkProductActionsContainer />

            <StyledGroupByFilter className="float-right d-flex filter-size">
              {activeTab === "range" && (
                <>
                  <div className="float-left header flex-grow-1">
                    <p> {`${i18next.t("general.tellStoryBy")}`} </p>
                  </div>

                  <div className="float-right" style={{ minWidth: "40%" }}>
                    <Select
                      options={getOptions()}
                      value={selectedGroupByFilter}
                      onChange={handleGroupByFilterChange}
                      styles={styles}
                      placeholder={i18next.t("select.default")}
                      noOptionsMessage={() => i18next.t("select.noOptions")}
                    />
                  </div>
                </>
              )}
            </StyledGroupByFilter>

            <TabsList
              tabsConfig={getTabsConfig(
                isAdminUser,
                isAgentUser,
                isSalesRep,
                isSalesStaff,
                isSuperSupplierAdmin,
                isSupplierAdmin,
                forBuyplan,
                buyplanState,
                tabsConfigLoclisationData
              )}
            />
          </section>

          {!isAdminUser && (
            <SubmitOrderContainer isOpen={isModalOpen} closeModalCallback={toggleModalFn} />
          )}

          {confirmedBuyplanId && showDeclineOrderBtn() && (
            <DeclineOrderDialog
              isOpen={isDeclineModalOpen}
              closeModalCallback={toggleDeclineModel}
              buyplanId={confirmedBuyplanId}
            />
          )}
        </main>

        <ScrollToTop />
      </div>
    </>
  );
};

const StyledAppLayout = styled(AppLayout)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 20px 15px 0;

  @media (max-width: 575.98px) {
    flex-direction: column;
  }

  .main {
    width: var(--main-width);
    padding-left: 15px;

    @media (max-width: 575.98px) {
      width: 100%;
      padding-left: 0;
    }
  }

  .btn-wrap {
    padding: 11.5px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    @media (max-width: 575.98px) {
      float: none !important;
      margin-bottom: -1px;
      text-align: right;
    }
  }

  .release-banner {
    margin: 0 0 10px;
  }
`;

const StyledGroupByFilter = styled.div`
  padding-top: 7px;
  border-top: 1px solid rgb(221, 221, 221);
  border-bottom: 1px solid rgb(221, 221, 221);
  min-height: 55px;

  display: flex;
  width: 22%;

  & > .header {
    padding-top: 10px;
    padding-right: 10px;
  }
`;

export default React.memo(StyledAppLayout);
