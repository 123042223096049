export const buyplanReportActions = {
  startLoader: "START_BUYPLAN_REPORT_LOADER",
  request: "REQUEST_BUYPLAN_REPORT",
  requestStockModalReport: "REQUEST_STOCK_MODAL_BUYPLAN_REPORT",
  success: "BUYPLAN_REPORT_SUCCESS",
  failure: "BUYPLAN_REPORT_FAILURE",
};

export const getBuyplanReport = (buyplanId) => {
  return {
    type: buyplanReportActions.request,
    payload: {
      buyplanId,
    },
  };
};

export const getStockModalBuyplanReport = (stockModalId) => {
  return {
    type: buyplanReportActions.requestStockModalReport,
    payload: {
      stockModalId,
    },
  };
};

export const getBuyplanReportSuccess = (payload) => {
  return {
    type: buyplanReportActions.success,
    payload,
  };
};

export const getBuyplanReportFailure = (errorPayload) => {
  return {
    type: buyplanReportActions.failure,
    payload: errorPayload,
  };
};
