import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  quantitiesSelector,
  qtyLabelsSelector,
  sizeBreaksSelector,
  attributeSeqSelector,
  sizeSequencesSelector,
  productReleaseSelector,
  atsSelector,
  lowQtyOffsetSelector
} from "selectors/quantityGridSelector.js";

import Loader from "components/Loader/Loader.jsx";

import styled from "styled-components";

import QtyGridForm from "components/QuantityGrid/QtyGridForm.jsx";
import StockModalQtyGridForm from "components/QuantityGrid/StockModal/QtyGridForm.jsx";
import { stockModelIdSelector } from "selectors/stockModelIdSelector";

const QuantityGrid = ({
  isLoading,
  deliveryMonths,
  attrsList,
  headers,
  sizeMinimums,
  filledMonths,
  intendedMonths,
  qtyGridProductRelease,
  buyplanItemId,
  pre_order_month_and_date,
  is_pre_order_product,
  pre_order_message,
  preOrderIntendedMonths,
  preOrderFilledMonths,
  bpsbToHighlight
}) => {
  let { stockModalItemId } = useParams();
  const confirmedStockModalId = useSelector(stockModelIdSelector);
  stockModalItemId = stockModalItemId || buyplanItemId;
  const quantities = useSelector(quantitiesSelector);
  const qtyLabels = useSelector(qtyLabelsSelector);
  const sizeBreaks = useSelector(sizeBreaksSelector);
  const attributeSeq = useSelector(attributeSeqSelector);
  const sizeSequences = useSelector(sizeSequencesSelector);
  const productRelease = useSelector(productReleaseSelector);
  const stockModalAts = useSelector(atsSelector);
  const lowQtyOffset = useSelector(lowQtyOffsetSelector);

  if (isLoading) {
    return <Loader />;
  }

  if (confirmedStockModalId) {
    return (
      <StockModalQtyGridForm
        stockModalItemId={stockModalItemId}
        quantities={quantities}
        qtyLabels={qtyLabels}
        sizeBreaks={sizeBreaks}
        attributeSeq={attributeSeq}
        sizeSequences={sizeSequences}
        sizeMinimums={sizeMinimums}
        productRelease={productRelease}
        ats={stockModalAts}
        lowQtyOffset={lowQtyOffset}
      />
    );
  }
  return (
    <QtyGridForm
      deliveryMonths={deliveryMonths}
      attrsList={attrsList}
      headers={headers}
      sizeMinimums={sizeMinimums}
      filledMonths={filledMonths}
      intendedMonths={intendedMonths}
      qtyGridProductRelease={qtyGridProductRelease}
      pre_order_month_and_date={pre_order_month_and_date}
      is_pre_order_product={is_pre_order_product}
      pre_order_message={pre_order_message}
      preOrderIntendedMonths={preOrderIntendedMonths}
      preOrderFilledMonths={preOrderFilledMonths}
      bpsbToHighlight={bpsbToHighlight}
    />
  );
};

const StyledQuantityGrid = styled(QuantityGrid)`
  @media (max-width: 575.98px) {
    padding-top: 60px;
  }

  .modal-header {
    align-items: center;
  }

  .modal-title {
    font-size: 24.5px;
    line-height: 30px;
    font-weight: 700;

    @media (max-width: 575.98px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export default React.memo(StyledQuantityGrid);
