import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "assets/stylesheets/bootstrap-v4.3.1.min.css";
import "assets/stylesheets/font-awesome-v5.11.2.min.css";
import "notyf/notyf.min.css";
import "assets/stylesheets/App.css";

import ErrorBoundary from "components/ErrorBoundary.jsx";
import Loader from "components/Loader/Loader.jsx";

import AppLayout from "components/layouts/AppLayout.jsx";
import BrandLayout from "components/layouts/BrandLayout.jsx";
import StockModalLayout from "components/layouts/StockModalLayout.jsx";
import DND from "containers/DND";

const App = ({ isLoading, hasErrors, data }) => {
  React.useEffect(() => {
    import("webfontloader").then(WebFont => {
      WebFont.load({
        google: {
          families: ["Roboto:300", "Roboto:400", "Roboto:500", "Roboto:700"]
        }
      });
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  } else if (hasErrors) {
    return <div className="error-msg">{data.message}</div>;
  } else {
    return (
      <ErrorBoundary>
        <Router>
          <Switch>
            <Route path="/releases/:releaseId/products/for/:buyplanId" component={AppLayout} />
            <Route path="/releases/:releaseId/products/DND" component={DND} />
            <Route path="/releases/:releaseId/products" component={AppLayout} />

            <Route path="/brands/:brandId/products/for/:buyplanId" component={BrandLayout} />

            <Route path="/brands/:brandId/products" component={BrandLayout} />

            <Route
              path="/releases/:releaseId/stock_models/:stockModalId"
              component={StockModalLayout}
            />

            <Route path="/releases/:releaseId/stock_model_products" component={StockModalLayout} />
          </Switch>
          {/* </Container> */}
        </Router>
      </ErrorBoundary>
    );
  }
};

export default React.memo(App);
