import { takeLatest, put } from "redux-saga/effects";

import { globalMenuActions } from "actions/globalMenuActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestGlobalMenu = function*() {
  yield put({
    type: GET_DATA,
    path: `/api/megamenu.json`,
    actions: globalMenuActions
  });
};

export const watchRequestGlobalMenu = function*() {
  yield takeLatest(globalMenuActions.request, requestGlobalMenu);
};
