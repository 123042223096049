import { takeLatest, put, race, take, call, select } from "redux-saga/effects";
import { POST_DATA } from "actions/apiOperations.js";
import { declineOrderActions } from "actions/declineOrderActions";
import { addNotification } from "notificationStore";
import { redirectTo } from "apis/apiHelpers";
import { buyplanStateSelector } from "selectors/buyplanIdSelector";

export const requestDeclineOrder = function*(action) {
  const { buyplanId, message } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/buyplans/${buyplanId}/decline_submitted_order.json`,
    actions: declineOrderActions,
    params: { message: message },
  });

  const buyplanState = yield select(buyplanStateSelector);

  const { success, failure } = yield race({
    success: take(declineOrderActions.success),
    failure: take(declineOrderActions.failure),
  });

  if (success) {
    addNotification({ message: success?.payload?.data?.message });
  }
  if (failure) {
    addNotification({ type: "danger", message: failure?.payload?.errors[0] });
  }
  yield call(redirectTo, `/${buyplanState}s`);
};

export const watchRequestDeclineOrder = function*() {
  yield takeLatest(declineOrderActions.request, requestDeclineOrder);
};
