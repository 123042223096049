import { createSelector } from "reselect";

export const listShortUrlsReducerSelector = createSelector(
  state => state.get("listShortUrlsReducer"),
  listShortUrlsReducer => listShortUrlsReducer
);

export const isLoadingSelector = createSelector(
  listShortUrlsReducerSelector,
  listShortUrlsReducer => listShortUrlsReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  listShortUrlsReducerSelector,
  listShortUrlsReducer => listShortUrlsReducer.get("hasErrors")
);

export const listShortUrlsDataSelector = createSelector(
  listShortUrlsReducerSelector,
  listShortUrlsReducer => listShortUrlsReducer.get("data")
);
