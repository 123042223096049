import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { addNotification } from "notificationStore.js";

import { isValidDateForMonth } from "utils.js";

import {
  orderValidationsActions,
  getOrderValidations
} from "actions/orderValidationsActions.js";
import { getPayment } from "actions/stripePaymentActions.js";

import { getOrder, getConsignmentRequest } from "actions/orderActions.js";

import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import { buyplanStateSelector } from "selectors/buyplanInfoSelector.js";
import {
  validationsSelector,
  warningsSelector
} from "selectors/orderValidationsSelector.js";
import { stripePaymentsDataSelector, stripePaymentsErrorSelector, isLoadingSelector } from "selectors/stripePaymentsSelector.js"
import { orderInvoiceSelector, orderBuyplanSelector, isDaiwaBrandSelector } from "selectors/orderDetailsSelector.js";
import { orderDataSelector } from "selectors/orderSelector.js";
import {
  orderBillingAddressSelector,
  selectedShippingAddrIdSelector
} from "selectors/orderDetailsSelector.js";

import { haveValidationsChanged } from "reducers/orderValidationsReducer.js";

import CustomModal from "components/CustomModal/index.jsx";
import V2OrderDialogBody from "components/BuyplanReport/V2/OrderDialogBody.jsx";
import V2OrderDialogFooter from "components/BuyplanReport/V2/OrderDialogFooter.jsx";

import { CONSIGNMENT, PAST_MONTH_QTY_WARNING } from "constants.js";
import { getUpdateBuyplanSalesPerson } from "actions/updateSalesPersonActions";
import { setSalesPersonInOrderDetails } from "actions/orderDetailsActions";
import { setSalesPersonInBuyplanInfo } from "actions/buyplanInfoActions";
import { redirectToURL } from "apis/apiHelpers.js";
import Loader from 'components/Loader/Loader.jsx';
import i18next from "i18next";
import customShippingAddressReducer, { getInitialState } from "reducers/customShippingAddressReducer";
import { updateForm } from "actions/customShippingAddressActions";

let submitOrderParams = null;
let payNowClicked = false;
let haveValidationsPassed = false;
let haveWarningsPassed = false;

/* Note: Always Sync V2 - src/components/BuyplanReport/V2/OrderSummaryDialog.jsx */

const OrderSummaryDialog = ({ className, isOpen, closeModalCallback }) => {
  const dispatch = useDispatch();
  const buyplanId = useSelector(buyplanIdSelector);
  const buyplanState = useSelector(buyplanStateSelector);
  const validations = useSelector(validationsSelector);
  const warnings = useSelector(warningsSelector);
  const buyplan = useSelector(orderBuyplanSelector);
  const orderData = useSelector(orderDataSelector);
  const invoice = useSelector(orderInvoiceSelector);
  const orderNumber = orderData && orderData.get("order_number");
  const orderState = orderData && orderData.get("buyplan_state");
  const pastMonthQtyWarningsRegExp = new RegExp(PAST_MONTH_QTY_WARNING);
  const showShippingMethods = buyplan && buyplan.get("show_shipping_methods");
  const isDaiwaBrand = useSelector(isDaiwaBrandSelector);
  const billingAddress = useSelector(orderBillingAddressSelector);
  const selectedShippingAddrId = useSelector(selectedShippingAddrIdSelector);
  let shippingMethodLoclisationData = i18next.t("shippingMethod.required");
  let dateValidationLoclisationData = i18next.t("dateValidation.fail");
  let consignmentLoclisationData = i18next.t("order.consignment.success");
  let orderSuccessLoclisationData = i18next.t("order.success", { orderNumber: orderNumber });
  const stripePaymentData = useSelector(stripePaymentsDataSelector);
  const shouldRender =  isOpen && (!stripePaymentData.get("redirect_url") && !orderNumber)
  const isStripePaymentLoading = useSelector(isLoadingSelector);
  const depositAmountVal = invoice && invoice.get("minimum_deposit_amount");
  const errorMsgForToastr = useSelector(stripePaymentsErrorSelector);
  const [custPurchaseOrder, setCustPurchaseOrder] = useState(null);
  const onPurchaseOrderBlurCallback = useCallback(evt => {
    setCustPurchaseOrder(evt.target.value);
  }, []);

  const [deliveryInstructions, setdeliveryInstructions] = useState(null);
  const onDeliveryInstructionsBlurCallback = useCallback(evt => {
    setdeliveryInstructions(evt.target.value);
  }, []);

  const [depositAmount, setdepositAmount] = useState(depositAmountVal);
  const onDepositAmountCallback = useCallback(evt => {
    // eslint-disable-next-line no-console
    console.log(evt.target.value)
    setdepositAmount(evt.target.value);
  }, []);
  // eslint-disable-next-line no-console
  console.log(depositAmount)
  const [shippingMethod, setShippingMethod] = useState(null);
  const handleShippingMethodChange = selectedShippingMethod => {
    setShippingMethod(selectedShippingMethod.value);
  };

  const [deliveryDate, setDeliveryDate] = useState(null);
  const handleDeliveryDateChange = selectedDeliveryDate => {
    setDeliveryDate(selectedDeliveryDate);
  };

  const [cancelDate, setCancelDate] = useState(null);
  const handleCancelDateChange = selectedCancelDate => {
    setCancelDate(selectedCancelDate);
  };

  const [netsuiteOrderType, setNetsuiteOrderType] = useState(null);
  const handleNetsuiteOrderTypeChange = selectedNetsuiteOrderType => {
    setNetsuiteOrderType(selectedNetsuiteOrderType.value);
  };

  const [shippingAddress, setShippingAddress] = useState(null);
  const handleShippingAddressChange = selectedShippingAddress => {
    setShippingAddress(selectedShippingAddress.value);
  };

  const [customShippingAddress, dispatchCustomShippingAddress] = useReducer(customShippingAddressReducer, getInitialState());

  const handleSalesPersonChange = selectedSalesPerson => {
    dispatch(getUpdateBuyplanSalesPerson(buyplanId, selectedSalesPerson.value));
    dispatch(
      setSalesPersonInOrderDetails({
        id: selectedSalesPerson.value,
        name: selectedSalesPerson.label
      })
    );
    dispatch(
      setSalesPersonInBuyplanInfo({
        id: selectedSalesPerson.value,
        name: selectedSalesPerson.label
      })
    );
  };

  const [deliveryDates, setDeliveryDates] = useState({});
  const handleDeliveryDatesChange = ({
    target: {
      name,
      value,
      dataset: { month }
    }
  }) => {
    const deliveryMonth = name.match(/[A-Z]{3}[\d]{2}/)?.[0];

    if (value && !isValidDateForMonth(value, month)) {
      addNotification({
        type: "danger",
        message: i18next.t("order.errors.incorrectDeliveryMonth", {
          value: value,
          deliveryMonth: deliveryMonth
        })
      });
    }

    const tmpDeliveryDates = { ...deliveryDates, [deliveryMonth]: value };
    setDeliveryDates(tmpDeliveryDates);
  };

  // eslint-disable-next-line no-eq-null
  if (stripePaymentData && stripePaymentData.get("redirect_url") != null)
  {
    redirectToURL(stripePaymentData.get("redirect_url"));
  }

  const handleCloseModal = useCallback(() => {
    submitOrderParams = null;
    payNowClicked = false;
    haveValidationsPassed = false;
    haveWarningsPassed = false;

    closeModalCallback();
  }, [closeModalCallback]);

  const submitOrderCallback = useCallback((payNow) => () => {
    payNowClicked = payNow;
    // eslint-disable-next-line no-console
    console.log("depositAmount:" + depositAmount)
    submitOrderParams = {
      buyplan: {
        company_id: buyplan.get("company_name"),
        retailer_id: buyplan.get("retailer_name"),
        id: buyplanId,
        buyplan_state: buyplanState,
        order_detail_attributes: {
          order_number: null,
          customer_purchase_order: custPurchaseOrder,
          delivery_instructions: deliveryInstructions,
          deposit_amount: depositAmount,
          shipping_method: shippingMethod,
          netsuite_order_type: netsuiteOrderType,
          shipping_address: shippingAddress,
          delivery_days: deliveryDates,
          delivery_date: deliveryDate?.toDateString(),
          cancel_date: cancelDate?.toDateString(),
          billing_address_id: billingAddress.get("id"),
          shipping_address_id: selectedShippingAddrId,
          total_price: invoice.get('total_price'),
          total_instant_pay_discount_price: invoice.get('total_instant_pay_discount_price'),
          postage_amount: invoice.get('total_postage'),
          total_tax_price_on_pay_now: invoice.get('total_tax_price_on_pay_now'),
          custom_shipping_address: customShippingAddress.toJS()
        }
      }
    };

    if (showShippingMethods) {
      if (!shippingMethod) {
        addNotification({
          type: "danger",
          message: shippingMethodLoclisationData
        });
      } else {
        if (isDaiwaBrand) {
          if (cancelDate && deliveryDate > cancelDate) {
            addNotification({
              type: "danger",
              message: dateValidationLoclisationData
            });
          } else {
            dispatch(getOrderValidations(buyplanId, submitOrderParams));
          }
        } else {
          dispatch(getOrderValidations(buyplanId, submitOrderParams));
        }
      }
    } else {
      dispatch(getOrderValidations(buyplanId, submitOrderParams));
    }
  }, [
    dispatch,
    buyplanId,
    showShippingMethods,
    shippingMethod,
    cancelDate,
    deliveryDate,
    isDaiwaBrand,
    billingAddress,
    buyplan,
    buyplanState,
    custPurchaseOrder,
    deliveryDates,
    deliveryInstructions,
    depositAmount,
    netsuiteOrderType,
    selectedShippingAddrId,
    shippingAddress,
    invoice,
    dateValidationLoclisationData,
    shippingMethodLoclisationData,
    customShippingAddress
  ]);

  useEffect(() => {
    let haveValidationsPassed = false;
    let haveWarningsPassed = false;

    if (haveValidationsChanged(validations)) {
      const failingValidations = validations.filter(validation => {
        return validation.get("status") !== "pass";
      });

      if (failingValidations.size === 0) {
        haveValidationsPassed = true;
      } else {
        failingValidations.forEach(validation => {
          addNotification({
            type: "error",
            message: validation.get("name")
          });
        });
      }
    }

    if (!warnings || warnings.size === 0) {
      haveWarningsPassed = true;
    } else {
      let pastMonthQtyWarnings = 0;

      warnings.forEach(warning => {
        if (pastMonthQtyWarningsRegExp.test(warning)) {
          pastMonthQtyWarnings += 1;
        }

        addNotification({ type: "warning", message: warning });
      });

      if (pastMonthQtyWarnings === warnings.size) {
        haveWarningsPassed = true;
      }
    }

    if (haveValidationsPassed && haveWarningsPassed && !payNowClicked && !orderNumber) {
      if (buyplanState === CONSIGNMENT) {
        dispatch(getConsignmentRequest(buyplanId, submitOrderParams));
      } else {
        dispatch(getOrder(buyplanId, submitOrderParams));
      }
    }

    if (haveValidationsPassed && haveWarningsPassed && payNowClicked && !errorMsgForToastr) {
      if (!stripePaymentData.get("redirect_url") && !isStripePaymentLoading) {
        dispatch(getPayment(submitOrderParams));
      }
    }

    return () => dispatch({ type: orderValidationsActions.reset });
  }, [
    validations,
    warnings,
    dispatch,
    buyplanId,
    buyplan,
    custPurchaseOrder,
    deliveryInstructions,
    depositAmount,
    shippingMethod,
    netsuiteOrderType,
    shippingAddress,
    deliveryDates,
    pastMonthQtyWarningsRegExp,
    buyplanState,
    cancelDate,
    deliveryDate,
    orderNumber,
    errorMsgForToastr,
    isStripePaymentLoading,
    stripePaymentData,
    customShippingAddress
  ]);

  useEffect(() => {
    if (orderNumber) {
      addNotification({
        message: orderSuccessLoclisationData
      });
    }
  }, [orderNumber, orderSuccessLoclisationData]);

  useEffect(() => {
    if (orderState === "consignment_request") {
      addNotification({ message: consignmentLoclisationData });
    }
  }, [orderState, consignmentLoclisationData]);

  if (payNowClicked && haveValidationsPassed && haveWarningsPassed && isStripePaymentLoading) {
    return (
      <CustomModal
          className={className}
          isOpen={isOpen}
          title={i18next.t("order.paymentDetails")}
          body={
            <Loader/>
          }
          closeModalCallback={handleCloseModal}
        />)
  }

  return (
    shouldRender && (
      <CustomModal
        className={className}
        isOpen={isOpen}
        title={i18next.t("order.orderDetails")}
        body={shouldRender &&
          <V2OrderDialogBody
            onPurchaseOrderBlurCallback={onPurchaseOrderBlurCallback}
            onDeliveryInstructionsBlurCallback={
              onDeliveryInstructionsBlurCallback
            }
            onDepositAmountCallback={onDepositAmountCallback}
            handleShippingMethodChange={handleShippingMethodChange}
            handleNetsuiteOrderTypeChange={handleNetsuiteOrderTypeChange}
            handleShippingAddressChange={handleShippingAddressChange}
            handleDeliveryDatesChange={handleDeliveryDatesChange}
            handleDeliveryDateChange={handleDeliveryDateChange}
            handleCancelDateChange={handleCancelDateChange}
            handleSalesPersonChange={handleSalesPersonChange}
            onCustomShippingAddressChangeCallback={(evt) => dispatchCustomShippingAddress(updateForm(evt))}
            deliveryDate={deliveryDate}
            cancelDate={cancelDate}
          />
        }
        footer={
          <V2OrderDialogFooter
            closeModalFn={closeModalCallback}
            onClickCallback={submitOrderCallback}
          />
        }
        closeModalCallback={closeModalCallback}
      />
    )
  );
};

const StyledOrderSummaryDialog = styled(OrderSummaryDialog)`
  min-width: 90%;

  @media (max-width: 575.98px) {
    padding-top: 60px;
  }

  .modal-header {
    align-items: center;
  }

  .modal-title {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.2px;
    font-weight: 500;
    color: var(--bs-dark-navy);

    @media (max-width: 575.98px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .device-info {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 575.98px) {
      .invoice-from,
      .invoice-to {
        width: 100%;
      }
    }
  }

  .invoice-to {
    margin: 0 0 0 50px;

    @media (max-width: 575.98px) {
      margin: 0;
    }
  }

  .modal-footer {
    .btn {
      font-size: 14px;
      line-height: 18px;
      border-radius: 4px;
      padding: 5px 9px;

      &.btn-success {
        background-color: #40bf40;
        border-color: #40bf40;
      }
    }
  }
`;

export default React.memo(StyledOrderSummaryDialog);
