export const sizeBreakActions = {
  addSizeBreak: "ADD_SIZE_BREAK",
  removeSizeBreak: "REMOVE_SIZE_BREAK",
  success: "UPDATE_SIZE_BREAK_SUCCESS",
  failure: "UPDATE_SIZE_BREAK_FAILURE",
  updateQty: "UPDATE_QUANTITY"
};

export const addSizeBreak = (
  buyplanId,
  buyplanItemId,
  productReleaseId,
  sizeBreak,
  isPreOrder = false,
  options = {}
) => {
  const defaultOptions = {
    refreshBuyplanDetails: false,
    refreshBuyplanSummary: false
  };

  return {
    type: sizeBreakActions.addSizeBreak,
    payload: {
      buyplanId,
      buyplanItemId,
      productReleaseId,
      mode: "add",
      month: sizeBreak,
      is_pre_order: isPreOrder,
      options: { ...defaultOptions, ...options }
    }
  };
};

export const removeSizeBreak = (
  buyplanId,
  buyplanItemId,
  productReleaseId,
  sizeBreak,
  isPreOrder = false,
  options = {}
) => {
  const defaultOptions = {
    refreshBuyplanDetails: false,
    refreshBuyplanSummary: false
  };

  return {
    type: sizeBreakActions.removeSizeBreak,
    payload: {
      buyplanId,
      buyplanItemId,
      productReleaseId,
      mode: "remove",
      month: sizeBreak,
      is_pre_order: isPreOrder,
      options: { ...defaultOptions, ...options }
    }
  };
};
