import i18next from "i18next";
import React, { useState } from "react";
import styled from "styled-components";

const SearchForm = ({ className, handleSubmit, searchKeyword }) => {
  const [searchText, setSearchText] = useState("");
  const onChange = evt => setSearchText(evt.target.value);
  const onSubmit = evt => {
    evt.preventDefault();
    handleSubmit(searchText);
  };

  return (
    <form className={`${className} search-form`} onSubmit={onSubmit}>
      <div className="input-group">
        <input
          type="text"
          name="search"
          className="form-control filter-searchbox"
          placeholder={i18next.t("sidebar.searchForm.placeholder")}
          defaultValue={searchKeyword}
          onChange={onChange}
        />
        <button type="submit" className="btn filter-search-btn">
          {i18next.t("sidebar.searchForm.button")}
        </button>
      </div>
    </form>
  );
};

const StyledSearchForm = styled(SearchForm)`
  margin-bottom: 25px;
  margin-right: 1ex;

  .filter-searchbox {
    height: 30px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 300;
    padding: 4px 6px;
    color: var(--bs-light-grey);
    background-color: #f9f9f9;
    border-color: #cccccc;
    border-radius: 0;
  }

  .filter-searchbox::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #d3d3d3;
  }
  .filter-searchbox::-moz-placeholder {
    /* Firefox 19+ */
    color: #d3d3d3;
  }
  .filter-searchbox:-ms-input-placeholder {
    /* IE 10+ */
    color: #d3d3d3;
  }
  .filter-searchbox:-moz-placeholder {
    /* Firefox 18- */
    color: #d3d3d3;
  }

  .filter-searchbox:focus,
  .filter-search-btn:focus {
    box-shadow: none;
  }

  .filter-search-btn {
    background-color: var(--bs-dark-navy);
    border: 1px solid var(--bs-dark-navy);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: white;
    padding: 4px 8px;
    border-radius: 0;
  }
`;

export default React.memo(StyledSearchForm);
