export const updateSalesPersonActions = {
  startLoader: "START_UPDATE_SALES_PERSON_LOADER",
  requestBuyplanSalesPersonUpdate: "REQUEST_BUYPLAN_SALES_PERSON_UPDATE",
  requestStockModelSalesPersonUpdate: "REQUEST_STOCK_MODEL_SALES_PERSON_UPDATE",
  success: "UPDATE_SALES_PERSON_SUCCESS",
  failure: "UPDATE_SALES_PERSON_FAILURE",
};

export const getUpdateBuyplanSalesPerson = (buyplanId, userId) => {
  return {
    type: updateSalesPersonActions.requestBuyplanSalesPersonUpdate,
    payload: { buyplanId, userId },
  };
};

export const getUpdateStockModelSalesPerson = (stockModelId, userId) => {
  return {
    type: updateSalesPersonActions.requestStockModelSalesPersonUpdate,
    payload: { stockModelId, userId },
  };
};

export const getUpdateSalesPersonSuccess = (payload) => {
  return {
    type: updateSalesPersonActions.success,
    payload,
  };
};

export const getUpdateSalesPersonFailure = (payload) => {
  return {
    type: updateSalesPersonActions.failure,
    payload,
  };
};
