import React, { useCallback, useRef } from "react";
import { addNotification } from "notificationStore";
import i18next from "i18next";

const CopyToClipboard = ({ isReadonly, defaultValue }) => {
  const textInput = useRef();

  let loclisationData = i18next.t("shortUrl.copyToClipboard");

  const handleCopyToClipboardAction = useCallback(() => {
    textInput.current.select();
    document.execCommand("Copy");

    addNotification({ message: loclisationData });
  }, [loclisationData]);

  return (
    <div className="d-flex" key={defaultValue}>
      <input
        type="text"
        readOnly={isReadonly}
        className="col pl-0 pr-0"
        defaultValue={defaultValue}
        ref={textInput}
      />

      <button
        type="button"
        className="btn btn-link"
        onClick={handleCopyToClipboardAction}
      >
        <img
          src="/line-icons/clipboard.svg"
          alt="Copy to clipboard"
          height="20"
          width="20"
        />
      </button>
    </div>
  );
};

CopyToClipboard.defaultProps = {
  isReadonly: true
};

export default React.memo(CopyToClipboard);
