import { createSelector } from "reselect";

export const bulkSelectProductsReducerSelector = createSelector(
  state => state.get("bulkSelectProductsReducer"),
  bulkSelectProductsReducer => bulkSelectProductsReducer
);

export const isLoadingSelector = createSelector(
  bulkSelectProductsReducerSelector,
  bulkSelectProductsReducer => bulkSelectProductsReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  bulkSelectProductsReducerSelector,
  bulkSelectProductsReducer => bulkSelectProductsReducer.get("hasErrors")
);

export const dataSelector = createSelector(
  bulkSelectProductsReducerSelector,
  bulkSelectProductsReducer => bulkSelectProductsReducer.get("data")
);
