import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import QuantityGrid from "components/QuantityGrid/index.jsx";

import {
  attributeSeqSelector,
  productReleaseSelector,
  productNameSelector,
  productImgUrlSelector,
  productSkuSelector,
  deliveryMonthsSelector,
  headersSelector,
  sizeMinsSelector,
  preOrderDeliveryMonthSelector,
  isPreOrderProductSelector,
  preOrderMessageSelector,
  buyplanItemSelector,
  bpsbToHighlightSelector
} from "selectors/quantityGridSelector.js";

const BarcodeQtyGridContainer = () => {
  const { stockModalItemId } = useParams();
  const productName = useSelector(productNameSelector);
  const productImgUrl = useSelector(productImgUrlSelector);
  const productSku = useSelector(productSkuSelector);
  const buyplanItem = useSelector(buyplanItemSelector);
  const qtyGridProductRelease = useSelector(productReleaseSelector);
  const deliveryMonths = useSelector(deliveryMonthsSelector);
  const attrsList = useSelector(attributeSeqSelector);
  const headers = useSelector(headersSelector);
  const sizeMinimums = useSelector(sizeMinsSelector);
  const preOrderMonthAndDate = useSelector(preOrderDeliveryMonthSelector);
  const isPreOrderProduct = useSelector(isPreOrderProductSelector);
  const preOrderMessage = useSelector(preOrderMessageSelector);
  const bpsbToHighlight = useSelector(bpsbToHighlightSelector);

  return (
    <div className="d-flex">
      {productName && (
        <div>
          <div>
            <img src={productImgUrl} title="product thumbnail" width="100" />
          </div>
          <label className="mb-0">{productName}</label>
          <div>
            <label className="mr-2 mt-0">SKU: </label>
            {productSku}
          </div>
        </div>
      )}
      <div className="ml-3">
        { productName && <QuantityGrid
          isLoading={false}
          deliveryMonths={deliveryMonths}
          attrsList={attrsList}
          headers={headers}
          sizeMinimums={sizeMinimums}
          filledMonths={buyplanItem.filled_months}
          intendedMonths={buyplanItem.intended_months}
          qtyGridProductRelease={qtyGridProductRelease}
          buyplanItemId={stockModalItemId}
          pre_order_month_and_date={[preOrderMonthAndDate]}
          is_pre_order_product={isPreOrderProduct}
          pre_order_message={preOrderMessage}
          preOrderIntendedMonths={buyplanItem.pre_order_intended_months}
          preOrderFilledMonths={buyplanItem.pre_order_filled_months}
          bpsbToHighlight={bpsbToHighlight}
        />}
      </div>
    </div>
  );
};

export default React.memo(BarcodeQtyGridContainer);
