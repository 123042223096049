import { fromJS } from "immutable";

import { updateSalesPersonActions } from "actions/updateSalesPersonActions";

const initialState = fromJS({
  isLoading: true,
  data: {
    message: null,
  },
  errors: [],
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case updateSalesPersonActions.startLoader:
      return state.set("isLoading", true);
    case updateSalesPersonActions.success:
      return state
        .merge({
          isLoading: false,
          errors: [],
        })
        .set("data", fromJS(payload.data));
    case updateSalesPersonActions.failure:
      return state
        .merge({
          isLoading: false,
          data: { message: null },
        })
        .set("errors", fromJS(payload.errors));
    default:
      return state;
  }
};
