import React from "react";
import { useSelector } from "react-redux";

import { reportDivisionsTotalSelector } from "selectors/buyplanReportSelector.js";

import MonthReportTable from "components/BuyplanReport/MonthReportTable.jsx";
import i18next from "i18next";

const DivisionTotals = () => {
  const divisionsTotals = useSelector(reportDivisionsTotalSelector);

  return (
    <MonthReportTable
      headerText={i18next.t("invoice.divisionTotals")}
      totalsObj={divisionsTotals}
    />
  );
};

export default React.memo(DivisionTotals);
