import i18next from "i18next";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Row, Col, FormGroup, Label } from "reactstrap";

import { brightpearlAddressesSelector } from "selectors/orderDetailsSelector.js";

const getShippingAddressOptions = shippingAddresses => {
  return shippingAddresses.map(shippingAddress => ({
    value: shippingAddress?.[0],
    label: shippingAddress?.[1]
  }));
};

let isFirstLoad = true;

const BrightPearlAddresses = ({ handleShippingAddressChange }) => {
  const shippingAddresses = useSelector(brightpearlAddressesSelector);
  const shippingAddressOptions = useMemo(() => {
    return (
      shippingAddresses && getShippingAddressOptions(shippingAddresses.toJS())
    );
  }, [shippingAddresses]);

  useEffect(() => {
    if (isFirstLoad && Array.isArray(shippingAddressOptions) && shippingAddressOptions.length > 0) {
      handleShippingAddressChange(shippingAddressOptions[0]);
      isFirstLoad = false;
    }
  }, [shippingAddressOptions, handleShippingAddressChange]);

  return (
    <Row form>
      <Col>
        <FormGroup row>
          <Label for="shipping_address" sm={4}>
            {i18next.t("address.shippingAddress")}
          </Label>

          <Col sm={6}>
            <Select
              options={shippingAddressOptions}
              defaultValue={shippingAddressOptions?.[0]}
              name="buyplan[order_detail_attributes][shipping_address]"
              id="shipping_address"
              onChange={handleShippingAddressChange}
              placeholder={i18next.t("select.default")}
              noOptionsMessage={() => i18next.t("select.noOptions")}
            />
          </Col>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default React.memo(BrightPearlAddresses);
