import React from "react";
import styled from "styled-components";

const Alert = ({ variant = "primary", children }) => {
  return (
    <div className={`alert alert-${variant}`}>
      <P>{children}</P>
    </div>
  );
};

const P = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  text-align: center;
`;

export default React.memo(Alert);
