import { createSelector } from "reselect";

export const buyplanIdReducerSelector = createSelector(
  state => state.get("buyplanIdReducer"),
  buyplanIdReducer => buyplanIdReducer
);

export const isLoadingSelector = createSelector(
  buyplanIdReducerSelector,
  buyplanIdReducer => buyplanIdReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  buyplanIdReducerSelector,
  buyplanIdReducer => buyplanIdReducer.get("hasErrors")
);

export const buyplanIdDataSelector = createSelector(
  buyplanIdReducerSelector,
  buyplanIdReducer => buyplanIdReducer.get("data")
);

export const buyplanIdSelector = createSelector(
  buyplanIdDataSelector,
  buyplanIdReducer => buyplanIdReducer.get("id")
);

export const buyplanStateSelector = createSelector(
  buyplanIdDataSelector,
  buyplanIdReducer => buyplanIdReducer.get("state")
);
