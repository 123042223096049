import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";

import { getStockModalOrderDetails } from "actions/orderDetailsActions.js";

import {
  orderBuyplanSelector,
  orderInvoiceSelector,
  isBrightPearlOrderIntegratedSelector,
  isDaiwaBrandSelector,
  salesPersonListSelector,
  salesPersonSelector,
  isBuyplanOwnershipEnforcedSelector,
  poCharacterLimitSelector
} from "selectors/orderDetailsSelector.js";
import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";

import BuyplanTotals from "components/BuyplanDetails/BuyplanTotals.jsx";
import Addresses from "components/BuyplanReport/Addresses.jsx";
import NetsuiteOrderType from "components/BuyplanReport/NetsuiteOrderType.jsx";
import BrightPearlAddresses from "components/BuyplanReport/BrightPearlAddresses.jsx";
import BrightPearlDeliveryDates from "components/BuyplanReport/BrightPearlDeliveryDates.jsx";
import DaiwaOrderInfo from "components/BuyplanReport/DaiwaOrderInfo.jsx";
import SalesPersonSelect from "components/SalesPersonSelect/SalesPersonSelect";
import { getSalesPersonOptions } from "components/SalesPersonSelect/SalesPersonSelect";
import i18next from "i18next";

/* Note: Sync V2 - src/components/BuyplanReport/StockModal/V2/OrderDialogBody.jsx */

const OrderDialogBody = ({
  isChecked = false,
  onPurchaseOrderBlurCallback,
  onDeliveryInstructionsBlurCallback,
  handleShippingMethodChange,
  handleNetsuiteOrderTypeChange,
  handleShippingAddressChange,
  handleDeliveryDatesChange,
  handleDeliveryDateChange,
  handleCancelDateChange,
  handleSalesPersonChange,
  deliveryDate,
  cancelDate,
  onCustomShippingAddressChangeCallback
}) => {
  const dispatch = useDispatch();
  const stockModalId = useSelector(stockModelIdSelector);
  const buyplan = useSelector(orderBuyplanSelector);
  const invoice = useSelector(orderInvoiceSelector);
  const is_discounted = invoice.get("is_discounted");
  const total_postage = invoice.get("total_postage") > 0 ? true : false;
  const isBrightPearlOrderIntegrated = useSelector(
    isBrightPearlOrderIntegratedSelector
  );
  const isDaiwaBrand = useSelector(isDaiwaBrandSelector);

  const salesPersonList = useSelector(salesPersonListSelector);
  const selectedSalesPerson = useSelector(salesPersonSelector);
  const isBuyplanOwnershipEnforced = useSelector(
    isBuyplanOwnershipEnforcedSelector
  );
  const showShippingMethods = buyplan && buyplan.get("show_shipping_methods");
  const shippingMethods = buyplan && buyplan.get("shipping_methods");
  const selectedShippingMethod =
    buyplan && buyplan.get("selected_shipping_method");
  const customerPurchaseOrder =
    buyplan && buyplan.get("customer_purchase_order");
  const deliveryInstructions = buyplan && buyplan.get("delivery_instructions");
  const poCharacterLimit = useSelector(poCharacterLimitSelector);

  const shippingMethodOptions = shippingMethods => {
    return shippingMethods.map(method => ({
      value: method.get("code"),
      label: method.get("label")
    }));
  };

  const defaultShippingMethod = {
    value: selectedShippingMethod?.get("code") || "",
    label: selectedShippingMethod?.get("label") || "Select..."
  };

  const salesPersonOptions = useMemo(() => {
    return salesPersonList && getSalesPersonOptions(salesPersonList);
  }, [salesPersonList]);

  useEffect(() => {
    if (stockModalId) {
      dispatch(getStockModalOrderDetails(stockModalId));
    }
  }, [dispatch, stockModalId]);

  return (
    <>
      <div className="device-info">
        {isBrightPearlOrderIntegrated ? (
          <Row className="w-100">
            <Col md={5}>
              <BrightPearlAddresses
                handleShippingAddressChange={handleShippingAddressChange}
              />
            </Col>

            <Col md={5}>
              <BrightPearlDeliveryDates
                handleDeliveryDatesChange={handleDeliveryDatesChange}
              />
            </Col>
          </Row>
        ) : (
          <Addresses
            isChecked={isChecked}
            customShippingAddressFormHandler={onCustomShippingAddressChangeCallback}
          />
        )}
      </div>

      {isDaiwaBrand ? (
        <DaiwaOrderInfo
          handleShippingMethodChange={handleShippingMethodChange}
          handleNetsuiteOrderTypeChange={handleNetsuiteOrderTypeChange}
          handleDeliveryDateChange={handleDeliveryDateChange}
          handleCancelDateChange={handleCancelDateChange}
          onPurchaseOrderBlurCallback={onPurchaseOrderBlurCallback}
          onDeliveryInstructionsBlurCallback={
            onDeliveryInstructionsBlurCallback
          }
          deliveryDate={deliveryDate}
          cancelDate={cancelDate}
          handleSalesPersonChange={handleSalesPersonChange}
        />
      ) : (
        <OrderInfo className="order-info">
          <Form>
            <Row>
              <Col md={5}>
                <Row form>
                  <Col>
                    <FormGroup row>
                      <Label for="order_number" sm={4}>
                        {i18next.t("general.id")}
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="stock_model[order_detail_attributes][order_number]"
                          id="order_number"
                          disabled="disabled"
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col>
                    <FormGroup row>
                      <Label for="company_id" sm={4}>
                        {i18next.t("general.buyer")}
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="stock_model[company_id]"
                          id="company_id"
                          defaultValue={buyplan.get("company_name")}
                          disabled="disabled"
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col>
                    <FormGroup row>
                      <Label for="retailer_id" sm={4}>
                        {i18next.t("general.retailer")}
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="stock_model[retailer_id]"
                          id="retailer_id"
                          defaultValue={buyplan.get("retailer_name")}
                          disabled="disabled"
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                {salesPersonList &&
                  selectedSalesPerson &&
                  isBuyplanOwnershipEnforced && (
                    <SalesPersonSelect
                      salesPersonOptions={salesPersonOptions}
                      salesPerson={selectedSalesPerson}
                      handleSalesPersonChange={handleSalesPersonChange}
                      name="stock_model[sales_person_id]"
                      id="stock_model_sales_person_id"
                    />
                  )}
              </Col>

              <Col md={5}>
                {showShippingMethods && (
                  <Row form>
                    <Col>
                      <FormGroup row>
                        <Label for="shipping_methods" sm={4}>
                          Shipping Methods
                        </Label>

                        <Col sm={6}>
                          <Select
                            options={shippingMethodOptions(shippingMethods)}
                            name="buyplan[order_detail_attributes][shipping_method]"
                            id="shipping_methods"
                            onChange={handleShippingMethodChange}
                            defaultValue={defaultShippingMethod}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row form>
                  <Col>
                    <FormGroup row>
                      <Label for="customer_purchase_order" sm={4}>
                        {i18next.t("order.purchaseOrder")}
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          name="stock_model[order_detail_attributes][customer_purchase_order]"
                          id="customer_purchase_order"
                          onBlur={onPurchaseOrderBlurCallback}
                          defaultValue={customerPurchaseOrder}
                          maxLength={poCharacterLimit}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                {buyplan.get("show_delivery_instructions") && (
                  <Row form>
                    <Col>
                      <FormGroup row>
                        <Label for="delivery_instructions" sm={4}>
                          {i18next.t("order.deliveryInstructions")}
                        </Label>

                        <Col sm={6}>
                          <Input
                            type="textarea"
                            name="stock_model[order_detail_attributes][delivery_instructions]"
                            id="delivery_instructions"
                            onBlur={onDeliveryInstructionsBlurCallback}
                            rows={5}
                            cols={3}
                            defaultValue={deliveryInstructions}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row form>
                  <Col>
                    <NetsuiteOrderType
                      handleNetsuiteOrderTypeChange={
                        handleNetsuiteOrderTypeChange
                      }
                    />
                  </Col>
                </Row>
              </Col>

              {buyplan.get("order_note") && (
                <Col md={10}>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Label for="order_note" sm={2}>
                          {i18next.t("order.orderNotes")}
                        </Label>

                        <Col lg={9}>
                          <Input
                            type="textarea"
                            name="buyplan[order_detail_attributes][order_notes]"
                            id="order_notes"
                            disabled="disabled"
                            defaultValue={buyplan.get("order_note")}
                            rows={5}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Form>
        </OrderInfo>
      )}

      <StyledBuyplanTotals
        totalPriceLabel={i18next.t("invoice.orderTotal")}
        totalPriceFormatted={invoice.get("total_price_formatted")}
        totalItemsCountLabel={i18next.t("invoice.numberOfItems")}
        totalItemsCount={invoice.get("total_quantity")}
        className="mb-0"
        orderModal={true}
      />

      {(is_discounted || total_postage) && (
        <table className="float-right">
          <tbody>
            <tr>
              <th className="invoice-header pr-4">{i18next.t("invoice.originalTotal")}</th>
              <th>{invoice.get("original_price_formatted")}</th>
            </tr>
            <tr><td>--------------------------------------------------------------</td></tr>
            {is_discounted && (
              <>
                <tr>
                  <th className="invoice-header pr-4">
                    {i18next.t("invoice.orderDiscount", {
                      value: invoice.get("order_discount_percent")
                    })}
                  </th>
                  <th>{`-${invoice.get("order_discount_formatted")}`}</th>
                </tr>
                <tr>
                  <th className="invoice-header pr-4">{i18next.t("invoice.categoryDiscount")}</th>
                  <th>
                    {invoice.get("category_discount") > 0
                      ? `-${invoice.get("category_discount_formatted")}`
                      : invoice.get("category_discount_formatted")}
                  </th>
                </tr>
                <tr><td>--------------------------------------------------------------</td></tr>
                <tr>
                  <th className="invoice-header pr-4">{i18next.t("invoice.totalDiscount")}</th>
                  <th>{invoice.get("total_price_formatted")}</th>
                </tr>
                <tr><td>--------------------------------------------------------------</td></tr>
              </>
            )}
            {total_postage && (
              <>
                <tr>
                  <th className="invoice-header pr-4">{i18next.t("invoice.postageAmount")}</th>
                  <th>{invoice.get("total_postage_formatted")}</th>
                </tr>
                <tr><td>--------------------------------------------------------------</td></tr>
              </>
            )}
            <tr>
              <th className="invoice-header pr-4">{i18next.t("invoice.total")}</th>
              <th>{invoice.get("total_price_after_postage_formatted")}</th>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

const OrderInfo = styled.div`
  border-top: 1px solid #dddddd;
  padding-top: 2ex;

  label,
  .col-form-label,
  .form-control {
    font-size: 13px;
  }
`;

const StyledBuyplanTotals = styled(BuyplanTotals)`
  border-top: 1px solid #dddddd;
  padding-top: 2ex;
`;

export default React.memo(OrderDialogBody);
