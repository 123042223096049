import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

import ImageGalleryContainer from "containers/ImageGalleryContainer.jsx";
import { HiOutlineZoomIn } from "react-icons/hi";
import { IconContext } from "react-icons";

const Zoom = ({
  className,
  productReleaseId,
  buyplanId,
  isThreeSixty,
  tenantId,
  brandScopeTag,
  OrbUrl
}) => {
  let [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <>
      <div
        className={`zoom ${className}`}
        onClick={openModal}
      >
        {isThreeSixty && brandScopeTag && tenantId && (
          <div className='three-sixty-icon'>
            <img src="/360-icon.png" alt="360 icon" width="26" />
          </div>
        )}

        {productReleaseId && (
          <div className="zoom-item-quantity">
            <IconContext.Provider value={{ className: "zoom-icon" }}>
              <HiOutlineZoomIn size={26} />
            </IconContext.Provider>
          </div>
        )}
      </div>

      {isModalOpen && (
        <Modal
          className={`${className} modal-container mw-100 m-0`}
          isOpen={isModalOpen}
          toggle={closeModal}
          backdrop={true}
          size="preview"
        >
          <ModalBody className="w-100 text-center p-0">
            <Button close onClick={closeModal} />
            {isThreeSixty && brandScopeTag && tenantId ? (
              <iframe
                id="surroundingFrame"
                title="Orb 360"
                width="96%"
                height="100%"
                frameBorder="0"
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                src={OrbUrl}
              ></iframe>
            ) : (
              <ImageGalleryContainer
                productReleaseId={productReleaseId}
                buyplanId={buyplanId}
                OrbUrl={OrbUrl}
              />
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

const StyledZoom = styled(Zoom)`
  &.modal-preview {
    @media (max-width: 575.98px) {
      padding-top: 60px;
      height: calc(100vh - 60px);
    }

    .modal-content {
      border-radius: 0;

      @media (max-width: 575.98px) {
        height: calc(100vh - 60px);
      }
    }
  }

  button.close {
    position: absolute;
    top: 13px;
    right: 4px;
    color: #000;
    opacity: 1;

    span {
      display: block;
      font-size: 70px;
      line-height: 40px;
    }
  }

  .modal-body {
    height: 100vh;
    @media (max-width: 575.98px) {
      display: flex;
      align-items: center;
    }

    > img {
      height: 100vh;
      width: auto;
      margin-top: 0;

      @media (max-width: 575.98px) {
        width: 100%;
        height: auto;
      }
    }
  }
  margin-bottom: 1ex;
  cursor: pointer;

  svg {
    width: 1.6em;
    height: auto;
    stroke: #bdbdbd;
  }
  .zoom-icon {
    width: 22px;
    margin-top: 2px;
  }

  .three-sixty-icon {
    margin-top: 1ex;
    margin-bottom: 1ex;
  }

  .zoom-item-quantity, .three-sixty-icon {
    background-color: #ffffff;
    position: static;
    border: solid 1.5px #e5e5e5;
    border-radius: 3px;
    color: #c1c1c1;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    width: 28px;
    height: 28px;
  }
`;

export default React.memo(StyledZoom);
