import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { invoiceCategoryRulesSelector } from "selectors/buyplanReportSelector";

import MonthReportTableHead from "components/BuyplanReport/MonthReportTableHead.jsx";
import i18next from "i18next";

const StyledH6 = styled.h6`
  font-size: 12px;
  font-weight: bold;
  padding-left: 20px;
`;

const PriceBreakdown = () => {
  const invoiceCategoryRules = useSelector(invoiceCategoryRulesSelector);

  return (
    <>
      <table className="table month_report">
        <thead>
          <tr>
            <MonthReportTableHead
              headerText={i18next.t("invoice.categoryDiscounts")}
              shouldAlignCenter={false}
              colSpan={1}
            />
          </tr>
        </thead>
      </table>

      <table
        className="table month_report table-bordered table-sm"
        style={{ fontSize: "1rem" }}
      >
        <thead
          style={{
            backgroundColor: "var(--bs-dark-navy)",
            color: "var(--body-bg-color)"
          }}
        >
          <tr className="month-header">
            <th className="border-right-0 border-bottom-0">
              {i18next.t("invoice.category")}
            </th>
            <th className="border-right-0 border-left-0 border-bottom-0">
              {i18next.t("invoice.originalPrice")}
            </th>
            <th className="border-right-0 border-left-0 border-bottom-0">
              {i18next.t("invoice.discount")}
            </th>
            <th className="border-right-0 border-left-0 border-bottom-0">
              {i18next.t("invoice.discountedAmount")}
            </th>
            <th className="month_report_total border-left-0 border-bottom-0 text-right">
              {i18next.t("invoice.discountedPrice")}
            </th>
          </tr>
        </thead>

        <tbody>
          {invoiceCategoryRules.map((categoryRule, index) => {
            const name = categoryRule.get("name");
            const original_price_formatted = categoryRule.get(
              "original_price_formatted"
            );
            const discount_percent = categoryRule.get("discount_percent");
            const discounted_amount_formatted = categoryRule.get(
              "discounted_amount_formatted"
            );
            const discounted_price_formatted = categoryRule.get(
              "discounted_price_formatted"
            );

            return (
              <tr key={index}>
                <th className="border-right-0">
                  <StyledH6>{name}</StyledH6>
                </th>
                <th className="border-right-0 border-left-0">
                  <StyledH6>{original_price_formatted}</StyledH6>
                </th>
                <th className="border-right-0 border-left-0">
                  <StyledH6>{discount_percent}</StyledH6>
                </th>
                <th className="border-right-0 border-left-0">
                  <StyledH6>{discounted_amount_formatted}</StyledH6>
                </th>
                <th className="month_report_total border-left-0 text-right">
                  <StyledH6>{discounted_price_formatted}</StyledH6>
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default React.memo(PriceBreakdown);
