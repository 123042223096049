import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { getStockModalBuyplanDetails } from "actions/buyplanDetailsActions.js";
import { getStockModalFilters } from "actions/filterActions.js";

import {
  isLoadingSelector,
  buyplanDetailsDataSelector
} from "selectors/buyplanDetailsSelector.js";
import { appliedFiltersSelector } from "selectors/filtersSelector";
import { stockModelCnfTypeSelector } from "selectors/buyplanInfoSelector.js";
import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";

import Loader from "components/Loader/Loader.jsx";
import Thumbnail from "components/ProductCard/Thumbnail/Thumbnail.jsx";
import ProductInfo from "components/BuyplanDetails/ProductInfo.jsx";
import BuyplanTotals from "components/BuyplanDetails/BuyplanTotals.jsx";
import QtyGridForm from "components/QuantityGrid/StockModal/QtyGridForm.jsx";
import { buyplanDetailsAbilitiesSelector } from "selectors/buyplanDetailsSelector";

import { BUYPLAN } from "constants.js";

const BuyplanDetailsContainer = ({ className }) => {
  const dispatch = useDispatch();
  const { releaseId } = useParams();
  const stockModalId = useSelector(stockModelIdSelector);
  const warehouseId = useSelector(warehouseIdSelector);

  const isLoading = useSelector(isLoadingSelector);
  const buyplanDetails = useSelector(buyplanDetailsDataSelector);
  const appliedFilters = useSelector(appliedFiltersSelector);
  const cnfType = useSelector(stockModelCnfTypeSelector);
  const abilities = useSelector(buyplanDetailsAbilitiesSelector);

  useEffect(() => {
    stockModalId &&
      dispatch(
        getStockModalBuyplanDetails(
          stockModalId,
          appliedFilters.toJS(),
          warehouseId
        )
      );
  }, [dispatch, stockModalId, appliedFilters, warehouseId]);

  useEffect(() => {
    if (cnfType) {
      dispatch(getStockModalFilters(releaseId, stockModalId, BUYPLAN));
    }
  }, [dispatch, releaseId, stockModalId, cnfType]);

  if (isLoading) return <Loader />;

  const stockModalItems = buyplanDetails.get("stock_model_items");

  return (
    <>
      {stockModalItems && stockModalItems.size > 0 ? (
        stockModalItems.map(buyplanItem => {
          let preOrderDeliveryMonth = buyplanItem.getIn([
            "qty_grid",
            "pre_order_delivery_month"
          ])
          preOrderDeliveryMonth = preOrderDeliveryMonth ? [preOrderDeliveryMonth] : false
          return (
            <div
              key={buyplanItem.get("id")}
              className={`${className} box-details`}
            >
              <Thumbnail
                buyplanId={stockModalId}
                productReleaseId={buyplanItem.get("product_release_id")}
                imgSrc={buyplanItem.get("primary_image")}
                productCallout={buyplanItem.get("callout_label")}
                calloutBgColor={buyplanItem.get("callout_bg_color")}
                calloutBrColor={buyplanItem.get("callout_br_color")}
                calloutTxtColor={buyplanItem.get("callout_txt_color")}
                calloutIcon={buyplanItem.get("callout_icon")}
              />

              <ProductInfo
                name={buyplanItem.get("name")}
                sku={buyplanItem.get("sku")}
                displayColor={buyplanItem.get("colour_truncated")}
                status={buyplanItem.get("available_status")}
                productSpecification1Key={buyplanItem.get("product_specification_key")}
                productSpecification1Value={buyplanItem.get("product_specification_value")}
                displayUnitRetailPrice={buyplanItem.get(
                  "display_unit_retail_price"
                )}
                unitRetailPrice={buyplanItem.get("unit_retail_price")}
                wholesalePrice={buyplanItem.get("wholesale_price")}
                retailPriceFormatted={buyplanItem.get("retail_price_formatted")}
                totalQty={buyplanItem.get("total_quantity")}
                totalPrice={buyplanItem.get("total_price_formatted")}
                buyplanId={stockModalId}
                releaseId={releaseId}
                buyplanItemId={buyplanItem.get("id")}
                isStockModal={true}
                isDiscounted={buyplanItem.get("is_discounted")}
                pbDiscounted={buyplanItem.get("pb_discounted")}
                discountedDisplayWholesaleRange={buyplanItem.get(
                  "discounted_display_wholesale_range"
                )}
                discountedDisplayWholesalePrice={buyplanItem.get(
                  "discounted_display_wholesale_price"
                )}
                wholesaleRange={buyplanItem.get("wholesale_range")}
                abilities={abilities}
                pbPercentOff={buyplanItem.get("pb_percent_off")}
              />

              <QtyGridForm
                stockModalItemId={buyplanItem.get("id")}
                quantities={buyplanItem.getIn(["qty_grid", "quantities"])}
                qtyLabels={buyplanItem.getIn(["qty_grid", "quantity_labels"])}
                sizeBreaks={buyplanItem.getIn(["qty_grid", "size_breaks"])}
                attributeSeq={buyplanItem.getIn(["qty_grid", "attribute_sequence"])}
                sizeSequences={buyplanItem.getIn(["qty_grid", "size_sequence"])}
                sizeMinimums={buyplanItem.getIn(["qty_grid", "size_mins"])}
                productRelease={buyplanItem.getIn([
                  "qty_grid",
                  "product_release"
                ])}
                shouldFetchTotals={true}
                is_pre_order_product = {buyplanItem.get("is_pre_order_product")}
                pre_order_month_and_date = {preOrderDeliveryMonth}
                pre_order_message = {buyplanItem.get("pre_order_message")}
                bpsbToHighlight={buyplanItem.get("bpsb_to_highlight")}
              />
            </div>
          );
        })
      ) : (
        <p>No items have been added.</p>
      )}

      <BuyplanTotals
        totalPriceFormatted={buyplanDetails.getIn([
          "buyplan",
          "total_price_formatted"
        ])}
        totalItemsCount={buyplanDetails.getIn(["buyplan", "total_items_count"])}
        isBuyplanTab={true}
      />
    </>
  );
};

const StyledBuyplanDetailsContainer = styled(BuyplanDetailsContainer)`
  display: grid;
  grid-template-columns: 110px 14% 1fr;
  grid-column-gap: 20px;

  @media (max-width: 575.98px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-template-columns: unset;
    grid-column-gap: unset;

    .quantity-grid-form {
      padding-top: 16px;
    }
  }

  + .box-details {
    padding: 15px 0 0;
    border-top: 1px dotted #ddd;

    @media (max-width: 575.98px) {
      margin-top: 15px;

      + .buyplan-totals {
        margin-top: 15px;
      }
    }
  }

  .thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 138px;
    border: none;
    overflow: hidden;

    @media (max-width: 575.98px) {
      align-self: flex-start;
      margin-right: 16px;
    }
  }

  .product-info label {
    font-weight: bold;
  }
`;

export default React.memo(StyledBuyplanDetailsContainer);
