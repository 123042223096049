import React from "react";

import Invoice from "components/Invoice/Invoice.jsx";
import CustomShippingAddress from "./CustomShippingAddress";

const InvoiceTo = ({
  title,
  addrLine1,
  addrLine2,
  suburbTown,
  state,
  country,
  postalCode,
  isChecked,
  value,
  setSelectedShippingAddress,
  isCustomAddressInput = false,
  customShippingAddressFormHandler = null
}) => {
  return (
    <div className="invoice-to">
      <input
        type="radio"
        defaultChecked={isChecked}
        name="buyplan[order_detail_attributes][shipping_address_id]"
        value={value || ""}
        onChange={setSelectedShippingAddress}
        style={{ marginRight: "0.5em" }}
      />

      {isCustomAddressInput ? (
        <CustomShippingAddress title={title} onChangeHandler={customShippingAddressFormHandler} />
      ) : (
        <Invoice
          title={title}
          addrLine1={addrLine1}
          addrLine2={addrLine2}
          suburbTown={suburbTown}
          state={state}
          country={country}
          postalCode={postalCode}
        />
      )}
    </div>
  );
};

export default React.memo(InvoiceTo);
