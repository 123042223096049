import React from "react";
import classnames from "classnames";
import { TabContent, Nav, NavItem, NavLink } from "reactstrap";
import styled from "styled-components";

import GenerateLinkForm from "components/MarketingLinks/GenerateLinkForm.jsx";
import ListMarketingLinks from "components/MarketingLinks/ListMarketingLinks.jsx";
import i18next from "i18next";

const ModalBody = ({ className }) => {
  const [activeTab, setActiveTab] = React.useState("create-new-link");

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Nav tabs className={className}>
        {/* Tab 1: Create New Link */}
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "create-new-link" })}
            onClick={() => {
              toggle("create-new-link");
            }}
          >
            {i18next.t("sidebar.marketingLinks.createNewLink")}
          </NavLink>
        </NavItem>

        {/* Tab 2: Overwrite Existing Link */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "overwrite-existing-link"
            })}
            onClick={() => {
              toggle("overwrite-existing-link");
            }}
          >
            {i18next.t("sidebar.marketingLinks.overwriteExistingLink")}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        {activeTab === "create-new-link" ? (
          <GenerateLinkForm />
        ) : (
          <ListMarketingLinks />
        )}
      </TabContent>
    </>
  );
};

const StyledTabsList = styled(ModalBody)`
  .nav-item {
    & > .nav-link {
      border-left-style: none;
      border-top-style: none;
      border-right-style: none;
      border-bottom-style: solid;
      border-bottom-width: 3px;
      margin-right: 1rem;
      cursor: pointer;

      &.active {
        border-bottom-color: #3b90dd;
        color: #495057;
        background-color: #ffffff;
        cursor: not-allowed;
      }

      &:hover {
        border-bottom-color: #3b90dd;
        color: #11171f;
      }
    }
  }
`;

export default React.memo(StyledTabsList);
