import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  warehousesSelector,
  multipleWarehousesSelector,
  isLoadingSelector,
  hasErrorsSelector,
  errorSelector
} from "selectors/warehouseSelector.js";

import { updateWarehouse } from "actions/warehouseActions.js";

import Loader from "components/Loader/Loader.jsx";
import WarehouseFilter from "components/WarehouseFilter/index.jsx";

const WarehouseFilterContainer = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const hasErrors = useSelector(hasErrorsSelector);
  const error = useSelector(errorSelector);
  const multipleWarehouses = useSelector(multipleWarehousesSelector);
  const warehouses = useSelector(warehousesSelector);

  const onChange = selectedOption => {
    dispatch(updateWarehouse({ warehouseId: selectedOption["value"] }));
  };

  if (!multipleWarehouses) {
    return null;
  } else if (isLoading) {
    return <Loader />;
  } else if (hasErrors) {
    return <div className="error-msg">{error.message}</div>;
  } else {
    return <WarehouseFilter warehouses={warehouses} onChange={onChange} />;
  }
};

export default React.memo(WarehouseFilterContainer);
