import React from "react";
import { Form, Input, Label } from "reactstrap";

const ManualScanner = ({barcodeSubmit, showScannerGif, scannerGif, Barcodegif, setScannerMode }) => {

  return (
    <div className="mt-4 my-auto d-flex justify-content-center">
      <Form className="my-auto d-flex flex-wrap align-items-end" onSubmit={barcodeSubmit}>
        <div className="flex-basic mr-3">
          <Label for="manual-barcode" className="w-100 mt-1 d-flex justify-content-start">
            Scan or Enter Barcode:
          </Label>
          <Input
            type="text"
            name="manual-barcode"
            placeholder="Enter barcode"
            autoFocus={true}
            onFocus={showScannerGif}
            onBlur={setScannerMode}
            className="mr-3"
          />
        </div>
        {scannerGif && (
          <img
            className="text-center"
            src={Barcodegif}
            alt="loading..."
            width="80"
          />
        )}
      </Form>
    </div>
  );
};

export default React.memo(ManualScanner);
