import React from "react";

const PriceText = ({
  isDiscounted,
  pbDiscounted,
  pbPercentOff,
  showWsRange,
  displayWholesaleRange,
  displayWholesalePrice,
  discountedDisplayWholesaleRange,
  discountedDisplayWholesalePrice,
  displayRetailPrice,
  unitWholesalePrice,
  discountedPriceClass,
  showWsPrice = true,
  showRetailPrice = true,
  showDiscountPercent = false
}) => {
  const wsPriceOrRange = showWsRange ? displayWholesaleRange : displayWholesalePrice;
  const discountedWsPriceOrRange = showWsRange
    ? discountedDisplayWholesaleRange
    : discountedDisplayWholesalePrice;
  return (
    <>
      {showWsPrice &&
        (isDiscounted || pbDiscounted ? (
          <>
            <del>{wsPriceOrRange}</del>&nbsp;&nbsp;
          </>
        ) : (
          <>{wsPriceOrRange} </>
        ))}
      {(isDiscounted || pbDiscounted) && (
        <span className={discountedPriceClass}>{discountedWsPriceOrRange}</span>
      )}
      {showDiscountPercent && pbDiscounted && pbPercentOff && (
        <span className={discountedPriceClass}> ({pbPercentOff})</span>
      )}
      {showRetailPrice && displayRetailPrice && ` (${displayRetailPrice})`}
      {unitWholesalePrice && ` (Ea. ${unitWholesalePrice})`}
    </>
  );
};

export default React.memo(PriceText);
