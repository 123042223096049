// third party plugins
import React from "react";
import styled from "styled-components";
import { Badge } from "reactstrap";
import { useSelector } from "react-redux";

// utils
import { getShortDate } from "utils.js";

// constants
import { DELIVERY_MONTHS, TOP_HIERRACY_CATEGORIES } from "constants.js";

// selectors
import {
  showSegmentFilterSelector,
  segmentFilterLabelSelector,
  fetchedDynamicFiltersSelector,
  appliedDynamicFiltersSelector
} from "selectors/filtersSelector.js";

import { buyplanStateSelector } from "selectors/buyplanIdSelector.js";

// presentational components
import H5 from "components/H5.js";
import FilterRow from "components/Filters/List/FilterRow.jsx";
import FilterHeaderText from "components/Filters/FilterHeaderText.jsx";
import i18next from "i18next";

const isFilterVisibleFn = (filterName, filterValue, showSegmentFilterFlag) => {
  if (filterName === "segments" && showSegmentFilterFlag === false) {
    return false;
  }

  return filterValue.size === 0 ? false : true;
};

const getFilterDisplayName = (filterName, segmentFilterLabel) => {
  switch (filterName) {
    case "segments":
      return segmentFilterLabel ? segmentFilterLabel : i18next.t("tabsConfig.segments");
    case "filter_tags":
      return "Categories";
    case "genders":
      return "Gender";
    case "category4":
      return "Categories";
    default:
      return filterName.replace("_", " ");
  }
};

const filterValuesList = (
  category,
  filterOptions,
  filterSequence,
  selectedFilters,
  onToggle
) => {
  let visibleFilterOptions = [];

  const selectedSegmentsFilter = selectedFilters.get("segments");
  const segmentNames =
    selectedSegmentsFilter &&
    selectedSegmentsFilter.map(segment => segment.get("name"));

  if (filterSequence.get(0) === category) {
    filterOptions.forEach(filterOption => {
      const isExistingFilterOption = visibleFilterOptions.find(option => {
        return option.get("name") === filterOption.get("name");
      });

      isExistingFilterOption === undefined &&
        visibleFilterOptions.push(filterOption);
    });

    // Only for QuickFill category4 filters, execute this filtering logic
    if (filterSequence.get(0) === "category4") {
      const category3SelectedFilter = selectedFilters.getIn(["category3", 0]);

      if (category3SelectedFilter) {
        visibleFilterOptions = visibleFilterOptions.filter(filterOption => {
          return (
            filterOption.get("parent_id") ===
            category3SelectedFilter.get("name")
          );
        });
      }
    }
  } else {
    const selectedDivisionNamesFilter = selectedFilters.get("divisions");
    const divisionNames =
      selectedDivisionNamesFilter &&
      selectedDivisionNamesFilter.map(division => division.get("name"));

    const selectedCollectionNamesFilter = selectedFilters.get("collections");
    const collectionNames =
      selectedCollectionNamesFilter &&
      selectedCollectionNamesFilter.map(collection => collection.get("name"));

    const selectedCategory4NamesFilter = selectedFilters.get("category4");
    const category4Names =
      selectedCategory4NamesFilter &&
      selectedCategory4NamesFilter.map(category4 => category4.get("name"));

    filterOptions.forEach(filterOption => {
      switch (category) {
        case "category4":
          if (
            segmentNames === undefined ||
            segmentNames.size === 0 ||
            segmentNames.includes(filterOption.get("segment_id"))
          ) {
            const isExistingFilterOption = visibleFilterOptions.find(option => {
              return option.get("name") === filterOption.get("name");
            });

            isExistingFilterOption === undefined &&
              visibleFilterOptions.push(filterOption);
          }

          const category3SelectedFilter = selectedFilters.getIn(["category3", 0]);

          if (category3SelectedFilter) {
            visibleFilterOptions = visibleFilterOptions.filter(filterOption => {
              return (
                filterOption.get("parent_id") ===
                category3SelectedFilter.get("name")
              );
            });
          }

          break;
        case "divisions":
          if (
            segmentNames.size === 0 ||
            segmentNames.includes(filterOption.get("segment_id"))
          ) {
            const isExistingFilterOption = visibleFilterOptions.find(option => {
              return option.get("name") === filterOption.get("name");
            });

            isExistingFilterOption === undefined &&
              visibleFilterOptions.push(filterOption);
          }
          break;
        case "collections":
          if (
            divisionNames.size === 0 ||
            divisionNames.includes(filterOption.get("division_id"))
          ) {
            const isExistingFilterOption = visibleFilterOptions.find(option => {
              return option.get("name") === filterOption.get("name");
            });

            if (segmentNames && segmentNames.size > 0) {
              isExistingFilterOption === undefined &&
                segmentNames.includes(filterOption.get("segment_id")) &&
                visibleFilterOptions.push(filterOption);
            } else {
              isExistingFilterOption === undefined &&
                visibleFilterOptions.push(filterOption);
            }
          }
          break;
        default:
          if (filterOption.get("category4_id")) {
            if (
              category4Names.size === 0 ||
              category4Names.includes(filterOption.get("category4_id"))
            ) {
              const isExistingFilterOption = visibleFilterOptions.find(
                option => {
                  return option.get("name") === filterOption.get("name");
                }
              );

              if (segmentNames && segmentNames.size > 0) {
                isExistingFilterOption === undefined &&
                  segmentNames.includes(filterOption.get("segment_id")) &&
                  visibleFilterOptions.push(filterOption);
              } else {
                isExistingFilterOption === undefined &&
                  visibleFilterOptions.push(filterOption);
              }
            }
          } else if (
            collectionNames?.size === 0 ||
            collectionNames?.includes(filterOption?.get("collection_id"))
          ) {
            const isExistingFilterOption = visibleFilterOptions?.find(
              option => {
                return option?.get("name") === filterOption?.get("name");
              }
            );

            if (
              segmentNames &&
              segmentNames.size > 0 &&
              divisionNames &&
              divisionNames.size > 0
            ) {
              isExistingFilterOption === undefined &&
                segmentNames.includes(filterOption.get("segment_id")) &&
                divisionNames.includes(filterOption.get("division_id")) &&
                visibleFilterOptions.push(filterOption);
            } else if (segmentNames && segmentNames.size > 0) {
              isExistingFilterOption === undefined &&
                segmentNames.includes(filterOption.get("segment_id")) &&
                visibleFilterOptions.push(filterOption);
            } else if (divisionNames && divisionNames.size > 0) {
              isExistingFilterOption === undefined &&
                divisionNames.includes(filterOption.get("division_id")) &&
                visibleFilterOptions.push(filterOption);
            } else {
              isExistingFilterOption === undefined &&
                visibleFilterOptions.push(filterOption);
            }
          }
      }
    });

    if (category === DELIVERY_MONTHS) {
      // sorting delivery months to display in proper order under "Refine By" listing
      visibleFilterOptions.sort(
        (a, b) => a.get('name').localeCompare(b.get('name'))
      )
    }
  }

  return visibleFilterOptions.map((filterValueObj, index) => {
    const filterValue = filterValueObj.get("name");
    const segmentId = filterValueObj.get("segment_id");
    const divisionId = filterValueObj.get("division_id");
    const collectionId = filterValueObj.get("collection_id");

    let isChecked = false;

    if (selectedFilters.get(category)) {
      isChecked = selectedFilters.get(category).find(obj => {
        if (!obj) return false;

        return obj.get("name") === filterValue;
      });
    } else {
      isChecked = false;
    }

    let formattedValue;

    if (category === DELIVERY_MONTHS) {
      formattedValue = getShortDate(filterValue);
    }

    return (
      <FilterRow
        key={`${segmentId}-${divisionId}-${category}-${filterValue}-${index}`}
        filterName={filterValue}
        segment={segmentId}
        division={divisionId}
        collection={collectionId}
        category={category}
        filterValue={filterValue}
        formattedValue={formattedValue}
        isChecked={isChecked}
        onToggle={onToggle}
      />
    );
  });
};

const FiltersList = ({ onToggle, onFilterCategoryReset }) => {
  const dynamicFilters = useSelector(fetchedDynamicFiltersSelector);
  const filterSequence = dynamicFilters.get("filter_sequence");

  const buyplanState = useSelector(buyplanStateSelector);
  const isQuickFill = buyplanState === "qfill";
  const showSegmentFilter = useSelector(showSegmentFilterSelector);
  const segmentFilterLabel = useSelector(segmentFilterLabelSelector);
  const appliedDynamicFilters = useSelector(appliedDynamicFiltersSelector);

  const indexOfCategory = filterSequence.indexOf("filter_tags");
  const indexOfQfCategory = isQuickFill ? filterSequence.indexOf("category4") : -1;
  const indexOfQfSegments = isQuickFill ? filterSequence.indexOf("segments") : -1;

  let categoryIndex = false;
  if (indexOfCategory !== -1) categoryIndex = indexOfCategory;
  if (indexOfQfCategory !== -1) categoryIndex = indexOfQfCategory + 1;
  if (indexOfQfSegments !== -1) categoryIndex = indexOfQfSegments + 1;

  return filterSequence.map((filterName, index) => {
    if (TOP_HIERRACY_CATEGORIES.includes(filterName))
      return null

    const filterValue = dynamicFilters.get(filterName);

    if (isFilterVisibleFn(filterName, filterValue, showSegmentFilter) === false)
      return null;

    const filterDisplayName = getFilterDisplayName(
      filterName,
      segmentFilterLabel
    );

    return (
      <React.Fragment key={filterName}>
        {categoryIndex === index && <H5 className="mt-4 mb-4">{i18next.t("sidebar.refineBy")}</H5>}

        <details open={filterName === "divisions"}>
          <summary>
            <div className="card border-0">
              <div className="card-header border-0 bg-white pl-3 pt-1 pb-1">
                <FilterHeaderText
                  className="d-inline-block text-uppercase"
                  text={filterDisplayName}
                />
              </div>
            </div>
          </summary>

          <div className="filter-item">
            {appliedDynamicFilters.get(filterName) &&
              appliedDynamicFilters.get(filterName).size > 0 && (
                <StyledBadge
                  className="mb-1"
                  pill
                  onClick={onFilterCategoryReset}
                  data-category={filterName}
                >
                  Clear
                </StyledBadge>
              )}

            {filterValuesList(
              filterName,
              filterValue,
              filterSequence,
              appliedDynamicFilters,
              onToggle
            )}
          </div>
        </details>
      </React.Fragment>
    );
  });
};

const StyledBadge = styled(Badge)`
  background-color: #e7f1f8;
  color: #3b90dd;
  margin-top: 10px;
  margin-left: 19px;
  font-size: 0.7rem;
  padding: 0.3rem;
  font-weight: 500;
  cursor: pointer;
`;

export default React.memo(FiltersList);
