import styled from "styled-components";

export default styled.button`
  text-shadow: none;
  filter: none;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  box-shadow: none;
  padding: 4px 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  border-style: initial;
  border: 1px solid
`;
