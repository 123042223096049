import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { removeAllBuyplanItems } from "actions/removeAllBuyplanItemsActions.js";

import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";

import ConfirmationDialog from "components/ConfirmationDialog.jsx";

const ConfirmationDialogContainer = () => {
  const dispatch = useDispatch();
  const buyplanId = useSelector(buyplanIdSelector);
  const acceptCallbackFn = useCallback(() => {
    dispatch(removeAllBuyplanItems(buyplanId));
  }, [dispatch, buyplanId]);

  return (
    <ConfirmationDialog
      className="ml-2"
      btnText="Remove All"
      dialogText="Are you sure?"
      acceptCallback={acceptCallbackFn}
    />
  );
};

export default React.memo(ConfirmationDialogContainer);
