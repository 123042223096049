export const authTokenActions = {
  request: "GET_TOKEN_REQUEST",
  success: "GET_TOKEN_SUCCESS",
  failure: "GET_TOKEN_FAILURE"
};

export const getToken = () => {
  return {
    type: authTokenActions.request
  };
};

export const getTokenSuccess = token => {
  return {
    type: authTokenActions.success,
    payload: { token }
  };
};

export const getTokenFailure = error => {
  let errObj = error instanceof Error ? error : new Error(error);

  return {
    type: authTokenActions.failure,
    payload: { error: errObj }
  };
};
