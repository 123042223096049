export const productReorderListActions = {
  init: "INITIATE_PRODUCT_REORDER_LIST_SAGA",
  request: "PRODUCT_REORDER_LIST_REQUEST",
  success: "PRODUCT_REORDER_LIST_SUCCESS",
  failure: "PRODUCT_REORDER_LIST_FAILURE",
  update: "PRODUCT_REORDER_LIST_UPDATE"
};

export const getProductListReorder = (
  releaseId,
  limit = 10,
  offset = 0,
  filters,
  override = false,
  type = null,
  buyplanId,
  warehouseId,
  category = null,
  subcategory = null
) => {
  return {
    type: productReorderListActions.init,
    payload: {
      releaseId,
      limit,
      offset,
      filters,
      override,
      type,
      buyplan_id: buyplanId,
      warehouse_id: warehouseId,
      category: category,
      subcategory: subcategory
    }
  };
};

export const getProductReorderListSuccess = payload => {
  return {
    type: productReorderListActions.success,
    payload
  };
};

export const getProductReorderListFailure = errorPayload => {
  return {
    type: productReorderListActions.failure,
    payload: errorPayload
  };
};

export const updateProductReorderList = payload => {
  return {
    type: productReorderListActions.update,
    payload
  };
};
