import React from "react";

import BannerInfo from "components/ReleaseBanner/BannerInfo.jsx";
import BuyplanInfo from "components/ReleaseBanner/BuyplanInfo.jsx";

const ReleaseBanner = ({
  imgUrl,
  altText,
  releaseName,
  buyplanName,
  buyplanState,
  createdBy,
  assignedTo,
  retailerName,
  isTemporaryBuyplan,
  currencyCode
}) => {
  return (
    <div className="release-banner text-center">
      <BannerInfo
        imgUrl={imgUrl}
        altText={altText}
        releaseName={releaseName}
        currencyCode={currencyCode}
      />

      {isTemporaryBuyplan || (
        <BuyplanInfo
          name={buyplanName}
          state={buyplanState}
          createdBy={createdBy}
          assignedTo={assignedTo}
          retailerName={retailerName}
        />
      )}
    </div>
  );
};

export default React.memo(ReleaseBanner);

ReleaseBanner.defaultProps = {
  altText: "release banner img"
};
