import { createSelector } from "reselect";

export const warehouseReducerSelector = createSelector(
  state => state.get("warehouseReducer"),
  warehouseReducer => warehouseReducer
);

export const isLoadingSelector = createSelector(
  warehouseReducerSelector,
  warehouseReducer => warehouseReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  warehouseReducerSelector,
  warehouseReducer => warehouseReducer.get("hasErrors")
);

export const errorSelector = createSelector(
  warehouseReducerSelector,
  warehouseReducer => warehouseReducer.get("error")
);

export const warehouseDataSelector = createSelector(
  warehouseReducerSelector,
  warehouseReducer => warehouseReducer.get("data")
);

export const warehouseIdSelector = createSelector(
  warehouseDataSelector,
  warehouseReducer => warehouseReducer.get("warehouse_id")
);

export const multipleWarehousesSelector = createSelector(
  warehouseDataSelector,
  warehouseReducer => warehouseReducer.get("multiple_warehouses")
);

export const warehousesSelector = createSelector(
  warehouseDataSelector,
  warehouseReducer => warehouseReducer.get("warehouses")
);
