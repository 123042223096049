import qs from "qs";

// eslint-disable-next-line no-undef
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const getDefaultHeaders = apiToken => {
  return {
    "Content-Type": "application/json",
    Accept: "application/brandscope.com; version=1",
    Authorization: `Bearer ${apiToken}`
  };
};

const getRequestUrl = reqPath => {
  return apiBaseUrl ? `${apiBaseUrl}${reqPath}` : `/${reqPath}`;
};

export const objToQueryString = data => {
  const options = {
    encode: true,
    arrayFormat: "brackets",
    addQueryPrefix: true
  };

  return qs.stringify(data, options);
};

export const getJSON = (
  reqPath,
  apiToken,
  signal,
  { payload = {}, additionalHeaders = {}, credentials = "include" }
) => {
  let queryString = objToQueryString(payload);

  return fetch(`${getRequestUrl(reqPath)}${queryString}`, {
    method: "GET",
    credentials,
    referrer: "no-referrer",
    redirect: "manual",
    signal,
    headers: new Headers({
      ...getDefaultHeaders(apiToken),
      ...additionalHeaders
    })
  });
};

export const postJSON = (
  reqPath,
  apiToken,
  signal,
  { payload = {}, additionalHeaders = {}, credentials = "include" }
) => {
  return fetch(getRequestUrl(reqPath), {
    method: "POST",
    credentials,
    referrer: "no-referrer",
    redirect: "manual",
    signal,
    headers: new Headers({
      ...getDefaultHeaders(apiToken),
      ...additionalHeaders
    }),
    body: payload instanceof FormData ? payload : JSON.stringify(payload)
  });
};

export const putJSON = (
  reqPath,
  apiToken,
  signal,
  { payload = {}, additionalHeaders = {}, credentials = "include" }
) => {
  return fetch(getRequestUrl(reqPath), {
    method: "PUT",
    credentials,
    referrer: "no-referrer",
    redirect: "manual",
    signal,
    headers: new Headers({
      ...getDefaultHeaders(apiToken),
      ...additionalHeaders
    }),
    body: payload instanceof FormData ? payload : JSON.stringify(payload)
  });
};

export const patchJSON = (
  reqPath,
  apiToken,
  signal,
  { payload = {}, additionalHeaders = {}, credentials = "include" }
) => {
  return fetch(getRequestUrl(reqPath), {
    method: "PATCH",
    credentials,
    referrer: "no-referrer",
    redirect: "manual",
    signal,
    headers: new Headers({
      ...getDefaultHeaders(apiToken),
      ...additionalHeaders
    }),
    body: payload instanceof FormData ? payload : JSON.stringify(payload)
  });
};

export const deleteJSON = (
  reqPath,
  apiToken,
  signal,
  { payload = {}, additionalHeaders = {}, credentials = "include" }
) => {
  return fetch(getRequestUrl(reqPath), {
    method: "DELETE",
    credentials,
    referrer: "no-referrer",
    redirect: "manual",
    signal,
    headers: new Headers({
      ...getDefaultHeaders(apiToken),
      ...additionalHeaders
    }),
    body: payload instanceof FormData ? payload : JSON.stringify(payload)
  });
};

export const redirectTo = relativePath => {
  return window.location.assign(`${apiBaseUrl}${relativePath}`);
};

export const redirectToURL = relativePath => {
  return window.location.assign(`${relativePath}`);
};

export const redirectToLogin = () => {
  const signInPath = "/users/sign_in";

  if (window && window.location) {
    const { pathname: currentPath, search: queryParams } = window.location;

    if (queryParams) {
      return redirectTo(
        `${signInPath}?redirectTo=${currentPath}&queryParams=${queryParams}`
      );
    }

    return redirectTo(`${signInPath}?redirectTo=${currentPath}`);
  }

  return redirectTo(signInPath);
};
