import { combineReducers } from "redux-immutable";

import appReducer from "reducers/appReducer.js";
import currentUserReducer from "reducers/currentUserReducer.js";
import productsReducer from "reducers/productsReducer.js";
import filtersReducer from "reducers/filtersReducer.js";
import productReleaseReducer from "reducers/productReleaseReducer.js";
import quantityGridReducer from "reducers/quantityGridReducer.js";
import buyplanDetailsReducer from "reducers/buyplanDetailsReducer.js";
import buyplanSummaryReducer from "reducers/buyplanSummaryReducer.js";
import buyplanReportReducer from "reducers/buyplanReportReducer.js";
import invalidQtyProductsReducer from "reducers/invalidQtyProductsReducer.js";
import invalidStockModelQtyProductsReducer from "reducers/invalidStockModelQtyProductsReducer.js";
import orderDetailsReducer from "reducers/orderDetailsReducer.js";
import orderValidationsReducer from "reducers/orderValidationsReducer.js";
import orderReducer from "reducers/orderReducer.js";
import bannerInfoReducer from "reducers/bannerInfoReducer.js";
import buyplanInfoReducer from "reducers/buyplanInfoReducer.js";
import quickFillBannerInfoReducer from "reducers/quickFillBannerInfoReducer.js";
import globalMenuReducer from "reducers/globalMenuReducer.js";
import categoryFilterReducer from "reducers/categoryFilterReducer.js";
import warehouseReducer from "reducers/warehouseReducer.js";
import buyplanIdReducer from "reducers/buyplanIdReducer.js";
import stockModelIdReducer from "reducers/stockModelIdReducer.js";
import stockModelCopyReducer from "reducers/stockModelCopyReducer.js";
import groupedProductsReducer from "reducers/groupedProductsReducer.js";
import generateShortUrlReducer from "reducers/generateShortUrlReducer.js";
import listShortUrlsReducer from "reducers/listShortUrlsReducer.js";
import overwriteShortUrlReducer from "reducers/overwriteShortUrlReducer.js";
import bulkSelectProductsReducer from "reducers/bulkSelectProductsReducer.js";
import bulkDeselectProductsReducer from "reducers/bulkDeselectProductsReducer.js";
import pricingDetailsVisibilityReducer from "reducers/pricingDetailsVisibilityReducer.js";
import updateSalesPersonReducer from "./updateSalesPersonReducer";
import declineOrderReducer from "./declineOrderReducer";
import productReorderReducer from "reducers/productReorderReducer.js";
import productReorderListReducer from "reducers/productReorderListReducer";
import stripePaymentsReducer from "./stripePaymentsReducer.js";
import viewAtsReducer from "reducers/viewAtsReducer.js";

export default combineReducers({
  appReducer,
  currentUserReducer,
  productsReducer,
  filtersReducer,
  productReleaseReducer,
  quantityGridReducer,
  buyplanDetailsReducer,
  buyplanSummaryReducer,
  buyplanReportReducer,
  invalidQtyProductsReducer,
  invalidStockModelQtyProductsReducer,
  orderDetailsReducer,
  orderValidationsReducer,
  orderReducer,
  bannerInfoReducer,
  buyplanInfoReducer,
  quickFillBannerInfoReducer,
  globalMenuReducer,
  categoryFilterReducer,
  warehouseReducer,
  buyplanIdReducer,
  stockModelIdReducer,
  stockModelCopyReducer,
  groupedProductsReducer,
  generateShortUrlReducer,
  listShortUrlsReducer,
  overwriteShortUrlReducer,
  bulkSelectProductsReducer,
  bulkDeselectProductsReducer,
  pricingDetailsVisibilityReducer,
  updateSalesPersonReducer,
  declineOrderReducer,
  productReorderReducer,
  productReorderListReducer,
  stripePaymentsReducer,
  viewAtsReducer
});
