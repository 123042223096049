export const TIMEOUT_DURATION = 2000; // in milliseconds
export const MAX_RETRIES = 3;
export const DELAY_BETWEEN_RETRIES = 1000; // in milliseconds
export const CLOUDINARY_BASE_URL = "https://res.cloudinary.com/brandscope/";
export const MONTH_NUM_TO_ABBR_MAPPING = {
  "01": "JAN",
  "1":  "JAN",
  "02": "FEB",
  "2":  "FEB",
  "03": "MAR",
  "3":  "MAR",
  "04": "APR",
  "4":  "APR",
  "05": "MAY",
  "5":  "MAY",
  "06": "JUN",
  "6":  "JUN",
  "07": "JUL",
  "7":  "JUL",
  "08": "AUG",
  "8":  "AUG",
  "09": "SEP",
  "9":  "SEP",
  "10": "OCT",
  "11": "NOV",
  "12": "DEC"
};
export const RANGE = "RANGE";
export const CNF_RANGE = "CNF_RANGE";
export const BUYPLAN = "BUYPLAN";
export const SUMMARY = "SUMMARY";
export const CONSIGNMENT = "consignment";
export const CONSIGNMENT_REQUEST = "consignment_request";
export const DYNAMIC = "dynamic";
export const TEMPLATE = "template";
export const MARKETING = "Marketing";
export const CATALOGUE_ORDER = "range";
export const PER_PAGE = 30;
export const EXCEED_QTY_ERROR =
  "You have entered more Qtys than are available.";
export const PAST_MONTH_QTY_WARNING =
  "Quantities for past months will be set to ZERO";
export const QTY_MULTIPLES_MISMATCH =
  "Item can only be ordered in multiples of";
export const ATS_STATUSES = {
  ok: "ok",
  lowQty: "low_qty",
  soldOut: "sold_out",
  overQty: "over_qty",
  cumulativeOverQty: "cumulative_over_qty",
  orderQtyBlock: "order_qty_blk_mismatch",
  monthMinOrderQty: "month_min_order_qty_mismatch",
  sizeMinOrderQty: "size_min_order_qty_mismatch"
};
export const DELIVERY_MONTHS = "delivery_months";
export const TOP_HIERRACY_CATEGORIES = ["category1",  "category2",  "category3"];

// User roles
export const BRANDSCOPE_ADMIN = "Brandscope Admin";
export const RETAILER_HQ_ADMIN = "Retailer HQAdmin";
export const RETAILER_ADMIN = "Retailer Admin";
export const RETAILER_EMPLOYEE = "Retailer Employee";
export const SUPER_SUPPLIER_ADMIN = "Super Supplier Admin";
export const SUPPLIER_ADMIN = "Supplier Admin";
export const SALES_REP = "Salesrep";
export const SALES_STAFF = "Sales Staff";
export const AGENT_ADMIN = "Agent Admin";
export const BULK_PRODUCT_ACTION_THRESHOLD = 150;
export const BPI_PER_PAGE = 5;
