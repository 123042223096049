export const removeAllBuyplanItemsActions = {
  startLoader: "START_REMOVE_ALL_BUYPLAN_ITEMS_LOADER",
  request: "REQUEST_REMOVE_ALL_BUYPLAN_ITEMS",
  success: "REMOVE_ALL_BUYPLAN_ITEMS_SUCCESS",
  failure: "REMOVE_ALL_BUYPLAN_ITEMS_FAILURE"
};

export const removeAllBuyplanItems = buyplanId => {
  return {
    type: removeAllBuyplanItemsActions.request,
    payload: { buyplanId }
  };
};

export const removeAllBuyplanItemsSuccess = payload => {
  return {
    type: removeAllBuyplanItemsActions.success,
    payload
  };
};

export const removeAllBuyplanItemsFailure = errorPayload => {
  return {
    type: removeAllBuyplanItemsActions.failure,
    errorPayload
  };
};
