import React from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";

import { deliveryDatesSelector } from "selectors/orderDetailsSelector.js";

const BrightPearlDeliveryDates = ({ handleDeliveryDatesChange }) => {
  const deliveryDates = useSelector(deliveryDatesSelector);

  return (
    <Table responsive>
      <thead className="thead-light">
        <tr>
          <th></th>
          {deliveryDates?.map(deliveryDate => {
            return (
              <th
                key={deliveryDate.get("formatted_date")}
                className="text-center"
              >
                {deliveryDate.get("formatted_date")}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Requested Delivery Date</td>

          {deliveryDates?.map(deliveryDate => {
            const formattedDeliveryDate = deliveryDate.get("formatted_date");

            return (
              <td
                key={formattedDeliveryDate}
                className="text-center align-middle"
              >
                <input
                  type="text"
                  name={`buyplan[order_detail_attributes][delivery_days][${formattedDeliveryDate}]`}
                  className="w-75 text-center"
                  data-month={deliveryDate.get("month")}
                  onChange={handleDeliveryDatesChange}
                />
              </td>
            );
          })}
        </tr>
      </tbody>
    </Table>
  );
};

export default React.memo(BrightPearlDeliveryDates);
