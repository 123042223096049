import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import CameraScanner from "components/BarcodeScanner/cameraScanner.jsx";
import ManualScanner from "components/BarcodeScanner/manualScanner.jsx";
import Barcodegif from "assets/images/barcodescan.gif";
import SubmitOrderBtn from "components/SubmitOrderBtn";
import { barcodeScanningStatus } from "selectors/quantityGridSelector";
import { Html5Qrcode } from "html5-qrcode";
import classNames from "classnames";
import { addNotification } from "notificationStore";
import i18next from "i18next";

const qrCodeRegionId = "html5qr-code-region";
const qrCodeCameraPreferences = { facingMode: "environment" }; // prefer rear camera
const qrCodeConfig = {
  // Optional, frame per seconds for qr code scanning
  fps: 60,

  // Optional, if you want bounded box UI
  qrbox: { width: 500, height: 500 },
  formatsToSupport: ["UPC_A", "EAN_13"],
  // Show torch button if the device supports it
  showTorchButtonIfSupported: true
};

const ScanSettings = ({
  setScanType,
  isCameraScan,
  setScanMode,
  isManualMode,
  isStockModel,
  isScanning,
  setIsScanning,
  successCallbackFn,
  barcodeSubmit,
}) => {
  const [scannerGif, setScannerGif] = useState(false);
  const [shouldStartScan, setShouldStartScan] = useState(false)

  const setScannerMode = () => { setShouldStartScan(!shouldStartScan) }
  const showScannerGif = () => { setScannerGif(true) };

  const barcodeScanningFailed = useSelector(barcodeScanningStatus);

  useEffect(() => {
    setShouldStartScan(false)
  }, [barcodeScanningFailed]);

  const barcodeScannerRef = useRef();

  const startBarcodeScanner = () => {
    barcodeScannerRef.current
      .start(qrCodeCameraPreferences, qrCodeConfig, qrCodeSuccessCallback)
      .then(() => {
        setIsScanning(true);
      })
      .catch(() => {
        addNotification({
          type: "danger",
          message: i18next.t("barcode.scannerStartFailed")
        });
      });
  };

  const stopBarcodeScanner = () => {
    const scanner = barcodeScannerRef.current;

    if (scanner?.isScanning) {
      scanner
        .stop()
        .then(() => {
          setIsScanning(false);
        })
        .catch(() => {
          addNotification({
            type: "danger",
            message: i18next.t("barcode.scannerStopFailed")
          });
        });
    }
  };

  const qrCodeSuccessCallback = (decodedText) => {
    successCallbackFn(decodedText);

    stopBarcodeScanner();
  };

  useEffect(() => {
    barcodeScannerRef.current = new Html5Qrcode(qrCodeRegionId);

    // startBarcodeScanner();

    return () => {
      stopBarcodeScanner();
    };
  }, []);

  useEffect(() => {
    if (!isCameraScan) {
      stopBarcodeScanner(); // Turn off the camera when "handledScanner" is selected
    }
  }, [isCameraScan]);

  return (
    <>
      <div className="d-flex">
        <div className={`d-flex ${isStockModel ? 'col-4' : 'col-2'}`} >
          <div onMouseDown={setScanType}>
            <h6>Scan Type</h6>
            <div>
              <input
                type="radio"
                value="handledScanner"
                checked={!isCameraScan}
                className="mr-1"
                id="handledScanner"
              />
              <label htmlFor="handledScanner"> Handheld scanner </label>
            </div>
            <div className="align-items-left">
              <input
                type="radio"
                value="cameraScanner"
                checked={isCameraScan}
                className="mr-1"
                id="cameraScanner"
              />
              <label htmlFor="cameraScanner"> Device Camera </label>
            </div>
          </div>

          {isStockModel && (
            <div onMouseDown={setScanMode} className="ml-3">
              <h6>Scan Mode</h6>
              <div>
                <input
                  type="radio"
                  value=""
                  checked={!isManualMode}
                  className="mr-1"
                  id="incrementMode"
                />
                <label htmlFor="incrementMode"> Increment Mode </label>
              </div>
              <div>
                <input
                  type="radio"
                  value="manualMode"
                  checked={isManualMode}
                  className="mr-1"
                  id="manualMode"
                />
                <label htmlFor="manualMode"> Manual Mode </label>
              </div>
            </div>
          )}
        </div>
        <div className={`${isStockModel ? 'col-8' : 'col-10'} d-flex flex-wrap justify-content-center align-items-end flex-grow-1`} >
          {isCameraScan && (
            <CameraScanner
              isScanning={isScanning}
              startBarcodeScanner={startBarcodeScanner}
              stopBarcodeScanner={stopBarcodeScanner}
            />
          )}
          {!isCameraScan && (
            shouldStartScan ?
            <ManualScanner
              barcodeSubmit={barcodeSubmit}
              showScannerGif={showScannerGif}
              scannerGif={scannerGif}
              Barcodegif={Barcodegif}
              setScannerMode={setScannerMode}
            /> :
            <div>
              <SubmitOrderBtn type="button" className="btn" onClick={setScannerMode}>
                Start Scanning
              </SubmitOrderBtn>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex">
        <div className={`d-flex ${isStockModel ? 'col-4' : 'col-2'}`} />
        <div className={`d-flex ${isStockModel ? 'col-8' : 'col-10'} justify-content-center`} >
        <div
            id={qrCodeRegionId}
            className={ classNames("mt-4 w-50 mr-4") }
        >
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ScanSettings);
