import React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";
import ProductsCount from "components/ProductsCount/index.jsx";

import FiltersListContainer from "containers/FiltersContainer.jsx";

import { activeTabSelector } from "selectors/filtersSelector.js";
import SalesPersonSelectContainer from "containers/SalesPersonSelectContainer";

const Sidebar = () => {
  const activeTab = useSelector(activeTabSelector);

  if (activeTab === "report") return null;

  return (
    <StyledSidebar className="sidebar">
      <ProductsCount />
      <SalesPersonSelectContainer />

      <FiltersListContainer />
    </StyledSidebar>
  );
};

const StyledSidebar = styled.aside`
  height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0;
  width: var(--sidebar-width);
  min-width: var(--sidebar-min-width);
  max-width: var(--sidebar-width);
  position: relative;

  @media (max-width: 575.98px) {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export default React.memo(Sidebar);
