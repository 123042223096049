import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import List from "@researchgate/react-intersection-list";

import {
  groupedProductsSelector,
  isLoadingSelelctor,
  errorSelector
} from "selectors/groupedProductsSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";
import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import { currentUserDataSelector } from "selectors/currentUserSelector.js";
import { appliedFiltersSelector } from "selectors/filtersSelector.js";

import { getProducts } from "actions/productsActions.js";
import { toggleFilterGroupState } from "actions/filterActions.js";

import Loader from "components/Loader/Loader.jsx";
import BannerImg from "components/ReleaseBanner/BannerImg.jsx";
import ProductCard from "components/ProductCard/ProductCard.jsx";

import { PER_PAGE, DELIVERY_MONTHS } from "constants.js";
import { getShortDate } from "utils.js";
import i18next from "i18next";
import { showDiscountPercentSelector } from "selectors/productsSelector";

const itemsRenderer = (items, ref) => {
  return <div ref={ref}>{items}</div>;
};

let prevOffset = 0;

const GroupedProductsContainer = ({ groupName, groupByFilter }) => {
  const { releaseId } = useParams();
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const buyPlanState = queryParams.get("type");
  const buyplanId = useSelector(buyplanIdSelector);
  const warehouseId = useSelector(warehouseIdSelector);

  const isLoading = useSelector(isLoadingSelelctor);
  const error = useSelector(errorSelector);
  const result = useSelector(groupedProductsSelector);
  const showDiscountPercent = useSelector(showDiscountPercentSelector);

  const data = result.get(groupName);
  const appliedFilters = useSelector(appliedFiltersSelector);

  const currentUser = useSelector(currentUserDataSelector);
  const hideWholesalePrice = currentUser.get("hide_ws_price");

  const itemRenderer = index => {
    let product = data.getIn(["pr", index]).toJS();

    if (product) {
      let prdct = product.product;
      const isPreOrderProduct = prdct?.is_pre_order_enabled || false;

      return (
        <ProductCard
          key={`${product.id}-${prdct.display_colour}-${prdct.sku}-${index}`}
          imgPath={prdct.primary_image}
          imgAltText={prdct.name}
          productId={product.product_id}
          releaseId={product.release_id}
          showMonths={true}
          captionName={prdct.name}
          captionColor={prdct.display_colour}
          hideWholesalePrice={hideWholesalePrice}
          retailPriceFormatted={product.retail_price_formatted}
          displayUnitRetailPrice={product.display_unit_retail_price}
          captionSku={prdct.sku}
          isDiscounted={product["discounted?"]}
          pbDiscounted={product.pb_discounted}
          effectiveDisplayWholesalePrice={product.effective_display_wholesale_price}
          discountedDisplayWholesalePrice={product.discounted_wholesale_price_formatted}
          discountedDisplayWholesaleRange={product.discounted_display_wholesale_range}
          displayWholesalePrice={product.display_wholesale_price}
          pbPercentOff={product.pb_percent_off}
          showDiscountPercent={showDiscountPercent}
          isPreOrderProduct={isPreOrderProduct}
          productCallout={product.callout_label}
          calloutBgColor={product.callout_bg_color}
          calloutBrColor={product.callout_br_color}
          calloutTxtColor={product.callout_txt_color}
          calloutIcon={product.callout_icon}
        />
      );
    }
  };

  const onIntersection = (size) => {
    let newOffset = prevOffset + PER_PAGE;

    if (size !== prevOffset) {
      dispatch(
        getProducts(
          releaseId,
          PER_PAGE,
          newOffset,
          appliedFilters.toJS(),
          false,
          buyPlanState,
          buyplanId,
          warehouseId,
          groupByFilter,
          groupName
        )
      );
      prevOffset = newOffset;
    }
  };

  if (isLoading || data === undefined) {
    return <Loader />;
  } else if (error) {
    return <div className="error-msg">{data.get("message")}</div>;
  } else if (data) {
    const totalProductsCount = data.get("count");
    const loadedProducts = data.get("pr");
    const loadedProductsCount = loadedProducts ? loadedProducts.size : 0;
    const bannerUrl = data.get("banner_url");

    if (groupByFilter === DELIVERY_MONTHS) {
      groupName = getShortDate(groupName);
    }

    const awaitingMoreProducts = () => {
      return totalProductsCount > PER_PAGE && loadedProductsCount < totalProductsCount;
    };

    if (totalProductsCount === 0) {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          {i18next.t("dnd.messages.noProductsFoundFor", {
            groupName: groupName
          })}
        </div>
      );
    }

    return (
      <StyledDetails
        open={appliedFilters?.getIn(["open_filter_groups", groupName])}
        onClick={evt => {
          if (evt.target.nodeName === "SUMMARY") {
            evt.preventDefault();

            // need to negate as attribue returns prev value before opening
            const isSummaryOpen = !evt.target.parentNode.hasAttribute("open");

            evt.target.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "start"
            });

            dispatch(
              toggleFilterGroupState({
                groupName,
                isOpen: isSummaryOpen
              })
            );
          }
        }}
        className="mb-4"
      >
        <summary className="text-capitalize black-white-card-header">{groupName}</summary>
        {bannerUrl && <BannerImg srcUrl={bannerUrl} altText={groupName} />}

        <FilterItem className="mt-4">
          <List
            pageSize={PER_PAGE}
            itemCount={loadedProductsCount}
            awaitMore={awaitingMoreProducts()}
            threshold="50%"
            itemsRenderer={itemsRenderer}
            renderItem={itemRenderer}
            onIntersection={onIntersection}
          />
          {awaitingMoreProducts() && <Loader />}
        </FilterItem>
      </StyledDetails>
    );
  }
};

const FilterItem = styled.div`
  overflow: auto;
  max-height: 600px;
`;

const StyledDetails = styled.details`
  border-radius: 3px;
  margin: 1em 0;

  & > summary {
    background: #f9f9f9;
    color: var(--bs-dark-black);
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 5px 10px;
    outline: none;
  }

  &[open] > summary {
    background: var(--bs-dark-navy);
    color: #fff;
    border: 1px solid var(--bs-dark-navy);
    margin-bottom: 20px;
  }
`;

export default React.memo(GroupedProductsContainer);
