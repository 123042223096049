import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { buyplanIdSelector } from "selectors/productsSelector.js";
import { isLoadingSelector } from "selectors/buyplanReportSelector.js";

import { getBuyplanReport } from "actions/buyplanReportActions.js";

import Loader from "components/Loader/Loader";
import OrderDetails from "components/BuyplanReport/OrderDetails.jsx";
import PriceBreakdown from "components/BuyplanReport/PriceBreakdown.jsx";
import CategoryDiscount from "components/BuyplanReport/CategoryDiscount.jsx";
import MonthTotals from "components/BuyplanReport/MonthTotals.jsx";
import DivisionTotals from "components/BuyplanReport/DivisionTotals.jsx";
import CategoryTotals from "components/BuyplanReport/CategoryTotals.jsx";

const BuyplanReportContainer = () => {
  const dispatch = useDispatch();
  const { brandId, buyplanId: buyplanIdFromParams } = useParams();
  const buyplanId = useSelector(buyplanIdSelector) || buyplanIdFromParams;
  const isLoading = useSelector(isLoadingSelector);

  useEffect(() => {
    dispatch(getBuyplanReport(buyplanId));
  }, [dispatch, buyplanId]);

  if (isLoading) return <Loader />;

  return (
    <div className="report-container">
      <OrderDetails />

      <PriceBreakdown />
      <CategoryDiscount />

      {!brandId && <MonthTotals />}

      <DivisionTotals />

      <CategoryTotals />
    </div>
  );
};

export default React.memo(BuyplanReportContainer);
