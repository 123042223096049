import { fromJS } from "immutable";

import { buyplanDetailsActions } from "actions/buyplanDetailsActions.js";
import { quantityGridActions } from "actions/quantityGridActions.js";
import { buyplanTotalsActions } from "actions/buyplanTotalsActions.js";
import { sizeBreakActions } from "actions/StockModal/sizeBreakActions.js";
import { qtyGridActions } from "actions/StockModal/qtyGridActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    abilities: {
      can_edit_buyplan: null,
      can_view_wholesale_range: null,
      show_discount_percent: false
    },
    brand: {
      is_cumulative_ats: null,
      is_default_ats_zero: null,
      amount_visible_offset: null,
      low_quantity_offset: null
    },
    buyplan: {
      id: null,
      state: null,
      total_price: null,
      total_price_formatted: null,
      total_items_count: null
    },
    buyplan_items: [
      {
        id: null,
        name: null,
        sku: null,
        category: null,
        gender: null,
        primary_image: null,
        product_release_id: null,
        name_truncated: null,
        colour_truncated: null,
        display_colour: null,
        colour_code: null,
        available_status: null,
        serial_number: null,
        is_discounted: null,
        wholesale_range: null,
        wholesale_price: null,
        display_range: null,
        total_quantity: null,
        total_price: null,
        unit_retail_price: null,
        retail_price_formatted: null,
        total_price_formatted: null,
        discounted_display_wholesale_range: null,
        discounted_display_wholesale_price: null,
        intended_months: [],
        filled_months: [],
        pre_order_intended_months: [],
        pre_order_filled_months: [],
        qty_grid: {
          ats: {},
          headers: [],
          size_mins: [],
          po_dates: {},
          product_release: {
            id: null,
            is_ats_visible: null,
            order_qty_block: null,
            product_requirements: []
          },
          delivery_months: [
            {
              delivery_month_short: null,
              month_date: null,
              index: null,
              attr_rows: {}
            }
          ],
          pre_order_delivery_month : {
            delivery_month_short: null,
            month_date: null,
            index: null,
            attr_rows: {}
          }
        }
      }
    ],
    last_loaded_bp_count: 0
  }
});

const getIndexFromStockModalItemId = (state, stockModalItemId) => {
  if (state.getIn(["data", "stock_model_items"])) {
    return state
      .getIn(["data", "stock_model_items"])
      .findIndex(stockModalItem => {
        return stockModalItem.get("id") === stockModalItemId;
      });
  }
};

export default (state = initialState, action) => {
  const payload = action.payload;

  let stockModalItemId,
    sizeBreakSize,
    newQty,
    stockModalItemIndex,
    sizeBreakKey;

  switch (action.type) {
    case buyplanDetailsActions.startLoader:
      return state.set("isLoading", true);
    case buyplanDetailsActions.success:
      if (payload.override || payload.data.stock_model) {
        return state
          .merge({
            hasErrors: false,
            isLoading: false,
          })
          .set("data", fromJS(payload.data))
          .setIn(["data", "last_loaded_bp_count"], payload.data.buyplan_items?.length || 0);
      } else {
        return state
          .merge({
            hasErrors: false,
            isLoading: false,
          })
          .mergeIn(["data", "buyplan_items"], fromJS(payload.data.buyplan_items))
          .setIn(["data", "last_loaded_bp_count"], payload.data.buyplan_items.length);
      }
    case buyplanDetailsActions.failure:
      return state
        .merge({
          hasErrors: true,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case quantityGridActions.updateSuccess:
      const {
        abilities,
        brand,
        buyplan,
        buyplan_item: {
          id: buyplan_item_id,
          buyplan_id,
          intended_months,
          filled_months,
          pre_order_intended_months,
          pre_order_filled_months,
          total_quantity,
          total_price,
          total_price_formatted
        },
        ats,
        headers,
        size_mins,
        attribute_sequence,
        po_dates,
        product_release,
        delivery_months,
        pre_order_delivery_month
      } = payload.data;

      const buyplanItemIndex = state
        .getIn(["data", "buyplan_items"])
        .findIndex(buyplanItem => buyplanItem.get("id") === buyplan_item_id);

      return state
        .mergeIn(["data"], fromJS({ abilities, brand, buyplan }))
        .mergeIn(
          ["data", "buyplan_items", buyplanItemIndex],
          fromJS({
            id: buyplan_item_id,
            buyplan_id,
            intended_months,
            filled_months,
            pre_order_intended_months,
            pre_order_filled_months,
            total_quantity,
            total_price,
            total_price_formatted,
            qty_grid: {
              ats,
              delivery_months,
              headers,
              size_mins,
              attribute_sequence,
              po_dates,
              product_release,
              pre_order_delivery_month
            }
          })
        );
    case qtyGridActions.updateQtySuccess:
      stockModalItemId = payload.data.stock_model_item_id;
      stockModalItemIndex = getIndexFromStockModalItemId(
        state,
        stockModalItemId
      );

      if (![undefined, null, -1].includes(stockModalItemIndex)) {
        return state.mergeIn(
            ["data", "stock_model_items", stockModalItemIndex],
            fromJS(payload.data.stock_model_item_data
          )
        )
      } else {
        return state;
      }
    case buyplanTotalsActions.success:
      return state.mergeIn(["data", "buyplan"], {
        total_price: payload.data.total_price,
        total_price_formatted: payload.data.total_price_formatted,
        total_items_count: payload.data.total_items_count
      });
    case sizeBreakActions.locallyUpdateStockQty:
      stockModalItemId = payload.stockModalItemId;
      sizeBreakSize = payload.sizeBreakSize;
      sizeBreakKey = payload.sizeBreakKey;
      newQty = payload.newQty;
      stockModalItemIndex = getIndexFromStockModalItemId(
        state,
        stockModalItemId
      );

      if (stockModalItemIndex >= 0) {
        const sizeBreak = state.getIn([
          "data",
          "stock_model_items",
          stockModalItemIndex,
          "qty_grid",
          "size_breaks",
          sizeBreakKey,
          sizeBreakSize
        ]);
        const countQty = sizeBreak.getIn(["count_quantity", "quantity"]);
        const newFillQty = newQty - countQty > 0 ? newQty - countQty : 0;

        return state
          .setIn(
            [
              "data",
              "stock_model_items",
              stockModalItemIndex,
              "qty_grid",
              "size_breaks",
              sizeBreakKey,
              sizeBreakSize,
              "fill_quantity",
              "quantity"
            ],
            newFillQty
          )
          .setIn(
            [
              "data",
              "stock_model_items",
              stockModalItemIndex,
              "qty_grid",
              "size_breaks",
              sizeBreakKey,
              sizeBreakSize,
              "stock_quantity",
              "quantity"
            ],
            newQty
          );
      } else {
        return state;
      }
    case sizeBreakActions.locallyUpdateCountQty:
      stockModalItemId = payload.stockModalItemId;
      sizeBreakSize = payload.sizeBreakSize;
      sizeBreakKey = payload.sizeBreakKey;
      newQty = payload.newQty;
      stockModalItemIndex = getIndexFromStockModalItemId(
        state,
        stockModalItemId
      );

      if (stockModalItemIndex >= 0) {
        const sizeBreak = state.getIn([
          "data",
          "stock_model_items",
          stockModalItemIndex,
          "qty_grid",
          "size_breaks",
          sizeBreakKey,
          sizeBreakSize
        ]);
        const stockQty = sizeBreak.getIn(["stock_quantity", "quantity"]);
        const newFillQty = stockQty - newQty > 0 ? stockQty - newQty : 0;
        return state
          .setIn(
            [
              "data",
              "stock_model_items",
              stockModalItemIndex,
              "qty_grid",
              "size_breaks",
              sizeBreakKey,
              sizeBreakSize,
              "count_quantity",
              "quantity"
            ],
            newQty
          )
          .setIn(
            [
              "data",
              "stock_model_items",
              stockModalItemIndex,
              "qty_grid",
              "size_breaks",
              sizeBreakKey,
              sizeBreakSize,
              "fill_quantity",
              "quantity"
            ],
            newFillQty
          );
      } else {
        return state;
      }
    case sizeBreakActions.locallyUpdateFillQty:
      stockModalItemId = payload.stockModalItemId;
      sizeBreakSize = payload.sizeBreakSize;
      sizeBreakKey = payload.sizeBreakKey;
      newQty = payload.newQty;

      stockModalItemIndex = getIndexFromStockModalItemId(
        state,
        stockModalItemId
      );

      if (stockModalItemIndex >= 0) {
        const sizeBreak = state.getIn([
          "data",
          "stock_model_items",
          stockModalItemIndex,
          "qty_grid",
          "size_breaks",
          sizeBreakKey,
          sizeBreakSize
        ]);

        const existingCountQty = sizeBreak.getIn([
          "count_quantity",
          "quantity"
        ]);
        const countQty = isNaN(parseInt(existingCountQty))
          ? 0
          : parseInt(existingCountQty);
        const newQtyInt = isNaN(parseInt(newQty)) ? 0 : parseInt(newQty);
        const newStockQty = countQty + newQtyInt;

        return state
          .setIn(
            [
              "data",
              "stock_model_items",
              stockModalItemIndex,
              "qty_grid",
              "size_breaks",
              sizeBreakKey,
              sizeBreakSize,
              "stock_quantity",
              "quantity"
            ],
            newStockQty
          )
          .setIn(
            [
              "data",
              "stock_model_items",
              stockModalItemIndex,
              "qty_grid",
              "size_breaks",
              sizeBreakKey,
              sizeBreakSize,
              "fill_quantity",
              "quantity"
            ],
            newQty
          );
      } else {
        return state;
      }
    default:
      return state;
  }
};
