import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckboxWithLabel from "components/CheckboxWithLabel.jsx";
import { orderMinumumsSelector, orderMinimumsByMonthSelector } from "selectors/orderDetailsSelector.js";
import { toggleMinOrderConfirmationAck } from "actions/orderDetailsActions.js";
import i18next from "i18next";
import { Trans } from 'react-i18next'

const OrderMinimumsContainer = ({ className }) => {
  const dispatch = useDispatch();
  const orderMinimums = useSelector(orderMinumumsSelector);
  const orderMinimumsByMonth = useSelector(orderMinimumsByMonthSelector);

  const isMinOrderByMonthFeatureEnabled = orderMinimumsByMonth?.get("apply_order_minimums");
  const isMinOrderFeatureEnabled = !!orderMinimums?.get("apply_order_minimums") || !!isMinOrderByMonthFeatureEnabled;
  const isMinOrderEnforced = !!orderMinimums?.get("enforce_order_minimums") || !!isMinOrderByMonthFeatureEnabled;
  const isAcknowledged = orderMinimums?.get("is_acknowledged") || false;
  const minOrderAmt = orderMinimums?.get("minimum_order_amount") || orderMinimumsByMonth?.get("minimum_order_amount");
  const isMinOrderCriteraMet = orderMinimumsByMonth?.get("is_min_order_criteria_met") || orderMinimums?.get("is_min_order_criteria_met");
  const months = orderMinimumsByMonth?.get("invalid_months")?.toArray();

  let loclisationData =
    isMinOrderByMonthFeatureEnabled ?
    <Trans>
      { i18next.t("order.enforceOrderMinimumsByMonthMsg", { amount: minOrderAmt}) }
      <br/> { i18next.t("order.not_met_for") }
        <ul>
          { months?.map(function (month, index) {
            return (<li key={index}>{month}</li>);
          })}
        </ul>
    </Trans> :
    i18next.t("order.enforceOrderMinimumsMsg", { amount: minOrderAmt });

  const onToggle = useCallback(() => {
    dispatch(toggleMinOrderConfirmationAck(!isAcknowledged));
  }, [dispatch, isAcknowledged]);

  // Don't render anything if feature is disabled
  if (!isMinOrderFeatureEnabled || isMinOrderCriteraMet) return null;
  const includeCheckbox = !isMinOrderEnforced;
  const minOrderEnforcedMsg = isMinOrderEnforced
    ? loclisationData
    : orderMinimums?.get("enforce_order_not_met_message") || "Agree";

  return (
    <div className={`${className} text-center text-danger`}>
      <CheckboxWithLabel
        className="text-danger"
        includeCheckbox={includeCheckbox}
        id="minimum_order_confirmation"
        isChecked={isAcknowledged}
        onToggle={onToggle}
        labelText={minOrderEnforcedMsg}
        textClasses="text-danger"
      />
    </div>
  );
};

export default React.memo(OrderMinimumsContainer);
