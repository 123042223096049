export const viewAtsActions = {
  startLoader: "START_ATS_DATA_LOADER",
  request: "ATS_DATA_REQUEST",
  success: "ATS_DATA_SUCCESS",
  failure: "ATS_DATA_FAILURE",
};

export const getAtsData = payload => {
  return {
    type: viewAtsActions.request,
    payload
  };
};
