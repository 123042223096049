import React, { useEffect, useCallback, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactSortable } from "react-sortablejs";

import {
  getBuyplanSummary,
  updateBuyplanItemsLocally
} from "actions/buyplanSummaryActions.js";
import { reOrderBuyplanMonths } from "actions/reOrderBuyplanMonthsActions.js";
import { addSizeBreak, removeSizeBreak } from "actions/sizeBreakActions.js";
import { getFilters } from "actions/filterActions";

import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import {
  buyplanSummaryBuyplanSelector,
  buyplanSummaryBuyplanItemsSelector
} from "selectors/buyplanSummarySelector.js";
import { appliedFiltersSelector, isDataLoadingSelector } from "selectors/filtersSelector";

import BuyplanSummaryItemCard from "components/BuyplanSummary/BuyplanSummaryItemCard.jsx";
import BuyplanTotals from "components/BuyplanDetails/BuyplanTotals.jsx";

import { SUMMARY } from "constants.js";
import { isFutureMonthFn } from "utils.js";
import { preOrderCustomTextSelector } from "selectors/buyplanInfoSelector";

const BuyplanSummaryContainer = ({ className }) => {
  const dispatch = useDispatch();
  const { releaseId } = useParams();
  const buyplanId = useSelector(buyplanIdSelector);
  const buyplan = useSelector(buyplanSummaryBuyplanSelector);
  const buyplanItems = useSelector(buyplanSummaryBuyplanItemsSelector);
  const preOrderCustomText = useSelector(preOrderCustomTextSelector);
  const sortedBuyplanItems = buyplanItems
    .filterNot((val, key) => key === preOrderCustomText)
    .sortBy(buyplanItem => buyplanItem);
  const sortedPreOrderBuyplanItems = buyplanItems
    .filter((val, key) => key === preOrderCustomText)
    .sortBy(buyplanItem => buyplanItem);
  const appliedFilters = useSelector(appliedFiltersSelector);
  const isDataLoading = useSelector(isDataLoadingSelector);
  const summaryRef = useRef(null);

  const onEnd = useCallback(
    evt => {
      const sourceDroppableId = evt.from.parentElement.dataset.month;
      const destinationDroppableId = evt.to.parentElement.dataset.month;
      const sourceItemIndex = evt.oldDraggableIndex;
      const destinationItemIndex = evt.newDraggableIndex;
      const draggableId = evt.item.dataset.buyplanItemId;
      if (
        destinationDroppableId === null ||
        sourceDroppableId === destinationDroppableId
      )
        return;
      const sourceShortMonth = sortedBuyplanItems.getIn([
        sourceDroppableId,
        "short_month"
      ]);

      const productReleaseId = sortedBuyplanItems.getIn([
        sourceDroppableId,
        "items",
        sourceItemIndex,
        "product_release_id"
      ]);
      const droppableDate = sortedBuyplanItems.getIn([
        destinationDroppableId,
        "date"
      ]);
      const isPreOrder = sortedBuyplanItems.getIn([sourceDroppableId, "is_pre_order"]);
      if (sourceDroppableId === "UNASSIGNED") {
        const destinationShortMonth = sortedBuyplanItems.getIn([
          destinationDroppableId,
          "short_month"
        ]);

        dispatch(
          addSizeBreak(
            buyplanId,
            draggableId,
            productReleaseId,
            destinationShortMonth,
            isPreOrder,
            {
              refreshBuyplanSummary: true
            }
          )
        );
      } else if (destinationDroppableId === "UNASSIGNED") {
        dispatch(
          removeSizeBreak(
            buyplanId,
            draggableId,
            productReleaseId,
            sourceShortMonth,
            {
              refreshBuyplanSummary: true
            }
          )
        );
      } else {
        const destinationShortMonth = sortedBuyplanItems.getIn([
          destinationDroppableId,
          "short_month"
        ]);

        dispatch(
          reOrderBuyplanMonths(
            buyplanId,
            draggableId,
            sourceShortMonth,
            destinationShortMonth
          )
        );
      }
      if (isFutureMonthFn(droppableDate)) {
        dispatch(
          updateBuyplanItemsLocally({
            sourceDroppableId,
            destinationDroppableId,
            draggableId,
            sourceItemIndex,
            destinationItemIndex
          })
        );
      }
    },
    [dispatch, buyplanId, sortedBuyplanItems]
  );

  useEffect(() => {
    if (buyplanId) {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");

      dispatch(getFilters(releaseId, SUMMARY, buyplanId, code));
    }
  }, [dispatch, releaseId, buyplanId]);

  useEffect(() => {
    buyplanId &&
      isDataLoading === false &&
      dispatch(getBuyplanSummary(buyplanId, appliedFilters.toJS()));
  }, [dispatch, buyplanId, appliedFilters, isDataLoading]);

  useLayoutEffect(() => {
    summaryRef.current && summaryRef.current.scrollIntoView();
  }, [sortedBuyplanItems]);

  return (
    <div className={`${className} buyplan-summary-container`} ref={summaryRef}>
      {sortedBuyplanItems.entrySeq().map(([formattedDate, buyplanItem]) => {
        return (
          <div
            key={buyplanItem.get("short_month")}
            className="buyplan-summary-card"
            data-month={formattedDate}
          >
            <div className="heading">
              {getFormattedDateAndPrice(
                formattedDate,
                buyplanItem.get("total_price_formatted")
              )}
            </div>

            <ReactSortable
              tag="div"
              group="shared"
              list={buyplanItem.get("items").toJS()}
              // eslint-disable-next-line no-empty-function
              setList={() => {}} //mandatory prop
              onEnd={evt => {
                onEnd(evt);
              }}
            >
              {buyplanItem.get("items").map((item) => {
                const itemId = item.get("id");
                return (
                  <BuyplanSummaryItemCard
                    key={itemId}
                    buyplanId={buyplanId}
                    buyplanItem={item}
                    releaseId={releaseId}
                    isPreOrder={buyplanItem.get("is_pre_order")}
                  />
                );
              })}
            </ReactSortable>
          </div>
        );
      })}

      {sortedPreOrderBuyplanItems.entrySeq().map(([formattedDate, buyplanItem]) => {
        return (
          <div
            key={buyplanItem.get("short_month")}
            className="buyplan-summary-card"
            data-month={formattedDate}
          >
            <div className="heading">
              {getFormattedDateAndPrice(formattedDate, buyplanItem.get("total_price_formatted"))}
            </div>

            {buyplanItem.get("items").map((item) => {
              const itemId = item.get("id");
              return (
                <BuyplanSummaryItemCard
                  key={itemId}
                  buyplanId={buyplanId}
                  buyplanItem={item}
                  releaseId={releaseId}
                  isPreOrder={buyplanItem.get("is_pre_order")}
                />
              );
            })}
          </div>
        );
      })}

      <BuyplanTotals
        totalPriceFormatted={buyplan.get("total_price_formatted")}
        totalItemsCount={buyplan.get("total_items_count")}
      />
    </div>
  );
};

const getFormattedDateAndPrice = (formattedDate, totalFormattedPrice) => {
  return totalFormattedPrice ? `${formattedDate} - ${totalFormattedPrice}` : formattedDate;
};

const StyledBuyplanSummaryContainer = styled(BuyplanSummaryContainer)`
  margin-top: -18px;

  .buyplan-summary-card {
    + .buyplan-summary-card {
      border-top: 1px solid #ddd;
    }
  }

  .heading {
    font-size: 12px;
    font-weight: bold;
    color: #333333;
    padding: 8px;
  }

  .draggable-wrap {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 575.98px) {
      justify-content: center;
    }
  }

  .buyplan-totals {
    padding-top: 15px;
    border-top: 1px dotted #ddd;
  }
`;

export default React.memo(StyledBuyplanSummaryContainer);
