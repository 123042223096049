import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getBannerInfo } from "actions/bannerInfoActions.js";
import {
  getBuyplanInfo,
  getStockModalInfo
} from "actions/buyplanInfoActions.js";

import {
  isLoadingSelector as isBannerInfoLoadingSelector,
  bannerInfoDataSelector
} from "selectors/bannerInfoSelector.js";

import {
  isLoadingSelector as isBuyplanInfoLoadingSelector,
  buyplanInfoDataSelector
} from "selectors/buyplanInfoSelector.js";
import { buyplanIdSelector } from "selectors/productsSelector.js";
import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";

import ReleaseBanner from "components/ReleaseBanner/ReleaseBanner.jsx";

const getBannerSkeleton = () => (
  <img
    src="/range_banner_info.svg"
    alt="release banner placeholder"
    className="w-100"
    style={{ minHeight: "225px", marginX: "auto" }}
  />
);

const ReleaseBannerContainer = () => {
  const dispatch = useDispatch();
  const isBannerInfoLoading = useSelector(isBannerInfoLoadingSelector);
  const isBuyplanInfoLoading = useSelector(isBuyplanInfoLoadingSelector);
  const bannerInfoData = useSelector(bannerInfoDataSelector);
  const buyplanInfoData = useSelector(buyplanInfoDataSelector);

  const { releaseId, buyplanId: buyplanIdFromParams } = useParams();
  const buyplanId = useSelector(buyplanIdSelector) || buyplanIdFromParams;
  const stockModalId = useSelector(stockModelIdSelector);
  const isTemporaryBuyplan = stockModalId || buyplanId ? false : true;

  // get banner info
  useEffect(() => {
    dispatch(getBannerInfo(releaseId));
  }, [dispatch, releaseId]);

  // get buyplan info
  useEffect(() => {
    if (stockModalId) {
      dispatch(getStockModalInfo(stockModalId));
    }

    if (!stockModalId && buyplanId) {
      dispatch(getBuyplanInfo(buyplanId));
    }
  }, [dispatch, buyplanId, stockModalId]);

  if (isBannerInfoLoading) {
    return getBannerSkeleton();
  } else if (isTemporaryBuyplan) {
    return (
      <ReleaseBanner
        isTemporaryBuyplan={isTemporaryBuyplan}
        imgUrl={bannerInfoData.get("banner_url")}
        altText="banner image"
        releaseName={bannerInfoData.get("release_name")}
        currencyCode={bannerInfoData.get("currency_code")}
      />
    );
  } else if (isBuyplanInfoLoading) {
    return getBannerSkeleton();
  } else {
    return (
      <ReleaseBanner
        isTemporaryBuyplan={isTemporaryBuyplan}
        imgUrl={bannerInfoData.get("banner_url")}
        altText="banner image"
        releaseName={bannerInfoData.get("release_name")}
        currencyCode={bannerInfoData.get("currency_code")}
        buyplanName={buyplanInfoData.get("name")}
        buyplanState={buyplanInfoData.get("state")}
        createdBy={buyplanInfoData.get("created_by")}
        assignedTo={buyplanInfoData.get("assigned_to")}
        retailerName={buyplanInfoData.get("retailer")}
      />
    );
  }
};

export default React.memo(ReleaseBannerContainer);
