export const globalMenuActions = {
  startLoader: "START_GLOBAL_MENU_LOADER",
  request: "REQUEST_GLOBAL_MENU",
  success: "GLOBAL_MENU_SUCESS",
  failure: "GLOBAL_MENU_FAILURE",
  reset: "RESET_GLOBAL_MENU",
};

export const getGlobalMenu = () => {
  return {
    type: globalMenuActions.request,
  };
};

export const getGlobalMenuSuccess = (payload) => {
  return {
    type: globalMenuActions.success,
    payload,
  };
};

export const getGlobalMenuFailure = (errorPayload) => {
  return {
    type: globalMenuActions.failure,
    payload: errorPayload,
  };
};
