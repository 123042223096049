import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";

import { apiBaseUrl } from "apis/apiHelpers.js";
import i18next from "i18next";
import { smTypeSelector } from "selectors/buyplanInfoSelector";

const BuyplanInfo = ({
  name,
  state,
  createdBy,
  assignedTo,
  retailerName,
  className
}) => {
  const stockModalId = useSelector(stockModelIdSelector);
  const isSuperfill = useSelector(smTypeSelector) == "superfill" ? true : false;
  const isBuyplanTemplate = state === "template";
  const isBuyplanConsignment = state === "consignment";
  const isBuyplanConsignmentReq = state === "consignment_request";

  if (isBuyplanTemplate) {
    return (
      <div className={`${className} banner-caption`}>
        ({createdBy} -{" "}
        {stockModalId && isSuperfill ? i18next.t("general.superfill") : stockModalId ? i18next.t("general.cnf") : i18next.t("general.buyplan")}
        {i18next.t("general.template")}: {name} - &nbsp;
        <a
          href={`${apiBaseUrl}${
            stockModalId && isSuperfill ? "/superfills" : stockModalId ? "/count_and_fills" : ""
          }/templates`}
        >
          {i18next.t("general.returnLinks.templateList", {
            buyplanType: stockModalId && isSuperfill ? i18next.t("general.superfill") : stockModalId ? i18next.t("general.cnf") : "BP"
          })}
        </a>
        )
      </div>
    );
  } else {
    return (
      <div className={`${className} banner-caption`}>
        ({retailerName} - {assignedTo}
        &nbsp; - {stockModalId && ( isSuperfill ? `${i18next.t("general.superfill")} :` : `${i18next.t("general.cnf")} :`)}
        &nbsp; {name} - &nbsp;
        {stockModalId ? (
          isSuperfill ? (
            <a href={`${apiBaseUrl}/superfills/buyplans`}>
            {i18next.t("general.returnLinks.backToList", {
              buyplanType: i18next.t("general.superfills")
            })}
          </a>
          ) :
          (
          <a href={`${apiBaseUrl}/count_and_fills/buyplans`}>
            {i18next.t("general.returnLinks.backToList", {
              buyplanType: i18next.t("general.cnfs")
            })}
          </a>
          )
        ) : isBuyplanConsignment ? (
          <a href={`${apiBaseUrl}/consignments`}>
            {i18next.t("general.returnLinks.backToList", {
              buyplanType: i18next.t("general.consignment")
            })}
          </a>
        ) : isBuyplanConsignmentReq ? (
          <a href={`${apiBaseUrl}/consignment_requests`}>
            {i18next.t("general.returnLinks.backTo", {
              buyplanType: i18next.t("general.consignmentRequests")
            })}
          </a>
        ) : (
          <a href={`${apiBaseUrl}/buyplans`}>
            {i18next.t("general.returnLinks.backToBpList")}
          </a>
        )}
        )
      </div>
    );
  }
};

const StyledBuyplanInfo = styled(BuyplanInfo)`
  font-size: 14px;
  font-weight: 400;
  color: var(--bs-dark-black);
  letter-spacing: 0.5px;
`;

export default React.memo(StyledBuyplanInfo);
