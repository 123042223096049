import { takeLatest, put } from "redux-saga/effects";

import { productReleaseActions } from "actions/productReleaseActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestProductRelease = function*(action) {
  const { productReleaseId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/product_releases/${productReleaseId}.json`,
    actions: productReleaseActions,
    params
  });
};

export const watchRequestProductRelease = function*() {
  yield takeLatest(productReleaseActions.init, requestProductRelease);
};
