import { createSelector } from "reselect";

export const stockModelCopyReducerSelector = createSelector(
  state => state.get("stockModelCopyReducer"),
  stockModelCopyReducer => stockModelCopyReducer
);

export const isLoadingSelector = createSelector(
  stockModelCopyReducerSelector,
  stockModelCopyReducer => stockModelCopyReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  stockModelCopyReducerSelector,
  stockModelCopyReducer => stockModelCopyReducer.get("hasErrors")
);

export const stockModelCopyDataSelector = createSelector(
  stockModelCopyReducerSelector,
  stockModelCopyReducer => stockModelCopyReducer.get("data")
);

export const copiedStockModelIdSelector = createSelector(
  stockModelCopyDataSelector,
  stockModelCopyReducer => stockModelCopyReducer.get("id")
);
