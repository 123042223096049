export const bulkSelectProductsActions = {
  startLoader: "START_BULK_SELECT_PRODUCTS_LOADER",
  init: "REQUEST_BULK_SELECT_PRODUCTS",
  success: "BULK_SELECT_PRODUCTS_SUCCESS",
  failure: "BULK_SELECT_PRODUCTS_FAILURE",
  reset: "BULK_SELECT_PRODUCTS_RESET"
};

export const requestBulkSelectProducts = releaseId => {
  return {
    type: bulkSelectProductsActions.init,
    payload: {
      releaseId
    }
  };
};

export const bulkSelectProductsSucceeded = payload => {
  return {
    type: bulkSelectProductsActions.success,
    payload
  };
};

export const bulkSelectProductsFailed = errorPayload => {
  return {
    type: bulkSelectProductsActions.failure,
    errorPayload
  };
};

export const resetBulkSelectProducts = () => {
  return {
    type: bulkSelectProductsActions.reset
  };
};
