import { createSelector } from "reselect";

export const invalidQtyProductsReducerSelector = createSelector(
  state => state.get("invalidQtyProductsReducer"),
  invalidQtyProductsReducer => invalidQtyProductsReducer
);

export const isLoadingSelector = createSelector(
  invalidQtyProductsReducerSelector,
  invalidQtyProductsReducer => invalidQtyProductsReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  invalidQtyProductsReducerSelector,
  invalidQtyProductsReducer => invalidQtyProductsReducer.get("hasErrors")
);

export const invalidQtyProductsDataSelector = createSelector(
  invalidQtyProductsReducerSelector,
  invalidQtyProductsReducer => invalidQtyProductsReducer.get("data")
);
