import { takeEvery } from "redux-saga/effects";

import { PUT_DATA } from "actions/apiOperations.js";

import { putJSON } from "apis/apiHelpers.js";

import sharedApiSaga from "sagas/sharedApiSaga.js";

export default function* watchPutData() {
  yield takeEvery(PUT_DATA, putData);
}

export const putData = function*({ path, actions, params }) {
  return yield* sharedApiSaga(putJSON, path, actions, params);
};
