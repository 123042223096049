import React from "react";

import CategorySubMenu from "components/QuickFillCategoryFilter/CategorySubMenu.jsx";

const CategoryMenu = ({
  level1Menu,
  subMenu,
  onLevel1Click,
  onLevel2Click,
  onLevel3Click
}) => {
  return (
    <li aria_haspopup="true">
      <span className="wsmenu-click">
        <i className="wsmenu-arrow" />
      </span>

      <a className="navtext" href="#menu_item">
        <span>&nbsp;</span>
        <span onClick={onLevel1Click}>{level1Menu}</span>
      </a>

      <div className="wsmegamenu clearfix halfmenu">
        <div className="container-fluid">
          <div className="row">
            {subMenu.keySeq().toList().map((level2Menu, index) => {
              const menuList = subMenu.get(level2Menu).keySeq().toList();
              return (
                <CategorySubMenu
                  key={index}
                  level1Menu={level1Menu}
                  level2Menu={level2Menu}
                  menuList={menuList}
                  onLevel2Click={onLevel2Click}
                  onLevel3Click={onLevel3Click}
                />
              );
            })}
          </div>
        </div>
      </div>
    </li>
  );
};

export default React.memo(CategoryMenu);
