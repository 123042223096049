import React from "react";
import styled from "styled-components";

const SizeBreakInput = ({
  className,
  placeholderText,
  sizeBreakId,
  sizeBreakType,
  sizeBreakSize,
  isDisabled,
  isReadonly,
  defaultValue,
  title,
  updateSizeBreakQty,
  handleSubmission,
  sizeBreakKey
}) => {
  const handleOnKeyUp = (evt) => {
    const evt_value = evt.target.value

    if (evt_value > 99999){
      evt.preventDefault()
      evt.stopPropagation()
      evt.target.value = ""
    }
  }

  return (
    <input
      type="text"
      inputmode='numeric'
      pattern='[0-9]*'
      title={title}
      className={`${className}`}
      name={`size_breaks[${sizeBreakId}][${sizeBreakType}]`}
      placeholder={placeholderText}
      disabled={isDisabled}
      readOnly={isReadonly}
      value={defaultValue ?? ""}
      onKeyUp={handleOnKeyUp}
      onChange={updateSizeBreakQty}
      onBlur={handleSubmission}
      data-size-break-id={sizeBreakId}
      data-size-break-type={sizeBreakType}
      data-size-break-size={sizeBreakSize}
      data-size-break-key={sizeBreakKey}
    />
  );
};

const StyledSizeBreakInput = styled(SizeBreakInput)`
  width: 24px;
  height: 22px;
  font-size: 10px;
  padding: 0 1px;
  text-align: center;
  color: #000000;
  font-weight: 400;
  background-color: #f9f9f9;
  box-shadow: none;
  border-radius: 0;
  font-family: "Roboto";
  margin: 0;
  line-height: 20px;
  border: 1px solid #ccc;
  vertical-align: middle;
  display: inline-block;
  cursor: ${props => (props.disabled === true ? "not-allowed" : "unset")};
  ${props => props.lowQty && "border: 1px solid var(--low-qty-border-color)"};
  ${props => props.markRed && "border: 1px solid var(--error-border-color)"};
  ${props => props.qtyExceeded && "border: 2px solid var(--qty-exceeded-border-color)"};

  ::placeholder {
    color: #d5d5d5;
  }

  &:focus {
    border-color: var(--bs-dark-black);
  }
`;

export default React.memo(StyledSizeBreakInput);
