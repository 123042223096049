import { fromJS } from "immutable";

import { currentUserActions } from "actions/currentUserActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    name: null,
    email: null,
    company: null,
    role: null,
    time_zone: null,
    locale: null,
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case currentUserActions.startLoader:
      return state.set("isLoading", true);
    case currentUserActions.reset:
      return initialState;
    case currentUserActions.success:
      return state.merge({
        isLoading: false,
        hasErrors: false,
        data: fromJS(action.payload.data)
      });
    case currentUserActions.failure:
      return state.merge({
        isLoading: false,
        hasErrors: true,
        data: fromJS(action.payload.data)
      });
    default:
      return state;
  }
};
