import { takeEvery } from "redux-saga/effects";

import { PATCH_DATA } from "actions/apiOperations.js";

import { patchJSON } from "apis/apiHelpers.js";

import sharedApiSaga from "sagas/sharedApiSaga.js";

export default function* watchPatchData() {
  yield takeEvery(PATCH_DATA, patchData);
}

export const patchData = function*({ path, actions, params }) {
  return yield* sharedApiSaga(patchJSON, path, actions, params);
};
