import { takeLatest, put, race, take, delay } from "redux-saga/effects";
import { productReorderActions } from "actions/productReorderActions.js";
import { POST_DATA } from "actions/apiOperations.js";
import { addNotification } from "notificationStore";
import enMessages from "locales/en.js";
import { TIMEOUT_DURATION } from "constants.js";

export const requestProductReorder = function*(action) {
  const { releaseId, ...params } = action.payload;
  yield put({
    type: POST_DATA,
    path: `/api/releases/${releaseId}/reorder_products`,
    actions: productReorderActions,
    params
  });

  const { success, failure, timeout } = yield race({
    success: take(productReorderActions.success),
    failure: take(productReorderActions.failure),
    timeout: delay(TIMEOUT_DURATION)
  });

  if (success) {
    addNotification({ message: success.payload?.data?.message });
  }
  else if (timeout) {
    addNotification({ type: "danger", message: enMessages.timeout });
  }
  else {
    addNotification({ type: "danger", message: failure.payload.data.errors[0] });
  }
};
export const watchRequestProductReorder = function*() {
  yield takeLatest(productReorderActions.init, requestProductReorder);
};
