import { fromJS } from "immutable";

import { stockModelCopyActions } from "actions/stockModelCopyActions.js"

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    id: null,
    state: null
  }
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case stockModelCopyActions.startLoader:
      return state.set("isLoading", true);
    case stockModelCopyActions.success:
      return state
        .merge({
          hasErrors: false,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case stockModelCopyActions.failure:
      return state
        .merge({
          hasErrors: true,
          isLoading: false
        })
    default:
      return state;
  }
};

