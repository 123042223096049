import React from "react";
import styled from "styled-components";
import { BsTrash as BsTrashIcon } from "react-icons/bs";

const BsTrash = ({ className, onClickHandler }) => {
  return (
    <div className={`${className} trash-box`}>
      <BsTrashIcon className="remove-icon" onClick={onClickHandler} />
    </div>
  );
};

const StyledBsTrash = styled(BsTrash)`
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: solid 1px #e5e5e5;
  border-radius: 3px;

  & > .remove-icon {
    width: 2em;
    height: auto;
    color: var(--badge-default-bg-color);
  }
`;

export default React.memo(StyledBsTrash);
