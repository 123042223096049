export const buyplanItemActions = {
  startLoader: "START_BUYPLAN_ITEM_LOADER",
  addBuyplanItem: "ADD_BUYPLAN_ITEM",
  removeBuyplanItem: "REMOVE_BUYPLAN_ITEM",
  addStockModalItem: "ADD_STOCK_MODAL_ITEM",
  removeStockModalItem: "REMOVE_STOCK_MODAL_ITEM",
  success: "UPDATE_BUYPLAN_ITEM_SUCCESS",
  failure: "UPDATE_BUYPLAN_ITEM_FAILURE"
};

export const addBuyplanItem = (buyplanId, productReleaseId) => {
  return {
    type: buyplanItemActions.addBuyplanItem,
    payload: { buyplanId, product_release_id: productReleaseId }
  };
};

export const removeBuyplanItem = (buyplanId, buyplanItemId, options = {}) => {
  const defaultOptions = {
    refreshBuyplanDetails: false,
    refreshBuyplanSummary: false
  };

  return {
    type: buyplanItemActions.removeBuyplanItem,
    payload: {
      buyplanId,
      buyplanItemId,
      releaseId: options.releaseId,
      options: { ...defaultOptions, ...options }
    }
  };
};

export const addStockModalItem = (
  stockModalId,
  productReleaseId,
  options = {}
) => {
  const defaultOptions = { refreshStockModalDetails: false };

  return {
    type: buyplanItemActions.addStockModalItem,
    payload: {
      stockModalId,
      product_release_id: productReleaseId,
      options: { ...defaultOptions, ...options }
    }
  };
};

export const removeStockModalItem = (
  stockModalId,
  stockModalItemId,
  options = {}
) => {
  const defaultOptions = { refreshStockModalRangePage: true };
  return {
    type: buyplanItemActions.removeStockModalItem,
    payload: {
      stockModalId,
      stockModalItemId,
      releaseId: options.releaseId,
      options: { ...defaultOptions, ...options }
    }
  };
};

export const getLikeDislikeSuccess = payload => {
  return {
    type: buyplanItemActions.success,
    payload
  };
};

export const getLikeDislikeFailure = errorPayload => {
  return {
    type: buyplanItemActions.failure,
    errorPayload
  };
};
