import { takeLatest, put } from "redux-saga/effects";

import { stockModelIdActions } from "actions/stockModelIdActions.js"

import { GET_DATA } from "actions/apiOperations.js";

export const requestStockModelId = function*(action) {
  const { releaseId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/releases/${releaseId}/find_or_create_stock_model`,
    actions: stockModelIdActions,
    params
  });
};

export const watchRequestStockModelId = function*() {
  yield takeLatest(stockModelIdActions.request, requestStockModelId);
};
