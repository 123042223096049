import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { getProductRelease } from "actions/productReleaseActions.js";
import {
  isLoadingSelector,
  hasErrorsSelector,
  errorSelector,
  productSelector,
  productImagesSelector,
  deliveryMonthsSelector,
  productReleaseSelector,
  productReleaseAbilitiesSelector,
  productSpecificationsSelector,
  productReleaseNotesSelector,
  agentNotesSelector,
  productChangesSelector,
  buyplanItemIdSelector
} from "selectors/productReleaseSelector.js";
import {
  findByProductReleaseId,
  productsDataSelector,
  buyplanIdSelector
} from "selectors/productsSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";

import ProductModal from "components/ProductModal/index.jsx";

import { isRequestForStockModal } from "utils.js";

const shouldShowMonths = (brandId, stockModalId) => {
  return brandId === undefined && stockModalId === undefined;
};

const ProductReleaseContainer = () => {
  const { brandId, productReleaseId, stockModalId, releaseId } = useParams();
  const history = useHistory();
  const isModalOpen = true;
  const location = useLocation();
  const isStockModal = isRequestForStockModal(location.pathname);
  const buyplanType = isStockModal ? "stock_model" : "buyplan";
  const buyplanId = useSelector(buyplanIdSelector);
  const buyplanItemId = useSelector(buyplanItemIdSelector);

  const closeModalCallback = useCallback(() => {
    return history.push(
      history.location.pathname.split(`/${productReleaseId}`)[0]
    );
  }, [history, productReleaseId]);

  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);
  const hasErrors = useSelector(hasErrorsSelector);
  const error = useSelector(errorSelector);
  const product = useSelector(productSelector);
  const productsData = useSelector(productsDataSelector);
  const captionName = product.get("name");
  const captionSku = product.get("sku");
  const captionColor = product.get("color");
  const productSpecification1Key = product.get("product_specification_key");
  const productSpecification1Value = product.get("product_specification_value");
  const productImages = useSelector(productImagesSelector);
  const deliveryMonths = useSelector(deliveryMonthsSelector);
  const abilities = useSelector(productReleaseAbilitiesSelector);
  const productRelease = useSelector(productReleaseSelector);
  const memoizedProductRelease = useSelector(
    findByProductReleaseId(productReleaseId)
  );

  const wholesalePrice = productRelease.get("wholesale_price");
  const wholesaleRange = productRelease.get("wholesale_range");
  const retailPrice = productRelease.get("retail_price");
  const unitWholesalePrice = productRelease.get("unit_wholesale_price");
  const unitRetailPrice = productRelease.get("unit_retail_price");
  const specifications = useSelector(productSpecificationsSelector);
  const changes = useSelector(productChangesSelector);
  const myNote = useSelector(productReleaseNotesSelector);
  const agentNote = useSelector(agentNotesSelector);
  const productModalKey = `${memoizedProductRelease?.prev_product_release_id}-${memoizedProductRelease?.next_product_release_id}`;
  const warehouseId = useSelector(warehouseIdSelector);
  const isSoldOut =
    memoizedProductRelease &&
    memoizedProductRelease["ats_visible?"] &&
    memoizedProductRelease.status?.[warehouseId] === "SoldOut";
  const isThreeSixty = typeof productsData?.get("tenant_id") === "string";
  const tenantId = productsData?.get("tenant_id") || null;
  const brandScopeTag =
    memoizedProductRelease?.product?.product_orb360_tag_value || null;
  const isPreOrderProduct= memoizedProductRelease?.product?.is_pre_order_enabled || false;
  useEffect(() => {
    dispatch(
      getProductRelease({
        productReleaseId,
        buyplan_id: buyplanId,
        buyplan_type: buyplanType
      })
    );
  }, [dispatch, productReleaseId, buyplanId, buyplanType]);

  if (memoizedProductRelease) {
    let {
      pb_discounted
    } = memoizedProductRelease;
    let discounted = memoizedProductRelease["discounted?"];
    return (
      <ProductModal
        key={productModalKey}
        isLoading={isLoading}
        hasErrors={hasErrors}
        error={error}
        isOpen={isModalOpen}
        closeModal={closeModalCallback}
        product={product}
        captionName={captionName}
        captionSku={captionSku}
        captionColor={captionColor}
        productSpecification1Key={productSpecification1Key}
        productSpecification1Value={productSpecification1Value}
        productImages={productImages}
        productReleaseId={productReleaseId}
        buyplanItemId={buyplanItemId}
        badges={deliveryMonths}
        wholesalePrice={wholesalePrice}
        wholesaleRange={wholesaleRange}
        retailPriceFormatted={retailPrice}
        unitWholesalePrice={unitWholesalePrice}
        unitRetailPrice={unitRetailPrice}
        abilities={abilities}
        specifications={specifications}
        changes={changes}
        myNote={myNote}
        agentNote={agentNote}
        showLikeDislike={stockModalId === undefined}
        showMonths={shouldShowMonths(brandId, stockModalId)}
        prevProductReleaseId={
          memoizedProductRelease
            ? memoizedProductRelease.prev_product_release_id
            : null
        }
        nextProductReleaseId={
          memoizedProductRelease
            ? memoizedProductRelease.next_product_release_id
            : null
        }
        buyplanId={buyplanId}
        stockModalId={stockModalId}
        releaseId={releaseId}
        brandId={brandId}
        isStockModal={isStockModal}
        discounted={discounted}
        pbDiscounted={pb_discounted}
        isSoldOut={isSoldOut}
        isThreeSixty={isThreeSixty}
        tenantId={tenantId}
        brandScopeTag={brandScopeTag}
        pbPercentOff={memoizedProductRelease?.pb_percent_off}
        discountedDisplayWholesalePrice={memoizedProductRelease?.effective_display_wholesale_price}
        discountedDisplayWholesaleRange={memoizedProductRelease?.discounted_display_wholesale_range}
        isPreOrderProduct={isPreOrderProduct}
      />
    );
  }
};

export default React.memo(ProductReleaseContainer);
