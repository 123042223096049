import styled from "styled-components";

export default styled.button`
  background-color: ${props => props.bgColor ? props.bgColor : "#40bf40" };
  color: #ffffff;
  text-shadow: none;
  filter: none;
  border-radius: 0;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  box-shadow: none;
  padding: 5px 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  margin-right: 20px;
  border-right: 1px solid #dddddd;
`;
