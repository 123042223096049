import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { findByProductId } from "selectors/productsSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";
import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";

import Thumbnail from "components/ProductCard/Thumbnail/Thumbnail.jsx";
import Actions from "components/ProductCard/Actions/Actions.jsx";
import Months from "components/ProductCard/Months/Months.jsx";
import Caption from "components/ProductCard/Caption/Caption.jsx";

const ProductCard = ({
  className,
  productId,
  releaseId,
  showMonths,
  releaseName,
  captionName,
  captionSku,
  captionColor,
  imgPath,
  imgAltText,
  hideWholesalePrice,
  retailPriceFormatted,
  displayUnitRetailPrice,
  isDiscounted,
  pbDiscounted,
  effectiveDisplayWholesalePrice,
  discountedDisplayWholesaleRange,
  discountedDisplayWholesalePrice,
  showWsRange,
  displayWholesalePrice,
  displayWholesaleRange,
  isThreeSixty,
  tenantId,
  brandScopeTag,
  pbPercentOff,
  showDiscountPercent,
  isPreOrderProduct,
  productCallout,
  calloutBgColor,
  calloutBrColor,
  calloutTxtColor,
  calloutIcon,
}) => {
  const memoizedProductRelease = useSelector(
    findByProductId(productId, releaseId)
  );
  const productReleaseId = memoizedProductRelease?.id;
  const buyplanItemId = memoizedProductRelease?.buyplan_item?.id;
  const buyplanId = useSelector(buyplanIdSelector);
  const stockModalId = useSelector(stockModelIdSelector);
  const warehouseId = useSelector(warehouseIdSelector);
  const isSoldOut =
    memoizedProductRelease &&
    memoizedProductRelease["ats_visible?"] &&
    memoizedProductRelease.status?.[warehouseId] === "SoldOut";
  return (
    <div
      className={`${className} product mb-3`}
      data-product-id={productId}
      data-release-id={releaseId}
    >
      <Thumbnail
        productId={productId}
        buyplanId={buyplanId}
        productReleaseId={productReleaseId}
        isSoldOut={isSoldOut}
        imgSrc={imgPath}
        altText={imgAltText}
        productCallout={productCallout}
        calloutBgColor={calloutBgColor}
        calloutBrColor={calloutBrColor}
        calloutTxtColor={calloutTxtColor}
        calloutIcon={calloutIcon}
      />

      <div
        className="buyplan text-center"
        data-ats-visible="false"
        data-sold="true"
      >
        <Actions
          productReleaseId={productReleaseId}
          buyplanItemId={buyplanItemId}
          buyplanId={buyplanId}
          stockModalId={stockModalId}
          imgUrl={imgPath && imgPath.replace(/,h_\d+,w_\d+/, "")}
          imgAltText={imgAltText}
          isSoldOut={isSoldOut}
          isThreeSixty={isThreeSixty}
          tenantId={tenantId}
          brandScopeTag = {brandScopeTag}
          isPreOrderProduct={isPreOrderProduct}
          productId={productId}
        />
      </div>

      <Caption
        name={captionName}
        color={captionColor}
        hideWholesalePrice={hideWholesalePrice}
        retailPriceFormatted={retailPriceFormatted}
        displayUnitRetailPrice={displayUnitRetailPrice}
        sku={captionSku}
        releaseName={releaseName}
        isDiscounted={isDiscounted}
        pbDiscounted={pbDiscounted}
        effectiveDisplayWholesalePrice={effectiveDisplayWholesalePrice}
        discountedDisplayWholesaleRange={discountedDisplayWholesaleRange}
        discountedDisplayWholesalePrice={discountedDisplayWholesalePrice}
        showWsRange={showWsRange}
        displayWholesalePrice={displayWholesalePrice}
        displayWholesaleRange={displayWholesaleRange}
        pbPercentOff={pbPercentOff}
        showDiscountPercent={showDiscountPercent}
      />

      {showMonths && (
        <StyledMonths
          productId={productId}
          productReleaseId={productReleaseId}
          releaseId={releaseId}
        />
      )}
    </div>
  );
};

const StyledProductCard = styled(ProductCard)`
  display: inline-block;
  margin-bottom: 20px;
  margin-left: 0px;
  min-height: 300px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid transparent;
  position: relative;
  vertical-align: top;
  width: 195px;
  margin-right: 20px;

  @media (max-width: 575.98px) {
    width: 100%;
    margin-right: 0;
  }

  &:hover {
    border: 1px solid #ddd;
  }
`;

const StyledMonths = styled(Months)`
  .badge {
    font-size: 10px;
    padding: 2px;
    font-family: var(--body-font-family);
    letter-spacing: 0.75px;
    font-weight: 400;
  }
`;

export default React.memo(StyledProductCard);
