import { takeLatest, put, all, select } from "redux-saga/effects";

import {
  appliedFiltersSelector,
  fetchedDynamicFiltersSelector
} from "selectors/filtersSelector.js";

import { groupByProductsActions } from "actions/groupByProductsActions.js";
import { groupByBannerActions } from "actions/groupByBannerActions.js";
import { POST_DATA, GET_DATA } from "actions/apiOperations.js";

export const requestGroupByProducts = function*(action) {
  let { releaseId, selectedGroupByFilter, ...params } = action.payload;
  let requests = [];
  let applicableFilters = [];

  const appliedFilters = yield select(appliedFiltersSelector);
  const fetchedDynamicFilters = yield select(fetchedDynamicFiltersSelector);

  if (
    appliedFilters.getIn(["dynamic_filters", selectedGroupByFilter]) &&
    appliedFilters.getIn(["dynamic_filters", selectedGroupByFilter]).size > 0
  ) {
    applicableFilters = appliedFilters.getIn([
      "dynamic_filters",
      selectedGroupByFilter
    ]);
  } else {
    fetchedDynamicFilters.get(selectedGroupByFilter).forEach(filterOption => {
      const isExistingFilterOption = applicableFilters.find(option => {
        return option.get("name") === filterOption.get("name");
      });

      isExistingFilterOption === undefined &&
        applicableFilters.push(filterOption);
    });
  }

  applicableFilters.map(filter => {
    let payload = {
      ...params,
      filters: appliedFilters,
      category: selectedGroupByFilter,
      subcategory: filter
    };

    requests.push(
      put({
        type: POST_DATA,
        path: `/api/releases/${releaseId}/products.json`,
        actions: groupByProductsActions,
        params: payload
      }),
      put({
        type: GET_DATA,
        path: `/api/releases/${releaseId}/banner_asset`,
        actions: groupByBannerActions,
        params: { type: selectedGroupByFilter, value: filter.toJS() }
      })
    );
    return null;
  });

  yield all(requests);
};

export const watchRequestGroupByProducts = function*() {
  yield takeLatest(groupByProductsActions.request, requestGroupByProducts);
};
