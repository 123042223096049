import { Map } from "immutable";

import { authTokenActions } from "actions/authTokenActions.js";

const initialState = Map({
  isLoading: true,
  token: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case authTokenActions.success:
      const authToken = action.payload.token;

      return state.merge({
        isLoading: false,
        token: authToken
      });
    case authTokenActions.failure:
      return state.merge({
        isLoading: false,
        token: null
      });
    default:
      return state;
  }
};
