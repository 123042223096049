export const reOrderBuyplanMonthsActions = {
  startLoader: "START_RE_ORDER_BUYPLAN_MONTHS_LOADER",
  request: "REQUEST_RE_ORDER_BUYPLAN_MONTHS",
  success: "RE_ORDER_BUYPLAN_MONTHS_SUCCESS",
  failure: "RE_ORDER_BUYPLAN_MONTHS_FAILURE"
};

export const reOrderBuyplanMonths = (
  buyplanId,
  buyplanItemId,
  oldMonth,
  newMonth
) => {
  return {
    type: reOrderBuyplanMonthsActions.request,
    payload: {
      buyplanId,
      buyplanItemId,
      old: oldMonth,
      new: newMonth
    }
  };
};

export const reOrderBuyplanMonthsSuccess = payload => {
  return {
    type: reOrderBuyplanMonthsActions.success,
    payload
  };
};

export const reOrderBuyplanMonthsFailure = errorPayload => {
  return {
    type: reOrderBuyplanMonthsActions.failure,
    errorPayload
  };
};
