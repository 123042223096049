export const stockModelIdActions = {
  startLoader: "START_STOCK_MODEL_ID_LOADER",
  request: "REQUEST_STOCK_MODEL_ID",
  success: "STOCK_MODEL_ID_SUCCESS",
  failure: "STOCK_MODEL_ID_FAILURE",
  update: "STOCK_MODEL_ID_UPDATE"
};

export const getStockModelId = payload => {
  return {
    type: stockModelIdActions.request,
    payload
  };
};

export const updateStockModelId = payload => {
  return {
    type: stockModelIdActions.update,
    payload
  };
};

