import React, { useState } from "react";
import CustomModal from "components/CustomModal/index.jsx";
import { Input } from "reactstrap";
import { getDeclineOrderRequest } from "actions/declineOrderActions";
import { useDispatch } from "react-redux";
import { addNotification } from "notificationStore";
import i18next from "i18next";

const DeclineOrderDialog = ({ isOpen, closeModalCallback, buyplanId }) => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const handleMessageChange = e => {
    setMessage(e.target.value);
  };
  const handleDeclineOrder = () => {
    if (!message) {
      addNotification({
        type: "danger",
        message: i18next.t("orderApproval.provideMessage")
      });
    } else {
      dispatch(getDeclineOrderRequest(buyplanId, message));
      closeModalCallback();
    }
  };

  const modalBody = (
    <div>
      <h6>Message for Retailer</h6>
      <Input
        type="textarea"
        name="message_for_retailer"
        id="message_for_retailer"
        value={message}
        placeholder={i18next.t("orderApproval.writeMessage")}
        onChange={handleMessageChange}
        rows={10}
      />
    </div>
  );
  const modalFooter = (
    <div>
      <button className="btn btn-primary rounded" onClick={handleDeclineOrder}>
        {i18next.t("buttons.submit")}
      </button>
    </div>
  );

  return (
    isOpen && (
      <CustomModal
        className=""
        isOpen={isOpen}
        title={i18next.t("buttons.declineOrder")}
        body={modalBody}
        footer={modalFooter}
        closeModalCallback={closeModalCallback}
      />
    )
  );
};

export default React.memo(DeclineOrderDialog);
