export const bannerInfoActions = {
  startLoader: "START_BANNER_INFO_LOADER",
  request: "REQUEST_BANNER_INFO",
  success: "BANNER_INFO_SUCESS",
  failure: "BANNER_INFO_FAILURE",
  reset: "RESET_BANNER_INFO",
};

export const getBannerInfo = (releaseId) => {
  return {
    type: bannerInfoActions.request,
    payload: { releaseId },
  };
};

export const getBannerInfoSuccess = (payload) => {
  return {
    type: bannerInfoActions.success,
    payload,
  };
};

export const getBannerInfoFailure = (errorPayload) => {
  return {
    type: bannerInfoActions.failure,
    payload: errorPayload,
  };
};
