import { createSelector } from "reselect";

export const productReorderListReducerSelector = createSelector(
  state => state.get("productReorderListReducer"),
  productReorderListReducer => productReorderListReducer
);

export const isLoadingSelector = createSelector(
  productReorderListReducerSelector,
  productReorderListReducer => productReorderListReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  productReorderListReducerSelector,
  productReorderListReducer => productReorderListReducer.get("hasErrors")
);

export const errorSelector = createSelector(
  productReorderListReducerSelector,
  productReorderListReducer => productReorderListReducer.get("error")
);

export const productReorderListDataSelector = createSelector(
  productReorderListReducerSelector,
  productReorderListReducer => productReorderListReducer.get("data")
);
