import { createSelector } from "reselect";

export const productsReducerSelector = createSelector(
  state => state.get("productsReducer"),
  productsReducer => productsReducer
);

export const isLoadingSelector = createSelector(
  productsReducerSelector,
  productsReducer => productsReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  productsReducerSelector,
  productsReducer => productsReducer.get("hasErrors")
);

export const productsDataSelector = createSelector(
  productsReducerSelector,
  productsReducer => productsReducer.get("data")
);

export const productReleasesSelector = createSelector(
  productsDataSelector,
  productsData => productsData.get("pr")
);

export const buyplanIdSelector = createSelector(
  productsDataSelector,
  productsData => {
    return (
      productsData.get("buyplan_id") ||
      productsData.getIn(["stock_model", "id"])
    );
  }
);

export const warehouseIdSelector = createSelector(
  productsDataSelector,
  productsData => productsData.get("warehouse_id")
);

export const totalProductsCountSelector = createSelector(
  productsDataSelector,
  productsData => productsData.get("count")
);

export const buyplanItemsCountSelector = createSelector(
  productsDataSelector,
  productsData => productsData.get("buyplan_items_count")
);

export const displayWsRangeSelector = createSelector(
  productsDataSelector,
  productsData => productsData.get("display_ws_range")
);

export const showDiscountPercentSelector = createSelector(
  productsDataSelector,
  productsData => productsData.get("show_discount_percent")
);

export const showBarcodeScanForBpSelector = createSelector(
  productsDataSelector,
  productsData => productsData.get("allow_barcode_scanning_for_bp")
);

export const showBarcodeScanForCnfSelector = createSelector(
  productsDataSelector,
  productsData => productsData.get("allow_barcode_scanning_for_cnf")
);

export const findByProductReleaseId = productReleaseId => {
  return createSelector(productReleasesSelector, productsList => {
    return productsList.find(
      product => parseInt(product.id) === parseInt(productReleaseId)
    );
  });
};

export const findByStockModalItemId = stockModalItemId => {
  return createSelector(productReleasesSelector, productsList => {
    return productsList.find(
      product =>
        parseInt(product.stock_model_item.id) === parseInt(stockModalItemId)
    );
  });
};

export const findByProductId = (productId, releaseId) => {
  return createSelector(productReleasesSelector, productsList => {
    return productsList.find(product => {
      return (
        parseInt(product.product_id) === parseInt(productId) &&
        parseInt(product.release_id) === parseInt(releaseId)
      );
    });
  });
};

export const isBadgeSelected = (intendedMonths, currentMonth) => {
  return intendedMonths ? intendedMonths.includes(currentMonth) : false;
};
