import { fromJS } from "immutable";

import { pricingDetailsVisibilityActions } from "actions/pricingDetailsVisibilityActions.js";

const initialState = fromJS({});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case pricingDetailsVisibilityActions.showPricingDetails:
      return state.set(+payload.buyplanItemId, true);
    case pricingDetailsVisibilityActions.hidePricingDetails:
      return state.set(+payload.buyplanItemId, false);
    case pricingDetailsVisibilityActions.togglePricingDetails:
      return state.set(
        +payload.buyplanItemId,
        !state.get(+payload.buyplanItemId)
      );
    default:
      return state;
  }
};
