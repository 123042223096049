import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import Select from "react-select";
import i18next from "i18next";
import { countriesSelector, selectedShippingAddrIdSelector } from "selectors/orderDetailsSelector";
import StyledTitle from "./StyledTitle";

const CustomShippingAddress = ({ title, onChangeHandler }) => {
  const selectedShippingAddrId = useSelector(selectedShippingAddrIdSelector);
  const countries = useSelector(countriesSelector);
  const countriesOptions = countries => {
    return countries.map(country => ({
      value: country,
      label: country
    }));
  };

  const handleKeys = e => {
    const specialChars = /[*!|,%#@():;"'/’<>?^.\t]/;

    if(specialChars.test(e.key))
      e.preventDefault();
  }

  return (
    <>
      <StyledTitle>{title}</StyledTitle>
      {!selectedShippingAddrId && (
        <Form>
          <Row>
            <Col>
              <FormGroup row className="align-items-flex-end">
                <Col>
                  <div className="d-flex justify-content-left align-items-flex-end h-100">
                    {i18next.t("address.form.hints.specialCharactersNotAllowed")}
                  </div>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label for="custom_shipping_addr_company_name">
                    {i18next.t("address.form.labels.companyName")}
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="custom_shipping_addr_company_name"
                    id="custom_shipping_addr_company_name"
                    data-testid="custom_shipping_addr_company_name"
                    onChange={onChangeHandler}
                    onKeyDown={handleKeys}
                  />
                </Col>
                <Col sm={3}>
                  <FormText color="muted">{i18next.t("address.form.hints.companyName")}</FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label for="custom_shipping_addr_customer_name">
                    {i18next.t("address.form.labels.customerName")}
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="custom_shipping_addr_customer_name"
                    id="custom_shipping_addr_customer_name"
                    data-testid="custom_shipping_addr_customer_name"
                    onChange={onChangeHandler}
                    onKeyDown={handleKeys}
                  />
                </Col>
                <Col sm={3}>
                  <FormText color="muted">{i18next.t("address.form.hints.customerName")}</FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label for="custom_shipping_addr_line1">
                    {i18next.t("address.form.labels.line1")}
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="custom_shipping_addr_line1"
                    id="custom_shipping_addr_line1"
                    data-testid="custom_shipping_addr_line1"
                    onChange={onChangeHandler}
                    onKeyDown={handleKeys}
                  />
                </Col>
                <Col sm={3}>
                  <FormText color="muted">{i18next.t("address.form.hints.line1")}</FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label for="custom_shipping_addr_line2">
                    {i18next.t("address.form.labels.line2")}
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="custom_shipping_addr_line2"
                    id="custom_shipping_addr_line2"
                    data-testid="custom_shipping_addr_line2"
                    onChange={onChangeHandler}
                    onKeyDown={handleKeys}
                  />
                </Col>
                <Col sm={3}>
                  <FormText color="muted">{i18next.t("address.form.hints.line2")}</FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label for="custom_shipping_addr_city">
                    {i18next.t("address.form.labels.city")}
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="custom_shipping_addr_city"
                    id="custom_shipping_addr_city"
                    data-testid="custom_shipping_addr_city"
                    onChange={onChangeHandler}
                    onKeyDown={handleKeys}
                  />
                </Col>
                <Col sm={3}>
                  <FormText color="muted">{i18next.t("address.form.hints.city")}</FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label for="custom_shipping_addr_state">
                    {i18next.t("address.form.labels.state")}
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="custom_shipping_addr_state"
                    id="custom_shipping_addr_state"
                    data-testid="custom_shipping_addr_state"
                    onChange={onChangeHandler}
                    onKeyDown={handleKeys}
                  />
                </Col>
                <Col sm={3}>
                  <FormText color="muted">{i18next.t("address.form.hints.state")}</FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label for="custom_shipping_addr_postcode">
                    {i18next.t("address.form.labels.postCode")}
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="custom_shipping_addr_postcode"
                    id="custom_shipping_addr_postcode"
                    data-testid="custom_shipping_addr_postcode"
                    onChange={onChangeHandler}
                    onKeyDown={handleKeys}
                  />
                </Col>
                <Col sm={3}>
                  <FormText color="muted">{i18next.t("address.form.hints.postCode")}</FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label for="custom_shipping_addr_country">
                    {i18next.t("address.form.labels.country")}
                  </Label>
                </Col>
                <Col sm={5}>
                  <Select
                    options={countriesOptions(countries)}
                    name="custom_shipping_addr_country"
                    inputId="custom_shipping_addr_country"
                    onChange={onChangeHandler}
                    placeholder={i18next.t("select.default")}
                    noOptionsMessage={() => i18next.t("select.noOptions")}
                  />
                </Col>
                <Col sm={3}>
                  <FormText color="muted">{i18next.t("address.form.hints.country")}</FormText>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default React.memo(CustomShippingAddress);
