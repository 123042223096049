import React from "react";
import LineIcon from "react-lineicons";

export const LoaderIcon = ({ useDarkTheme = false }) => (
  <LineIcon
    name="spiner-solid lni-is-spinning"
    style={{ color: useDarkTheme ? "var(--bs-light-grey)" : "var(--white)" }}
  />
);

const Loader = ({ className = "h1", withContainer = true }) => {
  if (withContainer) {
    return (
      <div
        className={`${className} d-flex justify-content-center align-items-center`}
      >
        <LoaderIcon useDarkTheme={true} />
      </div>
    );
  } else {
    return <LoaderIcon useDarkTheme={false} />;
  }
};

export default React.memo(Loader);
