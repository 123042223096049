import { takeLatest, put } from "redux-saga/effects";

import { invalidQtyProductsActions } from "actions/invalidQtyProductsActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestInvalidQtyProducts = function*(action) {
  const { buyplanId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/buyplans/${buyplanId}/invalid_quantity_products.json`,
    actions: invalidQtyProductsActions,
    params
  });
};

export const watchRequestInvalidQtyProducts = function*() {
  yield takeLatest(
    invalidQtyProductsActions.request,
    requestInvalidQtyProducts
  );
};
