// third party plugins
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import LineIcon from "react-lineicons";

// selectors
import {
  isLoadingSelector as isBulkSelectProductsLoadingSelector,
  dataSelector as bulkSelectProductsResponseSelector
} from "selectors/bulkSelectProductsSelector.js";

import {
  isLoadingSelector as isBulkDeselectProductsLoadingSelector,
  dataSelector as bulkDeselectProductsResponseSelector
} from "selectors/bulkDeselectProductsSelector.js";

import { totalProductsCountSelector, buyplanItemsCountSelector } from "selectors/productsSelector.js";
import { activeTabSelector } from "selectors/filtersSelector.js";
import { selectedGroupByFilterSelector } from "selectors/groupedProductsSelector.js";
import {
  buyplanIdSelector,
  buyplanStateSelector
} from "selectors/buyplanIdSelector";
import { currentUserDataSelector } from "selectors/currentUserSelector";

// actions
import {
  resetBulkSelectProducts,
  requestBulkSelectProducts
} from "actions/bulkSelectProductsActions.js";
import {
  resetBulkDeselectProducts,
  requestBulkDeselectProducts
} from "actions/bulkDeselectProductsActions.js";

// custom utils
import {
  BULK_PRODUCT_ACTION_THRESHOLD,
  RANGE,
  CATALOGUE_ORDER,
  BUYPLAN,
  TEMPLATE
} from "constants.js";
import { isAdminUser } from "utils.js";
import { addNotification } from "notificationStore.js";
// presentational components
import OutlineButton from "components/Buttons/OutlineButton/OutlineButton.jsx";
import { LoaderIcon } from "components/Loader/Loader.jsx";
import i18next from "i18next";
import OperationConfirmationDialog from "./OperationConfirmationDialog";

const BulkProductActionsContainer = () => {
  const dispatch = useDispatch();

  const { releaseId } = useParams();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const onConfirmationDialogClosed = () => setIsConfirmationDialogOpen(false);

  let onSelectDangerLoclisationData = i18next.t(
    "bulkSelectThresholdExceeded",
    { amount: BULK_PRODUCT_ACTION_THRESHOLD }
  );
  let onDeSelectDangerLoclisationData = i18next.t(
    "bulkDeselectThresholdExceeded",
    { amount: BULK_PRODUCT_ACTION_THRESHOLD }
  );
  // bulk selection related variables
  const isBulkSelectProductsLoading = useSelector(
    isBulkSelectProductsLoadingSelector
  );
  const bulkSelectProductsResponse = useSelector(
    bulkSelectProductsResponseSelector
  );
  const isBulkSelectionSuccessful = bulkSelectProductsResponse.get("success");
  const bulkSelectionMessage = bulkSelectProductsResponse.get("message");

  // bulk de-selection related variables
  const isBulkDeselectProductsLoading = useSelector(
    isBulkDeselectProductsLoadingSelector
  );
  const bulkDeselectProductsResponse = useSelector(
    bulkDeselectProductsResponseSelector
  );
  const isBulkDeselectionSuccessful = bulkDeselectProductsResponse.get(
    "success"
  );
  const bulkDeselectionMessage = bulkDeselectProductsResponse.get("message");

  const isBulkActionInProgress =
    isBulkSelectProductsLoading || isBulkDeselectProductsLoading;
  const totalProductsCount = useSelector(totalProductsCountSelector);
  const buyplanItemsCount = useSelector(buyplanItemsCountSelector);
  const activeTab = useSelector(activeTabSelector);
  const selectedGroupByFilter = useSelector(selectedGroupByFilterSelector);
  const buyplanId = useSelector(buyplanIdSelector);
  const currentUser = useSelector(currentUserDataSelector);
  const buyplanState = useSelector(buyplanStateSelector);
  const isNonAdminUser = !isAdminUser(currentUser.get("role"));
  const isBuyplanOrTemplate = [
    BUYPLAN.toLowerCase(),
    TEMPLATE.toLowerCase()
  ].includes(buyplanState);

  const onSelectAllProducts = () => {
    if (totalProductsCount <= BULK_PRODUCT_ACTION_THRESHOLD) {
      dispatch(requestBulkSelectProducts(releaseId));
    } else {
      addNotification({
        type: "danger",
        message: onSelectDangerLoclisationData
      });
    }
  };

  const onDeselectAllProducts = () => {
    setIsConfirmationDialogOpen(false);
    if (buyplanItemsCount <= BULK_PRODUCT_ACTION_THRESHOLD) {
      dispatch(requestBulkDeselectProducts(releaseId));
    } else {
      addNotification({
        type: "danger",
        message: onDeSelectDangerLoclisationData
      });
    }
  };

  useEffect(() => {
    const msg = bulkSelectionMessage || bulkDeselectionMessage;

    if (isBulkSelectionSuccessful || isBulkDeselectionSuccessful) {
      msg &&
        addNotification({
          message: bulkSelectionMessage || bulkDeselectionMessage
        });
    } else {
      msg &&
        addNotification({
          type: "danger",
          message: bulkSelectionMessage || bulkDeselectionMessage
        });
    }

    return () => {
      dispatch(resetBulkSelectProducts());
      dispatch(resetBulkDeselectProducts());
    };
  }, [
    dispatch,
    isBulkSelectionSuccessful,
    isBulkDeselectionSuccessful,
    bulkSelectionMessage,
    bulkDeselectionMessage
  ]);

  if (totalProductsCount === 0) return null;

  if (
    buyplanId &&
    isNonAdminUser &&
    isBuyplanOrTemplate &&
    activeTab === RANGE.toLowerCase() &&
    selectedGroupByFilter.get("value") === CATALOGUE_ORDER
  ) {
    return (
      <div
        className="float-right pl-3"
        style={{
          height: "55px",
          borderTop: "1px solid #dddddd",
          borderBottom: "1px solid #dddddd",
          padding: "11.5px 0"
        }}
      >
        <OutlineButton
          variant="outline-dark"
          size="sm"
          style={{ height: "30px", marginRight: "10px" }}
          onClick={onSelectAllProducts}
          disabled={isBulkActionInProgress}
        >
          {isBulkActionInProgress ? (
            <LoaderIcon useDarkTheme={true} />
          ) : (
            <>
              <LineIcon name="checkmark" />
              &nbsp; {i18next.t("general.all")}
            </>
          )}
        </OutlineButton>

        <OutlineButton
          variant="outline-dark"
          size="sm"
          style={{ height: "30px" }}
          onClick={() => setIsConfirmationDialogOpen(true)}
          disabled={isBulkActionInProgress}
        >
          {isBulkActionInProgress ? (
            <LoaderIcon useDarkTheme={true} />
          ) : (
            <>
              <LineIcon name="trash" />
              &nbsp; {i18next.t("general.all")}
            </>
          )}
        </OutlineButton>
        <OperationConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          message={i18next.t('general.product_delete_confirmation')}
          onCloseCallback={onConfirmationDialogClosed}
          onAcceptCallback={onDeselectAllProducts}
          onRejectCallback={onConfirmationDialogClosed}
        />
      </div>
    );
  }
};

export default React.memo(BulkProductActionsContainer);
