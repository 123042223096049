import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { addNotification } from "notificationStore.js";

import { copyStockModelAsTemplate } from "actions/stockModelCopyActions.js";

import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";
import { copiedStockModelIdSelector } from "selectors/stockModelCopySelector.js";

import CustomModal from "components/CustomModal/index.jsx";
import CopyAsTemplateBody from "components/BuyplanReport/StockModal/CopyAsTemplateBody.jsx";
import OrderDialogFooter from "components/BuyplanReport/OrderDialogFooter.jsx";
import i18next from "i18next";

const CopyAsTemplateDialog = ({ className, isOpen, closeModalCallback }) => {
  const dispatch = useDispatch();
  const stockModalId = useSelector(stockModelIdSelector);
  const templateId = useSelector(copiedStockModelIdSelector);

  let loclisationData = i18next.t("stockModal.copyAsTemplate.success");
  const [copyName, setCopyName] = useState(null);
  const onCopyNameBlurCallback = useCallback(evt => {
    setCopyName(evt.target.value);
  }, []);

  const submitCopyNameCallback = useCallback(() => {
    dispatch(copyStockModelAsTemplate({ stockModalId, copyName }));
  }, [dispatch, stockModalId, copyName]);

  useEffect(() => {
    if (templateId) {
      addNotification({
        message: loclisationData
      });
    }
  }, [templateId, loclisationData]);

  return (
    isOpen && (
      <CustomModal
        className={className}
        isOpen={isOpen}
        title="Copy As Template"
        body={
          <CopyAsTemplateBody onCopyNameBlurCallback={onCopyNameBlurCallback} />
        }
        footer={
          <OrderDialogFooter
            closeModalFn={closeModalCallback}
            onClickCallback={submitCopyNameCallback}
          />
        }
        closeModalCallback={closeModalCallback}
      />
    )
  );
};

export default React.memo(CopyAsTemplateDialog);
