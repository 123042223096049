import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { addNotification } from "notificationStore.js";
import { BiHide, BiShow } from "react-icons/bi";
import styled from "styled-components";
import { getCurrentUser } from "actions/currentUserActions.js";

import { filtersReducerSelector } from "selectors/filtersSelector.js";
import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import { productReorderListDataSelector } from "selectors/productReorderListSelector";
import { currentUserDataSelector, isLoadingSelector } from "selectors/currentUserSelector.js";

import {
  getProductListReorder,
  updateProductReorderList
} from "actions/productReorderListActions.js";
import { getProductReorder } from "actions/productReorderActions.js";

import { PER_PAGE } from "constants.js";

import GlobalMenuContainer from "containers/GlobalMenuContainer";
import RangeSlider from "containers/RangeSlider.jsx";
import ProductList from "components/Dnd/ProductList.jsx";
import Loader from "components/Loader/Loader.jsx";
import CustomModal from "components/CustomModal/index.jsx";
import i18next from "i18next";

const StyledSubmitBtn = styled.button`
  background-color: var(--brandscope-blue);
  border-color: var(--brandscope-blue);
  width: 40%;
`;
const CardWraper = styled.div`
  font-size: ${props => props.width};
`;

const DND = ({ className }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { releaseId } = useParams();
  const currentUser = useSelector(currentUserDataSelector);
  const isUserLoading = useSelector(isLoadingSelector);
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const buyPlanState = queryParams.get("type");
  const filters = useSelector(filtersReducerSelector);
  const appliedFilters = filters.get("appliedFilters");
  const data = useSelector(productReorderListDataSelector);
  const [isproductsDataChanged, setIsproductsDataChanged] = useState(false);
  const [textDisappear, setTextDisappear] = useState(false);
  const buyplanId = useSelector(buyplanIdSelector);
  const [parentVal, setParentVal] = useState(100);
  const [isPopup, setIsPopup] = useState(false);
  useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(
      getProductListReorder(
        releaseId,
        PER_PAGE,
        0,
        appliedFilters,
        true,
        buyPlanState,
        buyplanId,
        ""
      )
    );
  }, [dispatch, releaseId, appliedFilters, buyPlanState, buyplanId]);

  const onSetList = useCallback(
    newList => {
      const shouldDispatch = newList.some(data => !data.chosen);
      const islistChanges = newList.some(data => data.chosen);
      islistChanges && setIsproductsDataChanged(islistChanges);
      shouldDispatch && dispatch(updateProductReorderList({ data: newList }));
    },
    [dispatch]
  );

  const onSubmit = () => {
    let productIds = [];
    const products = getProductsData();

    products.forEach(product => {
      productIds.push(product.id);
    });
    //dispatch save re-arrange sequence api
    dispatch(
      getProductReorder({
        releaseId,
        reorder: productIds
      })
    );

    dispatch(
      getProductListReorder(
        releaseId,
        PER_PAGE,
        0,
        appliedFilters,
        true,
        buyPlanState,
        buyplanId,
        ""
      )
    );
    redirectToProductsPage(history);
  };
  const hideText = () => {
    setTextDisappear(true);
  };
  const showText = () => {
    setTextDisappear(false);
  };
  const redirectToProductsPage = history => {
    const path = history.location.pathname.split("/");
    const index = path.indexOf("products");
    history.push(path.slice(0, index + 1).join("/"));
  };
  const handleReturn = () => {
    if (!isproductsDataChanged) redirectToProductsPage(history);
    else setIsPopup(true);
  };
  const closeModalCallback = () => {
    setIsPopup(false);
  };
  const getProductsData = () => {
    const products = Object.assign(data?.get("pr"));
    return products?.toJS();
  };
  const isDndAccessible = currentUser.get("is_dnd_accessible");
  if (!isUserLoading && !isDndAccessible) {
    addNotification({
      type: "danger",
      message: i18next.t("dnd.errors.unauthorized")
    });
    redirectToProductsPage(history);
    return <Loader />;
  }
  const modalFooter = (
    <div>
      <button className="btn btn-primary rounded" onClick={onSubmit}>
        Yes
      </button>
      <button
        className="btn rounded"
        onClick={() => {
          const products = getProductsData();
          dispatch(updateProductReorderList({ data: products }));
          redirectToProductsPage(history);
        }}
      >
        {i18next.t("general.no")}
      </button>
    </div>
  );
  const modalBody = (
    <div>
      <h6>{i18next.t("dnd.messages.unsavedChanges")}</h6>
    </div>
  );

  return (
    <>
      <GlobalMenuContainer />

      <div className={className}>
        <div id="dnd" className={className}>
          <div className={`editable-buttons ${className}`} id="newButton">
            <RangeSlider parentVal={parentVal} setParentVal={setParentVal} />

            {!textDisappear && <BiHide className={`textHide ${className}`} onClick={hideText} />}
            {textDisappear && <BiShow className={`textHide ${className}`} onClick={showText} />}

            <StyledSubmitBtn
              className="btn btn-primary editable-submit"
              onClick={() => {
                onSubmit();
              }}
            >
              <i className="icon-ok icon-white" />
              {i18next.t("buttons.save")}
            </StyledSubmitBtn>

            <button type="button" className="btn editable-cancel ml-1" onClick={handleReturn}>
              <i className="icon-remove" />
              {i18next.t("buttons.return")}
            </button>
          </div>
          {data && data.size && (
            <CardWraper className="cardWrapper">
              <ReactSortable
                id="dragNdrop"
                tag="div"
                group="shared"
                list={data?.get("pr").toJS()}
                setList={onSetList} //mandatory prop
                // eslint-disable-next-line no-empty-function
                onChange={() => {}}
                animation={150}
              >
                {data
                  ?.get("pr")
                  .toJS()
                  .map((product, index) => (
                    <ProductList
                      product={product}
                      index={index}
                      textDisappear={textDisappear}
                      scale={parentVal}
                      key={index}
                    />
                  ))}
              </ReactSortable>
            </CardWraper>
          )}
          {isPopup && (
            <CustomModal
              isOpen={isPopup}
              body={modalBody}
              footer={modalFooter}
              closeModalCallback={closeModalCallback}
            />
          )}
        </div>
      </div>
    </>
  );
};

const StyledDND = styled(DND)`
  #dnd {
    margin-left: 5%;
  }
  #newButton {
    float: right;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 5%;
    display: flex;
  }
  .textHide {
    display: inline-block;
    margin-left: 0px;
    min-height: 46px;
    text-align: center;
    border: 1px solid transparent;
    position: relative;
    width: 46px;
    margin-right: 12px;
  }
  #dragNdrop {
    display: inline-block;
  }
`;

export default React.memo(StyledDND);
