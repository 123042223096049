import { takeLeading, put } from "redux-saga/effects";

import { orderValidationsActions } from "actions/orderValidationsActions.js";

import { GET_DATA } from "actions/apiOperations.js";

import { addNotification } from "notificationStore.js";

import i18next from "i18next";

export const requestOrderValidations = function*(action) {
  const { buyplanId, ...params } = action.payload;

  var custom_address = params.buyplan.order_detail_attributes.custom_shipping_address

  if (isCustomAddressValid(custom_address)) {
    yield put({
      type: GET_DATA,
      path: `/api/buyplans/${buyplanId}/submit_order_validations.json`,
      actions: orderValidationsActions,
      params
    });
  }
};

export const watchRequestOrderValidations = function*() {
  yield takeLeading(orderValidationsActions.request, requestOrderValidations);
};

export const requestStockModalOrderValidations = function*(action) {
  const { stockModalId, ...params } = action.payload;

  var custom_address = params.stock_model.order_detail_attributes.custom_shipping_address

  if (isCustomAddressValid(custom_address)) {
    yield put({
      type: GET_DATA,
      path: `/api/stock_models/${stockModalId}/submit_order_validations.json`,
      actions: orderValidationsActions,
      params
    });
  }
};

export const watchRequestStockModalOrderValidations = function*() {
  yield takeLeading(
    orderValidationsActions.requestStockModalValidations,
    requestStockModalOrderValidations
  );
};

const isCustomAddressValid = (custom_address) => {
  var is_address_valid = true

  if (hasRestrictedChars(custom_address.company_name)) {
    is_address_valid = false
    showFieldError(i18next.t("address.form.labels.companyName"))
  }

  if (hasRestrictedChars(custom_address.customer_name)) {
    is_address_valid = false
    showFieldError(i18next.t("address.form.labels.customerName"))
  }

  if (hasRestrictedChars(custom_address.line1)) {
    is_address_valid = false
    showFieldError(i18next.t("address.form.labels.line1"))
  }

  if (hasRestrictedChars(custom_address.line2)) {
    is_address_valid = false
    showFieldError(i18next.t("address.form.labels.line2"))
  }

  if (hasRestrictedChars(custom_address.city)) {
    is_address_valid = false
    showFieldError(i18next.t("address.form.labels.city"))
  }

  if (hasRestrictedChars(custom_address.state)) {
    is_address_valid = false
    showFieldError(i18next.t("address.form.labels.state"))
  }

  if (hasRestrictedChars(custom_address.postcode)) {
    is_address_valid = false
    showFieldError(i18next.t("address.form.labels.postCode"))
  }

  return is_address_valid
};

const hasRestrictedChars = (value) => {
  if (["", null, undefined].includes(value)) { return false }

  const specialChars = /[*!|,%#@():;"'/’<>?^.\t]/;

  return specialChars.test(value)
};

const showFieldError = (fieldName) => {
  addNotification({
    type: "danger",
    message: i18next.t("address.form.errors.specialCharactersNotAllowed", { fieldName })
  });
};
