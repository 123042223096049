import { takeEvery } from "redux-saga/effects";

import { GET_DATA } from "actions/apiOperations.js";

import { getJSON } from "apis/apiHelpers.js";

import sharedApiSaga from "sagas/sharedApiSaga.js";

export default function* watchGetData() {
  yield takeEvery(GET_DATA, getData);
}

export const getData = function*({ path, actions, params }) {
  return yield* sharedApiSaga(getJSON, path, actions, params);
};
