import { createSelector } from "reselect";

export const quantityGridReducerSelector = createSelector(
  state => state.get("quantityGridReducer"),
  quantityGridReducer => quantityGridReducer
);

export const isLoadingSelector = createSelector(
  quantityGridReducerSelector,
  quantityGridReducer => quantityGridReducer.get("isLoading")
);

export const errorSelector = createSelector(
  quantityGridReducerSelector,
  quantityGridReducer => quantityGridReducer.get("error")
);

export const dataSelector = createSelector(
  quantityGridReducerSelector,
  quantityGridReducer => quantityGridReducer.get("data")
);

export const atsSelector = createSelector(dataSelector, data =>
  data.get("ats")
);

export const brandSelector = createSelector(dataSelector, data =>
  data.get("brand")
);

export const showHorizontalHeaderSelector = createSelector(
  brandSelector,
  brand => brand.get("show_horizontal_header")
);

export const selectedConfigSelector = createSelector(brandSelector, brand =>
  brand.get("selected_config")
);

export const showSizeLevelPricingSelector = createSelector(
  brandSelector,
  brand => brand.get("show_size_level_pricing")
);

export const headersSelector = createSelector(dataSelector, data =>
  data.get("headers")
);

export const sizeMinsSelector = createSelector(dataSelector, data =>
  data.get("size_mins")
);

export const attributeSeqSelector = createSelector(dataSelector, data =>
  data.get("attribute_sequence")
);

export const abilitiesSelector = createSelector(dataSelector, data =>
  data.get("abilities")
);

export const poDatesSelector = createSelector(dataSelector, data =>
  data.get("po_dates")
);

export const productReleaseSelector = createSelector(dataSelector, data =>
  data.get("product_release")
);

export const deliveryMonthsSelector = createSelector(dataSelector, data =>
  data.get("delivery_months")
);

export const sizeSequencesSelector = createSelector(dataSelector, data =>
  data.get("size_sequence")
);

export const quantitiesSelector = createSelector(dataSelector, data =>
  data.get("quantities")
);

export const sizeBreaksSelector = createSelector(dataSelector, data =>
  data.get("size_breaks")
);

export const qtyLabelsSelector = createSelector(dataSelector, data =>
  data.get("quantity_labels")
);

export const lowQtyOffsetSelector = createSelector(dataSelector, data =>
  data.getIn(["brand", "low_quantity_offset"])
);

export const buyplanItemSelector = createSelector(dataSelector, data => {
  return data.get("buyplan_item") || data.get("stock_model_item");
});

export const showDiscountPercentSelector = createSelector(abilitiesSelector,
  abilities => abilities.get("show_discount_percent")
);

export const preOrderDeliveryMonthSelector = createSelector(dataSelector, data =>
  data.get("pre_order_delivery_month")
);

export const isPreOrderProductSelector = createSelector(dataSelector, data =>
  data.get("is_pre_order_product")
);

export const preOrderMessageSelector = createSelector(dataSelector, data =>
  data.get("pre_order_message")
);

export const productNameSelector = createSelector(dataSelector, data =>
  data.getIn(["product_release", "product_name"])
);

export const productSkuSelector = createSelector(dataSelector, data =>
  data.getIn(["product_release", "product_sku"])
);

export const productImgUrlSelector = createSelector(dataSelector, data =>
  data.getIn(["buyplan_item", "primary_image_url"]) || data.getIn(["stock_model_item", "primary_image_url"])
);

export const logSelector = createSelector(
  quantityGridReducerSelector,
  quantityGridReducer => quantityGridReducer.get("scanLogs")
);

export const barcodeScanningStatus = createSelector(
  quantityGridReducerSelector,
  quantityGridReducer => quantityGridReducer.get("barcodeScanningFailed")
);

export const bpsbToHighlightSelector = createSelector(dataSelector, data =>
  data.get("bpsb_to_highlight")
);