export const qtyGridActions = {
  startLoader: "START_STOCK_MODAL_QTY_GRID_LOADER",
  requestQtyGrid: "REQUEST_STOCK_MODAL_QTY_GRID",
  qtyGridSuccess: "STOCK_MODAL_QTY_GRID_SUCCESS",
  qtyGridFailure: "STOCK_MODAL_QTY_GRID_FAILURE",
  requestUpdateQty: "REQUEST_UPDATE_STOCK_MODAL_QUANTITY",
  updateQtySuccess: "UPDATE_STOCK_MODAL_QUANTITY_SUCCESS",
  updateQtyFailure: "UPDATE_STOCK_MODAL_QUANTITY_FAILURE",
  reset: "RESET_STOCK_MODAL_QUANTITY_GRID"
};

export const requestQtyGrid = ({
  stockModalId,
  stockModalItemId,
  productReleaseId,
  warehouseId
}) => {
  return {
    type: qtyGridActions.requestQtyGrid,
    payload: {
      stockModalId,
      stockModalItemId,
      productReleaseId,
      warehouse_id: warehouseId
    }
  };
};

export const requestUpdateStockModalQty = ({
  stockModalId,
  stockModalItemId,
  warehouseId,
  sizeBreakId,
  stockQuantity,
  countQuantity,
  fillQuantity,
  options = {}
}) => {
  return {
    type: qtyGridActions.requestUpdateQty,
    payload: {
      stockModalId,
      stockModalItemId,
      warehouse_id: warehouseId,
      sizeBreakId,
      stock_quantity: stockQuantity,
      count_quantity: countQuantity,
      fill_quantity: fillQuantity,
      options: {
        shouldFetchTotals: options.shouldFetchTotals || false
      }
    }
  };
};

export const resetQuantityGrid = () => {
  return {
    type: qtyGridActions.reset
  };
};
