import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import List from "@researchgate/react-intersection-list";
import { CSSTransition } from "react-transition-group";

import "assets/stylesheets/transition.css";

import {
  isLoadingSelector,
  hasErrorsSelector,
  productsDataSelector,
  displayWsRangeSelector,
  showDiscountPercentSelector
} from "selectors/productsSelector.js";
import { filtersReducerSelector, isDataLoadingSelector } from "selectors/filtersSelector.js";
import { stockModelCnfTypeSelector } from "selectors/buyplanInfoSelector.js";
import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";
import { currentUserDataSelector } from "selectors/currentUserSelector.js";

import { getStockModalProducts } from "actions/productsActions.js";
import { getStockModalFilters } from "actions/filterActions.js";

import FilterPillContainer from "containers/FilterPillContainer.jsx";
import ProductCard from "components/ProductCard/ProductCard.jsx";
import Loader from "components/Loader/Loader.jsx";

import { CNF_RANGE } from "constants.js";
import i18next from "i18next";

export const PER_PAGE = 30;

const itemsRenderer = (items, ref) => {
  return <div ref={ref}>{items}</div>;
};

const getProductsListLoader = () => <Loader />;

let prevOffset = 0;

const ProductsListContainer = () => {
  const { releaseId } = useParams();
  const stockModalId = useSelector(stockModelIdSelector);

  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);
  const hasErrors = useSelector(hasErrorsSelector);
  const data = useSelector(productsDataSelector);
  const cnfType = useSelector(stockModelCnfTypeSelector);

  const filters = useSelector(filtersReducerSelector);
  const isFetchingFilters = filters.get("isLoading");
  const appliedFilters = filters.get("appliedFilters");

  const currentUser = useSelector(currentUserDataSelector);
  const hideWholesalePrice = currentUser.get("hide_ws_price");
  const showWsRange = useSelector(displayWsRangeSelector);
  const showDiscountPercent = useSelector(showDiscountPercentSelector);
  const isDataLoading = useSelector(isDataLoadingSelector);

  const itemRenderer = index => {
    let product = data.getIn(["pr", index]);
    if (product) {
      let prdct = product.product;
      const isThreeSixty = (data.has('tenant_id') && data?.get("tenant_id"))?true:false;
      const tenantId = (data.has('tenant_id') && data?.get("tenant_id")) || null;
      const brandScopeTag = prdct?.product_orb360_tag_value || null;
      return (
        <ProductCard
          key={`${product.id}-${prdct.display_colour}-${prdct.sku}-${index}`}
          imgPath={prdct.primary_image}
          imgAltText={prdct.name}
          productId={product.product_id}
          releaseId={product.release_id}
          showMonths={false}
          captionName={prdct.name}
          captionColor={prdct.display_colour}
          hideWholesalePrice={hideWholesalePrice}
          retailPriceFormatted={product.retail_price_formatted}
          displayUnitRetailPrice={product.display_unit_retail_price}
          captionSku={prdct.sku}
          isDiscounted={product["discounted?"]}
          pbDiscounted={product.pb_discounted}
          effectiveDisplayWholesalePrice={
            product.effective_display_wholesale_price
          }
          discountedDisplayWholesaleRange={
            product.discounted_display_wholesale_range
          }
          discountedDisplayWholesalePrice={
            product.discounted_wholesale_price_formatted
          }
          showWsRange={showWsRange}
          displayWholesaleRange={product.display_wholesale_range}
          brandScopeTag={brandScopeTag}
          isThreeSixty={isThreeSixty}
          tenantId={tenantId}
          displayWholesalePrice={product.display_wholesale_price}
          pbPercentOff={product.pb_percent_off}
          showDiscountPercent={showDiscountPercent}
          productCallout={product.callout_label}
          calloutBgColor={product.callout_bg_color}
          calloutBrColor={product.callout_br_color}
          calloutTxtColor={product.callout_txt_color}
          calloutIcon={product.callout_icon}
        />
      );
    }
  };

  const onIntersection = (size) => {
    let newOffset = prevOffset + PER_PAGE;

    if (size !== prevOffset) {
      dispatch(
        getStockModalProducts(
          releaseId,
          stockModalId,
          PER_PAGE,
          newOffset,
          appliedFilters,
          false
        )
      );
      prevOffset = newOffset;
    }
  };

  useEffect(() => {
    if (cnfType) {
      dispatch(getStockModalFilters(releaseId, stockModalId, CNF_RANGE));
    }
  }, [dispatch, releaseId, stockModalId, cnfType]);

  useEffect(() => {
    prevOffset = 0;

    if (isDataLoading === false) {
      dispatch(
        getStockModalProducts(
          releaseId,
          stockModalId,
          PER_PAGE,
          0,
          appliedFilters,
          true
        )
      );
    }
  }, [dispatch, isFetchingFilters, releaseId, stockModalId, appliedFilters]);

  const totalProductsCount = data.get("count");
  const loadedProducts = data.get("pr");
  const loadedProductsCount = loadedProducts.size;
  const awaitingMoreProducts = () => data.get("mostRecentPrSize") === PER_PAGE;

  return (
    <>
      {isLoading && getProductsListLoader()}

      {hasErrors && <div className="error-msg">{data.get("message")}</div>}

      {!isLoading && totalProductsCount === 0 && (
        <div className="d-flex justify-content-center align-items-center h-100">
          {i18next.t("dnd.messages.noProductsFound")}
        </div>
      )}

      <CSSTransition
        classNames="fade"
        timeout={1200}
        in={!isLoading && !hasErrors && totalProductsCount > 0}
        unmountOnExit
      >
        <>
          <FilterPillContainer />
          <List
            pageSize={PER_PAGE}
            itemCount={loadedProductsCount}
            awaitMore={awaitingMoreProducts()}
            threshold="50%"
            itemsRenderer={itemsRenderer}
            renderItem={itemRenderer}
            onIntersection={onIntersection}
          />

          {awaitingMoreProducts() && getProductsListLoader()}
        </>
      </CSSTransition>
    </>
  );
};

export default React.memo(ProductsListContainer);
