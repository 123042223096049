import { fromJS } from "immutable";

import { CATALOGUE_ORDER } from "constants.js";

import { filterActions } from "actions/filterActions.js";
import { warehouseActions } from "actions/warehouseActions.js";

const initialState = fromJS({
  isLoading: true,
  isDataLoading: true,
  hasErrors: false,
  error: null,
  activeTab: "range",
  fetchedFilters: {
    show_segment_filter: false,
    segment_filter_label: null,
    common_filters: {
      search: null, // currently not in response
      filter_sequence: [], // currently not in response
      only_buy_plan_items: 0,
      exclude_sold_out_items: 0,
      min_price: 0,
      max_price: 0,
      currency: "AUD",
      currency_symbol: "$"
    },
    dynamic_filters: {
      filter_sequence: [], // currently not in response
      segments: [],
      divisions: [],
      collections: []
    }
  },
  appliedFilters: {
    common_filters: {
      search: null, // currently not in response
      filter_sequence: [], // currently not in response
      only_buy_plan_items: null,
      exclude_sold_out_items: null,
      min_price: null,
      max_price: null
    },
    dynamic_filters: {
      filter_sequence: [], // currently not in response
      segments: [],
      divisions: [],
      collections: [],
      category1: [],
      category2: [],
      category3: [],
      category4: []
    },
    tell_story_by: CATALOGUE_ORDER,
    open_filter_groups: {}
  }
});

export default (state = initialState, action) => {
  const payload = action.payload;
  const commonCategories = [
    "only_buy_plan_items",
    "only_price_book_items",
    "count_and_fill_only",
    "exclude_sold_out_items",
    "only_pre_order_products"
  ];

  let filterObj;

  switch (action.type) {
    case filterActions.startLoader:
      return state.set("isLoading", true);
    case filterActions.success:
      let dynamicFiltersSeq = [];
      const { applied_filters: appliedFilters, ...rest } = payload.data;

      if (payload.metadata && payload.metadata.rangeType === "quickFill") {
        const { segments, ...rest } = payload.data?.dynamic_filters || payload.data.fetchedFilters.dynamic_filters;

        if (segments) {
          dynamicFiltersSeq = {segments, ...rest};
        } else {
          dynamicFiltersSeq = rest;
        }
      } else {
        dynamicFiltersSeq = payload.data?.dynamic_filters || payload.data.fetchedFilters.dynamic_filters ;
      }

      return state
        .merge({
          isLoading: false,
          hasErrors: false,
          isDataLoading: false,
          error: null
        })
        .mergeIn(["fetchedFilters"], fromJS(rest))
        .mergeIn(["fetchedFilters", "common_filters"], {
          filter_sequence: fromJS(Object.keys(payload.data?.common_filters || payload.data.fetchedFilters.common_filters))
        })
        .mergeIn(["fetchedFilters", "dynamic_filters"], {
          filter_sequence: fromJS(Object.keys(dynamicFiltersSeq))
        })
        .mergeIn(["appliedFilters"], fromJS(appliedFilters));
    case filterActions.failure:
      return initialState.merge({
        isLoading: false,
        hasErrors: true,
        isDataLoading: false,
        error: fromJS(payload.data)
      });
    case filterActions.applyFilters:
      state = state.merge({
        isDataLoading: false
      })

      if (payload.category !== undefined) {
        const { category, level } = payload;
        let newState = state;
        let cat1FilterObj, cat2FilterObj, cat3FilterObj;

        switch (level) {
          case 0:
            newState = state
              .setIn(["appliedFilters", "dynamic_filters", "category1"], fromJS([]))
              .setIn(["appliedFilters", "dynamic_filters", "category2"], fromJS([]))
              .setIn(["appliedFilters", "dynamic_filters", "category3"], fromJS([]))
              .setIn(["appliedFilters", "dynamic_filters", "category4"], fromJS([]));
            break;
          case 1:
            cat1FilterObj = state
              .getIn(["fetchedFilters", "dynamic_filters", "category1"])
              .find(obj => obj.get("name") === category);

            newState = state
              .setIn(["appliedFilters", "dynamic_filters", "category1"], fromJS([cat1FilterObj]))
              .setIn(["appliedFilters", "dynamic_filters", "category2"], fromJS([]))
              .setIn(["appliedFilters", "dynamic_filters", "category3"], fromJS([]))
              .setIn(["appliedFilters", "dynamic_filters", "category4"], fromJS([]));
            break;
          case 2:
            cat2FilterObj = state
              .getIn(["fetchedFilters", "dynamic_filters", "category2"])
              .find(obj => obj.get("name") === category);

            cat1FilterObj = state
              .getIn(["fetchedFilters", "dynamic_filters", "category1"])
              .find(obj => cat2FilterObj.get("parent_id") === obj.get("name"));

            newState = state
              .setIn(["appliedFilters", "dynamic_filters", "category2"], fromJS([cat2FilterObj]))
              .setIn(["appliedFilters", "dynamic_filters", "category1"], fromJS([cat1FilterObj]))
              .setIn(["appliedFilters", "dynamic_filters", "category3"], fromJS([]))
              .setIn(["appliedFilters", "dynamic_filters", "category4"], fromJS([]));
            break;
          case 3:
            cat3FilterObj = state
              .getIn(["fetchedFilters", "dynamic_filters", "category3"])
              .find(obj => obj.get("name") === category);

            cat2FilterObj = state
              .getIn(["fetchedFilters", "dynamic_filters", "category2"])
              .find(obj => cat3FilterObj.get("parent_id") === obj.get("name"));

            cat1FilterObj = state
              .getIn(["fetchedFilters", "dynamic_filters", "category1"])
              .find(obj => cat2FilterObj.get("parent_id") === obj.get("name"));

            newState = state
              .setIn(["appliedFilters", "dynamic_filters", "category3"], fromJS([cat3FilterObj]))
              .setIn(["appliedFilters", "dynamic_filters", "category2"], fromJS([cat2FilterObj]))
              .setIn(["appliedFilters", "dynamic_filters", "category1"], fromJS([cat1FilterObj]))
              .setIn(["appliedFilters", "dynamic_filters", "category4"], fromJS([]));
            break;
          default:
            return state;
        }

        return newState;
      }

      if (!payload.categoryId) {
        return state.mergeIn(["appliedFilters", "common_filters"], fromJS(payload));
      }

      if (commonCategories.includes(payload.categoryId)) {
        return state.setIn(["appliedFilters", "common_filters", payload.categoryId], 1);
      }

      let filterCategory = state.getIn(["appliedFilters", "dynamic_filters", payload.categoryId]);

      if (!filterCategory) {
        filterCategory = state
          .mergeIn(
            ["appliedFilters", "dynamic_filters"],
            fromJS({
              [payload.categoryId]: []
            })
          )
          .getIn(["appliedFilters", "dynamic_filters", payload.categoryId]);
      }

      filterObj = filterCategory.push(
        fromJS({
          name: payload.dataset.name,
          segment_id: payload.dataset.segment_id,
          division_id: payload.dataset.division_id,
          collection_id: payload.dataset.collection_id
        })
      );

      return state.setIn(["appliedFilters", "dynamic_filters", payload.categoryId], filterObj);
    case filterActions.removeFilters:
      if (commonCategories.includes(payload.categoryId)) {
        return state.merge({isLoading: false, isDataLoading: false}).setIn(["appliedFilters", "common_filters", payload.categoryId], 0);
      }

      if (payload.filterId === "All") {
        return state.merge({isLoading: false, isDataLoading: false}).setIn(
          ["appliedFilters", "dynamic_filters", payload.categoryId],
          state.getIn(["appliedFilters", "dynamic_filters", payload.categoryId]).clear()
        );
      }

      const categoryName = `${payload.categoryId.replace(/.$/, "")}_id`;

      return state.merge({isLoading: false, isDataLoading: false}).setIn(
        ["appliedFilters", "dynamic_filters"],
        state.getIn(["appliedFilters", "dynamic_filters"]).map((filterObj, filterName) => {
          if (filterName !== payload.categoryId) {
            return filterObj.filter(subFilter => {
              return subFilter.get(categoryName) !== payload.filterId;
            });
          }

          return filterObj.filter(subFilter => subFilter.get("name") !== payload.filterId);
        })
      );
    case filterActions.setActiveTab:
      return state.merge({
        isDataLoading: true,
        activeTab: payload.activeTab
      });
    case filterActions.toggleFilterGroupState:
      return state.mergeIn(
        ["appliedFilters", "open_filter_groups"],
        fromJS({
          [payload.groupName]: payload.isOpen
        })
      );
    case warehouseActions.update:
      return state.merge({
        isDataLoading: false
      });
    default:
      return state;
  }
};
