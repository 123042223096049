import { spawn } from "redux-saga/effects";

import watchGetData from "sagas/getDataSaga.js";
import watchPostData from "sagas/postDataSaga.js";
import watchPutData from "sagas/putDataSaga.js";
import watchPatchData from "sagas/patchDataSaga.js";
import watchDeleteData from "sagas/deleteDataSaga.js";
import watchFetchToken from "sagas/fetchTokenSaga.js";
import { watchRequestCurrentUser } from "sagas/currentUserSaga.js";
import {
  watchRequestProducts,
  watchRequestQuickFillProducts,
  watchRequestStockModalProducts
} from "sagas/productsSaga.js";
import { watchRequestGroupByProducts } from "sagas/groupByProductsSaga.js";
import {
  watchRequestFilters,
  watchQuickFillRequestFilters,
  watchStockModalRequestFilters
} from "sagas/filtersSaga.js";
import {
  watchAddBuyplanItem,
  watchRemoveBuyplanItem,
  watchAddStockModalItem,
  watchRemoveStockModalItem
} from "sagas/likeDislikeSaga.js";
import { watchRequestProductRelease } from "sagas/productReleaseSaga.js";
import { watchUpdateMyNote } from "sagas/myNoteSaga.js";
import { watchUpdateAgentNote } from "sagas/agentNoteSaga.js";
import {
  watchAddSizeBreak,
  watchRemoveSizeBreak
} from "sagas/sizeBreakSaga.js";
import {
  watchRequestQuantityGrid,
  watchRequestUpdateQuantityGrid
} from "sagas/quantityGridSaga.js";
import {
  watchRequestQtyGrid,
  watchRequestUpdateQty
} from "sagas/StockModal/qtyGridSaga.js";
import {
  watchStockModelRequestQtyGrid,
  watchBuyplanRequestQtyGrid
} from "sagas/StockModal/barcodeScanningSaga.js";
import {
  watchRequestBuyplanDetails,
  watchRequestStockModalBuyplanDetails
} from "sagas/buyplanDetailsSaga.js";
import { watchRequestBuyplanSummary } from "sagas/buyplanSummarySaga.js";
import {
  watchRequestBuyplanTotals,
  watchRequestStockModalBuyplanTotals
} from "sagas/buyplanTotalsSaga.js";
import { watchRequestReOrderBuyplanMonths } from "sagas/reOrderBuyplanMonthsSaga.js";
import {
  watchRequestBuyplanReport,
  watchRequestStockModalBuyplanReport
} from "sagas/buyplanReportSaga.js";
import { watchRequestInvalidQtyProducts } from "sagas/invalidQtyProductsSaga.js";
import { watchRequestInvalidStockModelQtyProducts } from "sagas/StockModal/invalidQtyProductsSaga";
import {
  watchRequestOrderDetails,
  watchRequestStockModalOrderDetails
} from "sagas/orderDetailsSaga.js";
import {
  watchRequestOrderValidations,
  watchRequestStockModalOrderValidations
} from "sagas/orderValidationsSaga.js";
import {
  watchRequestPayment
} from "sagas/stripePaymentSaga.js";
import {
  watchRequestOrder,
  watchRequestConsignmentRequest,
  watchRequestStockModalOrder
} from "sagas/orderSaga.js";
import { watchRequestBannerInfo } from "sagas/bannerInfoSaga.js";
import {
  watchRequestBuyplanInfo,
  watchRequestStockModalInfo
} from "sagas/buyplanInfoSaga.js";
import { watchQuickFillBannerInfo } from "sagas/quickFillBannerInfoSaga.js";
import { watchRemoveAllBuyplanItems } from "sagas/removeAllBuyplanItemsSaga.js";
import { watchRequestGlobalMenu } from "sagas/globalMenuSaga.js";
import {
  watchRequestBuyplanId,
  watchRequestBuyplanIdForQfill
} from "sagas/buyplanIdSaga.js";
import {
  watchRequesWarehouse,
  watchRequesWarehouseForStockModel
} from "sagas/warehouseSaga.js";
import { watchRequestStockModelId } from "sagas/stockModelIdSaga.js";
import { watchRequestStockModelCopy } from "sagas/stockModelCopySaga.js";
import { watchRequestShortUrl } from "sagas/generateShortUrlSaga.js";
import { watchRequestShortUrls } from "sagas/listShortUrlsSaga.js";
import { watchRequestOverwriteShortUrl } from "sagas/overwriteShortUrlSaga.js";
import { watchRequestBulkSelectProducts } from "sagas/bulkSelectProductsSaga.js";
import { watchRequestBulkDeselectProducts } from "sagas/bulkDeselectProductsSaga.js";
import {
  watchRequestBuyplanSalesPersonUpdate,
  watchRequestStockModelSalesPersonUpdate
} from "sagas/updateSalesPersonSaga";
import { watchRequestDeclineOrder } from "./declineOrderSaga";
import { watchRequestProductReorder } from "sagas/productReorderSaga";
import { watchRequestProductReorderList } from "sagas/productReorderListSaga";
import { watchrequestAtsData } from "sagas/viewAtsSaga.js";

export default function*() {
  yield spawn(watchGetData);
  yield spawn(watchPostData);
  yield spawn(watchPutData);
  yield spawn(watchPatchData);
  yield spawn(watchDeleteData);
  yield spawn(watchFetchToken);
  yield spawn(watchRequestCurrentUser);
  yield spawn(watchRequestProducts);
  yield spawn(watchRequestQuickFillProducts);
  yield spawn(watchRequestStockModalProducts);
  yield spawn(watchRequestGroupByProducts);
  yield spawn(watchRequestFilters);
  yield spawn(watchQuickFillRequestFilters);
  yield spawn(watchStockModalRequestFilters);
  yield spawn(watchAddBuyplanItem);
  yield spawn(watchRemoveBuyplanItem);
  yield spawn(watchAddStockModalItem);
  yield spawn(watchRemoveStockModalItem);
  yield spawn(watchRequestProductRelease);
  yield spawn(watchUpdateMyNote);
  yield spawn(watchUpdateAgentNote);
  yield spawn(watchAddSizeBreak);
  yield spawn(watchRemoveSizeBreak);
  yield spawn(watchRequestQuantityGrid);
  yield spawn(watchRequestQtyGrid);
  yield spawn(watchStockModelRequestQtyGrid);
  yield spawn(watchBuyplanRequestQtyGrid);
  yield spawn(watchRequestUpdateQuantityGrid);
  yield spawn(watchRequestUpdateQty);
  yield spawn(watchRequestBuyplanDetails);
  yield spawn(watchRequestStockModalBuyplanDetails);
  yield spawn(watchRequestBuyplanSummary);
  yield spawn(watchRequestBuyplanTotals);
  yield spawn(watchRequestStockModalBuyplanTotals);
  yield spawn(watchRequestReOrderBuyplanMonths);
  yield spawn(watchRequestBuyplanReport);
  yield spawn(watchRequestStockModalBuyplanReport);
  yield spawn(watchRequestInvalidQtyProducts);
  yield spawn(watchRequestInvalidStockModelQtyProducts);
  yield spawn(watchRequestOrderDetails);
  yield spawn(watchRequestStockModalOrderDetails);
  yield spawn(watchRequestOrderValidations);
  yield spawn(watchRequestStockModalOrderValidations);
  yield spawn(watchRequestOrder);
  yield spawn(watchRequestConsignmentRequest);
  yield spawn(watchRequestStockModalOrder);
  yield spawn(watchRequestBannerInfo);
  yield spawn(watchRequestBuyplanInfo);
  yield spawn(watchRequestStockModalInfo);
  yield spawn(watchQuickFillBannerInfo);
  yield spawn(watchRemoveAllBuyplanItems);
  yield spawn(watchRequestGlobalMenu);
  yield spawn(watchRequestBuyplanId);
  yield spawn(watchRequestBuyplanIdForQfill);
  yield spawn(watchRequesWarehouse);
  yield spawn(watchRequesWarehouseForStockModel);
  yield spawn(watchRequestStockModelId);
  yield spawn(watchRequestStockModelCopy);
  yield spawn(watchRequestShortUrl);
  yield spawn(watchRequestShortUrls);
  yield spawn(watchRequestOverwriteShortUrl);
  yield spawn(watchRequestBulkSelectProducts);
  yield spawn(watchRequestBulkDeselectProducts);
  yield spawn(watchRequestBuyplanSalesPersonUpdate);
  yield spawn(watchRequestStockModelSalesPersonUpdate);
  yield spawn(watchRequestDeclineOrder);
  yield spawn(watchRequestProductReorder);
  yield spawn(watchRequestProductReorderList);
  yield spawn(watchRequestPayment);
  yield spawn(watchrequestAtsData);
}
