import { Map, fromJS, List } from "immutable";

import { productReorderListActions } from "actions/productReorderListActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  error: null,
  data: Map({})
});

export default (state = initialState, action) => {
  const { data } = action.payload || {};
  const payload = action.payload;

  switch (action.type) {
    case productReorderListActions.init:
      return state.set("isLoading", true);
    case productReorderListActions.success:
      let { pr } = payload.data;
      let newSuccessState = state.mergeDeep({
        isLoading: false,
        hasErrors: false,
        error: null,
        data: Map({
          pr: List([])
        })
      });

      return newSuccessState.setIn(["data", "pr"], List(pr));
    case productReorderListActions.failure:
      let newErrorState = state.mergeDeep({
        isLoading: false,
        hasErrors: true,
        error: data
      });

      return newErrorState.set("data", initialState.data);
    case productReorderListActions.update:
      let UpdatePr = payload.data;
      let newUpdateState = state.mergeDeep({
        isLoading: false,
        hasErrors: false,
        error: null,
        data: Map({
          pr: List([])
        })
      });
      return newUpdateState.setIn(["data", "pr"], List(UpdatePr));
    default:
      return state;
  }
};
