import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { isLoadingSelector } from "selectors/buyplanReportSelector.js";
import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";

import { getStockModalBuyplanReport } from "actions/buyplanReportActions.js";

import Loader from "components/Loader/Loader";
import OrderDetails from "components/BuyplanReport/OrderDetails.jsx";
import DivisionTotals from "components/BuyplanReport/DivisionTotals.jsx";
import CategoryTotals from "components/BuyplanReport/CategoryTotals.jsx";

const BuyplanReportContainer = () => {
  const dispatch = useDispatch();
  const stockModalId = useSelector(stockModelIdSelector);
  const isLoading = useSelector(isLoadingSelector);

  useEffect(() => {
    if ( stockModalId !== undefined ) {
      dispatch(
        getStockModalBuyplanReport(stockModalId)
      )
    }
  }, [dispatch, stockModalId]);

  if ( isLoading ) return <Loader />

  return (
    <div className="report-container">
      <OrderDetails />

      <DivisionTotals />

      <CategoryTotals />
    </div>
  );
};

export default React.memo(BuyplanReportContainer);
