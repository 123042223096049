import React from "react";
import styled from "styled-components";

import CheckboxWithLabel from "components/CheckboxWithLabel.jsx";

const FilterRow = ({
  segment,
  division,
  collection,
  category,
  filterValue,
  formattedValue,
  isChecked,
  onToggle,
  className
}) => {
  return (
    <CheckboxWithLabel
      id={`${segment}-${division}-${category}-${filterValue}`}
      isChecked={isChecked || false}
      onToggle={onToggle}
      labelText={formattedValue || filterValue}
      className={className}
      data-segment-id={segment}
      data-division-id={division}
      data-collection-id={collection}
      data-category-id={category}
      data-filter-id={filterValue}
    />
  );
};

const StyledFilterRow = styled(FilterRow)`
  margin: 0 0 1px;
  padding: 1px 6px 1px 43px;
  &:last-child {
    padding-bottom: 10px;
  }
`;

export default React.memo(StyledFilterRow);
