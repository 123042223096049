import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  isLoadingSelector as areProductsLoadingSelector,
  hasErrorsSelector as doesProductsHaveErrorsSelector,
  findByProductReleaseId,
  productsDataSelector
} from "selectors/productsSelector.js";

import {
  isLoadingSelector as isProductReleaseLoadingSelector,
  hasErrorsSelector as doesProductReleaseHaveErrorsSelector,
  errorSelector as productReleaseErrorSelector
} from "selectors/productReleaseSelector.js";

import { stockModelIdSelector } from "selectors/stockModelIdSelector.js";
import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import { buyplanInfoDataSelector } from "selectors/buyplanInfoSelector.js";

import {
  addBuyplanItem,
  removeBuyplanItem,
  addStockModalItem,
  removeStockModalItem
} from "actions/likeDislikeActions.js";

import LikeDislike from "components/ProductCard/Actions/LikeDislike/LikeDislike.jsx";

import { DYNAMIC } from "constants.js";

import i18next from "i18next";

const LikeDislikeContainer = ({
  productReleaseId,
  buyplanItemId,
  isDisabled = false,
  isModalContext = false
}) => {
  const dispatch = useDispatch();
  const stockModalId = useSelector(stockModelIdSelector);
  const buyplanId = useSelector(buyplanIdSelector);

  const productRelease = useSelector(findByProductReleaseId(productReleaseId));

  const areProductsLoading = useSelector(areProductsLoadingSelector);
  const doesProductsHaveErrors = useSelector(doesProductsHaveErrorsSelector);
  const productsData = useSelector(productsDataSelector);

  const isProductReleaseLoading = useSelector(isProductReleaseLoadingSelector);
  const doesProductReleaseHaveErrors = useSelector(
    doesProductReleaseHaveErrorsSelector
  );
  const productReleaseError = useSelector(productReleaseErrorSelector);

  const stockModalInfo = useSelector(buyplanInfoDataSelector);
  const isStockModalTemplate = stockModalInfo.get("state") === "template";
  const isDynamic = stockModalInfo.get("cnf_type") === DYNAMIC;

  let isLoading, hasErrors, errorMsg;
  if (isModalContext) {
    isLoading = isProductReleaseLoading;
    hasErrors = doesProductReleaseHaveErrors;
    errorMsg = productReleaseError;
  } else {
    isLoading = areProductsLoading;
    hasErrors = doesProductsHaveErrors;
    errorMsg = productsData.get("message");
  }

  const toggleLiked = () => {
    if (stockModalId) {
      const stockModalItemId = productRelease.stock_model_item.id;
      if (stockModalItemId) {
        if (!isStockModalTemplate && !isDynamic) {
          // eslint-disable-next-line no-alert
          if (!window.confirm(i18next.t("stockModalItem.remove.removeItemFromStaticCnf"))) {
            return null;
          }
        }
        dispatch(removeStockModalItem(stockModalId, stockModalItemId));
      } else {
        dispatch(addStockModalItem(stockModalId, productReleaseId));
      }
    } else {
      dispatch(
        buyplanItemId
          ? removeBuyplanItem(buyplanId, buyplanItemId)
          : addBuyplanItem(buyplanId, productReleaseId)
      );
    }
  };

  if (isLoading) {
    return null;
  } else if (hasErrors) {
    return <div className="error-msg">{errorMsg}</div>;
  } else {
    return (
      <LikeDislike
        liked={buyplanItemId}
        toggleLiked={toggleLiked}
        isDisabled={isDisabled}
      />
    );
  }
};

export default React.memo(LikeDislikeContainer);
