export const generateShortUrlActions = {
  startLoader: "START_GENERATE_SHORT_URL_LOADER",
  request: "REQUEST_GENERATE_SHORT_URL",
  success: "GENERATE_SHORT_URL_SUCCESS",
  failure: "GENERATE_SHORT_URL_FAILURE",
  reset: "RESET_GENERATE_SHORT_URL",
};

export const generateShortUrl = (payload) => {
  return {
    type: generateShortUrlActions.request,
    payload,
  };
};

export const generateShortUrlSuccess = (payload) => {
  return {
    type: generateShortUrlActions.success,
    payload,
  };
};

export const generateShortUrlFailure = (errorPayload) => {
  return {
    type: generateShortUrlActions.failure,
    payload: errorPayload,
  };
};
