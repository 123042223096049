import React from "react";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      // Error path
      return (
        <h2
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          Something went wrong! Try reloading the page.
        </h2>
      );
    }

    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
