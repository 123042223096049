import React from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import styled from "styled-components";

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const ScrollToTop = ({ className }) => {
  return (
    <FaArrowCircleUp className={`${className} scrollTop`} onClick={scrollTop} />
  );
};

const StyledScrollToTop = styled(ScrollToTop)`
  width: 1em;
  position: fixed;
  bottom: 6ex;
  font-size: 2em;
  right: 1.3em;
  cursor: pointer;
`;

export default React.memo(StyledScrollToTop);
