import React from "react";
import styled from "styled-components";

import Invoice from "components/Invoice/Invoice.jsx";

const InvoiceFrom = ({
  title,
  addrLine1,
  addrLine2,
  suburbTown,
  state,
  country,
  postalCode
}) => {
  return (
    <StyledInvoiceForm className="invoice-from">
      <Invoice
        title={title}
        addrLine1={addrLine1}
        addrLine2={addrLine2}
        suburbTown={suburbTown}
        state={state}
        country={country}
        postalCode={postalCode}
      />
    </StyledInvoiceForm>
  );
};

const StyledInvoiceForm = styled.div`
  padding-left: 20px;
`;

export default React.memo(InvoiceFrom);
