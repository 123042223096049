import { takeLatest, put } from "redux-saga/effects";

import { viewAtsActions } from "actions/viewAtsActions.js";
import { GET_DATA } from "actions/apiOperations.js";

export const requestAtsData = function*(action) {
  const {
    productId,
    ...params
  } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/products/${productId}/product_ats`,
    actions: viewAtsActions,
    params
  });
};

export const watchrequestAtsData = function*() {
  yield takeLatest(viewAtsActions.request, requestAtsData);
};

