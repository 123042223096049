import React from "react";

import Loader from "components/Loader/Loader.jsx";
import CustomModal from "components/CustomModal/index.jsx";
import InvalidQtyProductsDialogBody from "components/BuyplanReport/InvalidQtyProductsDialogBody.jsx";

const InvalidQtyProductsDialog = ({
  className,
  isLoading,
  hasErrors,
  invalidQtyProductsData,
  isOpen,
  closeModalCallback
}) => {
  if (isOpen && isLoading) return <Loader />;
  if (hasErrors)
    return <div className="error-msg">{invalidQtyProductsData.message}</div>;

  return (
    isOpen && (
      <CustomModal
        className={className}
        isOpen={isOpen}
        title={
          <strong>
            You have entered incorrect quantities. Please update the quantities
            for the following SKU's
          </strong>
        }
        body={
          <InvalidQtyProductsDialogBody
            invalidQtyProductsData={invalidQtyProductsData}
          />
        }
        closeModalCallback={closeModalCallback}
      />
    )
  );
};

export default React.memo(InvalidQtyProductsDialog);
