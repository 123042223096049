import { takeLatest, put, select } from "redux-saga/effects";

import { fetchedDynamicFiltersSelector } from "selectors/filtersSelector.js";

import { productsActions } from "actions/productsActions.js";

import { POST_DATA } from "actions/apiOperations.js";

export const requestProducts = function*(action) {
  const { releaseId, ...params } = action.payload;
  const fetchedDynamicFilters = yield select(fetchedDynamicFiltersSelector);

  if (params.subcategory) {
    params.subcategory = fetchedDynamicFilters
      .getIn([params.category])
      .find(obj => obj.get("name") === params.subcategory);
  }

  yield put({
    type: POST_DATA,
    path: `/api/releases/${releaseId}/products.json`,
    actions: productsActions,
    params
  });
};

export const requestQuickFillProducts = function*(action) {
  const { brandId, ...params } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/brands/${brandId}/products.json`,
    actions: productsActions,
    params
  });
};

export const requestStockModalProducts = function*(action) {
  const { releaseId, stockModalId, ...params } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/releases/${releaseId}/stock_models/${stockModalId}/products.json`,
    actions: productsActions,
    params
  });
};

export const watchRequestProducts = function*() {
  yield takeLatest(productsActions.init, requestProducts);
};

export const watchRequestQuickFillProducts = function*() {
  yield takeLatest(
    productsActions.requestQuickFillProducts,
    requestQuickFillProducts
  );
};

export const watchRequestStockModalProducts = function*() {
  yield takeLatest(
    productsActions.requestStockModalProducts,
    requestStockModalProducts
  );
};
