export const orderDetailsActions = {
  startLoader: "START_ORDER_DETAILS_LOADER",
  request: "REQUEST_ORDER_DETAILS",
  requestStockModalOrder: "REQUEST_STOCK_MODAL_ORDER_DETAILS",
  success: "ORDER_DETAILS_SUCCESS",
  failure: "ORDER_DETAILS_FAILURE",
  setSelectedShippingAddress: "SET_SELECTED_SHIPPING_ADDRESS",
  toggleMinOrderConfirmationAck: "TOGGLE_MIN_ORDER_CONFIRMATION_ACK",
  setSalesPersonInOrderDetails: "SET_SALES_PERSON_IN_ORDER_DETAILS",
  reset: "ORDER_DETAILS_RESET"
};

export const getOrderDetails = (buyplanId) => {
  return {
    type: orderDetailsActions.request,
    payload: {
      buyplanId,
    },
  };
};

export const getStockModalOrderDetails = (stockModalId) => {
  return {
    type: orderDetailsActions.requestStockModalOrder,
    payload: {
      stockModalId,
    },
  };
};

export const getOrderDetailsSuccess = (payload) => {
  return {
    type: orderDetailsActions.success,
    payload,
  };
};

export const getOrderDetailsFailure = (errorPayload) => {
  return {
    type: orderDetailsActions.failure,
    payload: errorPayload,
  };
};

export const setSelectedShippingAddrId = (selectedShippingAddrId) => {
  return {
    type: orderDetailsActions.setSelectedShippingAddress,
    payload: { selectedShippingAddrId },
  };
};

export const toggleMinOrderConfirmationAck = (isAcknowledged) => {
  return {
    type: orderDetailsActions.toggleMinOrderConfirmationAck,
    payload: {
      isAcknowledged,
    },
  };
};

export const setSalesPersonInOrderDetails = payload => {
  return {
    type: orderDetailsActions.setSalesPersonInOrderDetails,
    payload
  }
}

export const resetOrderDetails = () => {
  return {
    type: orderDetailsActions.reset
  };
};