import React from "react";
import styled from "styled-components";

const FilterHeaderText = ({ className, text }) => (
  <div className={className}>{text}</div>
);

const StyledFilterHeaderText = styled(FilterHeaderText)`
  text-align: left;
  color: #222f3e;
  font-size: 13px;
  font-weight: 400;

  :hover {
    color: #3b90dd;
  }
`;

export default React.memo(StyledFilterHeaderText);
