import { fromJS } from "immutable";

import { buyplanReportActions } from "actions/buyplanReportActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    invoice: {
      is_discounted: null,
      original_price_formatted: null,
      total_price_formatted: null,
      total_quantity: null,
      totals_header: null,
      category_discount_formatted: null,
      total_price_with_category_discount_formatted: null,
      order_discount_formatted: null,
      order_discount_percent: null,
      category_rules: [
        {
          name: null,
          original_price_formatted: null,
          discount_percent: null,
          discounted_amount_formatted: null,
          discounted_price_formatted: null
        }
      ]
    },
    totals_header: null,
    months_totals: {},
    divisions_total: {},
    categories_total: {}
  }
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case buyplanReportActions.startLoader:
      return state.set("isLoading", true);
    case buyplanReportActions.success:
      return state
        .merge({
          hasErrors: false,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case buyplanReportActions.failure:
      return state
        .merge({
          hasErrors: true,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    default:
      return state;
  }
};
