export const currentUserActions = {
  startLoader: "START_CURRENT_USER_LOADER",
  request: "REQUEST_CURRENT_USER",
  success: "CURRENT_USER_SUCESS",
  failure: "CURRENT_USER_FAILURE",
  reset: "RESET_CURRENT_USER",
};

export const getCurrentUser = () => {
  return {
    type: currentUserActions.request,
  };
};

export const getCurrentUserSuccess = (payload) => {
  return {
    type: currentUserActions.success,
    payload,
  };
};

export const getCurrentUserFailure = (errorPayload) => {
  return {
    type: currentUserActions.failure,
    payload: errorPayload,
  };
};
