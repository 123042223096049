import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
import "components/ProductCard/Actions/Resources/Resources.css";

import CustomModal from "components/CustomModal/index.jsx";

import { findByProductReleaseId } from "selectors/productsSelector.js";

const videoRenderer = (item) => {
  return (
    <StyledVideoRenderer className="video-wrapper">
      <iframe src={item.embedUrl} title="video player" frameBorder="0" allowFullScreen></iframe>
    </StyledVideoRenderer>
  );
};

const Resources = ({ productReleaseId }) => {
  const [isPopoverOpen, setPopoverState] = useState(false);
  const togglePopover = () => setPopoverState(!isPopoverOpen);

  const productRelease = useSelector(findByProductReleaseId(productReleaseId));
  const releaseResources = productRelease && productRelease.release_resources;

  const [isImageGalleryOpen, setImageGalleryState] = useState(false);
  const [resourceIndex, setResourceIndex] = useState(0);

  let resourceImages = [];

  releaseResources &&
    releaseResources.map(obj => {
      if (!obj.remote_url.endsWith(".pdf")) {
        if (obj["video?"]) {
          resourceImages.push({
            original: obj.file_name,
            thumbnail: obj.file_name,
            embedUrl: obj.file_name,
            renderItem: videoRenderer
          });
        } else {
          resourceImages.push({
            original: obj.remote_url,
            thumbnail: obj.remote_url
          });
        }
      }

      return null;
    });

  const closeModalCallback = () => {
    setImageGalleryState(false);
  };

  const handleResourceImageLink = evt => {
    const resourceUrl = evt.target.href;
    const resourceIndex = resourceImages.findIndex(elm => elm.original === resourceUrl);

    if (resourceIndex >= 0) {
      evt.preventDefault();

      setPopoverState(false);
      setResourceIndex(resourceIndex);
      setImageGalleryState(true);
    }
  };

  return releaseResources && releaseResources.length > 0 ? (
    <div className={`resources`}>
      <>
        <StyledThreeBars id={`resource-icon-${productReleaseId}`} />

        <Popover
          trigger="legacy"
          placement="auto"
          target={`resource-icon-${productReleaseId}`}
          isOpen={isPopoverOpen}
          toggle={togglePopover}
        >
          <PopoverHeader>Resources</PopoverHeader>

          <PopoverBody>
            <StyledReleaseResources>
              {releaseResources.map(resource => {
                const fileName = resource["video?"] ? resource.file_name : resource.remote_url;

                return (
                  <li key={resource.file_name || resource.remote_url}>
                    <a
                      href={fileName}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleResourceImageLink}
                    >
                      {resource.title}
                    </a>
                  </li>
                );
              })}
            </StyledReleaseResources>
          </PopoverBody>
        </Popover>

        {isImageGalleryOpen && (
          <CustomModal
            isOpen={isImageGalleryOpen}
            modalSize="xl"
            body={
              <ImageGallery
                items={resourceImages}
                lazyLoad={true}
                showIndex={true}
                showThumbnails={false}
                showPlayButton={false}
                showFullscreenButton={false}
                startIndex={resourceIndex}
              />
            }
            closeModalCallback={closeModalCallback}
          />
        )}
      </>
    </div>
  ) : null;
};

const StyledThreeBars = styled(GiHamburgerMenu)`
  color: #bdbdbd;
  height: 4.6ex;
  width: auto;
  margin-bottom: 1ex;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
`;

const StyledReleaseResources = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    font-size: 0.8rem;
    padding: 6px 15px;
  }

  a {
    color: #333333;
    text-decoration: none;
  }
`;

const StyledVideoRenderer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 46%;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default React.memo(Resources);
