import { call, cancelled, select, put, race, take } from "redux-saga/effects";
import jwt_decode from "jwt-decode";

import { addNotification } from "notificationStore.js";

import { redirectTo } from "apis/apiHelpers";

import { getAuthToken } from "selectors/appSelector.js";

import { getToken, authTokenActions } from "actions/authTokenActions.js";

export default function*(apiHelperFn, path, actions, params = {}) {
  const abortController = new AbortController();

  if (actions.startLoader) yield put({ type: actions.startLoader });

  try {
    let token = yield select(getAuthToken);

    if (token) {
      const decodedToken = jwt_decode(token);
      const expiresAtSeconds = decodedToken.exp;
      const expiresAt = new Date(0).setUTCSeconds(expiresAtSeconds);
      const currentTime = new Date();
      const hasTokenExpired = currentTime >= expiresAt;

      if (hasTokenExpired) token = null;
    }

    if (!token) {
      yield put(getToken());

      const { success } = yield race({
        success: take(authTokenActions.success),
        error: take(authTokenActions.failure)
      });

      if (success) token = yield select(getAuthToken);
    }

    let rawResponse = yield call(
      apiHelperFn,
      path,
      token,
      abortController.signal,
      { payload: params }
    );

    if (rawResponse.ok) {
      try {
        const apiResponse = yield call([rawResponse, rawResponse.json]);

        if ([401, 403].includes(apiResponse.status)) {
          yield call(addNotification, {
            type: "danger",
            message: apiResponse.errors[0]
          });

          yield call(redirectTo, "/showroom");
        }

        yield put({
          type: actions.success,
          payload: {
            data: apiResponse.data || apiResponse,
            override: params.override,
            metadata: params.metadata
          }
        });

        return {
          rawResponse,
          apiResponse
        };
      } catch (err) {
        yield put({
          type: actions.failure,
          payload: { data: err }
        });

        return {
          rawResponse,
          error: err
        };
      }
    } else {
      if ([401, 403].includes(rawResponse.status)) {
        yield call(redirectTo, "/showroom");
      }

      let errorPayload = null;
      let errorResponse = null;

      try {
        errorResponse = yield call([rawResponse, rawResponse.json]);
        errorPayload = errorResponse.error || errorResponse.errors[0];
      } catch {
        errorPayload = rawResponse.statusText;
      }

      yield call(addNotification, {
        type: "danger",
        message: errorPayload
      });

      yield put({
        type: actions.failure,
        payload: { data: errorResponse }
      });

      return {
        rawResponse,
        error: errorResponse
      };
    }
  } catch (err) {
    yield call(addNotification, {
      type: "danger",
      message: err.message,
      dismissOptions: {
        duration: 0
      }
    });

    return {
      error: err
    };
  } finally {
    if (yield cancelled()) {
      // eslint-disable-next-line no-console
      console.debug("Aborting API call for ", path);

      abortController.abort();
    }
  }
}
