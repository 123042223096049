import { fromJS } from "immutable";

import { stockModelIdActions } from "actions/stockModelIdActions.js"

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    id: null
  }
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case stockModelIdActions.startLoader:
      return state.set("isLoading", true);
    case stockModelIdActions.success:
      return state
        .merge({
          hasErrors: false,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case stockModelIdActions.update:
      let data = state.get("data")
      return state
        .merge({
          data: data.merge({ id: payload.stockModalId })
        });
    case stockModelIdActions.failure:
      return state
        .merge({
          hasErrors: true,
          isLoading: false
        })
    default:
      return state;
  }
};

