import { fromJS } from "immutable";

import { orderValidationsActions } from "actions/orderValidationsActions.js";

const initialState = fromJS({
  isLoading: false,
  hasErrors: false,
  data: {
    validatations: [
      {
        name: null,
        status: null
      }
    ],
    warnings: []
  }
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case orderValidationsActions.startLoader:
      return state.set("isLoading", true);
    case orderValidationsActions.success:
      return state
        .merge({
          hasErrors: false,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case orderValidationsActions.failure:
      return state
        .merge({
          hasErrors: true,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case orderValidationsActions.reset:
      return initialState;
    default:
      return state;
  }
};

export const haveValidationsChanged = validations => {
  if (
    validations.size === 0 ||
    validations === initialState.getIn(["data", "validatations"])
  )
    return false;

  return true;
};

export const haveWarningsChanged = warnings => {
  if (
    warnings.size === 0 ||
    warnings === initialState.getIn(["data", "warnings"])
  )
    return false;

  return true;
};
