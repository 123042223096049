import { Notyf } from "notyf";

const defaultOptions = {
  duration: 5000,
  ripple: false,
  position: { x: "right", y: "top" },
  dismissible: true,
  icon: false,
  types: [
    {
      type: "success",
      className: "notyf__toast--success",
      icon: false
    },
    {
      type: "error",
      className: "notyf__toast--error",
      icon: false
    },
    {
      type: "danger",
      className: "notyf__toast--danger",
      icon: false
    },
    {
      type: "warning",
      className: "notyf__toast--warning",
      icon: false
    },
    {
      type: "info",
      className: "notyf__toast--info",
      icon: false
    }
  ]
};

const notyf = new Notyf(defaultOptions);

export const addNotification = ({ type, message }) => {
  return notyf.open({
    type: type || "success",
    message
  });
};

export const removeNotification = notificationId => {
  return notyf.dismiss(notificationId);
};
