export const myNoteActions = {
  requestNoteUpdation: "UPDATE_MY_NOTE",
  success: "MY_NOTE_SUCCESS",
  failure: "MY_NOTE_FAILURE",
};

export const updateMyNote = (payload) => {
  return {
    type: myNoteActions.requestNoteUpdation,
    payload,
  };
};

export const getMyNoteSuccess = (payload) => {
  return {
    type: myNoteActions.success,
    payload,
  };
};

export const getMyNoteFailure = (errorPayload) => {
  return {
    type: myNoteActions.failure,
    payload: errorPayload,
  };
};
