import React from "react";
import { useSelector } from "react-redux";
import i18next from "i18next";
import { showHorizontalHeaderSelector } from "selectors/quantityGridSelector.js";

const TableHead = ({ headers, sizeMinimums, isMinOrderCheckApplicable }) => {
  const showHorizontalHeader = useSelector(showHorizontalHeaderSelector);
  return (
    <thead>
      <tr>
        <th />

        {headers.entrySeq().map(([index, key]) => {
          if (index === 0) {
            return (
              <th key={`attr-rows-th-${key}`} className="attribute_col">
                {key}
              </th>
            );
          } else
          {
            if (showHorizontalHeader) {
              return (
                <th
                  key={`attr-rows-th-${key}`}
                  className="text-center align-middle"
                >
                  {key === null ? null : (
                    <div>
                      <span>{key}</span>
                    </div>
                  )}
                </th>
              );
            } else {
              return (
                <th key={`attr-rows-th-${key}`} className="rotated_col">
                <div>
                  <span>
                    <b>{key}</b>
                  </span>
                </div>
              </th>
              );
            }
          }
        })}
        { isMinOrderCheckApplicable &&
          <th className="rotated_col table-last-rotated-col">
            <div>
              <span>
                <b>
                  { i18next.t("qtyGrid.minimums") }
                </b>
              </span>
            </div>
          </th> }
      </tr>
      { sizeMinimums && sizeMinimums.size > 0 &&
        <tr className="size_min_header">
          <td />
          {sizeMinimums.entrySeq().map(([index, sizeMin]) => {
            if (index === 0) {
              return (
                <th key={`attr-rows-th-${sizeMin}`}>
                  {sizeMin}
                </th>
              );
            } else {
              return (
                <td key={`attr-rows-th-${sizeMin}`}>
                  <div>
                    <span>
                      <b>min. {sizeMin}</b>
                    </span>
                  </div>
                </td>
              );
            }
           })
          }
        </tr>
      }
    </thead>
  );
};

export default React.memo(TableHead);
