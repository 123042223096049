import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import i18next from "i18next";
import { useParams, useHistory } from "react-router-dom";
import { getAtsData } from "actions/viewAtsActions.js";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import {
  productAtsDataSelector,
  productSizesDataSelector,
  productAttrSizesDataSelector,
  productNameSelector,
  productSkuSelector
} from "selectors/viewAtsSelector.js";
import AtsTable from "components/ProductAts/AtsTable.jsx";
import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";
import { PiTruck } from "react-icons/pi";
import { IconContext } from "react-icons";

const ViewAtsContainer = () => {
  const { productId, releaseId} = useParams();
  const history = useHistory();

  const closeModalCallback = useCallback(() => {
    return history.push(
      history.location.pathname.split(`/${productId}`)[0]
    );
  }, [history, productId]);

  const dispatch = useDispatch();

  const atsData = useSelector(productAtsDataSelector);
  const sizes = useSelector(productSizesDataSelector);
  const attrSizes = useSelector(productAttrSizesDataSelector);
  const pName = useSelector(productNameSelector);
  const pSku = useSelector(productSkuSelector);
  const buyplanId = useSelector(buyplanIdSelector);

  useEffect(() => {
    dispatch(
      getAtsData({
        productId: productId,
        buyplan_id: buyplanId,
        release_id: releaseId
      })
    );
  }, [productId]);

  return (
    <StyledModal isOpen={true} backdrop={true} toggle={closeModalCallback}>
      <ModalHeader
        toggle={closeModalCallback}
        className="h4 align-items-center"
      >
        <IconContext.Provider value={{ className: "truck-icon" }}>
          <PiTruck fill = "#000000" size = {28} />
        </IconContext.Provider>
        {pName}: {pSku}
      </ModalHeader>

      <ModalBody>
        { atsData && atsData.size > 0 ?
            <AtsTable
              atsData={atsData}
              sizes={sizes}
              attrSizes={attrSizes}
            />
            : <div>{ i18next.t("ats.unavailable") }</div>
        }
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  min-width: 50%;
  max-width: 70%;

  @media (max-width: 575.98px) {
    padding-top: 60px;
  }

  .modal-content {
    height: 60vh;
  }

  .modal-header {
    align-items: center;
  }

  button.close {
    font-size: 1.5em;
  }

  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;

    @media (max-width: 575.98px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .modal-body {
    padding-top: auto;
    padding-bottom: 0;
    max-height: 68vh;
    overflow: auto;
    align-content: center; !important;

    .carousel-block {
      max-width: 312px;
    }

    hr {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .truck-icon {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;


export default React.memo(ViewAtsContainer);
