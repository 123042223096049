import { createSelector } from "reselect";

export const bulkDeselectProductsReducerSelector = createSelector(
  state => state.get("bulkDeselectProductsReducer"),
  bulkDeselectProductsReducer => bulkDeselectProductsReducer
);

export const isLoadingSelector = createSelector(
  bulkDeselectProductsReducerSelector,
  bulkDeselectProductsReducer => bulkDeselectProductsReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  bulkDeselectProductsReducerSelector,
  bulkDeselectProductsReducer => bulkDeselectProductsReducer.get("hasErrors")
);

export const dataSelector = createSelector(
  bulkDeselectProductsReducerSelector,
  bulkDeselectProductsReducer => bulkDeselectProductsReducer.get("data")
);
