import { takeLatest, put, race, take, select } from "redux-saga/effects";

import { appliedFiltersSelector } from "selectors/filtersSelector.js";
import { warehouseIdSelector } from "selectors/warehouseSelector.js";
import { currentUserDataSelector } from "selectors/currentUserSelector";
import { buyplanStateSelector } from "selectors/buyplanIdSelector.js";
import { buyplanInfoDataSelector } from "selectors/buyplanInfoSelector.js";

import { getStockModalProducts } from "actions/productsActions.js";
import { buyplanItemActions } from "actions/likeDislikeActions.js";

import {
  getStockModalFilters,
  getFilters,
  getQuickFillFilters
} from "actions/filterActions.js";
import {
  getStockModalBuyplanDetails
} from "actions/buyplanDetailsActions.js";
import { getBuyplanSummary } from "actions/buyplanSummaryActions.js";

import { POST_DATA, DELETE_DATA } from "actions/apiOperations.js";

import { isAdminUser, isStateConsignmentRequest } from "utils.js";
import { BUYPLAN, SUMMARY, DYNAMIC } from "constants.js";
export const PER_PAGE = 30;

export const addBuyplanItemAsync = function* (action) {
  const { buyplanId, ...params } = action.payload;
  const warehouseId = yield select(warehouseIdSelector);
  const currentUser = yield select(currentUserDataSelector);
  const isNonAdminUser = !isAdminUser(currentUser.get("role"));
  const buyplanState = yield select(buyplanStateSelector);
  const isNotConsignmentRequest = !isStateConsignmentRequest(buyplanState);

  if (buyplanId && isNonAdminUser && isNotConsignmentRequest) {
    yield put({
      type: POST_DATA,
      path: `/api/buyplans/${buyplanId}/items.json`,
      actions: buyplanItemActions,
      params: { ...params, warehouse_id: warehouseId }
    });
  }
};

export const removeBuyplanItemAsync = function* (action) {
  const {
    buyplanId,
    buyplanItemId,
    releaseId,
    options,
    ...params
  } = action.payload;
  const currentUser = yield select(currentUserDataSelector);
  const isNonAdminUser = !isAdminUser(currentUser.get("role"));
  const buyplanState = yield select(buyplanStateSelector);
  const isNotConsignmentRequest = !isStateConsignmentRequest(buyplanState);

  if (isNonAdminUser && isNotConsignmentRequest) {
    yield put({
      type: DELETE_DATA,
      path: `/api/buyplans/${buyplanId}/items/${buyplanItemId}`,
      actions: buyplanItemActions,
      params
    });

    const { success } = yield race({
      success: take(buyplanItemActions.success),
      error: take(buyplanItemActions.failure)
    });

    if (success && success.payload.data.id === null) {
      if (options.refreshBuyplanDetails) {
        yield put(
          options.brandId
            ? getQuickFillFilters(options.brandId, buyplanId, BUYPLAN)
            : getFilters(releaseId, BUYPLAN, buyplanId)
        );
      }

      if (options.refreshBuyplanSummary) {
        yield put(getBuyplanSummary(buyplanId));
        yield put(getFilters(releaseId, SUMMARY, buyplanId));
      }
    }
  }
};

export const watchAddBuyplanItem = function* () {
  yield takeLatest(buyplanItemActions.addBuyplanItem, addBuyplanItemAsync);
};

export const watchRemoveBuyplanItem = function* () {
  yield takeLatest(
    buyplanItemActions.removeBuyplanItem,
    removeBuyplanItemAsync
  );
};

export const addStockModalItemAsync = function* (action) {
  const { stockModalId, ...params } = action.payload;
  const warehouseId = yield select(warehouseIdSelector);
  const currentUser = yield select(currentUserDataSelector);
  const isNonAdminUser = !isAdminUser(currentUser.get("role"));

  if (stockModalId && isNonAdminUser) {
    yield put({
      type: POST_DATA,
      path: `/api/stock_models/${stockModalId}/items.json`,
      actions: buyplanItemActions,
      params: { ...params, warehouse_id: warehouseId }
    });
  }
};

export const watchAddStockModalItem = function* () {
  yield takeLatest(
    buyplanItemActions.addStockModalItem,
    addStockModalItemAsync
  );
};

export const removeStockModalItemAsync = function* (action) {
  const {
    stockModalId,
    stockModalItemId,
    releaseId,
    options,
    ...params
  } = action.payload;
  const currentUser = yield select(currentUserDataSelector);
  const isNonAdminUser = !isAdminUser(currentUser.get("role"));

  if (isNonAdminUser) {
    yield put({
      type: DELETE_DATA,
      path: `/api/stock_models/${stockModalId}/items/${stockModalItemId}`,
      actions: buyplanItemActions,
      params
    });

    const { success } = yield race({
      success: take(buyplanItemActions.success),
      error: take(buyplanItemActions.failure)
    });

    if (success && success.payload.data.id === null) {
      if (options.refreshStockModalRangePage) {
        const stockModalInfo = yield select(buyplanInfoDataSelector);
        const isStockModalTemplate = stockModalInfo.get("state") === "template";
        const isDynamic = stockModalInfo.get("cnf_type") === DYNAMIC;

        if (!isStockModalTemplate && !isDynamic) {
          const appliedFilters = yield select(appliedFiltersSelector);
          yield put(
            getStockModalProducts(releaseId, stockModalId, PER_PAGE, 0, appliedFilters.toJS(), true)
          );
        }
      }
      if (options.refreshBuyplanDetails) {
        const appliedFilters = yield select(appliedFiltersSelector);
        yield put(
          getStockModalBuyplanDetails(stockModalId, appliedFilters.toJS())
        );
        yield put(getStockModalFilters(releaseId, stockModalId, BUYPLAN));
      }
    }
  }
};

export const watchRemoveStockModalItem = function* () {
  yield takeLatest(
    buyplanItemActions.removeStockModalItem,
    removeStockModalItemAsync
  );
};
