import { takeLatest, put, call, take, race } from "redux-saga/effects";

import { buyplanIdActions } from "actions/buyplanIdActions.js";

import { redirectTo } from "apis/apiHelpers";

import { GET_DATA } from "actions/apiOperations.js";

export const requestBuyplanId = function*(action) {
  const { releaseId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/releases/${releaseId}/find_or_create_buyplan`,
    actions: buyplanIdActions,
    params
  });

  // eslint-disable-next-line no-unused-vars
  const { success, error } = yield race({
    success: take(buyplanIdActions.success),
    error: take(buyplanIdActions.failure)
  });
  if (error) {
    yield call(redirectTo, `/buyplans`);
  }
};

export const watchRequestBuyplanId = function*() {
  yield takeLatest(buyplanIdActions.request, requestBuyplanId);
};

export const requestBuyplanIdForQfill = function*(action) {
  const { brandId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/brands/${brandId}/find_or_create_buyplan`,
    actions: buyplanIdActions,
    params
  });
};

export const watchRequestBuyplanIdForQfill = function*() {
  yield takeLatest(buyplanIdActions.requestForQfill, requestBuyplanIdForQfill);
};
