import { fromJS } from "immutable";

import { bulkSelectProductsActions } from "actions/bulkSelectProductsActions.js";

const initialState = fromJS({
  isLoading: false,
  hasErrors: false,
  data: {
    success: null,
    message: null,
    filtered_products_count: null,
    existing_buyplan_items_count: null,
    imported_products_count: null,
    failed_products_count: null
  }
});

export default (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case bulkSelectProductsActions.startLoader:
      return state.set("isLoading", true);
    case bulkSelectProductsActions.success:
      return state
        .merge({
          hasErrors: false,
          isLoading: false
        })
        .set("data", fromJS(payload.data));
    case bulkSelectProductsActions.failure:
      return state.merge({
        hasErrors: true,
        isLoading: false
      });
    case bulkSelectProductsActions.reset:
      return initialState;
    default:
      return state;
  }
};
