import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  getQuantityGrid,
  resetQuantityGrid
} from "actions/quantityGridActions.js";
import {
  requestQtyGrid as getStockModalQuantityGrid,
  resetQuantityGrid as resetStockModalQtyGrid
} from "actions/StockModal/qtyGridActions.js";

import {
  findByProductReleaseId,
  findByStockModalItemId
} from "selectors/productsSelector.js";

import { productReleaseDataSelector as productReleaseFromDetailsSelector } from "selectors/productReleaseSelector.js";

import {
  isLoadingSelector,
  productReleaseSelector,
  deliveryMonthsSelector,
  attributeSeqSelector,
  headersSelector,
  atsSelector,
  poDatesSelector,
  preOrderDeliveryMonthSelector,
  isPreOrderProductSelector,
  preOrderMessageSelector,
  sizeMinsSelector,
  bpsbToHighlightSelector
} from "selectors/quantityGridSelector.js";

import { buyplanIdSelector } from "selectors/buyplanIdSelector.js";

import { warehouseIdSelector } from "selectors/warehouseSelector";

import { CONSIGNMENT } from "constants.js";

import QuantityGrid from "components/QuantityGrid/index.jsx";

const QuantityGridContainer = ({ buyplanState }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stockModalItemId = queryParams.get("stockModalItemId");
  const params = useParams();
  const { stockModalId } = useParams();

  let productReleaseId, buyplanItemIdFromParams, buyplanItemId;

  if (stockModalId) {
    if (location.pathname.includes("quick-view")) {
      productReleaseId = params?.productReleaseId;
    } else {
      productReleaseId = queryParams.get("productReleaseId");
    }
  } else {
    productReleaseId = params.productReleaseId;
    buyplanItemIdFromParams = queryParams.get("buyplanItemId");
  }

  const buyplanId = useSelector(buyplanIdSelector);
  const warehouseId = useSelector(warehouseIdSelector);

  const productReleaseFromList = useSelector(
    productReleaseId
      ? findByProductReleaseId(productReleaseId)
      : findByStockModalItemId(stockModalItemId)
  );

  const productReleaseFromDetails = useSelector(
    productReleaseFromDetailsSelector
  );

  const productRelease =
    productReleaseFromList || productReleaseFromDetails?.toJS();
  if (
    buyplanItemIdFromParams &&
    buyplanItemIdFromParams !== "undefined" &&
    buyplanItemIdFromParams !== "null"
  ) {
    buyplanItemId = buyplanItemIdFromParams;
  } else if (productRelease && productRelease.buyplan_item) {
    buyplanItemId = productRelease.buyplan_item.id;
  }

  const history = useHistory();

  const ats = useSelector(atsSelector);
  const poDates = useSelector(poDatesSelector);

  const closeModalCallback = useCallback(() => {
    const path = history.location.pathname;
    if (path.includes("/stock-modal-items")) {
      return history.push(
        history.location.pathname.split("/stock-modal-items")[0]
      );
    } else if (buyplanState === CONSIGNMENT) {
      return history.push(
        history.location.pathname.split(`/${productReleaseId}`)[0] +
          "?type=consignment"
      );
    } else {
      return history.push(
        history.location.pathname.split(`/${productReleaseId}`)[0]
      );
    }
  }, [history, productReleaseId, buyplanState]);

  const isLoading = useSelector(isLoadingSelector);
  const qtyGridProductRelease = useSelector(productReleaseSelector);
  const deliveryMonths = useSelector(deliveryMonthsSelector);
  const attrsList = useSelector(attributeSeqSelector);
  const headers = useSelector(headersSelector);
  const sizeMinimums = useSelector(sizeMinsSelector);
  const pre_order_month_and_date = useSelector(preOrderDeliveryMonthSelector);
  const is_pre_order_product = useSelector(isPreOrderProductSelector);
  const pre_order_message = useSelector(preOrderMessageSelector);
  const bpsbToHighlight = useSelector(bpsbToHighlightSelector);

  useEffect(() => {
    const path = history.location.pathname;

    if (!path.includes("/stock-modal-items") && productReleaseId && buyplanId) {
      dispatch(
        getQuantityGrid({
          productReleaseId,
          buyplanId,
          buyplanItemId,
          warehouse_id: warehouseId
        })
      );

      return () => dispatch(resetQuantityGrid());
    }
  }, [
    dispatch,
    productReleaseId,
    buyplanId,
    buyplanItemId,
    warehouseId,
    history
  ]);

  useEffect(() => {
    const path = history.location.pathname;

    if (path.includes("/stock_models/")) {
      dispatch(
        getStockModalQuantityGrid({
          stockModalId,
          stockModalItemId,
          productReleaseId,
          warehouseId
        })
      );

      return () => dispatch(resetStockModalQtyGrid());
    }
  }, [
    dispatch,
    stockModalId,
    stockModalItemId,
    productReleaseId,
    history,
    warehouseId
  ]);

  return (
    <QuantityGrid
      isLoading={isLoading}
      title={productRelease && productRelease.product.name}
      deliveryMonths={deliveryMonths}
      filledMonths={productRelease && productRelease.buyplan_item.filled_months}
      attrsList={attrsList}
      headers={headers}
      sizeMinimums={sizeMinimums}
      intendedMonths={
        productRelease && productRelease.buyplan_item.intended_months
      }
      qtyGridProductRelease={qtyGridProductRelease}
      ats={ats}
      poDates={poDates}
      buyplanItemId={buyplanItemId}
      closeModalCallback={closeModalCallback}
      shouldFetchTotals={false}
      pre_order_month_and_date={[pre_order_month_and_date].filter(obj => obj)}
      is_pre_order_product={is_pre_order_product}
      pre_order_message={pre_order_message}
      preOrderIntendedMonths={productRelease && productRelease.buyplan_item.pre_order_intended_months}
      preOrderFilledMonths={productRelease && productRelease.buyplan_item.pre_order_filled_months}
      bpsbToHighlight={bpsbToHighlight}
    />
  );
};

export default React.memo(QuantityGridContainer);
