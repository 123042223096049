import React from "react";

import SubmitOrderBtn from "components/SubmitOrderBtn";

const BarcodeScanner = ({ isScanning, startBarcodeScanner, stopBarcodeScanner }) => {

  return (
    <div className="flex-basic align-items-end">
      <div className="text-center">
        {!isScanning && (
          <SubmitOrderBtn type="button" className="mr-4 my-auto" onClick={startBarcodeScanner}>
            Start camera scanner
          </SubmitOrderBtn>
        )}
      </div>

      <div className="text-center">
        {isScanning && (
          <SubmitOrderBtn type="button" className="mr-4 my-auto align-center" onClick={stopBarcodeScanner} bgColor={"#e51400"}>
            Stop camera scanner
          </SubmitOrderBtn>
        )}
      </div>
    </div>
  );
};

export default React.memo(BarcodeScanner);
