import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Range from "rc-slider/lib/Range";

import "rc-slider/dist/rc-slider.css";

import { fetchedCommonFiltersSelector } from "selectors/filtersSelector.js";

const PriceRangeFilter = ({
  minPrice = 0,
  maxPrice = 0,
  appliedMinPrice,
  appliedMaxPrice,
  onChange,
  className,
}) => {
  const [selectedMin, updateSelectedMin] = useState(appliedMinPrice || minPrice);
  const [selectedMax, updateSelectedMax] = useState(appliedMaxPrice || maxPrice);
  const fetchedFilters = useSelector(fetchedCommonFiltersSelector);

  const handleOnChange = ([newMin, newMax]) => {
    updateSelectedMin(newMin);
    updateSelectedMax(newMax);
  };

  return (
    <div className={`${className} rc-slider-wrap`}>
      <div className="slider-amount text-center">
        {fetchedFilters.get("currency_symbol")}
        {selectedMin} - {fetchedFilters.get("currency_symbol")}
        {selectedMax} {fetchedFilters.get("currency")}
      </div>

      <Range
        min={minPrice}
        max={maxPrice}
        defaultValue={[
          appliedMinPrice || minPrice,
          appliedMaxPrice || maxPrice,
        ]}
        allowCross={false}
        pushable={true}
        onChange={handleOnChange}
        onAfterChange={onChange}
      />
    </div>
  );
};

const StyledPriceRangeFilter = styled(PriceRangeFilter)`
  padding: 6px 14px;
  margin: 0 0 8px;

  .rc-slider-track {
    background-color: var(--bs-dark-navy);
  }

  .rc-slider-handle {
    border-color: var(--bs-dark-navy);

    &:active,
    &:hover,
    &:focus {
      border-color: var(--bs-dark-navy);
    }
  }
`;

export default React.memo(StyledPriceRangeFilter);
