import { fromJS } from "immutable";

import { buyplanInfoActions } from "actions/buyplanInfoActions.js";

const initialState = fromJS({
  isLoading: true,
  hasErrors: false,
  data: {
    id: null,
    name: null,
    state: null,
    created_by: null,
    assigned_to: null,
    retailer: null,
    cnf_type: null,
    is_buyplan_ownership_enforced: null,
    sales_person: null,
    sales_person_list: [],
    awaiting_approval: null,
    order_approval_rule_msg: null,
    show_pre_order_month: false,
    release_visible_to_retailers: true
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case buyplanInfoActions.startLoader:
      return state.set("isLoading", true);
    case buyplanInfoActions.reset:
      return initialState;
    case buyplanInfoActions.success:
      return state.merge({
        isLoading: false,
        hasErrors: false,
        data: fromJS(action.payload.data),
      });
    case buyplanInfoActions.failure:
      return state.merge({
        isLoading: false,
        hasErrors: true,
        data: fromJS(action.payload.data),
      });
    case buyplanInfoActions.setSalesPersonInBuyplanInfo:
      return state.setIn(["data", "sales_person"], fromJS(action.payload));
    default:
      return state;
  }
};
