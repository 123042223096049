import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { applyFilters } from "actions/filterActions.js";
import { applyCategoryFilter } from "actions/categoryFilterActions.js";

import { fetchedProductCategories } from "selectors/filtersSelector";
import { categoryFiltersReducerSelector } from "selectors/categoryFilterSelector";

import CategoryMenuList from "components/QuickFillCategoryFilter/CategoryMenuList.jsx";
import CategoryHeader from "components/QuickFillCategoryFilter/CategoryHeader.jsx";

const CategoryFilterContainer = () => {
  const dispatch = useDispatch();
  const productCategories = useSelector(fetchedProductCategories);
  const appliedCategories = useSelector(categoryFiltersReducerSelector);

  const onHomeClick = evt => {
    evt.preventDefault();

    dispatch(applyFilters({ category: "", level: 0 }));

    dispatch(
      applyCategoryFilter({
        level1: null,
        level2: null,
        level3: null
      })
    );
  };

  const onLevel1Click = evt => {
    evt.preventDefault();

    const category = evt.target.textContent;

    dispatch(applyFilters({ category: category, level: 1 }));

    dispatch(
      applyCategoryFilter({
        level1: category,
        level2: null,
        level3: null
      })
    );
  };

  const onLevel2Click = evt => {
    evt.preventDefault();

    const category = evt.target.text;
    const level1 = evt.target.dataset["level1"];

    dispatch(applyFilters({ category: category, level: 2 }));

    dispatch(
      applyCategoryFilter({
        level1: level1,
        level2: category,
        level3: null
      })
    );
  };

  const onLevel3Click = evt => {
    evt.preventDefault();

    const category = evt.target.text;
    const level1 = evt.target.dataset["level1"];
    const level2 = evt.target.dataset["level2"];

    dispatch(applyFilters({ category: category, level: 3 }));

    dispatch(
      applyCategoryFilter({
        level1: level1,
        level2: level2,
        level3: category
      })
    );
  };

  return (
    <div className="mb-3">
      <CategoryHeader
        appliedCategories={appliedCategories}
        onHomeClick={onHomeClick}
        onLevel1Click={onLevel1Click}
        onLevel2Click={onLevel2Click}
      />

      <CategoryMenuList
        productCategories={productCategories}
        onLevel1Click={onLevel1Click}
        onLevel2Click={onLevel2Click}
        onLevel3Click={onLevel3Click}
      />
    </div>
  );
};

export default React.memo(CategoryFilterContainer);
