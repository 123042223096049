import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  reportTotalsHeaderSelector,
  reportMonthsTotalSelector
} from "selectors/buyplanReportSelector.js";

import MonthReportTableHead from "components/BuyplanReport/MonthReportTableHead.jsx";
import MonthReportTableBody from "components/BuyplanReport/MonthReportTableBody.jsx";
import i18next from "i18next";

const MonthTotals = ({ className }) => {
  const totalsHeader = useSelector(reportTotalsHeaderSelector);
  const monthsTotals = useSelector(reportMonthsTotalSelector);

  return (
    <table className={`${className} table month_report`}>
      <thead>
        <tr>
          <MonthReportTableHead
            headerText={i18next.t("invoice.monthTotals")}
            shouldAlignCenter={false}
            colSpan={1}
          />

          <MonthReportTableHead
            headerText={i18next.t("invoice.itemTotals")}
            shouldAlignCenter={true}
            colSpan={1}
          />

          <MonthReportTableHead
            headerText={totalsHeader}
            shouldAlignCenter={true}
            colSpan={1}
          />
        </tr>
      </thead>

      <tbody>
        {monthsTotals &&
          monthsTotals.entrySeq().map(([monthName, monthObj]) => {
            const divisionRows = monthObj.get("division_rows");

            return (
              <React.Fragment key={monthName}>
                <MonthReportTableBody
                  name={monthName}
                  obj={monthObj}
                  hTag="h4"
                />

                {divisionRows.entrySeq().map(([divisionName, divisionObj]) => {
                  const categoryRows = divisionObj.get("category_rows");

                  return (
                    <React.Fragment key={divisionName}>
                      <MonthReportTableBody
                        name={divisionName}
                        obj={divisionObj}
                        hTag="h5"
                      />

                      {categoryRows
                        .entrySeq()
                        .map(([categoryName, categoryObj]) => {
                          return (
                            <MonthReportTableBody
                              key={categoryName}
                              name={categoryName}
                              obj={categoryObj}
                            />
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
      </tbody>
    </table>
  );
};

const StyledMonthTotals = styled(MonthTotals)`
  thead {
    th {
      padding: 0 10px;
      border-top: 0 none;
      border-bottom-width: 1px;

      h3 {
        color: var(--bs-dark-navy);
        font-size: 18px;
        line-height: 40px;
        font-weight: bold;
        margin: 10px 0;

        @media (max-width: 575.98px) {
          font-size: 14px;
          line-height: 18px;
          margin: 0 0 4px;
        }
      }

      &:last-child h3 {
        text-align: right;
      }
    }
  }

  tbody {
    tr {
      border: 1px solid #ddd;

      &:first-child {
        th {
          padding: 7px 10px;

          @media (max-width: 575.98px) {
            padding: 5px 20px;
          }
        }
      }
    }

    th {
      padding: 5px 20px;

      h4,
      h5,
      h6 {
        font-weight: bold;
        margin: 0;
      }

      h4 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        @media (max-width: 575.98px) {
          font-size: 13px;
          line-height: 17px;
        }
      }

      h5 {
        font-size: 13px;
        line-height: 20px;

        @media (max-width: 575.98px) {
          font-size: 12px;
          line-height: 16px;
        }
      }

      h6 {
        color: var(--bs-dark-black);
        font-size: 13px;
        line-height: 20px;

        @media (max-width: 575.98px) {
          font-size: 11px;
          line-height: 15px;
        }
      }

      &:last-child h4,
      &:last-child h5,
      &:last-child h6 {
        text-align: right;
      }
    }
  }
`;

export default React.memo(StyledMonthTotals);
