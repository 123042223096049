export const quantityGridActions = {
  startLoader: "START_QUANTITY_GRID_LOADER",
  request: "QUANTITY_GRID_REQUEST",
  success: "QUANTITY_GRID_SUCCESS",
  failure: "QUANTITY_GRID_FAILURE",
  requestUpdate: "REQUEST_UPDATE_QUANTITY_GRID",
  updateSuccess: "UPDATE_QUANTITY_GRID_SUCCESS",
  updateFailure: "UPDATE_QUANTITY_GRID_FAILURE",
  reset: "RESET_QUANTITY_GRID"
};

export const getQuantityGrid = payload => {
  return {
    type: quantityGridActions.request,
    payload
  };
};

export const updateQuantityGrid = payload => {
  return {
    type: quantityGridActions.requestUpdate,
    payload
  };
};

export const resetQuantityGrid = () => {
  return {
    type: quantityGridActions.reset
  };
};
