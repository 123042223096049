import { takeLatest, put } from "redux-saga/effects";

import { orderDetailsActions } from "actions/orderDetailsActions.js";

import { GET_DATA } from "actions/apiOperations.js";

export const requestOrderDetails = function*(action) {
  const { buyplanId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/buyplans/${buyplanId}/submit_order_info.json`,
    actions: orderDetailsActions,
    params
  });
};

export const watchRequestOrderDetails = function*() {
  yield takeLatest(orderDetailsActions.request, requestOrderDetails);
};

export const requestStockModalOrderDetails = function*(action) {
  const { stockModalId, ...params } = action.payload;

  yield put({
    type: GET_DATA,
    path: `/api/stock_models/${stockModalId}/submit_order_info.json`,
    actions: orderDetailsActions,
    params
  });
};

export const watchRequestStockModalOrderDetails = function*() {
  yield takeLatest(
    orderDetailsActions.requestStockModalOrder,
    requestStockModalOrderDetails
  );
};
