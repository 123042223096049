import { takeLatest, put, take, call, race } from "redux-saga/effects";

import { stockModelCopyActions } from "actions/stockModelCopyActions.js";

import { POST_DATA } from "actions/apiOperations.js";

import { redirectTo } from "apis/apiHelpers.js";

export const requestStockModelCopy = function*(action) {
  const { stockModalId, copyName } = action.payload;

  yield put({
    type: POST_DATA,
    path: `/api/stock_models/${stockModalId}/copy_as_template`,
    actions: stockModelCopyActions,
    params: {
      copy_name: copyName
    }
  });

  const { success } = yield race({
    success: take(stockModelCopyActions.success),
    failure: take(stockModelCopyActions.failure)
  });

  if (success) {
    yield call(redirectTo, "/count_and_fills/templates");
  }
};

export const watchRequestStockModelCopy = function*() {
  yield takeLatest(stockModelCopyActions.request, requestStockModelCopy);
};
