import React from "react";
import BarcodeQtyGridContainer from "containers/BarcodeQtyGridContainer.jsx";
import ScannerLogs from "components/ScannerLogs";

const ScanDetailsContainer = ({
  isManualMode
}) => {

  return (
    <div>
      {isManualMode && (
        <div className="d-flex justify-content-center" >
          <BarcodeQtyGridContainer />
        </div>
      )}
      {!isManualMode && (
        <div
          className="w-50 mx-auto mt-4 mb-4 scan-log-container overflow-auto"
          style={{ maxHeight: "85vh" }}
        >
          <ScannerLogs />
        </div>
      )}
    </div>
  );
};

export default React.memo(ScanDetailsContainer);
