import React from "react";
import CustomModal from "components/CustomModal";
import { Button } from "react-bootstrap";
import i18next from "i18next";

const OperationConfirmationDialog = ({
  isOpen,
  message,
  onCloseCallback,
  onAcceptCallback,
  onRejectCallback
}) => {
  return (
    isOpen && (
      <CustomModal
        dismissible={false}
        showCloseBtn={false}
        isOpen={isOpen}
        title={i18next.t("general.confirmation")}
        body={
          <div className="font-weight-normal" style={{ fontSize: "1rem" }}>
            {message}
          </div>
        }
        footer={
          <div>
            <Button className="mr-2" onClick={onAcceptCallback}>
              {i18next.t("general.yes")}
            </Button>
            <Button onClick={onRejectCallback}>{i18next.t("general.no")}</Button>
          </div>
        }
        closeModalCallback={() => onCloseCallback()}
      />
    )
  );
};

export default OperationConfirmationDialog;
