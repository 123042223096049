import { takeLatest, put, race, take } from "redux-saga/effects";

import { removeAllBuyplanItemsActions } from "actions/removeAllBuyplanItemsActions.js";

import { DELETE_DATA } from "actions/apiOperations.js";

export const removeAllBuyplanItems = function*(action) {
  const { buyplanId, ...params } = action.payload;

  yield put({
    type: DELETE_DATA,
    path: `/api/buyplans/${buyplanId}/remove_all_items.json`,
    actions: removeAllBuyplanItemsActions,
    params
  });

  const { success } = yield race({
    success: take(removeAllBuyplanItemsActions.success),
    error: take(removeAllBuyplanItemsActions.failure)
  });

  if (success) {
    window && window.location && window.location.reload();
  }
};

export const watchRemoveAllBuyplanItems = function*() {
  yield takeLatest(removeAllBuyplanItemsActions.request, removeAllBuyplanItems);
};
