export const buyplanTotalsActions = {
  startLoader: "START_BUYPLAN_TOTALS_LOADER",
  request: "REQUEST_BUYPLAN_TOTALS",
  requestStockModalTotals: "REQUEST_STOCK_MODAL_BUYPLAN_TOTALS",
  success: "BUYPLAN_TOTALS_SUCCESS",
  failure: "BUYPLAN_TOTALS_FAILURE"
};

export const getBuyplanTotals = (buyplanId) => {
  return {
    type: buyplanTotalsActions.request,
    payload: {
      buyplanId
    }
  };
};

export const getStockModalBuyplanTotals = (
  stockModalId,
  stockModelBuyPlanItemIds = []
) => {
  return {
    type: buyplanTotalsActions.requestStockModalTotals,
    payload: {
      stockModalId,
      item_ids: stockModelBuyPlanItemIds
    }
  };
};

export const getBuyplanTotalsSuccess = payload => {
  return {
    type: buyplanTotalsActions.success,
    payload
  };
};

export const getBuyplanTotalsFailure = errorPayload => {
  return {
    type: buyplanTotalsActions.failure,
    errorPayload
  };
};
