import { createSelector } from "reselect";

export const buyplanDetailsReducerSelector = createSelector(
  state => state.get("buyplanDetailsReducer"),
  buyplanDetailsReducer => buyplanDetailsReducer
);

export const isLoadingSelector = createSelector(
  buyplanDetailsReducerSelector,
  buyplanDetailsReducer => buyplanDetailsReducer.get("isLoading")
);

export const hasErrorsSelector = createSelector(
  buyplanDetailsReducerSelector,
  buyplanDetailsReducer => buyplanDetailsReducer.get("hasErrors")
);

export const buyplanDetailsDataSelector = createSelector(
  buyplanDetailsReducerSelector,
  buyplanDetailsReducer => buyplanDetailsReducer.get("data")
);

export const buyplanDetailsAbilitiesSelector = createSelector(
  buyplanDetailsDataSelector,
  buyplanDetailsReducer => buyplanDetailsReducer.get("abilities")
);
