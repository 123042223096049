import { takeLatest, put, race, take } from "redux-saga/effects";

import { currentUserActions } from "actions/currentUserActions.js";

import { GET_DATA } from "actions/apiOperations.js";
import { setDefaultLanguage } from "localisation/localisationWrapper";

export const requestCurrentUser = function*() {
  yield put({
    type: GET_DATA,
    path: `/api/current_user_info.json`,
    actions: currentUserActions
  });

  const { success } = yield race({
    success: take(currentUserActions.success),
    error: take(currentUserActions.failure)
  });
  
  if (success && success.payload.data.id) {
      setDefaultLanguage(success.payload.data.locale);
  }
};

export const watchRequestCurrentUser = function*() {
  yield takeLatest(currentUserActions.request, requestCurrentUser);
};
