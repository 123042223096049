export const productReorderActions = {
  init: "INITIATE_PRODUCT_REORDER_SAGA",
  request: "PRODUCT_REORDER_REQUEST",
  success: "PRODUCT_REORDER_SUCCESS",
  failure: "PRODUCT_REORDER_FAILURE"
};

export const getProductReorder = (payload) => {
  return {
    type: productReorderActions.init,
    payload,
  };
};

export const getProductReorderSuccess = (payload) => {
  return {
    type: productReorderActions.success,
    payload,
  };
};

export const getProductReorderFailure = (errorPayload) => {
  return {
    type: productReorderActions.failure,
    payload: errorPayload,
  };
};
