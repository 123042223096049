import React from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import H5 from "components/H5.js";
import Select from "react-select";
import i18next from "i18next";

export const getSalesPersonOptions = salesPersonList => {
  return salesPersonList.map(method => ({
    value: method.get("id"),
    label: method.get("name")
  }));
};

const SalesPersonSelect = ({
  id,
  name,
  salesPersonOptions,
  salesPerson,
  handleSalesPersonChange,
  sideBar = false
}) => {
  const salesPersonValue = {
    value: salesPerson.get("id") || "",
    label: salesPerson.get("name") || i18next.t("select.default")
  };
  if (sideBar) {
    return (
      <div>
        <H5>{i18next.t("sidebar.salesperson")}</H5>
        <hr />
        <Row>
          <Col sm={12}>
            <Select
              value={salesPersonValue}
              onChange={handleSalesPersonChange}
              options={salesPersonOptions}
              name={name || "sales_person_id"}
              id={id || "sales_person_id"}
              placeholder={i18next.t("select.default")}
              noOptionsMessage={() => i18next.t("select.noOptions")}
            />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <Row form>
        <Col>
          <FormGroup row>
            <Label for="sales_person_id" sm={4}>
              {i18next.t("sidebar.salesperson")}
            </Label>
            <Col sm={6}>
              <Select
                value={salesPersonValue}
                onChange={handleSalesPersonChange}
                options={salesPersonOptions}
                name={name || "sales_person_id"}
                id={id || "sales_person_id"}
                placeholder={i18next.t("select.default")}
                noOptionsMessage={() => i18next.t("select.noOptions")}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
};

export default React.memo(SalesPersonSelect);
