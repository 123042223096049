import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LineIcon from "react-lineicons";

const PrevNextBtn = ({
  prevProductReleaseId,
  nextProductReleaseId,
  prevQuickViewPath,
  nextQuickViewPath,
  className
}) => {
  return (
    <div className="ml-auto">
      {prevProductReleaseId && (
        <Link to={prevQuickViewPath} className={`btn btn-primary ${className}`}>
          <LineIcon name="arrow-left" />
          &nbsp; Previous
        </Link>
      )}
      {nextProductReleaseId && (
        <Link to={nextQuickViewPath} className={`btn btn-primary ${className}`}>
          Next &nbsp;
          <LineIcon name="arrow-right" />
        </Link>
      )}
    </div>
  );
};

const StyledPrevNextBtn = styled(PrevNextBtn)`
  background-color: #368ee0;
  color: #fff;
  text-shadow: none;
  filter: none;
  border-radius: 5px 5px 5px 5px;
  padding: 5px 9px;
  border: 0;
  box-shadow: none;
  display: inline-block;
  margin-bottom: 0;
  line-height: 20px;
  font-size: 14px;
  margin-left: 4px;
`;

export default React.memo(StyledPrevNextBtn);
