import { takeLatest, put, take, select, race } from "redux-saga/effects";

import { sizeBreakActions } from "actions/sizeBreakActions.js";
import {
  buyplanItemActions,
  addBuyplanItem,
  removeBuyplanItem
} from "actions/likeDislikeActions";
import { getBuyplanSummary } from "actions/buyplanSummaryActions.js";

import { findByProductReleaseId } from "selectors/productsSelector.js";
import { currentUserDataSelector } from "selectors/currentUserSelector";
import { buyplanStateSelector } from "selectors/buyplanIdSelector.js";

import { PATCH_DATA } from "actions/apiOperations.js";

import { isAdminUser, isStateConsignmentRequest } from "utils.js";

export const addSizeBreak = function*(action) {
  const {
    buyplanId,
    buyplanItemId,
    productReleaseId,
    options,
    ...params
  } = action.payload;
  const currentUser = yield select(currentUserDataSelector);
  const isNonAdminUser = !isAdminUser(currentUser.get("role"));
  const buyplanState = yield select(buyplanStateSelector);
  const isNotConsignmentRequest = !isStateConsignmentRequest(buyplanState);

  if (isNonAdminUser && isNotConsignmentRequest) {
    if (buyplanItemId) {
      yield put({
        type: PATCH_DATA,
        path: `/api/buyplans/${buyplanId}/items/${buyplanItemId}`,
        actions: sizeBreakActions,
        params
      });

      const { success, failure } = yield race({
        success: take(sizeBreakActions.success),
        failure: take(sizeBreakActions.failure)
      });

      if (success || failure) {
        if (options.refreshBuyplanSummary) {
          yield put(getBuyplanSummary(buyplanId));
        }
      }
    } else {
      yield put(addBuyplanItem(buyplanId, productReleaseId));

      const success = yield take(buyplanItemActions.success);

      if (success) {
        const productRelease = yield select(
          findByProductReleaseId(productReleaseId)
        );
        const newBuyplanItemId = productRelease.buyplan_item.id;

        yield put({
          type: PATCH_DATA,
          path: `/api/buyplans/${buyplanId}/items/${newBuyplanItemId}`,
          actions: sizeBreakActions,
          params
        });

        const { error } = yield race({
          error: take(sizeBreakActions.failure),
          success: take(sizeBreakActions.success)
        });

        if (error) {
          yield put(removeBuyplanItem(buyplanId, newBuyplanItemId));
        }
      }
    }
  }
};

export const watchAddSizeBreak = function*() {
  yield takeLatest(sizeBreakActions.addSizeBreak, addSizeBreak);
};

export const removeSizeBreak = function*(action) {
  const {
    buyplanId,
    buyplanItemId,
    options,
    ...params
  } = action.payload;
  const currentUser = yield select(currentUserDataSelector);
  const isNonAdminUser = !isAdminUser(currentUser.get("role"));
  const buyplanState = yield select(buyplanStateSelector);
  const isNotConsignmentRequest = !isStateConsignmentRequest(buyplanState);

  if (isNonAdminUser && isNotConsignmentRequest) {
    let removeBuyplanItemSuccess;

    yield put({
      type: PATCH_DATA,
      path: `/api/buyplans/${buyplanId}/items/${buyplanItemId}`,
      actions: sizeBreakActions,
      params
    });

    let response = yield race({
      success: take(sizeBreakActions.success),
      failure: take(sizeBreakActions.failure)
    });

    if (
      (removeBuyplanItemSuccess || response.success || response.failure) &&
      options.refreshBuyplanSummary
    ) {
      yield put(getBuyplanSummary(buyplanId));
    }
  }
};

export const watchRemoveSizeBreak = function*() {
  yield takeLatest(sizeBreakActions.removeSizeBreak, removeSizeBreak);
};
