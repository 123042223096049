import { fromJS } from "immutable";

import { groupByProductsActions } from "actions/groupByProductsActions.js";
import { groupByBannerActions } from "actions/groupByBannerActions.js";
import { filterActions } from "actions/filterActions.js";

import { CATALOGUE_ORDER, RANGE } from "constants.js";

import { humanize } from "utils.js";
import { productsActions } from "actions/productsActions";

const initialState = fromJS({
  isLoading: true,
  error: null,
  data: {
    selectedGroupByFilter: {
      label: humanize(CATALOGUE_ORDER),
      value: CATALOGUE_ORDER
    },
    manualOverride: false,
    prs: {}
  }
});

export default (state = initialState, action) => {
  let obj = {},
    key;
  switch (action.type) {
    case groupByProductsActions.reset:
    case groupByBannerActions.reset:
      return state.setIn(["data", "prs"], fromJS({}));
    case filterActions.setActiveTab:
      if (action.payload.activeTab !== RANGE.toLowerCase()) {
        return initialState;
      }

      return state;
    case groupByProductsActions.success:
      key = action.payload.data.subcategory.name;
      obj[key] = fromJS({
        pr: action.payload.data.pr,
        count: action.payload.data.count
      });
      return state
        .merge({
          isLoading: false,
          error: null
        })
        .mergeDeepIn(["data", "prs"], obj);
    case groupByProductsActions.alterSelectedGroupByFilter:
      return state
        .merge({ isLoading: true })
        .setIn(["data", "manualOverride"], true)
        .setIn(
          ["data", "selectedGroupByFilter"],
          fromJS(action.payload.selectedGroupByFilter)
        );
    case groupByBannerActions.success:
      const selectedFilter = state.getIn([
        "data",
        "selectedGroupByFilter",
        "value"
      ]);
      key = Object.keys(action.payload.data[selectedFilter])[0]; //generate subcategory key dynamically
      obj[key] = fromJS({
        banner_url: action.payload.data[selectedFilter][key]["banner_url"]
      });
      return state
        .merge({
          isLoading: false
        })
        .mergeDeepIn(["data", "prs"], obj);
    case groupByProductsActions.startLoader:
      return state
        .merge({ isLoading: true })
        .setIn(["data", "prs"], fromJS({}));
    case productsActions.success:
      const subcategory = action.payload.data.subcategory;

      return state.mergeIn(
        ["data", "prs", subcategory && subcategory.name, "pr"],
        fromJS(action.payload.data.pr)
      );
    case filterActions.success:
      if (!state.getIn(["data", "manualOverride"])) {
        const selectedStoryByFilter =
          action?.payload?.data?.applied_filters?.tell_story_by ||
          CATALOGUE_ORDER;

        return state.setIn(
          ["data", "selectedGroupByFilter"],
          fromJS({
            label: humanize(selectedStoryByFilter),
            value: selectedStoryByFilter
          })
        );
      } else {
        return state;
      }
    default:
      return state;
  }
};
